import React, { useState, useEffect } from "react";
import { Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PO_Activity_Drawer from "./PO_Activity_Drawer";
import SLMSidebar from "../../../SLMSidebar";
import { listPlannedActivity } from "../../../../action/costimizev2DashboardAction";
import MainHead from "../../../../costimize_v2/layout/MainHead";
import "../../../slm.css";
import { Icon } from "@iconify/react";
function SLM_POActivity(props) {
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [updatestatus, setUpdateStatus] = useState(true);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [oldrecords, setOldRecords] = useState();
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // fixed : "left"
    },
    {
      title: "SOW Status",
      dataIndex: "sow_status",
      key: "sow_status",
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
    },
    {
      title: "Project Phase",
      dataIndex: "project_phase",
      key: "project_phase",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
    },
    {
      title: "Pending Activity",
      dataIndex: "pending_activity",
      key: "pending_activity",
    },
    {
      title: "Next Target Date",
      dataIndex: "next_target_date",
      key: "next_target_date",
      // fixed : "right"
    },
    {
      title: "Accountability",
      dataIndex: "accountability",
      key: "accountability",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [showSpinner, setShowSpinner] = useState(true);
  const [tablerecords, setTableRecords] = useState(false);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const date = selectedTopdate;
    dispatch(listPlannedActivity({ companyName, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {
        const records = data.newfunctionquery18.map((datas) => {
          let formattedDate = new Date(datas.date);
          const options = {
            day: "numeric",
            month: "short",
          };
          let formattedDate_new = formattedDate.toLocaleDateString(
            "en-US",
            options
          );

          // let nextTargetDate = new Date(datas.next_target_date);
          // console.log("next target date type" , typeof nextTargetDate)
          // let nextTargetDate_new = nextTargetDate.toLocaleDateString("de-DE");
          // console.log("nextTargetDate_new", nextTargetDate_new);

          let nextTargetDate = new Date(datas.next_target_date);
          let date = nextTargetDate.getDate();
          let month = nextTargetDate.getMonth();
          let year = nextTargetDate.getFullYear();

          return {
            key: datas.activity_id,
            date: formattedDate_new,
            sow_status: datas.sow_status,
            project: datas.project,
            project_phase: datas.project_phase,
            priority: datas.priority,
            pending_activity: datas.pending_activity,
            next_target_date: date + "-" + month + "-" + year,
            accountability: datas.accountability,
            remarks: datas.remarks,
            action: (
              <Icon
                icon="material-symbols:edit"
                onClick={() => editPlannedActivity(datas)}
              />
            ),
            // <Icon icon="material-symbols:edit" />
            type: "data",
          };
        });
        setShowSpinner(false);
        setTableRecords(records);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [props, selectedTopdate]);

  const tableProps = {};

  const editPlannedActivity = (data) => {
    setOldRecords(data);
    setDrawerStatus(true);
    setDeleteStatus(true);
  };

  const handleAddClick = () => {
    setOldRecords({
      accountability: "",
      pending_activity: "",
      priority: "",
      project: "",
      project_phase: "",
      remarks: "",
      sow_status: "",
    });
    setDrawerStatus(true);
  };

  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  return (
    <>
      <PO_Activity_Drawer
        drawerstatus={drawerStatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        setUpdateStatus={setUpdateStatus}
        updatestatus={updatestatus}
        setDrawerStatus={setDrawerStatus}
        oldrecords={oldrecords}
      />

      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <SLMSidebar />
        <MainHead type="slm" title="Planned & Open Activity" />
        <div className="costimize-body-screen">
          {/* <div className="icons"></div> */}
          <div className="mt-3">
            <button
              className="table-add-button float-right"
              onClick={handleAddClick}
            >
              {/* <Icon icon="ion:add-circle-outline" /> */}
              <Icon icon="material-symbols:add-circle-outline" />
              Add
            </button>
          </div>
          <div>
            <Table
              {...tableProps}
              className="initial_response_timetable slm"
              columns={columns}
              style={{ overflowY: "auto" }}
              loading={{ indicator: <Spin />, spinning: showSpinner }}
              dataSource={tablerecords}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SLM_POActivity;
