import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import { Row, Col, Form, Input, Button } from "antd";
import { getServiceRequestData } from "../../../action/graphql/slm/SLM_reducers";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ServiceRequestTrendTable from "./ServiceRequestTrendTable";
import { Icon } from "@iconify/react";

const ServiceRequestChart = () => {
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [monthLabelList, setMonthLabelList] = useState([]);

  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  const { selectedTopdate } = useSelector((state) => state.constimize);
  // const [requestCount, setRequestCount] = useState({
  //   SR: [],
  //   CR: [],
  // });
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   getServiceRequestTableData();
  // }, []);

  // const companyName = "fivestar";
  // var month_list = [];

  // const getServiceRequestTableData = () => {
  //   month_list = [];
  //   let currentDate = new Date(selectedTopdate);
  //   let lastThreeMonthsDate = new Date(selectedTopdate);
  //   lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
  //   for (let i = 0; i < 3; i++) {
  //     let date = new Date(
  //       lastThreeMonthsDate.getFullYear(),
  //       lastThreeMonthsDate.getMonth() + i,
  //       1
  //     );
  //     month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
  //   }

  //   dispatch(
  //     getServiceRequestData({ companyName, selectedTopdate: month_list })
  //   )
  //     .unwrap()
  //     .then(({ data }) => {
  //       let graphData = data.newfunctionquery27;
  //       console.log({ graphData });
  //       setMonthLabelList([]);
  //       let labelArrayTemp = [];

  //       let requestCountTemp = {
  //         SR: [],
  //         CR: [],
  //       };
  //       month_list.forEach((month) => {
  //         let serviceReqCount = 0;
  //         let changeReqCount = 0;
  //         const checkMonth = moment(month, "YYYY-MM-DD").toDate();
  //         if (
  //           !monthLabelList.includes(
  //             moment(month, "YYYY-MM-DD").format("MMM YY")
  //           )
  //         ) {
  //           labelArrayTemp.push(moment(month, "YYYY-MM-DD").format("MMM YY"));
  //         }
  //         graphData.forEach((data) => {
  //           const dataMonth = moment(data.month, "YYYY-MM-DD").toDate();
  //           if (checkMonth.getMonth() === dataMonth.getMonth()) {
  //             if (data.request.toLowerCase() === "sr") {
  //               serviceReqCount++;
  //             } else if (data.request.toLowerCase() === "cr") {
  //               changeReqCount++;
  //             }
  //           }
  //         });
  //         requestCountTemp.SR.push(serviceReqCount);
  //         requestCountTemp.CR.push(changeReqCount);
  //       });
  //       setRequestCount(requestCountTemp);
  //       setMonthLabelList(labelArrayTemp);
  //     });
  // };

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
        return val;
      },
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: undefined,
      },

      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    // dataLabels: {
    //   enabled: false
    // },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      categories: monthLabelList,
    },
    fill: {
      opacity: 1,
      colors: ["#32A7A0"],
    },
    title: {
      text: `Service Request Trend`,
      align: "center",
      margin: 0,
      offsetX: 0,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#595959",
      },
    },
  };

  const series = [
    {
      name: "No of SR Recevied",
      data: ["1", "2", "3", "4"],
    },
  ];
  const handleIconClick = () => {
    setDrawerStatus(true);
  };

  return (
    <>
      <DrawerPlat
        drawerstatus={drawerStatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        title="Service Request"
      >
        <ServiceRequestTrendTable />
        {/* <Form layout="vertical">
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="request" label="Request">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="completed_on_time" label="Completed on Time">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="cancelled" label="Cancelled">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="failed" label="Failed">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="post_completion" label="Post Completion">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form> */}
      </DrawerPlat>
      <div className="incident-trends">
        <Icon
          icon="material-symbols:edit-note-sharp"
          onClick={handleIconClick}
          //onClick={() =>  setDrawerStatus(true)}
          //   style={{ float: "right" }}
        />
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={260}
        />
      </div>
    </>
  );
};

export default ServiceRequestChart;
