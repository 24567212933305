import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";

function TicketInformationTable({ camsAllDetailsData }) {
  // useEffect(()=>{
  //   console.log("inside");
  //   console.log({camsAllDetailsData});

  //   camsAllDetailsData.forEach(data => {
  //     console.log({data});
  //   })
  // },[camsAllDetailsData])
  const columns = [
    {
      dataField: "alert_type",
      text: "Alert Type",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
        borderLeftRadius: "5px",
      },
      sort: true,
    },
    {
      dataField: "from_address",
      text: "From Address",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "server_name",
      text: "Server Name",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "severity",
      text: `Severity Level`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "subject",
      text: `Subject`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "to_address",
      text: `To Address`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "date",
      text: `Date`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "time",
      text: `Time`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
  ];

  const data = [
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
  ];

  return (
    <div>
      <div className="alert-detail-table">
        <BootstrapTable
          keyField="id"
          data={camsAllDetailsData}
          columns={columns}
          bordered={false}
          noDataIndication="No Records"
        />
      </div>
    </div>
  );
}

export default TicketInformationTable;
