import React from "react";

function CarouselComponent(props) {
  return (
    <div>
      {" "}
      <div className="row">
        {props.isImageLeft ? (
          <>
            {" "}
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 ">
              <div
                className={
                  props.isSecondDiv
                    ? "carousel-2-height"
                    : "carousel-left-image"
                }
              >
                <img src={props.carouselImage} alt="dashboard" />
              </div>
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 d-flex justify-content-center align-items-center">
              <div className="carousel-text">
                <p>
                  “Unlock the potential of cost savings with &nbsp;{" "}
                  <span className="highlight">CloudStudio - Costimize</span> ,
                  your all-in-one multi-cloud cost management platform. Achieve
                  at least 28% in cost savings and optimize your cloud spending
                  today!”
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 d-flex justify-content-center align-items-center">
              <div className="carousel-text">
                <p>
                  "Transform your cloud platform with &nbsp;
                  <span className="highlight">CloudStudio - SysOps</span> ,
                  delivering transparency, visibility, and optimized efficiency
                  to empower your cloud operations."
                </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 ">
              <div
                className={
                  props.isSecondDiv
                    ? "carousel-2-height"
                    : "carousel-left-image"
                }
              >
                <img src={props.carouselImage} alt="dashboard" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CarouselComponent;
