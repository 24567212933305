import { memo, useState, useCallback, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Dropdown } from "antd";
import { Icon } from "@iconify/react";
import SpendVSBudgetPopup from "./SpendVSBudgetPopup";
import { useSelector } from "react-redux";

const SpendVSBudget = (props) => {
  const [open, setOpen] = useState(false);
  const [monthlist, setMonthList] = useState(0);
  const [chartdetails, setChartDetails] = useState({
    label: [],
    spendvalue: [],
    budgetvalue: [0, 0, 0],
    monthlist: [],
  });
  const { selectedTopdate } = useSelector((state) => state.constimize);

  useEffect(() => {
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month_list = [];
    var chart_new_format = [];
    var month_lable_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(
        `${monthNamesShort[date.getMonth()]}-${("" + date.getFullYear()).substr(
          2
        )}`
      );
      chart_new_format.push(props.spend[i]);
      month_lable_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    setChartDetails({
      label: month_list,
      spendvalue: chart_new_format,
      budgetvalue: props.budget,
      monthlist: month_lable_list,
    });
  }, [props]);

  useEffect(() => {
    props.setDayChartStatus(false);
  }, [selectedTopdate]);

  // const changeMonth = useCallback(
  //   (month) => {
  //     console.log("in");
  //     setOpen(true);
  //     setMonthList(month);
  //   },
  //   [open]
  // );

  const changeMonth = (month) => {
    // console.log("in");
    setOpen(true);
    setMonthList(month);
  };

  const items = [
    {
      label: <a onClick={() => changeMonth(6)}>6 Months</a>,
      key: "1",
    },
    {
      label: <a onClick={() => changeMonth(12)}>12 Months</a>,
      key: "2",
    },
  ];

  const series = [
    {
      name: "Budget",
      type: "column",
      data: chartdetails.budgetvalue,
    },
    {
      name: "Spend",
      type: "line",
      data: chartdetails.spendvalue,
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      events: {
        dataPointSelection: function (event, chartContext, config) {
          props.setSpendDate(chartdetails.monthlist[config.dataPointIndex]);
          props.setDayChartStatus(true);
        },
      },
    },
    stroke: {
      width: [0, 4],
      curve: "straight",
    },
    tooltip: {
      enabled: true,
      intersect: false,
      shared: true,
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    labels: chartdetails.label,
    // colors: ["#fcbb29", "#82ccdc"],
    // colors: ["#4cc0c0", "#fe1a67"],
    colors: ["#e37d05", "#11104e"],
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    markers: {
      size: 4,
      hover: {
        size: 7,
      },
    },
    yaxis: [
      {
        title: {
          text: "Budget",
          style: {
            fontSize: "12px",
            fontWeight: 500,
          },
        },
        labels: {
          formatter: (num) => {
            if (num === 0) {
              return "0";
            } else if (num === Infinity) {
              return "0";
            } else {
              return Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            }
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "Spend",
          style: {
            fontSize: "12px",
            fontWeight: 500,
          },
        },
        labels: {
          formatter: (num) => {
            if (num === 0) {
              return "0";
            } else if (num === Infinity) {
              return "0";
            } else {
              return Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            }
          },
        },
      },
    ],
  };

  return (
    <div style={{ position: "relative" }}>
      {open && (
        <SpendVSBudgetPopup
          open={open}
          setOpen={setOpen}
          monthlist={monthlist}
          setMonthList={setMonthList}
        />
      )}

      <h1 className="spend-chart-title">Budget vs Spend</h1>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={210}
      />
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        placement="bottomRight"
      >
        <a onClick={(e) => e.preventDefault()} className="chart-filter">
          <Icon icon="material-symbols:filter-alt-outline-sharp" />
        </a>
      </Dropdown>
    </div>
  );
};

export default memo(SpendVSBudget);
