import React, { memo, useEffect, useState } from "react";
import { Dropdown, Space } from "antd";
import { Icon } from "@iconify/react";
import FilterDropdownList from "./FilterDropdownList";
import { theme, message } from "antd";
import { getTopMenuList } from "../../action/costimizeV2Active";
import FilterDropdownDate from "./FilterDropdownDate";
import { useDispatch, useSelector } from "react-redux";
import { dropDownResult } from "../../custom_hook/CustomHook";
import {
  environmentListMethod,
  cloudListMethod,
  accountListMethod,
  applicationListMethod,
  selectedEnvironmentMethod,
  selectedTopDateMethod,
  selectedAccountMethod,
  selectedCloudMethod,
  selectedApplicationMethod,
  selectedServicesMethod,
  selectedOSMethod,
  selectedInfraMethod,
  selectedDbMethod,
  setServiceList,
  setInfraRList,
  setOsList,
  setDbList,
  setFilterButtonStatus,
} from "../../slice/costimizeSlice";
const { useToken } = theme;

const CostimizeFilter = () => {
  const { token } = useToken();
  const current = new Date();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const {
    applicationList,
    environmentList,
    accountList,
    cloudList,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    serviceList,
    infraList,
    osList,
    dbList,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    selectedTopdate,
    filterbuttonstatus,
  } = useSelector((state) => state.constimize);
  //state
  // const [selectedstaticdate, setSelectedStaticDate] = useState(
  //   `${current.getFullYear()}-${current.getMonth() + 1}-01`
  // );
  // const [selectedstaticdate, setSelectedStaticDate] = useState(selectedTopdate);
  const [recordslist, setRecordsList] = useState([]);
  const [environmentlist, setEnvironmentList] = useState([]);
  const [applicationlist, setApplicationList] = useState([]);
  const [accountlist, setAccountList] = useState([]);
  const [cloudlist, setCloudList] = useState([]);
  const [serviceslist, setServicesList] = useState([]);
  const [oslist, setOSList] = useState([]);
  const [infralist, setInfraList] = useState([]);
  const [dblist, setdbList] = useState([]);

  const [selectapplication, setSelectApplication] =
    useState(selectedApplication);
  const [selectenvironment, setSelectEnvironment] =
    useState(selectedEnvironment);
  const [selectaccount, setSelectAccount] = useState(selectedAccount);
  const [selectcloud, setSelectCloud] = useState(selectedCloud);
  const [selectedservicessta, setSelectedServices] = useState(selectedservices);
  const [selectedossta, setSelectedOS] = useState(selectedos);
  const [selectedinfrasta, setSelectedInfra] = useState(selectedinfra);
  const [selecteddbsta, setSelectedDB] = useState(selecteddb);
  const [datachanges, setDataChanges] = useState(true);
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };
  useEffect(() => {
    const companyName = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    // console.log(selectedTopdate);
    // console.log({ companyName, selectedTopdate });
    const cloud_list = selectedCloud.toString();
    dispatch(getTopMenuList({ companyName, selectedTopdate, cloud_list }))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        setRecordsList(data.newfunctionquery1);
        if (data.newfunctionquery1.length === 0) {
          messageApi.info("Records not get ready yet.");
        }
        //environment
        const environmentConfigs = data.newfunctionquery1.map(
          (v) => v.environment
        );
        const environmentarr = new Set([...environmentConfigs]);
        const environshort = [...environmentarr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        dispatch(environmentListMethod([...environshort]));
        setEnvironmentList([...environshort]);

        //cloud
        const cloudConfigs = data.newfunctionquery1.map((v) => v.cloud);
        // console.log(cloudConfigs)
        const cloudarr = new Set([...cloudConfigs]);
        const cloudshort = [...cloudarr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        dispatch(cloudListMethod([...cloudshort]));
        setCloudList([...cloudshort]);

        //account
        const accountConfigs = data.newfunctionquery1.map(
          (v) => v.account_name
        );
        const accountarr = new Set([...accountConfigs]);
        const accountshort = [...accountarr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        dispatch(accountListMethod([...accountshort]));
        setAccountList([...accountshort]);

        //application
        const applicationConfigs = data.newfunctionquery1.map(
          (v) => v.application
        );
        const applicationarr = new Set([...applicationConfigs]);
        const applicationshort = [...applicationarr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        dispatch(applicationListMethod([...applicationshort]));
        setApplicationList([...applicationshort]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [selectedTopdate, selectedCloud]);
  useEffect(() => {
    var newdropdownlist = recordslist;
    const filteredlist = recordslist.filter((datas) => {
      //dropdown condition
      var checkenvironment = dropDownResult(
        selectedEnvironment,
        datas.environment
      );
      var checkcloud = dropDownResult(selectedCloud, datas.cloud);
      var checkapplication = dropDownResult(
        selectedApplication,
        datas.application
      );
      var checkaccount = dropDownResult(selectedAccount, datas.account_name);

      const conditionloop = [
        checkapplication,
        checkenvironment,
        checkcloud,
        checkaccount,
      ];
      const eval_value = conditionloop.filter(function (element) {
        return element !== undefined;
      });
      var returnvalue;
      if (eval_value.length == 1) {
        returnvalue = eval(eval_value[0]);
      } else if (eval_value.length == 2) {
        returnvalue = eval(eval_value[0], eval_value[1]);
      } else if (eval_value.length == 3) {
        returnvalue = eval(eval_value[0], eval_value[1], eval_value[2]);
      } else if (eval_value.length == 4) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3]
        );
      } else if (eval_value.length == 5) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3],
          eval_value[4]
        );
      } else if (eval_value.length == 6) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3],
          eval_value[4],
          eval_value[5]
        );
      } else if (eval_value.length == 7) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3],
          eval_value[4],
          eval_value[5],
          eval_value[6]
        );
      } else if (eval_value.length == 8) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3],
          eval_value[4],
          eval_value[5],
          eval_value[6],
          eval_value[7]
        );
      }
      var returncondition;
      if (returnvalue === 1) {
        returncondition = true;
      } else if (returnvalue === true) {
        returncondition = true;
      } else {
        returncondition = false;
      }
      return returncondition;
    });
    if (filteredlist.length > 0) {
      newdropdownlist = filteredlist;
    }
    //service
    const serviceConfigs = newdropdownlist.map((v) => v.service);
    const servicearr = new Set([...serviceConfigs]);
    const serviceshort = [...servicearr].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );
    setServicesList([...serviceshort]);
    dispatch(setServiceList([...serviceshort]));

    //db
    const dbConfigsfilter = newdropdownlist.filter((datas) => {
      return datas.db !== null && datas.db !== "";
    });
    const dbConfigs = dbConfigsfilter.map((v) => v.db);
    const dbarr = new Set([...dbConfigs]);
    const dbshort = [...dbarr].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );
    setdbList([...dbshort]);
    dispatch(setDbList([...dbshort]));

    //os
    const osConfigsfilter = newdropdownlist.filter((datas) => {
      return datas.os !== null && datas.os !== "";
    });
    const osConfigs = osConfigsfilter.map((v) => v.os);
    const osarr = new Set([...osConfigs]);
    const osshort = [...osarr].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );
    setOSList([...osshort]);
    dispatch(setOsList([...osshort]));

    //infra
    const infraConfigs = newdropdownlist.map((v) => v.infra);
    const infraarr = new Set([...infraConfigs]);
    const infrashort = [...infraarr].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );
    setInfraList([...infrashort]);
    dispatch(setInfraRList([...infrashort]));
  }, [
    recordslist,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
  ]);
  //drop down
  useEffect(() => {
    dispatch(selectedEnvironmentMethod(selectenvironment));
    dispatch(selectedAccountMethod(selectaccount));
    dispatch(selectedCloudMethod(selectcloud));
    dispatch(selectedApplicationMethod(selectapplication));
    dispatch(selectedServicesMethod(selectedservicessta));
    dispatch(selectedOSMethod(selectedossta));
    dispatch(selectedInfraMethod(selectedinfrasta));
    dispatch(selectedDbMethod(selecteddbsta));
  }, [datachanges]);

  const handleChange = (type, value, checked) => {
    if (type === "Environments") {
      if (value === "all") {
        setSelectEnvironment(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectenvironment.indexOf("all") !== -1) {
            setSelectEnvironment([
              ...selectenvironment.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectEnvironment([...selectenvironment, value]);
          }
        } else {
          const result = selectenvironment.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectEnvironment(result_value);
        }
      }
    }
    if (type === "Cloud") {
      if (value === "all") {
        setSelectCloud(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectcloud.indexOf("all") !== -1) {
            setSelectCloud([...selectcloud.filter((x) => x !== "all"), value]);
          } else {
            setSelectCloud([...selectcloud, value]);
          }
        } else {
          const result = selectcloud.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectCloud(result_value);
        }
      }
    }
    if (type === "Accounts") {
      if (value === "all") {
        setSelectAccount(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectaccount.indexOf("all") !== -1) {
            setSelectAccount([
              ...selectaccount.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectAccount([...selectaccount, value]);
          }
        } else {
          const result = selectaccount.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectAccount(result_value);
        }
      }
    }
    if (type === "Applications") {
      if (value === "all") {
        setSelectApplication(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectapplication.indexOf("all") !== -1) {
            setSelectApplication([
              ...selectapplication.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectApplication([...selectapplication, value]);
          }
        } else {
          const result = selectapplication.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectApplication(result_value);
        }
      }
    }
    if (type === "Services") {
      if (value === "all") {
        setSelectedServices(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedservicessta.indexOf("all") !== -1) {
            setSelectedServices([
              ...selectedservicessta.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectedServices([...selectedservicessta, value]);
          }
        } else {
          const result = selectedservicessta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedServices(result_value);
        }
      }
    }
    if (type === "DB") {
      if (value === "all") {
        setSelectedDB(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedossta.indexOf("all") !== -1) {
            setSelectedDB([...selectedossta.filter((x) => x !== "all"), value]);
          } else {
            setSelectedDB([...selectedossta, value]);
          }
        } else {
          const result = selectedossta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedDB(result_value);
        }
      }
    }
    if (type === "Infra") {
      if (value === "all") {
        setSelectedInfra(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedinfrasta.indexOf("all") !== -1) {
            setSelectedInfra([
              ...selectedinfrasta.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectedInfra([...selectedinfrasta, value]);
          }
        } else {
          const result = selectedinfrasta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedInfra(result_value);
        }
      }
    }
    if (type === "OS") {
      if (value === "all") {
        setSelectedOS(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedossta.indexOf("all") !== -1) {
            setSelectedOS([...selectedossta.filter((x) => x !== "all"), value]);
          } else {
            setSelectedOS([...selectedossta, value]);
          }
        } else {
          const result = selectedossta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedOS(result_value);
        }
      }
    }
    setDataChanges(!datachanges);
  };

  const clearAllDate = () => {
    dispatch(setFilterButtonStatus(false));
    setSelectEnvironment(["all"]);
    setSelectAccount(["all"]);
    setSelectCloud(["all"]);
    setSelectApplication(["all"]);

    var currentdate = new Date();
    const month_2_current = new Date(
      currentdate.getFullYear(),
      currentdate.getMonth(),
      currentdate.getDate()
    );
    const month_2_current_new = `${month_2_current.getFullYear()}-${
      month_2_current.getMonth() + 1
    }-01`;
    // console.log(month_2_current_new);

    dispatch(selectedTopDateMethod(month_2_current_new));
    dispatch(selectedEnvironmentMethod(["all"]));
    dispatch(selectedAccountMethod(["all"]));
    dispatch(selectedCloudMethod(["all"]));
    dispatch(selectedApplicationMethod(["all"]));
    setDataChanges(!datachanges);
    // console.log("clear");
  };

  return (
    <>
      {contextHolder}
      <Dropdown
        trigger={["click"]}
        menuPosition="fixed"
        overlayClassName="plat-header-filter"
        placement="bottomRight"
        dropdownRender={(menu) => (
          <div className="filter-dropdown-render">
            <div
              style={{
                paddingTop: 5,
                paddingRight: 8,
                paddingBottom: 8,
                paddingLeft: 8,
              }}
              className="filter-dropdown-scroll"
            >
              <div className="filter-header">
                <p className="site-color filter-header-left">Filters</p>
                <p
                  className="site-color filter-header-right"
                  onClick={clearAllDate}
                >
                  Clear all
                </p>
              </div>
              <div className="filter-sub-header">
                <p className="site-color filter-sub-title">Global Filters</p>
              </div>
              <div>
                <FilterDropdownList
                  title="All Business Units"
                  list={[]}
                  selectedvalue={["all"]}
                  type="Business Units"
                />
              </div>
              <div className="mt-2">
                <FilterDropdownList
                  title="All Region"
                  list={[]}
                  selectedvalue={["all"]}
                  type="Region"
                />
              </div>
              <div className="mt-2">
                <FilterDropdownList
                  title="All Environments"
                  list={environmentlist}
                  statelist={setEnvironmentList}
                  reduxlist={environmentList}
                  selectedvalue={selectedEnvironment}
                  type="Environments"
                  handleChange={handleChange}
                />
              </div>
              <div className="mt-2">
                <FilterDropdownList
                  title="All Cloud"
                  list={cloudlist}
                  statelist={setCloudList}
                  reduxlist={cloudList}
                  type="Cloud"
                  selectedvalue={selectedCloud}
                  handleChange={handleChange}
                />
              </div>
              <div className="mt-2">
                <FilterDropdownList
                  title="All Accounts"
                  list={accountlist}
                  statelist={setAccountList}
                  reduxlist={accountList}
                  type="Accounts"
                  selectedvalue={selectedAccount}
                  handleChange={handleChange}
                />
              </div>
              <div className="mt-2">
                <FilterDropdownList
                  title="All Applications"
                  list={applicationlist}
                  statelist={setApplicationList}
                  reduxlist={applicationList}
                  type="Applications"
                  selectedvalue={selectedApplication}
                  handleChange={handleChange}
                />
              </div>
              <div className="mt-2">
                <FilterDropdownList
                  title="All Projects"
                  list={[]}
                  selectedvalue={["all"]}
                  type="Projects"
                />
              </div>

              <div className="mt-2">
                <FilterDropdownDate />
              </div>

              <div className="filter-sub-header mt-4">
                <p className="site-color filter-sub-title">Context Filters</p>
              </div>
              <div className="mt-2">
                <FilterDropdownList
                  title="All Services"
                  list={serviceslist}
                  statelist={setServicesList}
                  reduxlist={serviceList}
                  type="Services"
                  selectedvalue={selectedservices}
                  handleChange={handleChange}
                />
              </div>
              <div className="mt-2">
                <FilterDropdownList
                  title="All DB"
                  list={dblist}
                  statelist={setdbList}
                  reduxlist={dbList}
                  type="DB"
                  selectedvalue={selecteddb}
                  handleChange={handleChange}
                />
              </div>
              <div className="mt-2">
                <FilterDropdownList
                  title="All OS"
                  list={oslist}
                  statelist={setOSList}
                  reduxlist={osList}
                  type="OS"
                  selectedvalue={selectedos}
                  handleChange={handleChange}
                />
              </div>
              <div className="mt-2 mb-4">
                <FilterDropdownList
                  title="All Infra"
                  list={infralist}
                  statelist={setInfraList}
                  reduxlist={infraList}
                  type="Infra"
                  selectedvalue={selectedinfra}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
        )}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={
            "header-filter-btn " +
            (filterbuttonstatus && "header-filter-btn-active")
          }
        >
          <Space>
            <Icon icon="material-symbols:filter-alt-outline-sharp" />
            Filter
          </Space>
        </a>
      </Dropdown>
    </>
  );
};

export default memo(CostimizeFilter);
