import { memo, useEffect, useState } from "react";
import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { Table, Typography, Tooltip, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSpendDetailedView } from "../../action/costimizeV2Active";
import { numberFormat } from "../../custom_hook/CustomHook";

const SpendDetailsView = (props) => {
  //   console.log(props);
  const tableProps = {};
  const [tablerecords, setTableRecords] = useState([]);
  const [columns, setColumns] = useState([]);
  const [totalspend, setTotalSpend] = useState(0);
  const [preloader, setPreLoader] = useState(true);
  const changeDrawerStatus = () => {
    props.setSpendDetailsStatus(false);
  };
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);

  useEffect(() => {
    setPreLoader(true);
    const selectedTopdate_new = selectedTopdate;
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
    };

    dispatch(
      getSpendDetailedView({ companyName, selectedTopdate_new, apivalue })
    )
      .unwrap()
      .then(({ data }) => {
        // console.log("data");
        const spendtotal = data.newfunctionquery32.reduce(
          (a, v) => (a = +a + +v.spend),
          0
        );
        setTotalSpend(spendtotal);
        setTableRecords(data.newfunctionquery32);
        setPreLoader(false);
      })
      .catch((err) => {
        setPreLoader(false);
        console.log(err.message);
      });

    setColumns([
      {
        title: "Month",
        dataIndex: "reportmonth",
        key: "reportmonth",
        sorter: (a, b) => {
          return a.reportmonth > b.reportmonth ? -1 : 1;
        },
        render: (record) => {
          return record;
        },
      },
      {
        title: "Type",
        dataIndex: "lineitem_type",
        key: "lineitem_type",
        sorter: (a, b) => {
          return a.lineitem_type > b.lineitem_type ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 15) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 15)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },

      {
        title: "Account",
        dataIndex: "accountname",
        key: "accountname",
        sorter: (a, b) => {
          return a.accountname > b.accountname ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 15) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 15)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Spend",
        dataIndex: "spend",
        key: "spend",
        fixed: "left",
        sorter: (a, b) => {
          return a.spend > b.spend ? -1 : 1;
        },
        render: (record, index) => {
          return `$${Math.round(record)}`;
        },
      },
    ]);
  }, [
    props,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    selecteddb,
    selectedinfra,
    selectedos,
  ]);
  return (
    <>
      <DrawerPlat
        drawerstatus={props.spenddetailsstatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={false}
        title="Spend Detailed View"
      >
        <Spin spinning={preloader} wrapperClassName="costimize-loader">
          <p className="note-content">
            <b>Note</b> : The value comprises of usage, refunds, purchases, and
            rounding adjustments, but excludes tax.
          </p>
          <h1 className="drawer-spend-invoice">
            Invoice for the month : ${numberFormat(totalspend)}
          </h1>
          <Table
            {...tableProps}
            className="costimize-bootstrap-table"
            columns={columns}
            dataSource={tablerecords}
            pagination={true}
          />
        </Spin>
      </DrawerPlat>
    </>
  );
};

export default memo(SpendDetailsView);
