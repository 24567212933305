import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
const ProductionWindowCard = (props) => {
  var countfilteredWin1 = props.countsos.filter(function (element) {
    return (
      element.environment == "production" &&
      element.os == "windows" &&
      element.patch_status == "compliance"
    );
  }).length;
  var countfilteredWin2 = props.countsos.filter(function (element) {
    return (
      element.environment == "production" &&
      element.os == "windows" &&
      element.patch_status == "non-compliance"
    );
  }).length;
  const series = [countfilteredWin1, countfilteredWin2];
  const options = {
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    colors: ["#84AEE7", "#FFEAA0"],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val);
      },
    },
    title: {
      show: true,
      text: "Windows",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: true,
            position: "bottom",
          },
        },
      },
    ],

    // plotOptions: {
    //   pie: {
    //     donut: {
    //       labels: {
    //         show: true,
    //         name: {
    //           show: true,
    //         },
    //         value: {
    //           show: true,
    //         },
    //         total: {
    //           show: true,
    //           showAlways: true,
    //           label: "Security Score",
    //           formatter: function (val) {
    //             return 87 + "%";
    //           },
    //         },
    //       },
    //     },
    //   },
    // },
  };

  return (
    <>
      {/* <Card>
        <> */}
      <div id="chart">
        <ReactApexChart options={options} series={series} type="donut" />
      </div>
      {/* </>
      </Card> */}
    </>
  );
};

export default ProductionWindowCard;
