import React, { useState } from "react";
import { Card, Row, Col } from "antd";
import ProductionWindowCard from "./ProductionWindowChart";
import ProductionLinuxChart from "./ProductionLinuxChart";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import PatchManagementtable from "./PatchManagementtable";
import { useEffect } from "react";
import { Icon } from "@iconify/react";

const ProductionCard = (props) => {
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [productionFilter, setProductionFilter] = useState(false);
  const [nonProductionFilter, setNonProductionFilter] = useState(false);

  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  const handleIconClick = () => {
    setDrawerStatus(true);
    setProductionFilter(true);
    setNonProductionFilter(false);
  };

  return (
    <>
      <h6> Production </h6>
      <Card>
        <>
          <DrawerPlat
            drawerstatus={drawerStatus}
            changeDrawerStatus={changeDrawerStatus}
            deletestatus={deletestatus}
            title="Patch Management"
          >
            <div>
              {" "}
              <PatchManagementtable
                refreshData={props.refreshData}
                productionFilter={productionFilter}
                nonProductionFilter={nonProductionFilter}
              />
            </div>
          </DrawerPlat>
          <div id="chart">
            <Icon
              icon="material-symbols:edit-note-sharp"
              onClick={handleIconClick}
              //   style={{ float: "right" }}
            />

            <Row>
              <Col xxl={12} xl={12} lg={12} md={24} xs={24}>
                <ProductionWindowCard countsos={props.countsos} />
              </Col>
              <Col xxl={12} xl={12} lg={12} md={24} xs={24}>
                <ProductionLinuxChart countsos={props.countsos} />
              </Col>
            </Row>
          </div>
          <div className="text-center">
            <span className="">
              <span className="compliance-dot"></span>Compliance
            </span>
            &nbsp;
            <span>
              {" "}
              <span className="non-compliance-dot"></span>Non Compliance
            </span>
          </div>
        </>
      </Card>
    </>
  );
};

export default ProductionCard;
