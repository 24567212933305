import React, { memo, useEffect, useState } from "react";
import { Col, Row, Card } from "antd";
import MainHead from "../../costimize_v2/layout/MainHead";
import CostimizeSidebar from "../../costimize_v2/layout/CostimizeSidebar";
import BudgetPlanningSemiDonutChart from "./budgetPlanningSemiDonutChart";
import BudgetPlanningAreaChart from "./budgetPlanningAreaChart";
import "./budgetPlanning.css";
import { Tabs } from "antd";
import PlannedVsActualTable from "./PlannedVsActualTable";
import ApplicationBudgetTable from "./ApplicationBudgetTable";
import AnnualBudgetTable from "./AnnualBudgetTable";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectedSpend,
  getSpendDetails,
  listAnnualBudget,
  listApplicationBudget,
} from "../../action/costimizeV2Active";
import {
  numberFormat,
  checkRolePermission,
} from "../../custom_hook/CustomHook";
import YearDetailsScreen from "../../dashboard-v2/BudgetPlanning/YearDetailsScreen";
import YearBudgetDetailsScreen from "../../dashboard-v2/BudgetPlanning/YearBudgetDetailsScreen";

const BudgetPlanning = () => {
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [permission, setPermission] = useState({
    budgetwrite: checkRolePermission("budget_planning")
      ? checkRolePermission("budget_planning").write
      : 0,
  });
  const {
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedinfra,
    selecteddb,
    selectedservices,
    selectedos,
    selectedTopdate,
  } = useSelector((state) => state.constimize);
  const [addrecordsstatus, addRecordsStatus] = useState(false);
  const [projected, setProjected] = useState(0);
  const [yeardrawerstatus, yearsetDrawerStatus] = useState(false);
  const [yeardrawerdeletestatus, yearsetDrawerDeleteStatus] = useState(false);
  const [yearbudgetdrawerstatus, setYearBudgetDrawerStatus] = useState(false);
  const [monthallocated, setMonthAllocated] = useState(0);
  const [budgetyearrecords, setBudgetYearRecords] = useState();
  const [budgetChartHeight, setBudgetChartHeight] = useState(350);

  const [semichartvalue, setSemiChartValue] = useState(0);
  const [chartcal, setChartCal] = useState({
    spend: 0,
    budget: 0,
    balance: 0,
  });
  const [actualtablerecords, setActualTableRecords] = useState();
  const [annualplannedlist, setAnnualPlannedList] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const dispatch = useDispatch();

  const [yearlistrecords, setYearListRecords] = useState([]);
  const [budgetyearupdate, setBudgetYearUpdate] = useState(false);
  const [applicationbudgettable, setApplicationBudgetTable] = useState([]);
  const [budgetapplicationupdate, setBudgetApplicationUpdate] = useState(false);
  const [budgetapplicationdata, setBudgetApplicationData] = useState(false);
  const [rolestatus, seRoleStatus] = useState(true);

  //loader

  useEffect(() => {
    //role
    const role = JSON.parse(
      localStorage.getItem("CognitoIdentityServiceProvider.role")
    );
    if (role) {
      if (role[0]["budget_planning"] === "Read") {
        seRoleStatus(false);
      }
    }

    var projected_date = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-01`;
    var project_value = 0;
    var allocated_buget = 0;
    var month_spend = 0;
    var chartlength;
    var chartcurrentdate = new Date(selectedTopdate);
    if (selectedTopdate.split(",").length == 3) {
      chartlength = 3;
    } else if (selectedTopdate.split(",").length == 6) {
      chartlength = 6;
    } else if (selectedTopdate.split(",").length == 12) {
      chartlength = 12;
    } else {
      chartlength = 1;
      chartcurrentdate = new Date(selectedTopdate);
    }

    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
    };
    //projected
    dispatch(getProjectedSpend({ companyName, projected_date, apivalue }))
      .unwrap()
      .then(({ data }) => {
        if (data.newfunctionquery8) {
          setProjected(Number(data.newfunctionquery8[0].projected_spend));
          project_value = Number(data.newfunctionquery8[0].projected_spend);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    //actual spend
    var temp_chartdata = [];
    var temp_chartmonth = [];

    var actual_table_records = [];
    var current_year = new Date().getFullYear();
    for (let index = 0; index < 12; index++) {
      const month_2_current = new Date(
        chartcurrentdate.getFullYear(),
        chartcurrentdate.getMonth() - index,
        1
      );
      const selectedTopdate_new = `${current_year}-${+index + +1}-01`;
      actual_table_records.push(selectedTopdate_new);
    }
    var selectedTopdate_new = actual_table_records.toString();

    dispatch(getSpendDetails({ companyName, selectedTopdate_new, apivalue }))
      .unwrap()
      .then(({ data }) => {
        setActualTableRecords(data.newfunctionquery3);
      })
      .catch((err) => {
        console.log(err.message);
      });

    for (let index = 0; index < chartlength; index++) {
      const month_2_current = new Date(
        chartcurrentdate.getFullYear(),
        chartcurrentdate.getMonth() - index,
        1
      );
      const selectedTopdate_new = `${month_2_current.getFullYear()}-${
        month_2_current.getMonth() + 1
      }-01`;
      temp_chartmonth.push(selectedTopdate_new);
    }
    var selectedTopdate_new = temp_chartmonth.toString();
    // console.log({ companyName, selectedTopdate_new, apivalue });

    dispatch(getSpendDetails({ companyName, selectedTopdate_new, apivalue }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        //chart
        if (data.newfunctionquery3.length > 0) {
          month_spend = data.newfunctionquery3[0].spend;
        }
        var total_budget_value;
        if (monthallocated === 0) {
          total_budget_value = project_value;
        } else {
          total_budget_value = monthallocated;
        }
        setChartCal({
          spend: Number(month_spend),
          budget: Number(total_budget_value),
          balance: Number(total_budget_value - month_spend),
        });
        //chart
        var chart_percentage;
        console.log("checking");
        console.log(month_spend);
        console.log(Math.round((month_spend * 100) / 10));
        if (
          Number.isInteger(Math.round((month_spend * 100) / total_budget_value))
        ) {
          chart_percentage = Math.round(
            (month_spend * 100) / total_budget_value
          );
        } else {
          chart_percentage = 0;
        }
        setSemiChartValue(chart_percentage);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
      });
  }, [monthallocated, selectedTopdate]);

  useEffect(() => {
    //annual budget

    const cloud = selectedCloud.toString();
    dispatch(listAnnualBudget({ companyName, cloud }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        setYearListRecords(data.list_annual_budget);
        data.list_annual_budget.map((data) => {
          if (Number(data.budget_year) == new Date().getFullYear()) {
            var allocated_buget = 0;

            setAnnualPlannedList([
              data.budget_jan,
              data.budget_feb,
              data.budget_mar,
              data.budget_apr,
              data.budget_may,
              data.budget_jun,
              data.budget_jul,
              data.budget_aug,
              data.budget_sept,
              data.budget_oct,
              data.budget_nov,
              data.budget_dec,
            ]);

            //condition
            const current_month = new Date().getMonth();
            if (current_month === 1) {
              allocated_buget = data.budget_jan;
            } else if (current_month === 2) {
              allocated_buget = data.budget_feb;
            } else if (current_month === 3) {
              allocated_buget = data.budget_mar;
            } else if (current_month === 4) {
              allocated_buget = data.budget_apr;
            } else if (current_month === 5) {
              allocated_buget = data.budget_may;
            } else if (current_month === 6) {
              allocated_buget = data.budget_jun;
            } else if (current_month === 7) {
              allocated_buget = data.budget_jul;
            } else if (current_month === 8) {
              allocated_buget = data.budget_aug;
            } else if (current_month === 9) {
              allocated_buget = data.budget_sept;
            } else if (current_month === 10) {
              allocated_buget = data.budget_oct;
            } else if (current_month === 11) {
              allocated_buget = data.budget_nov;
            } else {
              allocated_buget = data.budget_dec;
            }
            setMonthAllocated(Number(allocated_buget));
          }
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [budgetyearupdate]);

  const editYearBudgetDetails = (data) => {
    setBudgetYearRecords(data);
    setYearBudgetDrawerStatus(true);
    yearsetDrawerDeleteStatus(true);
  };
  const addYearBudgetDetails = () => {
    setBudgetYearRecords();
    setYearBudgetDrawerStatus(true);
    yearsetDrawerDeleteStatus(false);
  };
  const editYearDetails = (data) => {
    setBudgetYearRecords(data);
    yearsetDrawerStatus(true);
    yearsetDrawerDeleteStatus(true);
  };

  const addYearDetails = () => {
    setBudgetYearRecords();
    yearsetDrawerStatus(true);
    yearsetDrawerDeleteStatus(false);
  };
  const items = [
    {
      key: "1",
      label: `Planned vs. Actual`,
      children: (
        <PlannedVsActualTable
          actualtablerecords={actualtablerecords}
          yearlistrecords={yearlistrecords}
          rolestatus={rolestatus}
        />
      ),
    },
    {
      key: "2",
      label: `Application Budget`,
      children: (
        <ApplicationBudgetTable
          editYearBudgetDetails={editYearBudgetDetails}
          addYearBudgetDetails={addYearBudgetDetails}
          applicationbudgettable={applicationbudgettable}
          budgetapplicationupdate={budgetapplicationupdate}
          budgetapplicationdata={budgetapplicationdata}
          annualplannedlist={annualplannedlist}
          rolestatus={rolestatus}
          permission={permission}
        />
      ),
    },
    {
      key: "3",
      label: `Annual Budget`,
      children: (
        <AnnualBudgetTable
          editYearDetails={editYearDetails}
          addYearDetails={addYearDetails}
          yearlistrecords={yearlistrecords}
          rolestatus={rolestatus}
          permission={permission}
        />
      ),
    },
  ];

  useEffect(() => {
    //application budget
    var budget_year;
    if (selectedTopdate.split(",").length > 1) {
      const datelist = selectedTopdate.split(",").map((data) => {
        return new Date(data).getFullYear();
      });
      const uniqueArr = new Set([...datelist]);
      budget_year = [...uniqueArr].toString();
    } else {
      budget_year = new Date(selectedTopdate).getFullYear();
    }
    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      budget_year: budget_year,
      cloud: selectedCloud.toString(),
      company_name: companyName,
      environment: selectedEnvironment.toString(),
    };
    dispatch(listApplicationBudget({ input }))
      .unwrap()
      .then(({ data }) => {
        setApplicationBudgetTable(data.list_application_budget);
        setBudgetApplicationData(!budgetapplicationdata);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    budgetapplicationupdate,
    selectedCloud,
    selectedAccount,
    selectedApplication,
    selectedEnvironment,
    selectedTopdate,
  ]);
  const yearchangeDrawerStatus = () => {
    yearsetDrawerStatus(false);
  };
  const changeyearBudgetDrawerStatus = () => {
    setYearBudgetDrawerStatus(false);
  };
  return (
    <>
      <YearDetailsScreen
        yeardrawerstatus={yeardrawerstatus}
        yearchangeDrawerStatus={yearchangeDrawerStatus}
        yearsetDrawerStatus={yearsetDrawerStatus}
        yeardrawerdeletestatus={yeardrawerdeletestatus}
        yearlistrecords={yearlistrecords}
        setYearListRecords={setYearListRecords}
        budgetyearrecords={budgetyearrecords}
        setBudgetYearUpdate={setBudgetYearUpdate}
        budgetyearupdate={budgetyearupdate}
      />
      <YearBudgetDetailsScreen
        yeardrawerstatus={yearbudgetdrawerstatus}
        yearchangeDrawerStatus={changeyearBudgetDrawerStatus}
        yearsetDrawerStatus={setYearBudgetDrawerStatus}
        yeardrawerdeletestatus={yeardrawerdeletestatus}
        budgetyearrecords={budgetyearrecords}
        budgetapplicationupdate={budgetapplicationupdate}
        setBudgetApplicationUpdate={setBudgetApplicationUpdate}
      />
      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <CostimizeSidebar />
        <MainHead title="Budget Planning" />
        <div className="costimize-body-screen">
          <Row gutter={[16, 16]} className="mt-1">
            <Col xxl={8} xl={8} lg={9} md={24} sm={24} xs={24}>
              <Row gutter={[16, 16]}>
                <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Card className="plat-card-1 p-3" style={{ height: "16rem" }}>
                    <div className="budgetPlanCardHeader">
                      <h1 className="budgetPlan-card-title">
                        Allocated Budget <br /> for the current month
                      </h1>
                    </div>
                    <div className="budgetPlanCardIcon">
                      <h1 className="card-value-1">
                        <Icon icon="mdi:dollar" />
                      </h1>
                    </div>
                    <div className="budgetPlanningCardCount">
                      <h1 className="card-value-1">
                        ${numberFormat(monthallocated)}
                      </h1>
                    </div>
                  </Card>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Card className="plat-card-1 p-3" style={{ height: "16rem" }}>
                    <div className="budgetPlanCardHeader">
                      <h1 className="budgetPlan-card-title">
                        Projected for the <br /> current month
                      </h1>
                    </div>
                    <div className="budgetPlanCardIcon projectIcon">
                      <h1 className="card-value-1">
                        <Icon icon="ic:sharp-balance" />
                      </h1>
                    </div>
                    <div className="budgetPlanningCardCount">
                      <h1 className="card-value-1">
                        ${numberFormat(projected)}
                      </h1>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xxl={6} xl={6} lg={7} md={24} sm={24} xs={24}>
              <Card
                className="plat-card-1 budgetPlanningSemiDonutChart"
                style={{
                  height: "16rem",
                }}
              >
                <BudgetPlanningSemiDonutChart
                  chartcal={chartcal}
                  semichartvalue={semichartvalue}
                />
              </Card>
            </Col>
            <Col xxl={10} xl={10} lg={8} md={24} sm={24} xs={24}>
              <Card
                className="plat-card-1"
                style={{
                  height: "16rem",
                }}
              >
                <BudgetPlanningAreaChart
                  actualtablerecords={actualtablerecords}
                  yearlistrecords={yearlistrecords}
                />
              </Card>
            </Col>
          </Row>
          <div className="mt-5">
            <Tabs
              defaultActiveKey="1"
              items={items}
              className="costimize-table-tab"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetPlanning;
