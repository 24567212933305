import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import Alert from "@mui/material/Alert";
import Spinner from "react-bootstrap/Spinner";

function Modal({
  flag,
  setCloseModal,
  content,
  onSubmit,
  accountValidate,
  buttonloader,
  closealert,
  errorname,
  changeCloseAlert,
  submitbuttonstatus,
}) {
  const [basicModal, setBasicModal] = useState(false);

  useEffect(() => {
    setBasicModal(flag);
  }, [flag]);

  const handleCloseModal = () => {
    setBasicModal(false);
    setCloseModal();
  };

  return (
    <>
      {basicModal && (
        <MDBModal
          backdrop
          show={basicModal}
          onHide={handleCloseModal}
          tabIndex="-1"
          staticBackdrop
        >
          <MDBModalDialog size="lg">
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Account Settings</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={handleCloseModal}
                ></MDBBtn>
              </MDBModalHeader>
              {closealert ? <Alert severity="error">{errorname}</Alert> : null}
              <MDBModalBody>{content}</MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={handleCloseModal}>
                  Close
                </MDBBtn>
                {submitbuttonstatus === "new" ? (
                  <MDBBtn onClick={onSubmit}>
                    {accountValidate === "validate"
                      ? "Validate Account"
                      : "Configure Account"}
                    {buttonloader && (
                      <Spinner
                        animation="border"
                        role="status"
                        className="spinner-size"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    )}
                  </MDBBtn>
                ) : (
                  <MDBBtn onClick={onSubmit}>Update Account</MDBBtn>
                )}
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      )}
    </>
  );
}

export default Modal;
