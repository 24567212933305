import "../admin_pages.css";
import { memo, useState } from "react";
import { Button, message, Steps, theme, Row, Col } from "antd";
import ProfileInfo from "./ProfileInfo";
import CompanyInfo from "./CompanyInfo";
import OfferingInfo from "./OfferingInfo";

const CustomerForm = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const steps = [
    {
      title: "Profile Information",
      content: (
        <ProfileInfo
          type="update"
          setCurrent={setCurrent}
          current={current}
          display_status={true}
        />
      ),
    },
    {
      title: "Company Information",
      content: (
        <CompanyInfo
          type="update"
          setCurrent={setCurrent}
          current={current}
          display_status={true}
        />
      ),
    },
    {
      title: "Customer Offering",
      content: (
        <OfferingInfo
          type="update"
          setCurrent={setCurrent}
          current={current}
          display_status={true}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  return (
    <>
      <Row>
        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
        <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20} className="mt-5">
          <div className="form-cover-step">
            <Steps
              type="navigation"
              size="small"
              className="customer-form-step "
              current={current}
              items={items}
            />
          </div>
          <div className="step-form-body">
            <div>{steps[current].content}</div>
          </div>
        </Col>
        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
      </Row>
    </>
  );
};

export default memo(CustomerForm);
