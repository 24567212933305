import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { loginUser } from "../action/userAction";
// import { tokenEndpoint } from "../amplify/auth-config";
import { userInfoMethod } from "../slice/userSlice";
import { message, Spin } from "antd";

const YourComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigateFn = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const hashParams = location.hash.substring(1);
    const result = hashParams.split("&");
    const idToken = result
      .find((param) => param.startsWith("id_token="))
      .split("=")[1];
    const decodedToken = jwtDecode(idToken);
    console.log("Decoded id_token:", decodedToken);
    // Access the payload data from the decoded token
    const user_id = decodedToken["cognito:username"];
    const exp_time = decodedToken["exp"];
    // const email = decodedToken.email;
    // console.log("Email:", email);
    // console.log(idToken);
    localStorage.setItem("_token", idToken);
    localStorage.setItem("CognitoIdentityServiceProvider.auth", user_id);
    const records = {
      user_id: user_id,
      _token: idToken,
    };

    // const tokenEndpoint =
    //   "https://cloudstd.auth.ap-south-1.amazoncognito.com/oauth2/token";
    // const cognitoClientId = "4n35uorls0sceif5u6uodpmm0m";
    // const cognitoClientSecret =
    //   "1bf07m2la15j7cqunala13p9ajlvav0oguiokpko14qep9po7doo";
    // const redirectUri =
    //   "https://cloudstd.auth.ap-south-1.amazoncognito.com/oauth2/idpresponse";
    // const azureAccessToken = idToken;

    // const requestBody = new URLSearchParams();
    // requestBody.append(
    //   "grant_type",
    //   "urn:ietf:params:oauth:grant-type:jwt-bearer"
    // );
    // requestBody.append("client_id", cognitoClientId);
    // requestBody.append("client_secret", cognitoClientSecret);
    // requestBody.append("scope", "openid");
    // requestBody.append("redirect_uri", redirectUri);
    // requestBody.append("code", azureAccessToken);

    // fetch(tokenEndpoint, {
    //   method: "POST",
    //   body: requestBody,
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     const cognitoJwtToken = data.id_token;
    //     // Use the Cognito JWT token for subsequent requests or store it for future use
    //     console.log("Cognito JWT token:", cognitoJwtToken);
    //   })
    //   .catch((error) => {
    //     console.error("Error obtaining Cognito JWT token:", error);
    //   });

    // dispatch(tokenEndpoint({ idToken, exp_time }))
    //   .unwrap()
    //   .then((data) => {
    //     console.log(data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    dispatch(loginUser(records))
      .unwrap()
      .then(({ data }) => {
        if (data.getPlatformUsers.status === "active") {
          console.log(data.getPlatformUsers.status);
          dispatch(userInfoMethod(data.getPlatformUsers));
          navigateFn("/role");
        } else {
          navigateFn("/signin");
          message.error("Inactive Account");
        }
      })
      .catch((err) => {
        console.log(err);
        //   console.log(err);
        //   setLoader(false);
        //   setSuccessInfo(false);
        //   setErrorContent("Loggedin Failiure!");
      });

    console.log(records);
  }, [location]);

  return <Spin spinning={true}></Spin>;
};

export default YourComponent;
