import { Button, Col, Form, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addUpdateForm } from "../../../action/graphql/devops/DevOpsAction";
function PreviewForm(props) {
  const navigateFn = useNavigate();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const devops = useSelector((state) => state.devops);
  const [basicDetailsInfo, setBasicDetailInfo] = useState(
    devops.saveBasicDetailsData.basicDetailsInfo
  );
  const [configDetailsInfo, setConfigDetailsInfo] = useState(
    devops.saveBasicDetailsData.configDetailsInfo
  );
  const [accountDetailsInfo, setAccountDetailsInfo] = useState(
    devops.saveBasicDetailsData.accountInfo
  );

  useEffect(() => {}, []);
  const [refreshModalOpen, setrefreshModalOpen] = useState(false);

  const handleSubmit = (values) => {
    setrefreshModalOpen(true);
    if (devops.requestAction === "add") {
      values.event_type = "insert";
      values.status = "x";
    } else {
      values.event_type = "update_status";
      values.status = "";
    }

    dispatch(
      addUpdateForm({
        basicDetailsInfo,
        configDetailsInfo,
        accountDetailsInfo,
        values,
      })
    );
  };
  const handleCancel = () => {
    setrefreshModalOpen(false);
  };
  const handleDashboardClick = () => {
    navigateFn("/devops-requestor");
  };
  return (
    <>
      <Form onFinish={handleSubmit} form={form}>
        <div className="basic-details">
          <Row gutter={[16, 16]} className="d-flex justify-content-center">
            <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  <span className="sub-heading-devops-config">Cloud :</span>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  {basicDetailsInfo.cloud_platform}
                </Col>
              </Row>
              <br />
              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  <span className="sub-heading-devops-config">
                    Account Name :
                  </span>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  {basicDetailsInfo.account_name}
                </Col>
              </Row>
              <br />
              <Row gutter={[16, 16]}>
                <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                  <span className="sub-heading-devops-config">
                    Launch Category :
                  </span>
                </Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  {basicDetailsInfo.service_name}
                </Col>
              </Row>
              <br />
              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  <span className="sub-heading-devops-config">
                    Configurations :
                  </span>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  {basicDetailsInfo.operation}
                </Col>
              </Row>
              <br />
              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  <span className="sub-heading-devops-config">
                    {" "}
                    Virtual Machine Name :
                  </span>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  {configDetailsInfo.virtual_machine_name}
                </Col>
              </Row>
              <br />
              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  <span className="sub-heading-devops-config">
                    {" "}
                    Virtual Machine Size :
                  </span>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  {configDetailsInfo.virtual_machine_size}
                </Col>
              </Row>
              <br />
              <Row gutter={[16, 16]}>
                <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
                  <span className="sub-heading-devops-config"> OS :</span>
                </Col>
                <Col xxl={1} xl={1} lg={1} md={1} sm={1}></Col>
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  {configDetailsInfo.os_publisher}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <br />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item>
              <Button
                htmlType="button"
                onClick={() => props.setCurrent(props.current - 1)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="form-button"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        open={refreshModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={400}
      >
        <div className="p-4">
          <Row>
            <Col>
              Your request has been created. You're now being automatically
              redirected to the
              <p className="d-flex justify-content-center">
                <a className="click-here-text" onClick={handleDashboardClick}>
                  Dashboard
                </a>
              </p>
            </Col>
          </Row>
          <br />
        </div>
      </Modal>
    </>
  );
}

export default PreviewForm;
