import React, { useEffect } from "react";
import yellowBg from "../../assets/images/homepage/yellow-bg.png";
import laptop from "../../assets/images/homepage/laptop.webp";
import tablet from "../../assets/images/homepage/tablet.png";
import mobile from "../../assets/images/homepage/mobile.webp";
import dashboardOne from "../../assets/images/homepage/dashboard-1.webp";
import dashboardTwo from "../../assets/images/homepage/dashboard-2.webp";
import dashboardThree from "../../assets/images/homepage/dashboard-3.webp";
import dashboardFour from "../../assets/images/homepage/dashboard-4.webp";
import "./landingPage.css";
import { Button, Carousel } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Footer from "./footer";
import CarouselComponent from "./carousel-components";
import { useState } from "react";

function LandingPage() {
  const [secOps, setSecOps] = useState(true);
  const [sysOps, setSysOps] = useState(false);
  const [devOps, setDevOps] = useState(false);
  const [finOps, setFinOps] = useState(false);
  const [isLargeSecOps, setIsLargeSecOps] = useState(true);
  const [isLargeDevOps, setIsLargeDevOps] = useState(false);
  const [isLargeSysOps, setIsLargeSysOps] = useState(false);
  const [isLargeFinOps, setIsLargeFinOps] = useState(false);
  useEffect(() => {
    setInterval(() => {
      // changeAuto();
    }, 3000);
  }, []);

  const changeAuto = () => {
    var textArray = ["secOps", "sysOps", "devOps", "finOps"];
    let randomNumber = Math.floor(Math.random() * textArray.length);
    // console.log(randomNumber);
    switch (randomNumber) {
      case 0:
        setDevOps(false);
        setSysOps(false);
        setFinOps(false);
        setSecOps(true);
        setIsLargeSysOps(false);
        setIsLargeDevOps(false);
        setIsLargeFinOps(false);
        setIsLargeSecOps(true);
        break;
      case 1:
        setDevOps(false);
        setFinOps(false);
        setSecOps(false);
        setSysOps(true);

        setIsLargeDevOps(false);
        setIsLargeFinOps(false);
        setIsLargeSecOps(false);
        setIsLargeSysOps(true);
        break;
      case 2:
        setSysOps(false);
        setFinOps(false);
        setSecOps(false);
        setDevOps(true);
        setIsLargeSysOps(false);
        setIsLargeFinOps(false);
        setIsLargeSecOps(false);
        setIsLargeDevOps(true);
        break;
      case 3:
        setDevOps(false);
        setSysOps(false);
        setSecOps(false);
        setFinOps(true);
        setIsLargeSysOps(false);
        setIsLargeDevOps(false);
        setIsLargeSecOps(false);
        setIsLargeFinOps(true);
        break;

      default:
        break;
    }
  };

  const handleCircleClick = (item) => {
    console.log(item);
    switch (item) {
      case "secOps":
        setDevOps(false);
        setSysOps(false);
        setFinOps(false);
        setSecOps(true);
        setIsLargeSysOps(false);
        setIsLargeDevOps(false);
        setIsLargeFinOps(false);
        setIsLargeSecOps(true);
        break;
      case "sysOps":
        setDevOps(false);
        setFinOps(false);
        setSecOps(false);
        setSysOps(true);

        setIsLargeDevOps(false);
        setIsLargeFinOps(false);
        setIsLargeSecOps(false);
        setIsLargeSysOps(true);
        break;
      case "devOps":
        setSysOps(false);
        setFinOps(false);
        setSecOps(false);
        setDevOps(true);
        setIsLargeSysOps(false);
        setIsLargeFinOps(false);
        setIsLargeSecOps(false);
        setIsLargeDevOps(true);
        break;
      case "finOps":
        setDevOps(false);
        setSysOps(false);
        setSecOps(false);
        setFinOps(true);
        setIsLargeSysOps(false);
        setIsLargeDevOps(false);
        setIsLargeSecOps(false);
        setIsLargeFinOps(true);
        break;

      default:
        break;
    }
  };
  return (
    <>
      <div className="home-page-landing">
        <div className="main-banner">
          <div className="row background-banner">
            <div className="col-xl-7 col-lg-8 col-md-8 col-sm-12 d-flex  align-items-center">
              {" "}
              <div className="cloudStudio">
                <h3 className="header-text">CloudStudio</h3>
                <p className="para-text-1">
                  Making your multi cloud operation more
                </p>
                <p className="para-text-2">
                  <span>visible, efficient and simple.</span>
                </p>
                <div className="d-flex justify-content-center">
                  <a
                    href="https://www.1cloudhub.com/contact-us/"
                    target="_blank"
                  >
                    <Button type="button" className="demo-button">
                      Book a Demo
                    </Button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-4 col-md-4 col-sm-12">
              <div className="yellowbg">
                <div className="yellow-img">
                  <img src={yellowBg} alt="yellow-bg" />
                </div>
              </div>
              <div className="devices">
                <div className="laptop">
                  <img src={laptop} alt="laptop" />
                </div>
                <div className="tablet">
                  <img src={tablet} alt="tablet" />
                </div>
                <div className="mobile">
                  <img src={mobile} alt="mobile" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="asset-management ">
          <div className="asset-header">
            <h3>Asset Management</h3>
            <p>
              <span>Cloud Studio</span> is your comprehensive platform that
              provides a 360 degree view of your <br />
              infrastructure’s assets, covering all aspects of multi cloud asset
              management.
            </p>
          </div>
        </div>
        <div className="circle-carousel mb-1">
          <div className="row">
            <div className="col">
              <div className="cirles">
                <div
                  className={
                    isLargeSecOps
                      ? "circle-1 circle-large"
                      : "circle-1 circle-small"
                  }
                  onClick={() => handleCircleClick("secOps")}
                >
                  <div className="">
                    <span className="circle-header">SecOps</span>
                    <ul>
                      <li>
                        <Icon icon="grommet-icons:threats" />
                        &nbsp; Respond to threats
                      </li>
                      <li>
                        <Icon icon="mdi:smoke-detector-variant-alert" />
                        &nbsp; Detect
                      </li>
                      <li>
                        <Icon icon="mdi:security-lock" />
                        &nbsp; Track security events
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={
                    isLargeSysOps
                      ? `circle-2 circle-large`
                      : `circle-2 circle-small`
                  }
                  onClick={() => handleCircleClick("sysOps")}
                >
                  <div className="">
                    <span className="circle-header">SysOps</span>
                    <ul>
                      <li>
                        <Icon icon="pajamas:issue-type-incident" />
                        &nbsp;Track incident
                      </li>
                      <li>
                        <Icon icon="healthicons:contraceptive-patch" />
                        &nbsp; Patch Management
                      </li>
                      <li>
                        <Icon icon="material-symbols:event-available" />
                        &nbsp; Event Management
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={
                    isLargeDevOps
                      ? `circle-3 circle-large`
                      : `circle-3 circle-small`
                  }
                  onClick={() => handleCircleClick("devOps")}
                >
                  <div className="">
                    <span className="circle-header">DevOps</span>
                    <ul>
                      <li>
                        <Icon icon="mdi:account-service" />
                        &nbsp; Self service
                      </li>
                      <li>
                        <Icon icon="pajamas:infrastructure-registry" />
                        &nbsp; RID
                      </li>
                      <li>
                        <Icon icon="eos-icons:pipeline-outlined" />
                        &nbsp; IaC pipelines
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={
                    isLargeFinOps
                      ? `circle-4 circle-large`
                      : `circle-4 circle-small`
                  }
                  onClick={() => handleCircleClick("finOps")}
                >
                  <div className="">
                    <span className="circle-header">FinOps</span>
                    <ul>
                      <li>
                        <Icon icon="fluent-mdl2:issue-tracking-mirrored" />
                        &nbsp;Track
                      </li>
                      <li>
                        <Icon icon="icon-park-outline:analysis" />
                        &nbsp; Analyze
                      </li>
                      <li>
                        <Icon icon="carbon:ibm-watson-knowledge-catalog" />
                        &nbsp; Realise
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="circle-5">
                  <div className="">
                    <span className="circle-header">Asset Management</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="circle-description">
                {secOps && (
                  <div id="secOps" className="secOps-circle-definition">
                    <h3>SecOps</h3>
                    <br />
                    <p>
                      With <span className="highlight">SecOps</span>{" "}
                      capabilities, you can track security events, detect and
                      respond to threats, and maintain compliance with industry
                      standards.
                    </p>
                  </div>
                )}
                {sysOps && (
                  <div id="sysops" className="sysops-circle-definition">
                    <h3>SysOps</h3>
                    <br />
                    <p>
                      Its <span className="highlight">SysOps</span> &nbsp;
                      capabilities offer comprehensive patch management and
                      event management features, ensuring your systems are
                      always up-to-date, secure, and running smoothly.
                    </p>
                  </div>
                )}
                {devOps && (
                  <div id="devOps" className="devOps-circle-definition">
                    <h3>DevOps</h3>
                    <br />
                    <p>
                      Finally, Cloud Studio's{" "}
                      <span className="highlight">DevOps</span> tools help you
                      automate and streamline your processes, enabling you to
                      rapidly deploy and scale your applications with ease.
                    </p>
                  </div>
                )}
                {finOps && (
                  <div id="finOps" className="finOps-circle-definition">
                    <h3>FinOps</h3>
                    <br />
                    <p>
                      Additionally, the platform's{" "}
                      <span className="highlight">FinOps</span> features enable
                      you to monitor your financial operations, optimize your
                      spending, and identify cost savings opportunities..
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="why-cloudstudio text-center  mb-4">
          <h3 className="">
            Why <span>CloudStudio?</span>
          </h3>
          <p className="para-text-1">
            Have complete control over your IT operations without limiting to
            few functions.
          </p>
        </div>
        <div className="carousel-section">
          <div className="carousel-inner-padding">
            <Carousel>
              <Carousel.Item interval={1000}>
                <CarouselComponent
                  isImageLeft={true}
                  carouselImage={dashboardOne}
                  isSecondDiv={false}
                />
              </Carousel.Item>
              <Carousel.Item>
                <CarouselComponent
                  interval={1000}
                  isImageLeft={false}
                  carouselImage={dashboardTwo}
                  isSecondDiv={true}
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <br />
        <div className="want-to-know-more d-flex justify-content-center p-4 ">
          <div className="">
            <p>
              Want to know more? &nbsp; &nbsp;
              <span>
                <a href="https://www.1cloudhub.com/contact-us/" target="_blank">
                  <Button type="button" className="book-a-demo">
                    Book a Demo
                  </Button>
                </a>
              </span>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default LandingPage;
