import { Icon } from "@iconify/react";
import { Card, Progress, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import PeemsStatusCards from "../PeemsStatusCards";
import { useDispatch } from "react-redux";
import {
  listJobSchedules,
  listOverview,
} from "../../action/graphql/peems/PeemsAPI";
import PeemsJobsSchedule from "./PeemsJobsSchedule";

function PeemsOverview() {
  const dispatch = useDispatch();

  const columns = [
    Table.EXPAND_COLUMN,

    {
      key: 1,
      name: "scheduled_date",
      title: "Scheduled Date",
      dataIndex: "schedule_date",
      fixed: "left",
      width: 200,
    },

    {
      key: 2,
      name: "job_name",
      title: "Job Name",
      dataIndex: "event_identifier",
      fixed: "left",
      width: 300,
    },

    {
      key: 5,
      name: "planned_jobs",
      title: "Planned Jobs ",
      dataIndex: "plannedJobs",
      fixed: "left",
      width: 250,
    },
    {
      key: 6,
      name: "Completed",
      title: "Completed Jobs",
      dataIndex: "Completed",
      fixed: "left",
      width: 200,
    },
    {
      key: 7,
      name: "Pending Jobs",
      title: "Pending Jobs",
      dataIndex: "active",
      fixed: "left",
      width: 150,
    },
    {
      key: 7,
      name: "Not Triggered Jobs",
      title: "Not Triggered Jobs",
      dataIndex: "not triggered",
      fixed: "left",
      width: 200,
    },
    {
      key: 7,
      name: "Failed Jobs",
      title: "Failed Jobs",
      dataIndex: "Failed",
      fixed: "left",
      width: 150,
    },
  ];
  const [responseData, setResponseData] = useState([]);
  const [statusCounts, setStatusCounts] = useState({
    successJobs: [],
    failureJobs: [],
    totalJobs: [],
    unTrigerredJobs: [],
    scheduleJobs: [],
  });

  const [isShowSpinner, setIsShowSpinner] = useState(true);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [data, setData] = useState([]);
  useEffect(() => {
    getOverviewJobDetails();
  }, []);
  const getOverviewJobDetails = () => {
    dispatch(listOverview({ companyName }))
      .unwrap()
      .then(({ data }) => {
        let response = JSON.parse(data.get_count_status);
        response = response.map((item, index) => {
          let plannedJobs =
            item.Completed + item.active + item.Failed + item["not triggered"];
          return {
            ...item,
            key: index,
            plannedJobs,
          };
        });

        setResponseData(response);
        setData(response);
        let successCount = 0;
        let failedCount = 0;
        let notTrigerredCount = 0;
        let scheduledCount = 0;
        let totalJobsCount = 0;

        response.forEach((element, index) => {
          let parseIntCompleted = parseInt(element.Completed);
          successCount = successCount + parseIntCompleted;
          failedCount = failedCount + parseInt(element.Failed);
          notTrigerredCount =
            notTrigerredCount + parseInt(element["not triggered"]);
          scheduledCount = scheduledCount + parseInt(element.active);
          totalJobsCount = totalJobsCount + parseInt(element.plannedJobs);
        });
        // let totalJobCount =
        //   successCount + failedCount + notTrigerredCount + scheduledCount;
        setStatusCounts({
          successJobs: successCount,
          totalJobs: totalJobsCount,
          unTrigerredJobs: notTrigerredCount,
          failureJobs: failedCount,
          scheduleJobs: scheduledCount,
        });
        setIsShowSpinner(false);
      });
  };

  const getStatusCounts = (data, key) => {
    let successJobs = data.filter(function (item) {
      return item.status == key;
    });
    return successJobs;
  };

  const uniqByKeepLast = (data, key) => {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  };
  // const uniqueAuthors = [...new Map(data.map((v) => [key[v], v])).values()];
  return (
    <>
      <div className="Peems-overview">
        <div className="d-flex job-cards justify-content-between">
          <PeemsStatusCards
            statusCounts={statusCounts && statusCounts}
            isShowSpinner={isShowSpinner}
          />
        </div>
      </div>
      <div className="peems-overview-table mt-5">
        <Table
          className="peems"
          columns={columns}
          pagination={true}
          loading={{ indicator: <Spin />, spinning: isShowSpinner }}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <>
                  <PeemsJobsSchedule
                    event_identifier={record.event_identifier}
                    startDate={record.schedule_date}
                    type="overview"
                  />
                </>
              );
            },
          }}
          dataSource={data}
        />
      </div>
    </>
  );
}

export default PeemsOverview;
