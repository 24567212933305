import { gql } from "@apollo/client";

const ADD_SAVINGS_MODEL = gql`
  mutation MyMutation(
    $application: String!
    $environment: String!
    $service: String!
    $account: String!
    $company_name: String!
    $commitment: String!
    $current_configuration: String!
    $lost_opportunity: String!
    $potential_savings: String!
    $realised_savings: String!
    $recommendation_rule: String!
    $recommended_configuration: String!
    $report_month: String!
    $resource_id: String!
    $resource_name: String!
    $resource_sub_category: String!
  ) {
    insert_recommendation_savings(
      account_name: $account
      application: $application
      commitment: $commitment
      company_name: $company_name
      current_configuration: $current_configuration
      environment: $environment
      lost_opportunity: $lost_opportunity
      potential_savings: $potential_savings
      realised_savings: $realised_savings
      recommendation_rule: $recommendation_rule
      recommended_configuration: $recommended_configuration
      report_month: $report_month
      resource_id: $resource_id
      resource_name: $resource_name
      resource_sub_category: $resource_sub_category
      service: $service
    ) {
      account_name
      application
      commitment
      current_configuration
      environment
      lost_opportunity
      realised_savings
      potential_savings
      recommendation_rule
      recommended_configuration
      report_month
      resource_id
      resource_name
      resource_sub_category
      service
    }
  }
`;

const UPDATE_SAVINGS_MODEL = gql`
  mutation MyMutation(
    $application: String!
    $environment: String!
    $service: String!
    $account: String!
    $company_name: String!
    $commitment: String!
    $current_configuration: String!
    $lost_opportunity: String!
    $potential_savings: String!
    $realised_savings: String!
    $recommendation_rule: String!
    $recommended_configuration: String!
    $report_month: String!
    $resource_id: String!
    $resource_name: String!
    $resource_sub_category: String!
  ) {
    update_recommendation_savings(
      account_name: $account
      application: $application
      commitment: $commitment
      company_name: $company_name
      current_configuration: $current_configuration
      environment: $environment
      lost_opportunity: $lost_opportunity
      potential_savings: $potential_savings
      recommendation_rule: $recommendation_rule
      recommended_configuration: $recommended_configuration
      report_month: $report_month
      resource_id: $resource_id
      resource_name: $resource_name
      resource_sub_category: $resource_sub_category
      service: $service
      realised_savings: $realised_savings
    ) {
      account_name
      application
      commitment
      current_configuration
      environment
      lost_opportunity
      potential_savings
      realised_savings
      recommendation_rule
      recommended_configuration
      report_month
      resource_id
      resource_name
      resource_sub_category
      service
    }
  }
`;

const GET_PAGINATION_COUNT = gql`
  query MyQuery(
    $service_name: String!
    $tagging_name: String!
    $company_name: String!
    $date: String!
  ) {
    newfunctionquery9(
      company_name: $company_name
      month: $date
      service: $service_name
      tagging_detail: $tagging_name
    ) {
      total_pages
    }
  }
`;

const GET_TAGGING_DOWNLOAD_LIST = gql`
  query MyQuery(
    $service_name: String!
    $tagging_name: String!
    $company_name: String!
    $date: String!
    $page: Int!
  ) {
    newfunctionquery7(
      company_name: $company_name
      month: $date
      service: $service_name
      page_number: $page
      page_size: 10000
      tagging_detail: $tagging_name
    ) {
      resourceid
      services
      application
      environment
      subcategory
      tagging_details
    }
  }
`;

const ADD_ANNUAL_BUDGET = gql`
  mutation MyMutation(
    $budget_amount: String!
    $budget_month: String!
    $budget_year: String!
    $company_name: String!
  ) {
    create_annual_budget(
      budget_amount: $budget_amount
      budget_month: $budget_month
      budget_year: $budget_year
      company_name: $company_name
    ) {
      budget_ids
      companys
      budget_years
      budget_months
      account_names
      budget_amounts
    }
  }
`;

const UPDATE_ANNUAL_BUDGET = gql`
  mutation MyMutation(
    $budget_amount: String!
    $budget_month: String!
    $budget_year: String!
    $company_name: String!
    $budget_id: String!
  ) {
    update_annual_budget(
      account_name: ""
      budget_amount: $budget_amount
      budget_id: $budget_id
      budget_month: $budget_month
      budget_year: $budget_year
      company_name: $company_name
    ) {
      budget_ids
      companys
      account_names
      budget_amounts
      budget_months
      budget_years
    }
  }
`;

const LIST_ANNUAL_BUDGET = gql`
  query MyQuery($company_name: String!, $cloud: String!) {
    list_annual_budget(company_name: $company_name, cloud: $cloud) {
      budget_id
      company
      budget_year
      budget_jan
      budget_jun
      budget_feb
      budget_mar
      budget_apr
      budget_may
      budget_jul
      budget_aug
      budget_sept
      budget_oct
      budget_nov
      budget_dec
      annual_total
    }
  }
`;

const LIST_APPLICATION_BUDGET = gql`
  query MyQuery(
    $company_name: String!
    $account_name: String!
    $application: String!
    $budget_year: String!
    $cloud: String!
    $environment: String!
  ) {
    list_application_budget(
      account_name: $account_name
      application: $application
      budget_year: $budget_year
      cloud: $cloud
      company_name: $company_name
      environment: $environment
    ) {
      budget_id
      budget_year
      account_name
      environment
      application
      budget_jan
      budget_feb
      budget_mar
      budget_apr
      budget_may
      budget_jun
      budget_jul
      budget_aug
      budget_sept
      budget_oct
      budget_nov
      budget_dec
      annual_total
    }
  }
`;

const DELETE_ANNUAL_BUDGET = gql`
  mutation MyMutation($budget_id: String!, $compnay_name: String!) {
    delete_annual_budget(budget_id: $budget_id, company_name: $compnay_name) {
      budget_id
      company
      account_name
      budget_month
      budget_year
      budget_amount
    }
  }
`;

const DELETE_APPLICATION_BUDGET = gql`
  mutation MyMutation($budget_id: String!, $companyName: String!) {
    delete_application_budget(
      budget_id: $budget_id
      company_name: $companyName
    ) {
      budget_id
      company
      account_name
      application
      environment
      budget_month
      budget_year
      budget_amount
    }
  }
`;

const ADD_APPLICATION_BUDGET = gql`
  mutation MyMutation(
    $account_name: String!
    $application: String!
    $budget_amount: String!
    $budget_month: String!
    $budget_year: String!
    $company_name: String!
    $environment: String!
  ) {
    create_application_budget(
      account_name: $account_name
      application: $application
      budget_amount: $budget_amount
      budget_month: $budget_month
      budget_year: $budget_year
      company_name: $company_name
      environment: $environment
    ) {
      budget_ids
      companys
      budget_years
      budget_months
      account_names
      applications
      environments
      budget_amounts
    }
  }
`;

const UPDATED_APPLICATION_BUDGET = gql`
  mutation MyMutation(
    $account_name: String!
    $application: String!
    $budget_amount: String!
    $budget_month: String!
    $budget_year: String!
    $company_name: String!
    $environment: String!
    $key: String!
  ) {
    update_application_budget(
      account_name: $account_name
      budget_amount: $budget_amount
      budget_id: $key
      budget_month: $budget_month
      budget_year: $budget_year
      company_name: $company_name
      application: $application
      environment: $environment
    ) {
      budget_ids
      companys
      applications
      environments
      account_names
      budget_amounts
      budget_months
      budget_years
    }
  }
`;

const LIST_SAVINGS_MODEL = gql`
  query MyQuery(
    $company_name: String!
    $account_name: String!
    $application: String!
    $cloud: String!
    $db: String!
    $environment: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery10(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      account_names
      applications
      commitment
      current_configuration
      environments
      lost_opportunity
      potential_savings
      recommendation_rule
      recommended_configuration
      report_month
      resource_id
      resource_name
      resource_sub_category
      services
      realised_savings
    }
  }
`;

const LIST_APPLICATION_SPEND = gql`
  query MyQuery(
    $company_name: String!
    $account_name: String!
    $application: String!
    $cloud: String!
    $db: String!
    $environment: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery16(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      application
      spend
      report_month
    }
  }
`;

const DELETE_SAVINGS_MODEL = gql`
  mutation MyMutation($companyName: String!, $deleteid: String!) {
    delete_recommendation_savings(
      company_name: $companyName
      resource_id: $deleteid
    ) {
      account_name
      application
      commitment
      current_configuration
      environment
      lost_opportunity
      potential_savings
      realised_savings
      recommendation_rule
      recommended_configuration
      report_month
      resource_id
      resource_name
      resource_sub_category
      service
    }
  }
`;

const LIST_INSTANCE_TRACKING = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
  ) {
    newfunctionquery15(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      month: $month
    ) {
      application
      environment
      report_month
      resourcename
      status
      changes_occured
      region
      root_device_type
      instance_type
      ebs_volume
    }
  }
`;

const LIST_SAVINGS_REALIZATION_CHART = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
    $os: String!
    $db: String!
    $infra: String!
    $service: String!
  ) {
    newfunctionquery11(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      realised_savings
      report_month
    }
  }
`;

const LIST_ASSET_MANAGED = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
    $os: String!
    $selectedservices: String!
    $selecteddb: String!
    $selectedinfra: String!
  ) {
    newfunctionquery13(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $selecteddb
      environment: $environment
      infra: $selectedinfra
      month: $month
      os: $os
      service: $selectedservices
    ) {
      applications
      cpu
      memory
      operating_system
      running_hours
      vm_count
    }
  }
`;

const LIST_INSTANCE_RUNTIME = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
    $os: String!
  ) {
    newfunctionquery12(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      month: $month
      os: $os
    ) {
      actual_runtime
      application
      environment
      planned_runtime
      report_month
      resourceid
      resourcename
      usagestartdate
    }
  }
`;

const LIST_INSTANCE_RUNTIME_OLD = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
    $os: String!
  ) {
    newfunctionquery12(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      month: $month
      os: $os
    ) {
      actual_runtime
      application
      environment
      planned_runtime
      report_month
      resourceid
      resourcename
      usagestartdate
    }
  }
`;

const CHART_INSTANCE_RUNTIME = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
    $os: String!
    $resourcename: String!
    $resourceid: String!
  ) {
    newfunctionquery14(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      month: $month
      os: $os
      resourcename: $resourcename
      resourceid: $resourceid
    ) {
      actual_runtime
      application
      environment
      planned_runtime
      report_month
      resourceid
      resourcename
      usagestartdate
    }
  }
`;

const UNDATE_INSTANCERUN_TIME = gql`
  mutation MyMutation(
    $application: String!
    $company_name: String!
    $environment: String!
    $planned_runtime: String!
    $planning_date: String!
    $report_month: String!
    $resource_id: String!
    $resource_name: String!
  ) {
    update_instance_tracking(
      application: $application
      company_name: $company_name
      environment: $environment
      planned_runtime: $planned_runtime
      planning_date: $planning_date
      report_month: $report_month
      resource_id: $resource_id
      resource_name: $resource_name
    ) {
      application
      environment
      planned_runtime
      resourceid
      resourcename
      usagestartdate
    }
  }
`;

const LIST_PLANNED_AND_ACTIVITY = gql`
  query MyQuery($company_name: String!, $date: String!) {
    newfunctionquery18(company_name: $company_name, report_month: $date) {
      accountability
      activity_id
      company
      date
      month
      next_target_date
      pending_activity
      priority
      project
      project_phase
      remarks
      sow_status
    }
  }
`;

const ADD_PLANNED_AND_ACTIVITY = gql`
  mutation MyMutation(
    $accountability: String!
    $company_name: String!
    $usagedate: String!
    $report_month: String!
    $next_target_date: String!
    $pending_activity: String!
    $priority: String!
    $project: String!
    $project_phase: String!
    $remarks: String!
    $sow_status: String!
  ) {
    insert_slm_planned_open_activity(
      accountability: $accountability
      company_name: $company_name
      usagedate: $usagedate
      report_month: $report_month
      next_target_date: $next_target_date
      pending_activity: $pending_activity
      priority: $priority
      project: $project
      project_phase: $project_phase
      remarks: $remarks
      sow_status: $sow_status
    ) {
      activity_id
      accountability
      company
      date
      month
      next_target_date
      pending_activity
      priority
      project
      project_phase
      remarks
      sow_status
    }
  }
`;

const UPDATE_PLANNED_AND_ACTIVITY = gql`
  mutation MyMutation(
    $accountability: String!
    $company_name: String!
    $usagedate: String!
    $report_month: String!
    $next_target_date: String!
    $pending_activity: String!
    $priority: String!
    $project: String!
    $project_phase: String!
    $remarks: String!
    $sow_status: String!
    $activity_id: String!
  ) {
    update_slm_planned_open_activity(
      activity_id: $activity_id
      accountability: $accountability
      company_name: $company_name
      usagedate: $usagedate
      report_month: $report_month
      next_target_date: $next_target_date
      pending_activity: $pending_activity
      priority: $priority
      project: $project
      project_phase: $project_phase
      remarks: $remarks
      sow_status: $sow_status
    ) {
      activity_id
      accountability
      company
      date
      month
      next_target_date
      pending_activity
      priority
      project
      project_phase
      remarks
      sow_status
    }
  }
`;

const DELETED_PLANNED_AND_ACTIVITY = gql`
  mutation MyMutation($activity_id: String!) {
    delete_slm_planned_open_activity(activity_id: $activity_id) {
      activity_id
      accountability
      company
      date
      month
      next_target_date
      pending_activity
      priority
      project
      project_phase
      remarks
      sow_status
    }
  }
`;

const ADD_RTO_RPO = gql`
  mutation MyMutation(
    $application: String!
    $company_name: String!
    $environment: String!
    $instance_name: String!
    $report_month: String!
    $rpo: String!
    $rto: String!
  ) {
    insert_rto_rpo(
      application: $application
      company_name: $company_name
      environment: $environment
      instance_name: $instance_name
      report_month: $report_month
      rpo: $rpo
      rto: $rto
    ) {
      application
      environment
      instance_name
      ops_id
      report_month
      rpo
      rto
    }
  }
`;

const ADD_RTO_RPO_OLD = gql`
  mutation MyMutation {
    insert_rto_rpo(
      application: "navision"
      company_name: "fivestar"
      environment: "Production"
      instance_name: "App"
      report_month: "2023-04-01"
      rpo: "22"
      rto: "12"
    ) {
      application
      environment
      instance_name
      ops_id
      report_month
      rpo
      rto
    }
  }
`;

const LIST_RTO_RPO = gql`
  query MyQuery($date: String!, $company_name: String!) {
    newfunctionquery19(company_name: $company_name, report_month: $date) {
      application
      environment
      instance_name
      ops_id
      report_month
      rpo
      rto
    }
  }
`;

const UPDATE_RTO_RPO = gql`
  mutation MyMutation(
    $application: String!
    $company_name: String!
    $environment: String!
    $instance_name: String!
    $report_month: String!
    $rpo: String!
    $rto: String!
    $key: String!
  ) {
    update_rto_rpo(
      application: $application
      company_name: $company_name
      environment: $environment
      instance_name: $instance_name
      report_month: $report_month
      rpo: $rpo
      rto: $rto
      ops_id: $key
    ) {
      application
      environment
      instance_name
      ops_id
      report_month
      rpo
      rto
    }
  }
`;

const DELETED_RTO_RPO = gql`
  mutation MyMutation($company_name: String!, $key: String!) {
    delete_rto_rpo(ops_id: $key, company_name: $company_name) {
      application
      environment
      instance_name
      ops_id
      report_month
      rpo
      rto
    }
  }
`;

const ADD_ACTION_ITEM = gql`
  mutation MyMutation(
    $category: String!
    $company_name: String!
    $owner: String!
    $points_discussed: String!
    $remarks: String!
    $status: String!
    $report_month: String!
    $usagedate: String!
  ) {
    insert_action_item_slm(
      category: $category
      company_name: $company_name
      owner: $owner
      points_discussed: $points_discussed
      remarks: $remarks
      status: $status
      report_month: $report_month
      usagedate: $usagedate
    ) {
      action_item_id
      category
      company
      date
      month
      owner
      points_discussed
      remarks
      status
    }
  }
`;

const LIST_ACTION_ITEM = gql`
  query MyQuery($date: String!, $company_name: String!) {
    newfunctionquery20(company_name: $company_name, report_month: $date) {
      action_item_id
      category
      company
      date
      month
      owner
      points_discussed
      remarks
      status
    }
  }
`;

const UPDATE_ACTION_ITEM = gql`
  mutation MyMutation(
    $category: String!
    $company_name: String!
    $owner: String!
    $points_discussed: String!
    $remarks: String!
    $status: String!
    $report_month: String!
    $usagedate: String!
    $action_item_id: String!
  ) {
    update_action_item_slm(
      category: $category
      company_name: $company_name
      owner: $owner
      points_discussed: $points_discussed
      remarks: $remarks
      status: $status
      report_month: $report_month
      usagedate: $usagedate
      action_item_id: $action_item_id
    ) {
      action_item_id
      category
      company
      date
      month
      owner
      points_discussed
      remarks
      status
    }
  }
`;
const DELETE_ACTION_ITEM = gql`
  mutation MyMutation($action_item_id: String!, $company_name: String!) {
    delete_action_item_slm(
      action_item_id: $action_item_id
      company_name: $company_name
    ) {
      action_item_id
      company
      date
      month
      owner
      points_discussed
      remarks
      status
      category
    }
  }
`;
const ADD_PROJECT_STATUS = gql`
  mutation MyMutation(
    $company_name: String!
    $next_milestone: String!
    $progress: String!
    $project_name: String!
    $project_phase: String!
    $project_target_date: String!
    $report_date: String!
    $report_month: String!
    $start_date: String!
    $status: String!
  ) {
    insert_project_status(
      company_name: $company_name
      next_milestone: $next_milestone
      progress: $progress
      project_name: $project_name
      project_phase: $project_phase
      project_target_date: $project_target_date
      report_date: $report_date
      report_month: $report_month
      start_date: $start_date
      status: $status
    ) {
      company
      next_milestone
      progress
      project_name
      project_phase
      project_target_date
      report_date
      month
      start_date
      status
      status_id
    }
  }
`;
const LIST_PROJECT_STATUS = gql`
  query MyQuery($date: String!, $company_name: String!) {
    newfunctionquery21(company_name: $company_name, report_month: $date) {
      company
      next_milestone
      progress
      project_name
      project_phase
      project_target_date
      report_date
      start_date
      status
      status_id
    }
  }
`;
const UPDATE_PROJECT_STATUS = gql`
  mutation MyMutation(
    $company_name: String!
    $next_milestone: String!
    $progress: String!
    $project_name: String!
    $project_phase: String!
    $project_target_date: String!
    $report_date: String!
    $report_month: String!
    $start_date: String!
    $status: String!
    $status_id: String!
  ) {
    update_project_status(
      company_name: $company_name
      next_milestone: $next_milestone
      progress: $progress
      project_name: $project_name
      project_phase: $project_phase
      project_target_date: $project_target_date
      report_date: $report_date
      report_month: $report_month
      start_date: $start_date
      status: $status
      status_id: $status_id
    ) {
      company
      next_milestone
      progress
      project_name
      project_phase
      project_target_date
      report_date
      month
      start_date
      status
      status_id
    }
  }
`;
//DELETE_PROJECT_STATUS
const DELETE_PROJECT_STATUS = gql`
  mutation MyMutation($status_id: String!, $company_name: String!) {
    delete_project_status_slm(
      company_name: $company_name
      status_id: $status_id
    ) {
      company
      next_milestone
      progress
      project_name
      project_phase
      project_target_date
      report_date
      start_date
      status
      status_id
    }
  }
`;
const ADD_KEY_ACTIVITES = gql`
  mutation MyMutation(
    $action_token: String!
    $application: String!
    $category: String!
    $company_name: String!
    $cost_impact: String!
    $description: String!
    $report_date: String!
    $report_month: String!
    $severity: String!
  ) {
    insert_key_activities_slm(
      action_token: $action_token
      application: $application
      category: $category
      company_name: $company_name
      cost_impact: $cost_impact
      description: $description
      report_date: $report_date
      report_month: $report_month
      severity: $severity
    ) {
      action_token
      application
      category
      company
      cost_impact
      date
      description
      key_id
      month
      severity
    }
  }
`;
//LIST_KEY_ACTIVITES
const LIST_KEY_ACTIVITES = gql`
  query MyQuery($date: String!, $company_name: String!) {
    newfunctionquery22(company_name: $company_name, report_month: $date) {
      action_token
      application
      category
      company
      cost_impact
      date
      description
      key_id
      month
      severity
    }
  }
`;
const UPDATE_KEY_ACTIVITES = gql`
  mutation MyMutation(
    $action_token: String!
    $application: String!
    $category: String!
    $company_name: String!
    $cost_impact: String!
    $description: String!
    $report_date: String!
    $report_month: String!
    $severity: String!
    $key_id: String!
  ) {
    update_key_activities_slm(
      action_token: $action_token
      application: $application
      category: $category
      company_name: $company_name
      cost_impact: $cost_impact
      description: $description
      report_date: $report_date
      report_month: $report_month
      severity: $severity
      key_id: $key_id
    ) {
      action_token
      application
      category
      company
      cost_impact
      date
      description
      key_id
      month
      severity
    }
  }
`;
const DELETE_KEY_ACTIVITES = gql`
  mutation MyMutation($key_id: String!, $company_name: String!) {
    delete_key_activities_slm(key_id: $key_id, company_name: $company_name) {
      action_token
      application
      category
      company
      cost_impact
      date
      description
      key_id
      month
      severity
    }
  }
`;

const LIST_INVOICE_TABLE_OLD = gql`
  query MyQuery($company_name: String!, $date: String!) {
    newfunctionquery31(company_name: $company_name, report_month: $date) {
      billing_currency
      client
      discount
      invoiceid
      account_name
      without_tax
      tax
      month
      och_billing_cost
      och_invoiceid
      och_platform_cost
      och_tax
      status
    }
  }
`;

const UPDATE_INVOICE_TABLE_OLD = gql`
  mutation MyMutation(
    $billing_currency: String!
    $company_name: String!
    $discount: String!
    $och_billing_cost: String!
    $och_invoiceid: String!
    $och_platform_cost: String!
    $och_tax: String!
    $month: String!
    $status: String!
    $invoiceid: String!
    $account_name: String!
  ) {
    spp_billing_insert_update(
      billing_currency: $billing_currency
      company_name: $company_name
      discount: $discount
      och_billing_cost: $och_billing_cost
      och_invoiceid: $och_invoiceid
      och_platform_cost: $och_platform_cost
      och_tax: $och_tax
      report_month: $month
      status: $status
      invoiceid: $invoiceid
      account_name: $account_name
    ) {
      account_name
      invoiceid
      billing_currency
      client
      discount
      month
      och_billing_cost
      och_invoiceid
      och_platform_cost
      och_tax
      status
    }
  }
`;
const ADD_INVOICE_TABLE = gql`
  mutation MyMutation(
    $billing_currency: String!
    $billing_entity: String!
    $cloud: String!
    $cloud_invoice_no: String!
    $discount: String!
    $invoice_no: String!
    $och_tax: String!
    $platform_cost: String!
    $report_month: String!
    $resell_partner: String!
    $status: String!
    $total_invoice_amount: String!
    $total_usage_amount: String!
    $usage: String!
    $address_1: String!
    $address_2: String!
    $city: String!
    $state: String!
    $pincode: String!
    $customer: String!
    $country: String!
  ) {
    spp_billing_insert(
      billing_currency: $billing_currency
      billing_entity: $billing_entity
      cloud: $cloud
      customer: $customer
      cloud_invoice_no: $cloud_invoice_no
      discount: $discount
      invoice_no: $invoice_no
      report_month: $report_month
      och_tax: $och_tax
      platform_cost: $platform_cost
      resell_partner: $resell_partner
      status: $status
      total_invoice_amount: $total_invoice_amount
      total_usage_amount: $total_usage_amount
      usage: $usage
      address_1: $address_1
      address_2: $address_2
      city: $city
      state: $state
      pincode: $pincode
      state: $state
      country: $country
    ) {
      spp_usage
      spp_total_usage_amount
      spp_total_invoice_amount
      spp_tax
      spp_status
      spp_resell_partner
      spp_platform_cost
      spp_month
      spp_invoice_no
      spp_discount
      spp_cloud_invoice_no
      spp_cloud
      spp_client_name
      spp_billing_entity
      spp_billing_currency
      spp_bill_id
      spp_address_1
      spp_address_2
      spp_city
      spp_state
      spp_country
    }
  }
`;

const LIST_INVOICE_TABLE = gql`
  query MyQuery($date: String!) {
    newfunctionquery33(month: $date) {
      spp_usage
      spp_total_usage_amount
      spp_total_invoice_amount
      spp_tax
      spp_status
      spp_resell_partner
      spp_platform_cost
      spp_month
      spp_invoice_no
      spp_discount
      spp_cloud_invoice_no
      spp_cloud
      spp_client_name
      spp_billing_entity
      spp_billing_currency
      spp_bill_id
      spp_address_1
      spp_address_2
      spp_city
      spp_state
      spp_pincode
      spp_country
    }
  }
`;

const UPDATE_INVOICE_TABLE = gql`
  mutation MyMutation(
    $billing_currency: String!
    $billing_entity: String!
    $cloud: String!
    $cloud_invoice_no: String!
    $discount: String!
    $invoice_no: String!
    $och_tax: String!
    $platform_cost: String!
    $report_month: String!
    $resell_partner: String!
    $status: String!
    $total_invoice_amount: String!
    $total_usage_amount: String!
    $usage: String!
    $address_1: String!
    $address_2: String!
    $city: String!
    $state: String!
    $pincode: String!
    $country: String!
    $customer: String!
    $bill_id: String!
  ) {
    spp_billing_update(
      address_1: $address_1
      address_2: $address_2
      city: $city
      state: $state
      country: $country
      pincode: $pincode
      billing_currency: $billing_currency
      billing_entity: $billing_entity
      cloud: $cloud
      cloud_invoice_no: $cloud_invoice_no
      customer: $customer
      discount: $discount
      invoice_no: $invoice_no
      och_tax: $och_tax

      platform_cost: $platform_cost
      report_month: $report_month
      resell_partner: $resell_partner

      status: $status
      total_invoice_amount: $total_invoice_amount
      total_usage_amount: $total_usage_amount
      usage: $usage
      bill_id: $bill_id
    ) {
      spp_usage
      spp_total_usage_amount
      spp_total_invoice_amount
      spp_tax
      spp_status
      spp_resell_partner
      spp_platform_cost
      spp_month
      spp_invoice_no
      spp_discount
      spp_cloud_invoice_no
      spp_cloud
      spp_client_name
      spp_billing_entity
      spp_billing_currency
      spp_bill_id
      spp_address_1
      spp_address_2
      spp_city
      spp_state
      spp_country
    }
  }
`;
const DELETE_INVOICE_TABLE = gql`
  mutation MyMutation($bill_id: String!) {
    delete_spp_billing(bill_id: $bill_id) {
      spp_usage
      spp_total_usage_amount
      spp_total_invoice_amount
      spp_tax
      spp_status
      spp_resell_partner
      spp_platform_cost
      spp_month
      spp_invoice_no
      spp_discount
      spp_cloud_invoice_no
      spp_cloud
      spp_client_name
      spp_billing_entity
      spp_billing_currency
      spp_bill_id
      spp_address_1
      spp_address_2
      spp_city
      spp_state
      spp_pincode
      spp_country
    }
  }
`;

const ADD_CLIENT_INVOICE = gql`
  mutation MyMutation(
    $billing_currency: String!
    $customer: String!
    $due_date: String!
    $invoiceid: String!
    $issued_date: String!
    $month: String!
    $platform_cost: String!
    $status: String!
    $tax_cost: String!
    $total_invoice_amount: String!
    $usage_cost: String!
    $vendor: String!
    $billtype: String!
    $s3_file_name: String!
  ) {
    spp_cli_insert(
      billing_currency: $billing_currency
      customer: $customer
      due_date: $due_date
      invoiceid: $invoiceid
      issued_date: $issued_date
      month: $month
      platform_cost: $platform_cost
      status: $status
      tax_cost: $tax_cost
      total_invoice_amount: $total_invoice_amount
      usage_cost: $usage_cost
      vendor: $vendor
      billtype: $billtype
      s3_file_name: $s3_file_name
    ) {
      bill_id
      billing_currency
      billingperiod
      billtype
      due_date
      invoiceid
      issued_date
      platform_cost
      status
      tax
      total_invoice_amount
      usage
      vendor
      s3_file_name
    }
  }
`;
const UPDATE_CLIENT_INVOICE = gql`
  mutation MyMutation(
    $billing_currency: String!
    $customer: String!
    $due_date: String!
    $invoiceid: String!
    $issued_date: String!
    $month: String!
    $platform_cost: String!
    $status: String!
    $tax_cost: String!
    $total_invoice_amount: String!
    $usage_cost: String!
    $vendor: String!
    $billtype: String!
    $bill_id: String!
    $s3_file_name: String!
  ) {
    spp_cli_update(
      billing_currency: $billing_currency
      customer: $customer
      due_date: $due_date
      invoiceid: $invoiceid
      issued_date: $issued_date
      month: $month
      platform_cost: $platform_cost
      status: $status
      tax_cost: $tax_cost
      total_invoice_amount: $total_invoice_amount
      usage_cost: $usage_cost
      vendor: $vendor
      billtype: $billtype
      bill_id: $bill_id
      s3_file_name: $s3_file_name
    ) {
      bill_id
      billing_currency
      billingperiod
      billtype
      due_date
      invoiceid
      issued_date
      platform_cost
      status
      tax
      total_invoice_amount
      usage
      vendor
      s3_file_name
    }
  }
`;
const DELETE_CLIENT_INVOICE = gql`
  mutation MyMutation($bill_id: String!, $customer: String!) {
    delete_spp_cli(bill_id: $bill_id, customer: $customer) {
      bill_id
      billing_currency
      billingperiod
      billtype
      due_date
      invoiceid
      issued_date
      platform_cost
      status
      tax
      total_invoice_amount
      usage
      vendor
      s3_file_name
    }
  }
`;

const LIST_INVOICE_DOCUMENT = gql`
  query MyQuery($bill_id: String!) {
    newfunctionquery31(bill_id: $bill_id) {
      spp_address_1
      spp_address_2
      spp_pincode
      spp_usage
      spp_total_usage_amount
      spp_total_invoice_amount
      spp_tax
      spp_status
      spp_state
      spp_resell_partner
      spp_platform_cost
      spp_pincode
      spp_month
      spp_invoice_no
      spp_discount
      spp_cloud_invoice_no
      spp_cloud
      spp_client_name
      spp_city
      spp_billing_entity
      spp_billing_currency
      spp_bill_id
    }
  }
`;

const LIST_CLIENT_INVOICE = gql`
  query MyQuery($customer: String!, $date: String!) {
    newfunctionquery34(customer: $customer, month: $date) {
      bill_id
      billing_currency
      billingperiod
      billtype
      due_date
      invoiceid
      issued_date
      platform_cost
      status
      tax
      total_invoice_amount
      usage
      s3_file_name
      vendor
    }
  }
`;

export {
  ADD_SAVINGS_MODEL,
  GET_PAGINATION_COUNT,
  GET_TAGGING_DOWNLOAD_LIST,
  ADD_ANNUAL_BUDGET,
  LIST_ANNUAL_BUDGET,
  DELETE_ANNUAL_BUDGET,
  ADD_APPLICATION_BUDGET,
  UPDATED_APPLICATION_BUDGET,
  DELETE_APPLICATION_BUDGET,
  UPDATE_ANNUAL_BUDGET,
  LIST_APPLICATION_BUDGET,
  LIST_SAVINGS_MODEL,
  UPDATE_SAVINGS_MODEL,
  DELETE_SAVINGS_MODEL,
  LIST_INSTANCE_TRACKING,
  LIST_SAVINGS_REALIZATION_CHART,
  LIST_ASSET_MANAGED,
  LIST_INSTANCE_RUNTIME,
  CHART_INSTANCE_RUNTIME,
  LIST_APPLICATION_SPEND,
  UNDATE_INSTANCERUN_TIME,
  LIST_PLANNED_AND_ACTIVITY,
  ADD_PLANNED_AND_ACTIVITY,
  UPDATE_PLANNED_AND_ACTIVITY,
  DELETED_PLANNED_AND_ACTIVITY,
  ADD_RTO_RPO,
  LIST_RTO_RPO,
  UPDATE_RTO_RPO,
  DELETED_RTO_RPO,
  ADD_ACTION_ITEM,
  LIST_ACTION_ITEM,
  UPDATE_ACTION_ITEM,
  DELETE_ACTION_ITEM,
  ADD_PROJECT_STATUS,
  LIST_PROJECT_STATUS,
  UPDATE_PROJECT_STATUS,
  DELETE_PROJECT_STATUS,
  ADD_KEY_ACTIVITES,
  LIST_KEY_ACTIVITES,
  UPDATE_KEY_ACTIVITES,
  DELETE_KEY_ACTIVITES,
  LIST_INVOICE_TABLE,
  UPDATE_INVOICE_TABLE,
  ADD_INVOICE_TABLE,
  DELETE_INVOICE_TABLE,
  LIST_INVOICE_DOCUMENT,
  LIST_CLIENT_INVOICE,
  DELETE_CLIENT_INVOICE,
  UPDATE_CLIENT_INVOICE,
  ADD_CLIENT_INVOICE,
};
