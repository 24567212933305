// features/user/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  environmentList: [],
};

const costimizespendSlice = createSlice({
  name: "costimizespend",
  initialState,
  reducers: {
    environmentListMethod: (state, { payload }) => {
      state.environmentList = payload;
    },
  },
  extraReducers: {},
});

export default costimizespendSlice.reducer;
export const { environmentListMethod } = costimizespendSlice.actions;
