import { memo } from "react";
import { Col, Row, Collapse } from "antd";
import { Icon } from "@iconify/react";
const { Panel } = Collapse;

const KeyEvent = () => {
  return (
    <>
      <Row className="mt-4">
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Collapse className="costimize-collapse">
            <Panel
              className="constimize-event-title"
              header="Key Events"
              key="1"
            >
              <ul>
                {/* <li className="constimize-event-list"><Icon icon="octicon:dot-fill-24" style={{fontSize: "15px"}} /> Lorem ipsum dolor sit amet <Icon icon="ri:external-link-fill" style={{color:"#58BBD1"}} /></li>
                                <li className="constimize-event-list"><Icon icon="octicon:dot-fill-24" style={{fontSize: "15px"}} /> Sit deleniti eveniet eum repudiandae blanditiis et earum omnis qui fuga veritatis in molestias fuga. <Icon icon="ri:external-link-fill" style={{color:"#58BBD1"}} /></li> */}
              </ul>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default memo(KeyEvent);
