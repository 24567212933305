import { gql } from "@apollo/client";

const GET_INCIDENT_TRENDS = gql`
  query MyQuery($company_name: String!, $report_month: String!) {
    newfunctionquery25(
      company_name: $company_name
      report_month: $report_month
    ) {
      company
      month
      priority_type
      ticket_id
    }
  }
`;

const GET_REQ_RESPONSE_TIME = gql`
  query MyQuery($company_name: String!, $report_month: String!) {
    newfunctionquery24(
      company_name: $company_name
      report_month: $report_month
    ) {
      action_item_id
      company
      initial_request_time
      month
      resolution_time
      service_level
      target
    }
  }
`;

const SERVICE_REQUEST_TIME = gql`
  query MyQuery($company_name: String!, $report_month: String!) {
    newfunctionquery27(
      company_name: $company_name
      report_month: $report_month
    ) {
      company
      completed_on_time
      month
      request
      request_status
      sr_request_id
    }
  }
`;

const ADD_SERVICE_REQUEST_TIME = gql`
  mutation MyMutation(
    $company_name: String!
    $completed_on_time: String!
    $report_month: String!
    $request_status: String!
    $request_type: String!
  ) {
    insert_service_request_slm(
      company_name: $company_name
      completed_on_time: $completed_on_time
      report_month: $report_month
      request_status: $request_status
      request_type: $request_type
    ) {
      company
      completed_on_time
      month
      request
      request_status
      sr_request_id
    }
  }
`;

const ADD_INCIDENT_TREND = gql`
  mutation MyMutation(
    $company_name: String!
    $priority_type: String!
    $report_month: String!
    $ticket_id: String!
  ) {
    insert_incident_trend_slm(
      company_name: $company_name
      priority_type: $priority_type
      report_month: $report_month
      ticket_id: $ticket_id
    ) {
      company
      month
      priority_type
      ticket_id
    }
  }
`;

const UPDATE_INCIDENT_TREND = gql`
  mutation MyMutation(
    $company_name: String!
    $priority_type: String!
    $report_month: String!
    $ticket_id: String!
  ) {
    update_incident_trend_slm(
      company_name: $company_name
      priority_type: $priority_type
      report_month: $report_month
      ticket_id: $ticket_id
    ) {
      company
      month
      priority_type
      ticket_id
    }
  }
`;

const DELETE_INCIDENT_TREND = gql`
  mutation MyMutation($company_name: String!, $ticket_id: String!) {
    delete_incident_trend_slm(
      company_name: $company_name
      ticket_id: $ticket_id
    ) {
      company
      month
      priority_type
      ticket_id
    }
  }
`;

const UPDATE_SERVICE_REQUEST_TIME = gql`
  mutation MyMutation(
    $company_name: String!
    $completed_on_time: String!
    $report_month: String!
    $request_status: String!
    $request_type: String!
    $sr_id: String!
  ) {
    update_service_request_slm(
      company_name: $company_name
      completed_on_time: $completed_on_time
      report_month: $report_month
      request_status: $request_status
      request_type: $request_type
      sr_id: $sr_id
    ) {
      company
      completed_on_time
      month
      request
      request_status
      sr_request_id
    }
  }
`;
const DELETE_SERVICE_REQUEST_TIME = gql`
  mutation MyMutation($company_name: String!, $sr_id: String!) {
    delete_service_request_slm(company_name: $company_name, sr_id: $sr_id) {
      company
      completed_on_time
      month
      request
      request_status
      sr_request_id
    }
  }
`;
const GET_SECURITY_HUB = gql`
  query MyQuery($company_name: String!, $report_month: String!) {
    newfunctionquery29(
      company_name: $company_name
      report_month: $report_month
    ) {
      company
      month
      security_current_status
      security_id
      standard
    }
  }
`;

const GET_ACTION_ITEM = gql`
  query MyQuery($company_name: String!, $report_month: String!) {
    newfunctionquery20(
      company_name: $company_name
      report_month: $report_month
    ) {
      action_item_id
      category
      company
      date
      month
      owner
      points_discussed
      remarks
      status
    }
  }
`;

const ADD_ACTION_ITEM = gql`
  mutation MyMutation(
    $category: String!
    $company_name: String!
    $owner: String!
    $points_discussed: String!
    $remarks: String!
    $status: String!
    $report_month: String!
    $usagedate: String!
  ) {
    insert_action_item_slm(
      category: $category
      company_name: $company_name
      owner: $owner
      points_discussed: $points_discussed
      remarks: $remarks
      status: $status
      report_month: $report_month
      usagedate: $usagedate
    ) {
      action_item_id
      category
      company
      date
      month
      owner
      points_discussed
      remarks
      status
    }
  }
`;

const UPDATE_ACTION_ITEM = gql`
  mutation MyMutation(
    $category: String!
    $company_name: String!
    $owner: String!
    $points_discussed: String!
    $remarks: String!
    $status: String!
    $report_month: String!
    $usagedate: String!
    $action_item_id: String!
  ) {
    update_action_item_slm(
      category: $category
      company_name: $company_name
      owner: $owner
      points_discussed: $points_discussed
      remarks: $remarks
      status: $status
      report_month: $report_month
      usagedate: $usagedate
      action_item_id: $action_item_id
    ) {
      action_item_id
      category
      company
      date
      month
      owner
      points_discussed
      remarks
      status
    }
  }
`;

const GET_PROJECT_STATUS = gql`
  query MyQuery($company_name: String!, $date: String!) {
    newfunctionquery21(company_name: $company_name, report_month: $date) {
      company
      next_milestone
      progress
      project_name
      project_phase
      project_target_date
      report_date
      start_date
      status
      status_id
    }
  }
`;

const LIST_COMPLIANCE = gql`
  query MyQuery($company_name: String!, $date: String!) {
    newfunctionquery28(company_name: $company_name, report_month: $date) {
      company
      environment
      month
      os
      patch_id
      patch_status
    }
  }
`;

const ADD_PATCH_ITEM = gql`
  mutation MyMutation(
    $company_name: String!
    $environment: String!
    $os: String!
    $patch_status: String!
    $report_month: String!
  ) {
    insert_patch_management_slm(
      company_name: $company_name
      environment: $environment
      os: $os
      patch_status: $patch_status
      report_month: $report_month
    ) {
      company
      environment
      month
      os
      patch_id
      patch_status
    }
  }
`;
const UPDATE_PATCH_ITEM = gql`
  mutation MyMutation(
    $company_name: String!
    $environment: String!
    $os: String!
    $patch_status: String!
    $report_month: String!
    $patch_id: String!
  ) {
    update_patch_management_slm(
      company_name: $company_name
      environment: $environment
      os: $os
      patch_status: $patch_status
      report_month: $report_month
      patch_id: $patch_id
    ) {
      company
      environment
      month
      os
      patch_id
      patch_status
    }
  }
`;

const DELETE_PATCH_ITEM = gql`
  mutation MyMutation($company_name: String!, $patch_id: String!) {
    delete_patch_management_slm(
      company_name: $company_name
      patch_id: $patch_id
    ) {
      company
      environment
      month
      os
      patch_id
      patch_status
    }
  }
`;

const ADD_INITIAL_REQUEST_TIME = gql`
  mutation MyMutation(
    $company_name: String!
    $initial_request_time: String!
    $report_month: String!
    $service_level: String!
    $target: String!
  ) {
    insert_sla_initial_request_response_time_slm(
      company_name: $company_name
      initial_request_time: $initial_request_time
      report_month: $report_month
      service_level: $service_level
      target: $target
    ) {
      action_item_id
      company
      initial_request_time
      month
      resolution_time
      service_level
      target
    }
  }
`;

const UPDATE_INITIAL_REQUEST_TIME = gql`
  mutation MyMutation(
    $company_name: String!
    $initial_request_time: String!
    $report_month: String!
    $service_level: String!
    $target: String!
    $sla_id: String!
    $resolution_time: String!
  ) {
    update_sla_initial_request_response_time_slm(
      company_name: $company_name
      initial_request_time: $initial_request_time
      report_month: $report_month
      resolution_time: $resolution_time
      service_level: $service_level
      sla_id: $sla_id
      target: $target
    ) {
      action_item_id
      company
      initial_request_time
      month
      resolution_time
      service_level
      target
    }
  }
`;

const DELETE_REQ_RES_TIME = gql`
  mutation MyMutation($company_name: String!, $sla_id: String!) {
    delete_sla_initial_request_response_time_slm(
      company_name: $company_name
      sla_id: $sla_id
    ) {
      action_item_id
      company
      initial_request_time
      month
      resolution_time
      service_level
      target
    }
  }
`;
const ADD_KEY_ACTIVITES = gql`
  mutation MyMutation(
    $action_token: String!
    $application: String!
    $category: String!
    $company_name: String!
    $cost_impact: String!
    $description: String!
    $report_date: String!
    $report_month: String!
    $severity: String!
  ) {
    insert_key_activities_slm(
      action_token: $action_token
      application: $application
      category: $category
      company_name: $company_name
      cost_impact: $cost_impact
      description: $description
      report_date: $report_date
      report_month: $report_month
      severity: $severity
    ) {
      action_token
      application
      category
      company
      cost_impact
      date
      description
      key_id
      month
      severity
    }
  }
`;
const UPDATE_KEY_ACTIVITES = gql`
  mutation MyMutation(
    $action_token: String!
    $application: String!
    $category: String!
    $company_name: String!
    $cost_impact: String!
    $description: String!
    $report_date: String!
    $report_month: String!
    $severity: String!
    $key_id: String!
  ) {
    update_key_activities_slm(
      action_token: $action_token
      application: $application
      category: $category
      company_name: $company_name
      cost_impact: $cost_impact
      description: $description
      report_date: $report_date
      report_month: $report_month
      severity: $severity
      key_id: $key_id
    ) {
      action_token
      application
      category
      company
      cost_impact
      date
      description
      key_id
      month
      severity
    }
  }
`;
const DELETE_KEY_ACTIVITES = gql`
  mutation MyMutation($key_id: String!, $company_name: String!) {
    delete_key_activities_slm(key_id: $key_id, company_name: $company_name) {
      action_token
      application
      category
      company
      cost_impact
      date
      description
      key_id
      month
      severity
    }
  }
`;

const ADD_PROJECT_STATUS = gql`
  mutation MyMutation(
    $company_name: String!
    $next_milestone: String!
    $progress: String!
    $project_name: String!
    $project_phase: String!
    $project_target_date: String!
    $report_date: String!
    $report_month: String!
    $start_date: String!
    $status: String!
  ) {
    insert_project_status(
      company_name: $company_name
      next_milestone: $next_milestone
      progress: $progress
      project_name: $project_name
      project_phase: $project_phase
      project_target_date: $project_target_date
      report_date: $report_date
      report_month: $report_month
      start_date: $start_date
      status: $status
    ) {
      company
      next_milestone
      progress
      project_name
      project_phase
      project_target_date
      report_date
      month
      start_date
      status
      status_id
    }
  }
`;
const UPDATE_PROJECT_STATUS = gql`
  mutation MyMutation(
    $company_name: String!
    $next_milestone: String!
    $progress: String!
    $project_name: String!
    $project_phase: String!
    $project_target_date: String!
    $report_date: String!
    $report_month: String!
    $start_date: String!
    $status: String!
    $status_id: String!
  ) {
    update_project_status(
      company_name: $company_name
      next_milestone: $next_milestone
      progress: $progress
      project_name: $project_name
      project_phase: $project_phase
      project_target_date: $project_target_date
      report_date: $report_date
      report_month: $report_month
      start_date: $start_date
      status: $status
      status_id: $status_id
    ) {
      company
      next_milestone
      progress
      project_name
      project_phase
      project_target_date
      report_date
      month
      start_date
      status
      status_id
    }
  }
`;
const DELETE_PROJECT_STATUS = gql`
  mutation MyMutation($status_id: String!, $company_name: String!) {
    delete_project_status_slm(
      company_name: $company_name
      status_id: $status_id
    ) {
      company
      next_milestone
      progress
      project_name
      project_phase
      project_target_date
      report_date
      start_date
      status
      status_id
    }
  }
`;
const LIST_KEY_ACTIVITES = gql`
  query MyQuery($date: String!, $company_name: String!) {
    newfunctionquery22(company_name: $company_name, report_month: $date) {
      action_token
      application
      category
      company
      cost_impact
      date
      description
      key_id
      month
      severity
    }
  }
`;

const DELETE_ACTION_ITEM = gql`
  mutation MyMutation($action_item_id: String!, $company_name: String!) {
    delete_action_item_slm(
      action_item_id: $action_item_id
      company_name: $company_name
    ) {
      action_item_id
      company
      date
      month
      owner
      points_discussed
      remarks
      status
      category
    }
  }
`;

const GET_CHANGE_REQUEST = gql`
  query MyQuery($company_name: String!, $date: String!) {
    newfunctionquery26(company_name: $company_name, report_month: $date) {
      change_request
      change_request_id
      change_request_type
      company
      month
    }
  }
`;

const ADD_CHANGE_REQUEST = gql`
  mutation MyMutation(
    $company_name: String!
    $change_request: String!
    $change_request_type: String!
    $report_month: String!
  ) {
    insert_change_request_slm(
      change_request: $change_request
      change_request_type: $change_request_type
      company_name: $company_name
      report_month: $report_month
    ) {
      change_request
      change_request_id
      change_request_type
      company
      month
    }
  }
`;
const UPDATE_CHANGE_REQUEST = gql`
  mutation MyMutation(
    $company_name: String!
    $change_request: String!
    $change_request_type: String!
    $report_month: String!
    $cr_id: String!
  ) {
    update_change_request_slm(
      change_request: $change_request
      change_request_type: $change_request_type
      company_name: $company_name
      report_month: $report_month
      cr_id: $cr_id
    ) {
      change_request
      change_request_id
      change_request_type
      company
      month
    }
  }
`;
const DELETE_CHANGE_REQUEST = gql`
  mutation MyMutation($company_name: String!, $cr_id: String!) {
    delete_change_request_slm(company_name: $company_name, cr_id: $cr_id) {
      change_request
      change_request_id
      change_request_type
      company
      month
    }
  }
`;
const SLM_COMPLIANCE_INSERT =gql`
mutation MyMutation($compliants: String, 
$cr_type:String, 
$customer: String!, 
$environment:String!,
$month:String!, 
$non_complaints:String, 
$operating_system:String!, 
$priority_type: String!, 
$request_category:String!, 
$request_description:String!, 
$ticket_id: String!, 
$ticket_status: String!, 
$application:String!) {
  slm_compliance_ticket_insert(
  compliants:$compliants, 
  cr_type:$cr_type, 
  customer:$customer, 
  environment: $environment,
  month: $month, 
  non_complaints:$non_complaints, 
  operating_system:$operating_system, 
  priority_type:$priority_type, 
  request_category:$request_category, 
  request_description:$request_description, 
  ticket_id:$ticket_id, 
  ticket_status:$ticket_status, 
  application:$application) {
    company
    compliants
    cr_type
    environment
    application
    month
    non_complaints
    operating_system
    priority_type
    request_category
    request_description
    ticket_id
    ticket_status
  }
}
`;
const SLM_COMPLIANCE_LIST=gql`
query MyQuery($company_name:String!,$report_month:String!) {
  slm_compliance_ticket(company_name:$company_name, report_month:$report_month) {
    application
    environment
    month
    request_category
    ticket_status
    cr_type
    priority_type
    operating_system
    service_request_count
    change_request_count
    incident_count
    compliants
    non_complaints
  }
}
`;
export {
  GET_INCIDENT_TRENDS,
  GET_REQ_RESPONSE_TIME,
  SERVICE_REQUEST_TIME,
  ADD_SERVICE_REQUEST_TIME,
  UPDATE_SERVICE_REQUEST_TIME,
  GET_SECURITY_HUB,
  GET_CHANGE_REQUEST,
  GET_PROJECT_STATUS,
  UPDATE_ACTION_ITEM,
  ADD_ACTION_ITEM,
  GET_ACTION_ITEM,
  LIST_COMPLIANCE,
  DELETE_PATCH_ITEM,
  UPDATE_PATCH_ITEM,
  ADD_PATCH_ITEM,
  ADD_INCIDENT_TREND,
  UPDATE_INCIDENT_TREND,
  DELETE_INCIDENT_TREND,
  ADD_INITIAL_REQUEST_TIME,
  UPDATE_INITIAL_REQUEST_TIME,
  DELETE_REQ_RES_TIME,
  DELETE_SERVICE_REQUEST_TIME,
  UPDATE_KEY_ACTIVITES,
  DELETE_KEY_ACTIVITES,
  ADD_KEY_ACTIVITES,
  ADD_PROJECT_STATUS,
  UPDATE_PROJECT_STATUS,
  DELETE_PROJECT_STATUS,
  LIST_KEY_ACTIVITES,
  DELETE_ACTION_ITEM,
  DELETE_CHANGE_REQUEST,
  UPDATE_CHANGE_REQUEST,
  ADD_CHANGE_REQUEST,
  SLM_COMPLIANCE_INSERT,
  SLM_COMPLIANCE_LIST
};
