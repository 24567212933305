import DrawerPlat from '../../properties/Drawer/DrawerPlat';
import { useState, useEffect } from "react";
import { message, Form, Input, Select, Button, Switch } from 'antd';
import { Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { addcompliance } from "../../action/graphql/slm/SLM_reducers";
// import "./activitytracker.css"
import { useDispatch, useSelector } from 'react-redux';
// import { AddcreateActionItem, updateaddActionitem, deleteActionItem } from "../../action/costimizev2DashboardAction";
const { TextArea } = Input;

const Complianceform = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const company = localStorage.getItem("CognitoIdentityServiceProvider.company");
    const [startDate, setStartDate] = useState(new Date());
    const [requestCategory, setRequestCategory] = useState(null);
    const handleSubmit = (value) => {
        console.log(value)
        console.log("test")
        const input = {
            company_name: company,
            application: value.application,
            compliants: value.compliants || 0,
            cr_type: value.cr_type  || null,
            environment: value.environment,
            non_complaints: value.non_complaints || 0,
            operating_system: value.operating_system,
            priority_type: value.priority_type,
            request_category: value.request_category,
            request_description: value.request_description,
            ticket_id: value.ticket_id,
            ticket_status: value.ticket_status,
            month: `${new Date(value.date).getFullYear()}-${new Date(value.date).getMonth() + 1}-${new Date(value.date).getDate()}`,
        }
        console.log(input);
        if (props.deletestatus) {
            // const action_item_id =props. oldrecords.action_item_id;
            // dispatch(updateaddActionitem({input, action_item_id}))
            // .unwrap()
            // .then(({ data }) => {
            //     message.success('Record Updated');
            //     props.setUpdateStatus(!props.updatestatus);
            //     props.setDrawerStatus(false); 
            //     setButtonLoading(false);

            // })
            // .catch(err => {
            //     console.log(err.message);
            // });
        } else {
            dispatch(addcompliance({ input }))
                .unwrap()
                .then(({ data }) => {
                    console.log(data)
                    message.success('New Records Added');
                    props.setUpdateStatus(!props.updatestatus);
                    props.setDrawerStatus(false);
                    // setButtonLoading(false);   
                })
                .catch(err => {
                    console.log(err.message);
                });
        }
    }
    const handleClick =(value)=>{
        // console.log(value)
        console.log("value")
        setRequestCategory(value)
    }
    return (
        <>
            <DrawerPlat
                drawerstatus={props.drawerstatus}
                changeDrawerStatus={props.changeDrawerStatus}
                // deletestatus={  props.deletestatus}
                title=" Compliance"
            // rowDelete={rowDelete}
            // confirmLoading = {confirmLoading}
            // setDrawerDeleteStatus={setDrawerDeleteStatus}
            >
                <Form
                    layout="vertical"
                    onFinish={handleSubmit}
                    form={form}
                >
                    <Row>
                        <Col>
                            <Form.Item
                                name="ticket_id"
                                label="Ticket ID"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        width: "100%",
                                    }}

                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name="environment"
                                label="Environment"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                 <Select
                                    showSearch
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[{
                                        value: "production",
                                        label: "Production",
                                    },
                                    {
                                        value: "non-production",
                                        label: "Non Production",
                                    },
                                   ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>

                        <Col>
                            <Form.Item
                                name="application"
                                label="Application"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        width: "100%",
                                    }}

                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name="date"
                                label="Date"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <DatePicker className='datepicker'
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                name="request_category"
                                label="Request Category"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                               
                            >
                               <Select
                                    showSearch
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[{
                                        value: "change-request",
                                        label: "Change Requst",
                                    },
                                    {
                                        value: "service-request",
                                        label: "Service Requst",
                                    },
                                    {
                                        value: "patch",
                                        label: "Patch",
                                    },
                                    {
                                        value: "incident",
                                        label: "Incident",
                                    },
                                   ]}
                                   onChange={handleClick}
                                //    onChange={(value) => setRequestCategory(value)}
                               
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name="priority_type"
                                label="Priority Type"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[{
                                        value: "p1",
                                        label: "P1 Critical",
                                    },
                                    {
                                        value: "p2",
                                        label:  "P2 Moderate",
                                    },
                                    {
                                        value: "p3",
                                        label: "P3 Low",
                                    },
                                  
                                   ]}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                name="request_description"
                                label="Request Description"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        width: "100%",
                                    }}

                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name="cr_type"
                                label="CR Type"
                                disabled={!requestCategory || requestCategory !== 'change-request'}
                            >
                                <Select
                                    showSearch
                                    disabled={!requestCategory || requestCategory !== 'change-request'}
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[{
                                        value: "emergency",
                                        label: "Emergency",
                                    },
                                    {
                                        value: "normal",
                                        label:  "Normal",
                                    },
                                    {
                                        value: "expedited",
                                        label: "Expedited",
                                    },
                                    {
                                        value: "failed",
                                        label: "Failed",
                                    },
                                    {
                                        value: "cancelled",
                                        label:  "Cancelled",
                                    },
                                    {
                                        value: "unauthorized",
                                        label: "Un-authorized",
                                    },
                                  
                                   ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                name="operating_system"
                                label="Operating System"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[{
                                        value: "windows",
                                        label: "Windows",
                                    },
                                    {
                                        value: "linux",
                                        label:  "Linux",
                                    },
                                  
                                  
                                   ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name="compliants"
                                label="Compliants"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: '',
                                //     },
                                // ]}
                            >
                                <Input
                                disabled={!requestCategory || requestCategory !== 'patch'}
                                    style={{
                                        width: "100%",
                                    }}

                                />
                            </Form.Item>
                        </Col>

                    </Row>



                    <Row>

                        <Col>
                            <Form.Item
                                name="non_complaints"
                                label="Non Compliants"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: '',
                                //     },
                                // ]}
                            >
                                <Input
                                 disabled={!requestCategory || requestCategory !== 'patch'}
                                    style={{
                                        width: "100%",
                                    }}

                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name="ticket_status"
                                label="Ticket Status"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        width: "100%",
                                    }}

                                />
                            </Form.Item>
                        </Col>
                    </Row>




                    <Row>
                        <Form.Item >
                            <Button htmlType="button" onClick={() => props.setDrawerStatus(false)}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" style={{ background: "#1b5f9d", color: "#fff", float: "right" }}  >
                                Submit
                            </Button>

                        </Form.Item>
                    </Row>

                </Form>

            </DrawerPlat>
        </>
    )
}
export default Complianceform;
