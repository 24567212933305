import { Table, Typography } from "antd";
import Dropdown from "react-bootstrap/Dropdown";
import { MDBIcon } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { listAssetManaged } from "../../action/costimizev2DashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

const { Text } = Typography;

const ProductionTable = () => {
  const dispatch = useDispatch();
  const {
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedTopdate,
    selectedos,
    selectedservices,
    selecteddb,
    selectedinfra,
  } = useSelector((state) => state.constimize);
  // const { companyName } = useSelector((state) => state.user);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [tabledata, setTabledata] = useState([]);
  const columns = [
    {
      title: "Application Name",
      dataIndex: "Application_Name",
      key: "Application_Name",

      //   sorter: (a, b) => {
      //       return ((a.date) > (b.date) ? -1 : 1);
      //   },
    },
    {
      title: "Environment Name",
      dataIndex: "Environment_Name",
      key: "Environment_Name",

      //   sorter: (a, b) => {
      //       return ((a.date) > (b.date) ? -1 : 1);
      //   },
    },
    {
      title: "Running Hours",
      dataIndex: "Running_Hours",
      key: "Running_Hours",
      //   sorter: (a, b) => {
      //       return ((a.sow_status) > (b.sow_status) ? -1 : 1);
      //   },
    },
    {
      title: "Operating System",
      dataIndex: "Operating_System",
      key: "Operating_System",
      //   sorter: (a, b) => {
      //       return ((a.project) > (b.project) ? -1 : 1);
      //   },
    },
    {
      title: "CPU Core",
      dataIndex: "CPU_Core",
      key: "CPU_Core",
      render: (record, index) => {
        if (index.cpu_core_sub) {
          return (
            <>
              {record}{" "}
              {index.cpu_core_sub >= 0 ? (
                <span className="text-success-icon-custom">
                  {" "}
                  <MDBIcon
                    fas
                    icon="caret-up"
                    style={{ fontSize: "10px" }}
                  />{" "}
                  {index.cpu_core_sub}{" "}
                </span>
              ) : (
                <span className="text-danger-icon-custom">
                  {" "}
                  <MDBIcon
                    fas
                    icon="caret-down"
                    style={{ fontSize: "10px" }}
                  />{" "}
                  {index.cpu_core_sub}{" "}
                </span>
              )}
            </>
          );
        } else {
          return record;
        }
      },
      //   sorter: (a, b) => {
      //       return ((a.project_phase) > (b.project_phase) ? -1 : 1);
      //   },
    },
    {
      title: "Memory",
      dataIndex: "Memory",
      key: "Memory",
      render: (record, index) => {
        if (index.memory_sub) {
          return (
            <>
              {record}{" "}
              {index.memory_sub >= 0 ? (
                <span className="text-success-icon-custom">
                  {" "}
                  <MDBIcon
                    fas
                    icon="caret-up"
                    style={{ fontSize: "10px" }}
                  />{" "}
                  {index.memory_sub}
                </span>
              ) : (
                <span className="text-danger-icon-custom">
                  {" "}
                  <MDBIcon
                    fas
                    icon="caret-down"
                    style={{ fontSize: "10px" }}
                  />{" "}
                  {index.memory_sub}
                </span>
              )}
            </>
          );
        } else {
          return record;
        }
      },
      //   sorter: (a, b) => {
      //       return ((a.priority) > (b.priority) ? -1 : 1);
      //   },
    },
    {
      title: "Disk Storage GB",
      dataIndex: "Disk_Storage_GB",
      key: "Disk_Storage_GB",
      render: (record, index) => {
        if (index.disk_storage_db_sub) {
          return (
            <>
              {record}{" "}
              {index.disk_storage_db_sub >= 0 ? (
                <span className="text-success-icon-custom">
                  {" "}
                  <MDBIcon
                    fas
                    icon="caret-up"
                    style={{ fontSize: "10px" }}
                  />{" "}
                  {index.disk_storage_db_sub}
                </span>
              ) : (
                <span className="text-danger-icon-custom">
                  {" "}
                  <MDBIcon
                    fas
                    icon="caret-down"
                    style={{ fontSize: "10px" }}
                  />{" "}
                  {index.disk_storage_db_sub}
                </span>
              )}
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "VM Count",
      dataIndex: "VM_Count",
      key: "VM_Count",
      render: (record, index) => {
        if (index.vm_count_sub) {
          return (
            <>
              {record}{" "}
              {index.vm_count_sub >= 0 ? (
                <span className="text-success-icon-custom">
                  {" "}
                  <MDBIcon
                    fas
                    icon="caret-up"
                    style={{ fontSize: "10px" }}
                  />{" "}
                  {index.vm_count_sub}
                </span>
              ) : (
                <span className="text-danger-icon-custom">
                  {" "}
                  <MDBIcon
                    fas
                    icon="caret-down"
                    style={{ fontSize: "10px" }}
                  />{" "}
                  {index.vm_count_sub}
                </span>
              )}
            </>
          );
        } else {
          return record;
        }
      },
      // sorter: (a, b) => {
      //     return ((a.next_target_date) > (b.next_target_date) ? -1 : 1);
      // },
    },

    // {
    //     title: ' ',
    //     dataIndex: 'action',
    //     key: 'action',
    //     render: (record, index) => {

    //         if(index.type === 'data'){
    //             const edit =
    //             <Dropdown.ItemText className="plat-dropdown-item-text" >
    //             <span className='plat-box-item-title list-expand-left'>
    //                 Edit
    //             </span>
    //             <span className='plat-box-item-title list-expand-right'>
    //             <Icon
    //                 icon="material-symbols:edit-outline"
    //                 class="table-add-records-icon"
    //             ></Icon>
    //             </span>
    //         </Dropdown.ItemText>

    //             var addicon = '';
    //             if(index.key == data.length){
    //                 addicon =
    //                  <Dropdown.ItemText className="plat-dropdown-item-text">
    //                  <span className='plat-box-item-title list-expand-left'>
    //                      Add
    //                  </span>
    //                  <span className='plat-box-item-title list-expand-right'>
    //                  <Icon icon="material-symbols:add-box-outline"  class="table-add-records-icon"></Icon>
    //                  </span>
    //              </Dropdown.ItemText>
    //             }
    //             return (
    //             <>
    //                <Dropdown className='plat-costimize-dropdown plat-box-costimize-dropdown'>
    //                     <Dropdown.Toggle className='plat-three-dort plat-tap-menu plat-box-three-dort' id="dropdown-button-dark-example1" variant="secondary">
    //                         <Icon icon="ph:dots-three-bold" class='plat-expandalt-icon-view'></Icon>
    //                     </Dropdown.Toggle>
    //                     <Dropdown.Menu className='plat-box-menu-list more-details'>
    //                         {edit}
    //                         {addicon}
    //                     </Dropdown.Menu>
    //                 </Dropdown>
    //             </>
    //             )
    //         }
    //     }
    // },
  ];

  const tableProps = {};

  useEffect(() => {
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      compnay_name: companyName,
      os: selectedos.toString(),
      selectedservices: selectedos.toString(),
      selecteddb: selectedos.toString(),
      selectedinfra: selectedos.toString(),
      date: selectedTopdate,
    };
    dispatch(listAssetManaged(apivalue))
      .unwrap()
      .then(({ data }) => {
        const list = data.newfunctionquery13.map((data, index) => {
          return {
            key: index,
            type: "data",
            action: "",
            Application_Name: data.applications,
            Environment_Name: "",
            Running_Hours: data.running_hours,
            Operating_System: data.operating_system,
            CPU_Core: data.cpu,
            cpu_core_sub: "",
            Memory: data.memory,
            memory_sub: "",
            Disk_Storage_GB: "",
            disk_storage_db_sub: "",
            VM_Count: data.vm_count,
            vm_count_sub: "",
          };
        });
        setTabledata(list);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <>
      <div className="budget-allocated-table">
        {/* <h1 className='plat-table-title'>Production</h1> */}
        <div className="table-width">
          <div className="table-planned-actual">
            <Table
              {...tableProps}
              className="savings_new_table"
              columns={columns}
              dataSource={tabledata}
              pagination={true}
              // rowClassName={(record, index) => record.type === 'footer' ? 'savings-table-footer-new' : ''}
              summary={(pageData) => {
                return (
                  <>
                    <Table.Summary.Row className="savings-table-footer-new">
                      <Table.Summary.Cell index={0}>
                        March Month Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row className="savings-table-footer-new">
                      <Table.Summary.Cell index={0}>
                        February Month Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row className="savings-table-footer">
                      <Table.Summary.Cell index={0}>
                        January Month Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7}>
                        <Text>0</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductionTable;
