import { Icon } from "@mui/material";
import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";

function SummaryViewTable({ severityTableData }) {
  // useEffect(() => {
  //   console.log(severityTableData);
  // }, [severityTableData]);
  const columns = [
    {
      dataField: "severity",
      text: "Priority Level",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
        borderLeftRadius: "5px",
      },
      formatter: (cell, row) => {
        return (
          <>
            {cell === "Critical" ? (
              <>
                <div className="circle-critical"></div> &nbsp;
                {cell}
              </>
            ) : cell === "Warning" ? (
              <>
                <div className="circle-warn"></div> &nbsp;
                {cell}
              </>
            ) : cell === "Ok" ? (
              <>
                <div className="circle-ok"></div> &nbsp;
                {cell}
              </>
            ) : cell === "Up" ? (
              <>
                <div className="circle-up"></div> &nbsp;
                {cell}
              </>
            ) : cell === "Unknown" ? (
              <>
                <div className="circle-unknown"></div> &nbsp;
                {cell}
              </>
            ) : cell === "Down" ? (
              <>
                <div className="circle-down"></div> &nbsp;
                {cell}
              </>
            ) : cell === "Unreachable" ? (
              <>
                <div className="circle-unreachable"></div> &nbsp;
                {cell}
              </>
            ) : cell === "Insufficient_data" ? (
              <>
                {" "}
                <div className="circle-unknown"></div> &nbsp;
                {cell}
              </>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      dataField: "server_count",
      text: "Distinct Servers Count",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
    },
    {
      dataField: "alert_count",
      text: "Alerts Count",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
    },
    {
      dataField: "cams_alert",
      text: `Smart Alert Processed  `,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
    },
  ];
  const data = [
    {
      priorityLevel: "Critical",
      serverCount: "33",
      alertsCount: "12",
      camsProcessed: "2",
    },
    {
      priorityLevel: "Warning",
      serverCount: "33",
      alertsCount: "12",
      camsProcessed: "2",
    },
    {
      priorityLevel: "Ok",
      serverCount: "33",
      alertsCount: "12",
      camsProcessed: "2",
    },
    {
      priorityLevel: "Up",
      serverCount: "33",
      alertsCount: "12",
      camsProcessed: "2",
    },
    {
      priorityLevel: "Down",
      serverCount: "33",
      alertsCount: "12",
      camsProcessed: "2",
    },
    {
      priorityLevel: "Unreachable",
      serverCount: "33",
      alertsCount: "12",
      camsProcessed: "2",
    },
    {
      priorityLevel: "Critical",
      serverCount: "33",
      alertsCount: "12",
      camsProcessed: "2",
    },
    {
      priorityLevel: "Warning",
      serverCount: "33",
      alertsCount: "12",
      camsProcessed: "2",
    },
    {
      priorityLevel: "Critical",
      serverCount: "33",
      alertsCount: "12",
      camsProcessed: "2",
    },
    {
      priorityLevel: "Warning",
      serverCount: "33",
      alertsCount: "12",
      camsProcessed: "2",
    },
  ];
  return (
    <div>
      {" "}
      <div className="summary-view-table">
        <BootstrapTable
          keyField="id"
          data={severityTableData}
          columns={columns}
          bordered={false}
          noDataIndication="No Records"
        />
      </div>
    </div>
  );
}

export default SummaryViewTable;
