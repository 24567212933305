import React from "react";
import footerLogo from "../../assets/images/homepage/1ch-footer-logo.png";
import { Icon } from "@iconify/react";

function footer() {
  return (
    <>
      <footer>
        <div className="footer-landing-page">
          <div className="row footer-padding">
            <div className="col-lg-4">
              <div className="">
                <div className="footer-logo">
                  <img src={footerLogo} alt="1ch-logo" />
                </div>
                <div className="social-media-logos">
                  <span className="follow-text">Follow us</span>
                  <span className="social-icons">
                    <div>
                      <Icon icon="skill-icons:twitter" />
                    </div>
                    <div>
                      <Icon icon="devicon:facebook" />
                    </div>
                    <div>
                      <Icon icon="logos:linkedin-icon" />
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="col"></div>

            <div className="col ">
              <div className="about">
                <h4>About</h4>
                <ul>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://www.1cloudhub.com/about/", "_blank");
                    }}
                  >
                    Our Story
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://www.1cloudhub.com/leadership-team/",
                        "_blank"
                      );
                    }}
                  >
                    Leadership
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://www.1cloudhub.com/press/", "_blank");
                    }}
                  >
                    Media
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://www.1cloudhub.com/topics/career/",
                        "_blank"
                      );
                    }}
                  >
                    Careers
                  </li>
                </ul>
              </div>
            </div>
            <div className="col ">
              <div className="about">
                <h4>Categories</h4>
                <ul>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://www.1cloudhub.com/our-offerings/",
                        "_blank"
                      );
                    }}
                  >
                    Our Offerings
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://www.1cloudhub.com/blog/", "_blank");
                    }}
                  >
                    Blogs
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://www.1cloudhub.com/case-studies/",
                        "_blank"
                      );
                    }}
                  >
                    Case Studies
                  </li>
                  <li>FAQs</li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://www.1cloudhub.com/privacy-policy/",
                        "_blank"
                      );
                    }}
                  >
                    Privacy Policy
                  </li>
                </ul>
              </div>
            </div>
            <div className="col ">
              <div className="about">
                <h4>Contact us</h4>
                <ul>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://www.1cloudhub.com/contact-us/",
                        "_blank"
                      );
                    }}
                  >
                    Singapore
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://www.1cloudhub.com/contact-us/",
                        "_blank"
                      );
                    }}
                  >
                    India
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://www.1cloudhub.com/contact-us/",
                        "_blank"
                      );
                    }}
                  >
                    United States
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://www.1cloudhub.com/contact-us/",
                        "_blank"
                      );
                    }}
                  >
                    Philippines
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://www.1cloudhub.com/contact-us/",
                        "_blank"
                      );
                    }}
                  >
                    Malaysia
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" d-flex justify-content-center p-5 copyrights">
            <div className="">
              &#169; 2023 CloudStudio. All Rights Reserved.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default footer;
