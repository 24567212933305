import { gql } from "@apollo/client";

const CHART_BOT_RESPONSE = gql`
  query MyQuery(
    $date_time: String!
    $schema: String!
    $user_name: String!
    $cloud: String!
    $query: String!
    $user_session: String!
  ) {
    chatbot_chat(
      date_time: $date_time
      event_type: "chat"
      schema: $schema
      user_name: $user_name
      cloud: $cloud
      ai: "vertexai"
      query: $query
      user_session: $user_session
    )
  }
`;

const REQUEST_CHATBOT_CREDIT = gql`
  query MyQuery($userId: String!) {
    chat_bot_credit_request(event_type: "credit_request", user_id: $userId)
  }
`;

const CHART_BOT_PROMPTS_LIST = gql`
  query MyQuery($cloud: String!, $prompt_type: String!, $schema: String!) {
    chatbot_prompts(
      cloud: $cloud
      event_type: "prompts"
      type: $prompt_type
      schema: $schema
    )
  }
`;

export { CHART_BOT_RESPONSE, REQUEST_CHATBOT_CREDIT, CHART_BOT_PROMPTS_LIST };
