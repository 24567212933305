import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SecurityTable from './SecurityTable';
import dummyimage from '../../../../assets/images/secuiry_dummy.png';


const Security = () => {
    return(
        <>
        <Row>
                <Col>
                    {/* <SecurityTable /> */}
                    <div className="incident-trends">
                        <img src={dummyimage} alt="dummyimage" />
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default Security;