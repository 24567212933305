import { JoinFull } from "@mui/icons-material";
import { Table } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { CgDetailsMore } from "react-icons/cg";

const PlannedvsActual = (props) => {
  const [plannedrecords, setPlannedrecords] = useState({
    jan: "0",
    feb: "0",
    mar: "0",
    apr: "0",
    may: "0",
    jun: "0",
    jul: "0",
    aug: "0",
    sep: "0",
    oct: "0",
    nov: "0",
    dec: "0",
    annual_total: "0",
  });
  const [tablerecords, setTableRecords] = useState([]);

  const columns = [
    {
      title: " ",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Jan",
      dataIndex: "jan",
      key: "jan",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.jan) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Feb",
      dataIndex: "feb",
      key: "feb",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.feb) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Mar",
      dataIndex: "mar",
      key: "mar",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.mar) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Apr",
      dataIndex: "apr",
      key: "apr",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.apr) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "May",
      dataIndex: "may",
      key: "may",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.may) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Jun",
      dataIndex: "jun",
      key: "jun",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.jun) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Jul",
      dataIndex: "jul",
      key: "jul",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.jul) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Aug",
      dataIndex: "aug",
      key: "aug",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.aug) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Sep",
      dataIndex: "sep",
      key: "sep",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.sep) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Oct",
      dataIndex: "oct",
      key: "oct",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.oct) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Nov",
      dataIndex: "nov",
      key: "nov",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.nov) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Dec",
      dataIndex: "dec",
      key: "dec",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.dec) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
    {
      title: "Annual Total",
      dataIndex: "annual_total",
      key: "annual_total",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "planned" || number === 0) {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        } else {
          if (Number(plannedrecords.annual_total) > number) {
            return (
              <>
                <span style={{ color: "rgb(10 137 0)" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          } else {
            return (
              <>
                <span style={{ color: "#be1e2d" }}>
                  {" "}
                  $
                  {number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                </span>
              </>
            );
          }
        }
      },
    },
  ];

  const tableProps = {};

  useEffect(() => {
    if (props.actualtablerecords) {
      const actual = props.actualtablerecords;
      var actual_list = [];
      for (let index = 0; index < 12; index++) {
        if (actual[index] != undefined) {
          actual_list.push(Math.round(Number(actual[index].spend)));
        } else {
          actual_list.push(0);
        }
      }
      const current_year = new Date().getFullYear();
      const actual_record = {
        key: "2",
        year: current_year,
        jan: actual_list[0],
        feb: actual_list[1],
        mar: actual_list[2],
        apr: actual_list[3],
        may: actual_list[4],
        jun: actual_list[5],
        jul: actual_list[6],
        aug: actual_list[7],
        sep: actual_list[8],
        oct: actual_list[9],
        nov: actual_list[10],
        dec: actual_list[11],
        annual_total: actual_list.reduce((partialSum, a) => partialSum + a, 0),
        action: "",
        title: "Actual",
        type: "actual",
      };
      //planned
      var planned_records = {
        key: "1",
        year: "2023",
        jan: "0",
        feb: "0",
        mar: "0",
        apr: "0",
        may: "0",
        jun: "0",
        jul: "0",
        aug: "0",
        sep: "0",
        oct: "0",
        nov: "0",
        dec: "0",
        annual_total: "0",
        action: "",
        title: "Planned",
        type: "planned",
      };
      props.yearlistrecords.map((data) => {
        if (Number(data.budget_year) == new Date().getFullYear()) {
          planned_records = {
            key: "1",
            year: Number(data.budget_year),
            jan: data.budget_jan,
            feb: data.budget_feb,
            mar: data.budget_mar,
            apr: data.budget_apr,
            may: data.budget_may,
            jun: data.budget_jun,
            jul: data.budget_jul,
            aug: data.budget_aug,
            sep: data.budget_sept,
            oct: data.budget_oct,
            nov: data.budget_nov,
            dec: data.budget_dec,
            annual_total: data.annual_total,
            action: "",
            title: "Planned",
            type: "planned",
          };
        }
      });

      setTableRecords([planned_records, actual_record]);
    }
  }, [props]);

  return (
    <>
      <div className="budget-allocated-table">
        <h1 className="plat-table-title">Planned vs Actual</h1>
        <div className="table-width">
          <div className="table-planned-actual">
            <Table
              {...tableProps}
              // className="budget-table"
              className="savings_new_table"
              columns={columns}
              dataSource={tablerecords}
              pagination={false}
              // rowClassName={(record, index) => {
              //     console.log(record);
              //     console.log(index);
              // }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PlannedvsActual;
