import React, { useEffect } from "react";
import { memo, useState } from "react";
import {
  Table,
  Spin,
  Popconfirm,
  Modal,
  Space,
  Form,
  Input,
  Row,
  Col,
  Button,
} from "antd";
import { capitalizeFirst } from "../../custom_hook/CustomHook";
import { useSelector } from "react-redux";

function ApproverMain() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const devopsStore = useSelector((state) => state.devops);
  console.log(devopsStore);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(
      devopsStore.approvalTableData.filter((item) => item.status === "Pending")
    );
  }, [tableData]);
  const [records, setRecords] = useState([
    {
      cr_id: "12234",
      cloud_platform: "AWS",
      cr_type: "VM",
      sub_task: " ",
      approved_time: "12/05/2023 10:00AM",
      status: "approved",
      comment: "",
      status_list: "",
      action: "",
    },
    {
      cr_id: "12234",
      cloud_platform: "AWS",
      cr_type: "VM",
      sub_task: " ",
      approved_time: "12/05/2023 10:00AM",
      status: "rejected",
      comment: "",
      status_list: "",
      action: "",
    },
    {
      cr_id: "12234",
      cloud_platform: "AWS",
      cr_type: "VM",
      sub_task: " ",
      approved_time: "12/05/2023 10:00AM",
      status: "waiting",
      comment: "",
      status_list: "",
      action: "",
    },
  ]);
  const [loader, setLoader] = useState(false);

  const handleConfirm = () => {
    // setIsModalOpen(true);
    Modal.success({
      content: (
        <>
          The request has been successfully{" "}
          <span style={{ color: "#3D9D1B" }}>APPROVED</span>
        </>
      ),
    });
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const history_columns = [
    {
      title: "CR ID",

      key: "cr_id",
      sorter: (a, b) => (a.cr_id > b.cr_id ? -1 : 1),
      align: "center",
      render: (a, b, c) => {
        return b.activity_type + b.id;
      },
    },
    {
      title: "Region",
      dataIndex: "cloud_platform",
      key: "cloud_platform",
      sorter: (a, b) => (a.cloud_platform > b.cloud_platform ? -1 : 1),
      align: "center",
    },
    {
      title: "Launch Category",
      dataIndex: "service_name",
      key: "cr_type",
      sorter: (a, b) => (a.cr_type > b.cr_type ? -1 : 1),
      align: "center",
    },
    {
      title: "Sub Task",
      dataIndex: "operation",
      key: "sub_task",
      sorter: (a, b) => (a.sub_task > b.sub_task ? -1 : 1),
      align: "center",
      width: "200",
    },
    {
      title: "Approved time",
      dataIndex: "approved_time",
      key: "approved_time",
      sorter: (a, b) => (a.approved_time > b.approved_time ? -1 : 1),
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => (a.status > b.status ? -1 : 1),
      align: "center",
      render: (text, record, index) => {
        var cell = record.status;
        return (
          <div className="d-flex justify-content-center">
            {cell === "approved" ? (
              <div
                className="p-1 table-col-status"
                style={{
                  color: "#1F6D1E",
                  border: "1px solid #1F6D1E",
                }}
              >
                Approved
              </div>
            ) : cell === "rejected" ? (
              <div
                className="p-1 table-col-status"
                style={{
                  color: "#BE1E2D",
                  border: "1px solid #BE1E2D",
                }}
              >
                Rejected
              </div>
            ) : cell === "Pending" ? (
              <div
                className="p-1 table-col-status"
                style={{
                  color: "#FCAF04",
                  border: "1px solid #FCAF04",
                }}
              >
                Waiting for<br></br> approval
              </div>
            ) : (
              <> </>
            )}
          </div>
        );
      },
    },
    {
      title: " ",
      dataIndex: "status_list",
      key: "status_list",
      align: "center",
      render: (record, index) => {
        return (
          <div>
            <span className="approvel-view-cr">View CR</span>
          </div>
        );
      },
    },
    {
      title: "Comment",
      dataIndex: "och_comments",
      key: "comment",
      align: "center",
      render: (record, index) => {
        if (record === "") {
          return <>-</>;
        } else {
          return record;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record, index) => {
        if (record.status === "Pending") {
          return (
            <>
              <div className="d-flex justify-content-center">
                <Space wrap>
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={handleConfirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button className="dev-approve-button">Approve</button>
                  </Popconfirm>
                </Space>
                <button
                  className="dev-reject-button"
                  onClick={() => setIsModalOpen(true)}
                >
                  Reject
                </button>
              </div>
            </>
          );
        } else {
          return <></>;
        }
      },
    },
  ];
  const handleSubmit = (values) => {};
  return (
    <>
      <Modal
        // title="Basic Modal"
        open={isModalOpen}
        onCancel={handleCancel}
        okText={<span className="reject-btn">Reject</span>}
        cancelText="Cancel"
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              setIsModalOpen(false);
              Modal.success({
                content: (
                  <>
                    The request has been successfully{" "}
                    <span style={{ color: "#BE1E2D" }}>REJECTED</span>
                  </>
                ),
              });
              // onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="comment"
                label="Comment"
                rules={[{ required: true, message: "" }]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <div className="costimize-table-width">
        <div className="instanceTrackingTable">
          <Spin spinning={loader} wrapperClassName="costimize-loader">
            <>
              {" "}
              <Table
                className="devops_Table"
                columns={history_columns}
                dataSource={tableData}
                pagination={true}
                showSizeChanger={false}
              />
            </>
          </Spin>
        </div>
      </div>
    </>
  );
}

export default memo(ApproverMain);
