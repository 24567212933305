import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Input, Select, message } from "antd";
import ReactApexChart from "react-apexcharts";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import { Table, Button, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getChangeRequest,
  addChangeRequest,
  updateChangeRequest,
  deleteChangeRequest,
} from "../../../action/graphql/slm/SLM_reducers";
import { Icon } from "@iconify/react";
const ChangeRequest = () => {
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [formDrawerStatus, setFormDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  var month_list = [];
  const [monthLabelList, setMonthLabelList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [updatestatus, setUpdateStatus] = useState(true);
  const [oldrecords, setOldRecords] = useState({});
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [statusCountObject, setStatusCountObject] = useState({
    emergency: [],
    normal: [],
    expedited: [],
    failed: [],
    cancelled: [],
    unauth: [],
  });
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [selectedMonth, setSelectedMonth] = useState(selectedTopdate);
  const [form] = Form.useForm();

  useEffect(() => {
    const date = selectedMonth;
    dispatch(getChangeRequest({ companyName, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {
        const records = data.newfunctionquery26.map((datas) => {
          console.log(data.newfunctionquery26);
          return {
            company: datas.company,
            change_request: datas.change_request,
            change_request_type: datas.change_request_type,
            cr_id: datas.change_request_id,
            month: datas.month,

            action: (
              <Icon
                icon="material-symbols:edit"
                onClick={() => handleEditClick(datas)}
              />
            ),
            type: "data",
          };
        });
        setTableData(records);

        console.log(records);
      });
  }, [updatestatus, selectedMonth]);
  const handleEditClick = (datas) => {
    console.log("edit");
    setFormDrawerStatus(true);
    setDeleteStatus(true);
    setOldRecords(datas);
    console.log(datas);
    const records = {
      change_request: datas.change_request,
      change_request_type: datas.change_request_type,
      month: datas.month,
    };
    console.log(records);
    form.setFieldsValue(records);
  };

  useEffect(() => {
    // month_list = [];
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      if (
        !month_list.includes(`${date.getFullYear()}-${date.getMonth() + 1}-01`)
      ) {
        month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
      }
    }
    console.log(month_list);
    getChangeRequestCall();
  }, []);

  useEffect(() => {}, [
    statusCountObject,
    monthLabelList,
    updatestatus,
    selectedMonth,
  ]);

  const onEditClick = (e) => {
    console.log(e);
  };
  const getChangeRequestCall = () => {
    console.log(month_list);
    dispatch(getChangeRequest({ companyName, selectedTopdate: month_list }))
      .unwrap()
      .then(({ data }) => {
        let tableDataTemp = [];
        let statusCountObjectTemp = {
          emergency: [],
          normal: [],
          expedited: [],
          failed: [],
          cancelled: [],
          unauth: [],
        };

        let labelArrayTemp = [];
        let graphData = data.newfunctionquery26;
        console.log({ graphData });
        month_list.forEach((month) => {
          let emergencyCount = 0;
          let normalCount = 0;
          let expeditedCount = 0;
          let failedCount = 0;
          let cancelledCount = 0;
          let unAuthorizedCount = 0;

          const checkMonth = moment(month, "YYYY-MM-DD").toDate();
          labelArrayTemp.push(moment(month, "YYYY-MM-DD").format("MMM YY"));
          graphData.forEach((data) => {
            const dataMonth = moment(data.month, "YYYY-MM-DD").toDate();
            if (checkMonth.getMonth() === dataMonth.getMonth()) {
              data.change_request_type.toLowerCase() === "emergency"
                ? emergencyCount++
                : data.change_request_type.toLowerCase() === "normal"
                ? normalCount++
                : data.change_request_type.toLowerCase() === "expedited"
                ? expeditedCount++
                : data.change_request_type.toLowerCase() === "failed"
                ? failedCount++
                : data.change_request_type.toLowerCase() === "cancelled"
                ? cancelledCount++
                : unAuthorizedCount++;
            }
          });

          statusCountObjectTemp.emergency.push(emergencyCount);
          statusCountObjectTemp.normal.push(normalCount);
          statusCountObjectTemp.expedited.push(expeditedCount);
          statusCountObjectTemp.failed.push(failedCount);
          statusCountObjectTemp.cancelled.push(cancelledCount);
          statusCountObjectTemp.unauth.push(unAuthorizedCount);

          setStatusCountObject(statusCountObjectTemp);

          let tableDataObj = {};
          // tableDataObj.month = month;
          // tableDataObj.emergency = emergencyCount;
          // tableDataObj.normal = normalCount;
          // tableDataObj.failed = failedCount;
          // tableDataObj.cancelled = cancelledCount;
          // tableDataObj.unauth = unAuthorizedCount;
          // tableDataObj.expedited = expeditedCount;
          // tableDataObj.month = month;
          // tableDataObj.change_request = change_request;
          // tableDataObj.change_request_type = change_request_type;
          // tableDataObj.cr_id = cr_id;
          tableDataObj.action = (
            <Icon
              icon="material-symbols:edit"
              //onClick={() => handleEditClick(datas)}
              onClick={onEditClick}
            />
          );
          tableDataTemp.push(tableDataObj);
        });
        console.log({ tableData });
        setTableData(tableDataTemp);
        setMonthLabelList(labelArrayTemp);
      });
  };
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  const changeFormDrawerStatus = () => {
    setFormDrawerStatus(false);
    setDrawerStatus(false);
  };

  const series = [
    {
      name: "Emergency",
      data: statusCountObject.emergency,
      color: "#A2C6F6",
    },
    {
      name: "Normal",
      data: statusCountObject.normal,
      color: "#0D5C63",
    },
    {
      name: "Expedited",
      data: statusCountObject.expedited,
      color: "#68CACA",
    },
    {
      name: "Failed",
      data: statusCountObject.failed,
      color: "#EAC435",
    },
    {
      name: "Cancelled",
      data: statusCountObject.cancelled,
      color: "#E40066",
    },
    {
      name: "Un-authorized",
      data: statusCountObject.unauth,
      color: "#301A4B",
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    fill: {
      color: ["#A2C6F6"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: monthLabelList,
    },
    yaxis: {
      decimalsInFloat: 0,
      labels: {
        formatter: function (value) {
          return parseInt(value);
        },
      },
      min: 0,
      max: 10,
      // tickAmount: 1
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    legend: {
      show: true,
      position: "right",
      style: {
        marginTop: "5rem",
      },
    },
  };
  const tableProps = {};

  const columns = [
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Change Request",
      dataIndex: "change_request",
      key: "change_request",
    },
    {
      title: "Change Request Type",
      dataIndex: "change_request_type",
      key: "change_request_type",
    },
    //  {
    //   title: "Emergency",
    //   dataIndex: "emergency",
    //   key: "emergency",
    // },
    // {
    //   title: "Normal",
    //   dataIndex: "normal",
    //   key: "normal",
    // },
    // {
    //   title: "Expedited",
    //   dataIndex: "expedited",
    //   key: "expedited",
    // },
    // {
    //   title: "Failed",
    //   dataIndex: "failed",
    //   key: "failed",
    // },
    // {
    //   title: "Cancelled",
    //   dataIndex: "cancelled",
    //   key: "cancelled",
    // },
    // {
    //   title: "Un-authorized",
    //   dataIndex: "unauth",
    //   key: "unauth",
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  const handleSubmit = (value) => {
    console.log("submit");
    const input = {
      company_name: companyName,
      report_month: `${new Date(value.date).getFullYear()}-${
        new Date(value.date).getMonth() + 1
      }-01`,
      change_request: value.change_request,
      change_request_type: value.change_request_type,
    };
    console.log(input);
    if (deletestatus) {
      const cr_id = oldrecords.change_request_id;
      dispatch(updateChangeRequest({ input, cr_id }))
        .unwrap()
        .then(({ data }) => {
          message.success("Record Updated");
          setUpdateStatus(!updatestatus);
          setFormDrawerStatus(false);
          getChangeRequestCall();
          //setDrawerStatus(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      dispatch(addChangeRequest({ input }))
        .unwrap()
        .then(({ data }) => {
          message.success("New Records Added");
          setUpdateStatus(!updatestatus);
          setFormDrawerStatus(false);
          //setDrawerStatus(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  const addRow = () => {
    setFormDrawerStatus(true);
    setDeleteStatus(false);
    const records = {
      change_request: " ",
      change_request_type: " ",
      month: " ",
    };
    console.log(records);
    form.setFieldsValue(records);
  };
  const rowDelete = () => {
    const cr_id = oldrecords.change_request_id;
    dispatch(deleteChangeRequest({ cr_id, companyName }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        //setDrawerStatus(false);
        setFormDrawerStatus(false);
        setUpdateStatus(!updatestatus);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <DrawerPlat
        drawerstatus={drawerStatus}
        changeDrawerStatus={changeDrawerStatus}
        //deletestatus={deletestatus}
        title="Change Request"
      >
        <div onClick={addRow}>
          <button className="table-add-button" style={{ float: "right" }}>
            {/* <Icon icon="ion:add-circle-outline" /> */}
            <Icon icon="material-symbols:add-circle-outline" />
            Add
          </button>
        </div>
        <Table
          {...tableProps}
          columns={columns}
          dataSource={tableData}
          className="slm"
          style={{ overflowX: "scroll" }}
        />
      </DrawerPlat>

      <DrawerPlat
        drawerstatus={formDrawerStatus}
        changeDrawerStatus={changeFormDrawerStatus}
        deletestatus={deletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        rowDelete={rowDelete}
        title="Change Request"
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          // initialValues={initialValues}
          form={form}
        >
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="date" label="Date">
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  picker="date"
                  // disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="change_request_type"
                label="Change Request Type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  options={[
                    {
                      value: "emergency",
                      label: "Emergency",
                    },
                    {
                      value: "normal",
                      label: "Normal",
                    },
                    {
                      value: "expedited",
                      label: "Expedited",
                    },
                    {
                      value: "failed",
                      label: "Failed",
                    },
                    {
                      value: "cancelled",
                      label: "Cancelled",
                    },
                    {
                      value: "unauthorized",
                      label: "Unauthorized",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="change_request" label="Change Request">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setFormDrawerStatus(true)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>

      <Row gutter={[16, 16]}>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card className="plat-card-1">
            <div>
              <Icon
                icon="material-symbols:edit-note-sharp"
                onClick={() => setDrawerStatus(true)}
                // style={{float : "right"}}
              />
            </div>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={350}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ChangeRequest;
