import React from "react";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Space,
  Popconfirm,
  Form,
  message,
  Table,
  DatePicker,
  Select,
  Input,
} from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import {
  AddPatchmanageItem,
  deletePatchItem,
  listcompliance,
  updatePatchmanageItem,
} from "../../../action/graphql/slm/SLM_reducers";
import { Icon } from "@iconify/react";
const { TextArea } = Input;
const PatchManagementtable = ({
  refreshData,
  productionFilter,
  nonProductionFilter,
}) => {
  const [tablerecords, setTablerecords] = useState([]);
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [selectedMonth, setSelectedMonth] = useState(selectedTopdate);
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [updatestatus, setUpdateStatus] = useState(true);
  const [oldrecords, setOldRecords] = useState({});
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [countsosfilter, setCountsosfilter] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);
  // const [filteredDataNon, setFilteredDataNon] = useState([]);
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const monthFormat = "YYYY-MM-01";
  const [form] = Form.useForm();
  useEffect(() => {
    const date = selectedMonth;

    dispatch(listcompliance({ companyName, date }))
      .unwrap()
      .then(({ data }) => {
        const countsos = data.newfunctionquery28.map((datas) => {
          return {
            company: datas.company,
            environment: datas.environment,
            date: datas.month,
            os: datas.os,
            patch_id: datas.patch_id,
            patch_status: datas.patch_status,
            action: (
              <Icon
                icon="material-symbols:edit"
                onClick={() => handleEditClick(datas)}
              />
            ),
            type: "data",
          };
        });
        setTablerecords(countsos);
        setCountsosfilter(countsos);

        refreshData();

        console.log("list:", countsos);
      });
  }, [updatestatus, selectedMonth]);

  const filteredData = countsosfilter.filter((item) => {
    if (productionFilter && item.environment === "production") {
      return true;
    } else if (nonProductionFilter && item.environment !== "production") {
      return true;
    }
    return false;
  });
  // useEffect(() => {
  //   const filteredCountsos = tablerecords.filter(
  //     (item) => item.environment === "production"
  //   );

  //   setFilteredData(filteredCountsos);
  //   console.log(filteredCountsos);
  //   const filteredCountsosNon = tablerecords.filter(
  //     (item) => item.environment === "non-production"
  //   );

  //   setFilteredDataNon(filteredCountsos);
  //   console.log(filteredCountsosNon);

  // }, [tablerecords]);

  // useEffect(() => {
  //   var records = {
  //     environment: " ",
  //     month: " ",
  //     os: " ",
  //     patch_status: " ",
  //     patch_id: " ",
  //   };
  //   if (oldrecords) {
  //     records = {
  //       environment: oldrecords.environment,
  //       month: oldrecords.month,
  //       os: oldrecords.os,
  //       patch_status: oldrecords.patch_status,
  //       patch_id: oldrecords.patch_id,
  //     };
  //   }
  //   form.setFieldsValue(records);
  // }, [oldrecords]);
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      },
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "OS Type",
      dataIndex: "os",
      key: "os",
    },
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
    },
    {
      title: "Patch Status",
      dataIndex: "patch_status",
      key: "patch_status",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  const addRow = () => {
    setDrawerStatus(true);
    setDeleteStatus(false);
    setOldRecords(null);
    const defaultEnvironment = productionFilter
      ? "production"
      : "non-production";
    const records = {
      environment: defaultEnvironment,
      month: " ",
      os: " ",
      patch_status: " ",
      patch_id: " ",
    };
    form.setFieldsValue(records);
  };
  const handleEditClick = (datas) => {
    setDeleteStatus(true);
    setOldRecords(datas);
    console.log(datas.month);
    setDrawerStatus(true);
    const records = {
      environment: datas.environment,
      date: moment(datas.month, "YYYY-MM-01"),
      os: datas.os,
      patch_status: datas.patch_status,
      patch_id: datas.patch_id,
    };
    console.log(records);
    form.setFieldsValue(records);
    console.log(records.month);
  };
  const rowDelete = () => {
    const patch_id = oldrecords.patch_id;
    dispatch(deletePatchItem({ patch_id, companyName }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        setUpdateStatus(!updatestatus);
        setDrawerStatus(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const onFinish = (value) => {
    console.log("submit");
    const input = {
      company_name: companyName,
      environment: value.environment,
      report_month: `${new Date(value.date).getFullYear()}-${
        new Date(value.date).getMonth() + 1
      }-01`,
      os: value.os,
      patch_status: value.patch_status,
      patch_id: value.patch_id,
    };
    console.log(input);
    if (deletestatus) {
      const patch_id = oldrecords.patch_id;
      dispatch(updatePatchmanageItem({ input, patch_id }))
        .unwrap()
        .then(({ data }) => {
          message.success("Record Updated");
          setUpdateStatus(!updatestatus);
          setDrawerStatus(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      dispatch(AddPatchmanageItem({ input }))
        .unwrap()
        .then(({ data }) => {
          message.success("New Records Added");
          setUpdateStatus(!updatestatus);
          setDrawerStatus(false);
          //refreshData();
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  return (
    <>
      <DrawerPlat
        drawerstatus={drawerStatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        rowDelete={rowDelete}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        title="Patch Management"
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Row>
            <Col xxl={6} xl={6} lg={12} md={24} sm={24} xs={24} className="p-1">
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  format={monthFormat}
                  picker="month"
                  //  selected={startDate}
                  //  onChange={(date) => setStartDate(date)}
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="os"
                label="OS Type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "windows",
                      label: "Windows",
                    },
                    {
                      value: "linux",
                      label: "Linux",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              {/* <Form.Item
                name="environment"
                label="Environment"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "production",
                      label: "Production",
                      //disabled: !productionFilter
                    },
                    {
                      value: "non-production",
                      label: "Non-Production",
                     // disabled: productionFilter,
                    },
                  ]}
                  //defaultValue={productionFilter ? "production" : "non-production"} // Set the default value based on the productionFilter
                />
              </Form.Item> */}
              <Form.Item name="environment" label="Environment">
                <Input
                  defaultValue={
                    productionFilter ? "production" : "non-production"
                  }
                  readOnly
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="patch_status"
                label="Patch Status"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "compliance",
                      label: "Compliance",
                    },
                    {
                      value: "non-compliance",
                      label: "Non-Compliance",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Form.Item>
              <Button htmlType="button" onClick={() => setDrawerStatus(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ background: "#1b5f9d", color: "#fff", float: "right" }}
              >
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </DrawerPlat>
      <div>
        <h2>Patch Management</h2>
        <Button
          className="table-add-button float-right"
          icon={<PlusCircleOutlined />}
          onClick={addRow}
        >
          Add
        </Button>
        <Table
          className="patch-table slm"
          columns={columns}
          //dataSource={tablerecords}
          dataSource={filteredData}
        />
      </div>
    </>
  );
};
export default PatchManagementtable;
