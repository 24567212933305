import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import DetailsView from "../assetandspend/DetailsView";
import { listResourcesSpendDate } from "../../action/costimizeV2Active";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";

const SpendDayResorcesChart = (props) => {
  const dispatch = useDispatch();
  const [detailsviewstatus, setDetailsViewStatus] = useState(false);
  const [popupdetails, setPopupDetails] = useState([]);

  const [chartvalues, setChartValues] = useState({
    value: [],
    categories: [],
    resourceid: [],
  });
  const {
    selectedCloud,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  // useEffect(() => {
  //   let result = props.records.map(({ category }) => category);
  //   setChartValues({
  //     value: props.records.map(({ value }) => value),
  //     categories: props.records.map(({ category }) => category),
  //   });
  // }, [props]);
  useEffect(() => {
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: props.selectedservice,
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date: props.resourcesdate,
      page: props.pagecount,
    };
    props.setPreloader(true);
    dispatch(listResourcesSpendDate({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        var value = [];
        var categories = [];
        var resourceid = [];
        data.daywise_spend_resorce.map((data) => {
          value.push(data.spend);
          categories.push(data.resourcename);
          resourceid.push(data.resourceid);
        });
        setChartValues({
          value: value,
          categories: categories,
          resourceid: resourceid,
        });
        props.setPreloader(false);
      })
      .catch((err) => {
        console.log(err);
        props.setPreloader(false);
      });
  }, [props.pagecount, props.resourcesdate]);
  const series = [
    {
      name: "Resource",
      data: chartvalues.value,
    },
  ];
  const options = {
    chart: {
      type: "bar",
      height: 350,
      // events: {
      //   dataPointSelection: function (event, chartContext, config) {
      //     openPopupDetails({
      //       resourceid: chartvalues.resourceid[config.dataPointIndex],
      //       rosourcename: chartvalues.categories[config.dataPointIndex],
      //       companyName: localStorage.getItem(
      //         "CognitoIdentityServiceProvider.company"
      //       ),
      //     });
      //   },
      // },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: chartvalues.categories,
      // range: 2,
      // max: 100,
      labels: {
        show: true,
        // rotate: 0,
        // maxChars: 10,
        // maxWidth: 10,
        // minHeight: 100,
        maxHeight: 80,
        // formatter: (value) => {
        //   if (value.length > 10) {
        //     return value.substring(0, 10);
        //   }
        //   return value;
        // },
      },
    },
    colors: ["#9C640C"],
    yaxis: {
      labels: {
        formatter: (record) => {
          const num = (Math.round(record * 100) / 100).toFixed(2);

          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
    fill: {
      opacity: 1,
    },
  };

  const openPopupDetails = (data) => {
    setPopupDetails(data);
    setDetailsViewStatus(true);
  };

  return (
    <>
      {detailsviewstatus && (
        <DetailsView
          detailsviewstatus={detailsviewstatus}
          setDetailsViewStatus={setDetailsViewStatus}
          details={popupdetails}
        />
      )}
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </>
  );
};

export default memo(SpendDayResorcesChart);
