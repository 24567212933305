import { memo, useEffect, useState } from "react";
import { Col, Row, Card, Spin } from "antd";
import { Icon } from "@iconify/react";
import TaggingChart from "./TaggingChart";
import ResourcesChart from "./ResourcesChart";
import { useDispatch, useSelector } from "react-redux";
import {
  getUderUtilized,
  listApplicationBudget,
  getStartStopScheduler,
  taggingResources,
} from "../../action/costimizeV2Active";
import resourceicon from "../../../assets/icons/dashboard_resorce_icon.svg";
import TaggingPopup from "./TaggingPopup";
import TaggingBestPractice from "./TaggingBestPractice";

const Compliance = () => {
  const {
    selectedCloud,
    selectedTopdate,
    selectedAccount,
    selectedApplication,
    selectedEnvironment,
    applicationList,
    selecteddb,
    selectedinfra,
    selectedos,
    selectedservices,
  } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  //state
  const [uderresources, setUnderResources] = useState({
    underresourcesper: 0,
    value: 0,
  });
  const [budgetallocation, setBudgetAllocation] = useState({
    budgetpercentage: 0,
    total_application: 0,
    buget_allocation: 0,
    status: false,
  });
  const [startstopscheduler, setStartStopScheduler] = useState({
    completed: 0,
    pending: 0,
    percentage: 0,
    status: false,
  });
  const [modelopen, setModelOpen] = useState(false);
  const [untaggedresources, setUntaggedResources] = useState({
    mandatory_tag: 0,
    automated_tag: 0,
    no_cost_impact_tags: 0,
    total_tag: 0,
    percentage: 0,
    list: [],
  });

  const [taggingpracticestatus, setTaggingPracticeStatus] = useState(false);

  //loader
  const [lresourcesstatus, setLResourcesStatus] = useState(true);
  const [lbudgetstatus, setLBudgetStatus] = useState(true);
  const [ltaggingstatus, setLTaggingStatus] = useState(true);
  const [lschedulerstatus, setSchedulerStatus] = useState(true);

  //Under Utilized Resources & Start Stop Scheduler
  useEffect(() => {
    setLResourcesStatus(true);
    setSchedulerStatus(true);
    var apivalue = {
      cloud: selectedCloud.toString(),
      account: selectedAccount.toString(),
    };
    //Under Utilized Resources
    // console.log({ companyName, selectedTopdate, apivalue });
    dispatch(getUderUtilized({ companyName, selectedTopdate, apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log("data");
        // console.log(data);
        setLResourcesStatus(false);
        if (data.newfunctionquery23.length > 0) {
          setUnderResources({
            underresourcesper: Math.round(
              (Math.round(data.newfunctionquery23[0].underutilized_resources) *
                100) /
                Math.round(data.newfunctionquery23[0].total_resources)
            ),
            value: Math.round(
              data.newfunctionquery23[0].underutilized_resources
            ),
          });
        } else {
          setUnderResources({
            underresourcesper: 0,
            value: 0,
          });
        }
      })
      .catch((err) => {
        setLResourcesStatus(false);
        console.log(err.message);
      });
    // var currentDate = new Date();
    // currentDate.setDate(currentDate.getDate() - 1);
    // let yesterdayDate = `${currentDate.getFullYear()}-${
    //   currentDate.getMonth() + 1
    // }-${currentDate.getDate()}`;
    // //   Start Stop Scheduler
    // console.log({ companyName, yesterdayDate, apivalue });
    var yesterdayDate = [
      `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`,
    ];
    yesterdayDate = yesterdayDate.toString();
    // console.log({ companyName, yesterdayDate, apivalue });
    dispatch(getStartStopScheduler({ companyName, yesterdayDate, apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log("scheduler checking");
        // console.log(data);
        setSchedulerStatus(false);
        const completedList = data.newfunctionquery17.filter(
          (v) => v.status.toLowerCase() === "completed"
        );
        const pendingList = data.newfunctionquery17.filter(
          (v) => v.status.toLowerCase() === "failed"
        );
        var percentage = 0;
        if (data.newfunctionquery17.length > 0) {
          percentage = Math.round(
            (completedList.length * 100) / data.newfunctionquery17.length
          );
        }
        setStartStopScheduler({
          completed: completedList.length,
          pending: pendingList.length,
          percentage: percentage,
          status: true,
        });
      })
      .catch((err) => {
        setSchedulerStatus(false);
        console.log(err.message);
      });
  }, [selectedCloud, selectedTopdate, companyName, selectedAccount]);

  //Budget Allocation
  useEffect(() => {
    setLBudgetStatus(true);
    var budget_year;
    if (selectedTopdate.split(",").length > 1) {
      const datelist = selectedTopdate.split(",").map((data) => {
        return new Date(data).getFullYear();
      });
      const uniqueArr = new Set([...datelist]);
      budget_year = [...uniqueArr].toString();
    } else {
      budget_year = new Date(selectedTopdate).getFullYear();
    }

    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      budget_year: budget_year,
      cloud: selectedCloud.toString(),
      company_name: companyName,
      environment: selectedEnvironment.toString(),
    };
    dispatch(listApplicationBudget({ input }))
      .unwrap()
      .then(({ data }) => {
        // console.log(applicationList);
        setLBudgetStatus(false);
        var percentage = 0;
        if (
          Number.isInteger(
            Math.round(
              (data.list_application_budget.length * 100) /
                applicationList.length
            )
          )
        ) {
          percentage = Math.round(
            (data.list_application_budget.length * 100) / applicationList.length
          );
        }
        setBudgetAllocation({
          budgetpercentage: percentage,
          total_application: applicationList.length,
          buget_allocation: data.list_application_budget.length,
          status: true,
        });
      })
      .catch((err) => {
        setLBudgetStatus(false);
        console.log(err.message);
      });
  }, [
    selectedCloud,
    selectedTopdate,
    companyName,
    selectedAccount,
    selectedApplication,
    selectedEnvironment,
    applicationList,
  ]);

  //tagging
  useEffect(() => {
    setLTaggingStatus(true);
    const input = {
      company_name: companyName,
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      cloud: selectedCloud.toString(),
      environment: selectedEnvironment.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      db: selecteddb.toString(),
      infra: selectedinfra.toString(),
      month: selectedTopdate,
    };

    dispatch(taggingResources({ input }))
      .unwrap()
      .then(({ data }) => {
        // console.log("issue checking");
        // console.log(data);
        const total_tagged = data.newfunctionquery2?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        const automated_tag = data.newfunctionquery2?.filter(
          (v, index) => v.tag_category === "Automated-tag"
        );

        const mandatory_tag = data.newfunctionquery2?.filter(
          (v, index) => v.tag_category === "Mandatory-tag"
        );
        const no_cost_impact_tags = data.newfunctionquery2?.filter(
          (v, index) => v.tag_category === "No-cost-impact-tags"
        );
        //count
        const automated_tag_count = automated_tag?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        const mandatory_tag_count = mandatory_tag?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        const no_cost_impact_tags_count = no_cost_impact_tags?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );

        //tagging count
        const tagged_records = mandatory_tag?.filter(
          (v, index) => v.tagging_details === "tagged"
        );
        const tagged_records_count = tagged_records?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        //partially count
        const partially_records = mandatory_tag?.filter(
          (v, index) => v.tagging_details === "partially_tagged"
        );
        const partially_records_count = partially_records?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        //untagged
        const untagged_records = mandatory_tag?.filter(
          (v, index) => v.tagging_details === "Untagged"
        );
        const untagged_records_count = untagged_records?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        const percentage_value =
          (tagged_records_count * 100) /
          (tagged_records_count +
            partially_records_count +
            untagged_records_count);

        setUntaggedResources({
          mandatory_tag: mandatory_tag_count,
          automated_tag: automated_tag_count,
          no_cost_impact_tags: no_cost_impact_tags_count,
          total_tag: total_tagged,
          percentage: Number.isNaN(percentage_value)
            ? 0
            : percentage_value.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }),
          list: data.newfunctionquery2,
        });
        // console.log("checking");
        // console.log({
        //   mandatory_tag: mandatory_tag_count,
        //   automated_tag: automated_tag_count,
        //   no_cost_impact_tags: no_cost_impact_tags_count,
        //   total_tag: total_tagged,
        //   percentage: Number.isNaN(percentage_value)
        //     ? 0
        //     : percentage_value.toLocaleString(undefined, {
        //         maximumFractionDigits: 2,
        //       }),
        //   list: data.newfunctionquery2,
        // });

        setLTaggingStatus(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLTaggingStatus(false);
      });
  }, [
    selectedCloud,
    selectedTopdate,
    companyName,
    selectedAccount,
    selectedApplication,
    selectedEnvironment,
    selecteddb,
    selectedinfra,
    selectedos,
    selectedservices,
  ]);
  return (
    <>
      {modelopen && (
        <TaggingPopup
          modelopen={modelopen}
          setModelOpen={setModelOpen}
          untaggedresources={untaggedresources}
        />
      )}
      <Row className="mt-3">
        <Col>
          <h1 className="site-color block-title">Compliance</h1>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt-1">
        <Col xxl={8} xl={10} lg={10} md={24} sm={24} xs={24}>
          <Row gutter={[16, 16]}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card className="plat-card-1">
                <Spin
                  spinning={lresourcesstatus}
                  wrapperClassName="costimize-loader"
                >
                  <div>
                    <h1 className="card-title-1">Under Utilized Resources</h1>
                  </div>
                  <div className="mt-3">
                    <h1 className="card-value-1">
                      <img
                        src={resourceicon}
                        style={{ width: "21px", marginRight: "7px" }}
                      />
                      {uderresources.underresourcesper}%
                    </h1>
                  </div>
                  <div className="mt-3">
                    <h1 className="card-1-bottom-value">
                      Underutilized Assets - {uderresources.value}
                    </h1>
                  </div>
                </Spin>
              </Card>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card className="plat-card-1">
                <Spin
                  spinning={lbudgetstatus}
                  wrapperClassName="costimize-loader"
                >
                  <div>
                    <h1 className="card-title-1">Budget Allocation</h1>
                  </div>
                  <div className="mt-3">
                    <h1 className="card-value-1">
                      <Icon
                        icon="solar:money-bag-bold"
                        className="quick-glance-icon"
                      />
                      {budgetallocation.budgetpercentage}%
                    </h1>
                  </div>
                  <div className="mt-3 card-1-bottom-flex">
                    <p className="card-1-bottom-value">
                      Total No of Application -
                      {budgetallocation.total_application}
                    </p>
                    <p className="card-1-bottom-value">
                      Budget Allocation - {budgetallocation.buget_allocation}
                    </p>
                  </div>
                </Spin>
              </Card>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card className="plat-card-1 tagging-relative">
                <Spin
                  spinning={ltaggingstatus}
                  wrapperClassName="costimize-loader"
                >
                  {taggingpracticestatus && (
                    <TaggingBestPractice
                      taggingpracticestatus={taggingpracticestatus}
                      setTaggingPracticeStatus={setTaggingPracticeStatus}
                    />
                  )}

                  <div className="mt-3">
                    <h1 className="card-title-1">
                      {" "}
                      Tagging{" "}
                      <Icon
                        style={{ cursor: "pointer", color: "#000" }}
                        icon="icons8:idea"
                        onClick={() => setTaggingPracticeStatus(true)}
                      />
                    </h1>
                  </div>
                  <div className="mt-3">
                    <h1 className="card-value-1">
                      <Icon icon="mdi:tag" className="quick-glance-icon" />
                      {untaggedresources.percentage}%
                    </h1>
                  </div>
                  <div className="tagging-absolute">
                    <Icon
                      icon="solar:menu-dots-bold"
                      style={{ fontSize: "24px" }}
                      onClick={() => setModelOpen(true)}
                    />
                  </div>
                </Spin>
              </Card>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card className="plat-card-1">
                <Spin
                  spinning={lschedulerstatus}
                  wrapperClassName="costimize-loader"
                >
                  <div>
                    <h1 className="card-title-1">Start/Stop Scheduler </h1>
                  </div>
                  <div className="mt-3">
                    <h1 className="card-value-1">
                      <Icon
                        icon="mdi:receipt-text-clock"
                        className="quick-glance-icon"
                      />
                      {startstopscheduler.percentage}%
                    </h1>
                  </div>
                  <div className="mt-3 card-1-bottom-flex">
                    <p className="card-1-bottom-value">
                      Completed - {startstopscheduler.completed}
                    </p>
                    <p className="card-1-bottom-value">
                      Failed - {startstopscheduler.pending}
                    </p>
                  </div>
                </Spin>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xxl={16} xl={14} lg={14} md={24} sm={24} xs={24}>
          <Card className="plat-card-1">
            <TaggingChart
              ltaggingstatus={ltaggingstatus}
              untaggedresources={untaggedresources}
            />
          </Card>
        </Col>
        {/* <Col xxl={8} xl={7} lg={7} md={24} sm={24} xs={24}>
          <Card className="plat-card-1">
            <ResourcesChart />
          </Card>
        </Col> */}
      </Row>
    </>
  );
};
export default memo(Compliance);
