import { Table, Typography } from "antd";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { CgDetailsMore } from "react-icons/cg";
import { Tooltip } from "antd";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import MyDocument from "./MyDocument";
import { useDispatch, useSelector } from "react-redux";
import { numberFormat } from "../../custom_hook/CustomHook";
import { Icon } from "@iconify/react";

const { Text } = Typography;

const AllListSavingsModel = (props) => {
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [printContent, setPrintContent] = useState();
  const [columns, setColumns] = useState([]);
  const [recommendationlist, setRecommendationList] = useState([]);
  const { selectedTopdate } = useSelector((state) => state.constimize);

  const tableProps = {};

  const [tablerecords, setTableRecords] = useState([]);
  useEffect(() => {
    var value_list = [];
    var keyvalue = 1;
    const tablelist = props.records.map((data, index) => {
      if (
        data.realised_savings === null ||
        Math.round(data.realised_savings) === 0
      ) {
        value_list.push({
          key: keyvalue,
          date: data.report_month,
          account: data.account_names,
          application: data.applications,
          environment: data.environments,
          services: data.services,
          resource_sub_category: data.resource_sub_category,
          recommendation_type: data.recommendation_rule,
          resource_id: data.resource_id,
          resource_name: data.resource_name,
          current_configuration: data.current_configuration,
          recommended_configuration: data.recommended_configuration,
          commitment: data.commitment,
          potential_savings: Number(data.potential_savings),
          realized_savings: data.realised_savings,
          lost_opportunity: data.lost_opportunity,
          action: "",
          type: "data",
        });
        keyvalue++;
      }
    });
    //recommendation type
    const recommendation_list = value_list.map((v) => v.recommendation_type);
    const recommendation_set = new Set([...recommendation_list]);
    const recommendation = [...recommendation_set];
    var recom_list = [];
    recommendation.map((value) => {
      recom_list.push({
        text: value,
        value: value,
      });
    });
    setRecommendationList(recom_list);
    const potential = value_list.reduce(
      (a, v) => (a = +a + +v?.potential_savings),
      0
    );
    const realised = value_list.reduce(
      (a, v) => (a = +a + +v?.realized_savings),
      0
    );
    console.log(value_list);
    setTableRecords(value_list);

    setColumns([
      {
        title: "Month",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => {
          return a.date > b.date ? -1 : 1;
        },
        render: (record) => {
          return record;
          // if(record === 'Total'){
          //   return record;
          // }else{
          //   return `${monthNamesShort[new Date(record).getMonth()]} ${new Date(record).getDate()}`;
          // }
        },
      },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
        sorter: (a, b) => {
          return a.account > b.account ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Application",
        dataIndex: "application",
        key: "application",
        fixed: "left",
        sorter: (a, b) => {
          return a.application > b.application ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Environment",
        dataIndex: "environment",
        key: "environment",
        sorter: (a, b) => {
          return a.environment > b.environment ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      // {
      //   title: 'Cloud',
      //   dataIndex: 'cloud',
      //   key: 'cloud',
      //   fixed: 'left',
      //   sorter: (a, b) => {
      //     const datalength = +data.length + +1;
      //     if(Number(a.key) === Number(datalength) || Number(b.key) === Number(datalength)){
      //       return '';
      //     }else{
      //       return ((a.cloud) > (b.cloud) ? -1 : 1);
      //     }
      //   },
      // },
      {
        title: "Services",
        dataIndex: "services",
        key: "services",
        sorter: (a, b) => {
          return a.services > b.services ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },

      // {
      //   title: 'Resource ID',
      //   dataIndex: 'resource_id',
      //   key: 'resource_id',
      //   sorter: (a, b) => {
      //       return ((a.identified) > (b.identified) ? -1 : 1);
      //   },

      // },

      {
        title: "Resource Name",
        key: "resource_name",
        dataIndex: "resource_name",
        sorter: (a, b) => {
          return a.resource_name > b.resource_name ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Current Configuration",
        dataIndex: "current_configuration",
        key: "current_configuration",
        sorter: (a, b) => {
          return a.current_configuration > b.current_configuration ? -1 : 1;
        },
      },
      {
        title: "Recommended Configuration",
        dataIndex: "recommended_configuration",
        key: "recommended_configuration",
        sorter: (a, b) => {
          return a.environment > b.environment ? -1 : 1;
        },
      },
      {
        title: "Recommendation Type",
        dataIndex: "recommendation_type",
        key: "recommendation_type",
        filters: recom_list,
        onFilter: (value, record) =>
          record.recommendation_type.indexOf(value) === 0,
        sorter: (a, b) => {
          return a.recommendation_type > b.recommendation_type ? -1 : 1;
        },
      },
      // {
      //   title: 'Resource Sub Category',
      //   dataIndex: 'resource_sub_category',
      //   key: 'resource_sub_category',
      //   sorter: (a, b) => {
      //       return ((a.resource_sub_category) > (b.account) ? -1 : 1);
      //   },
      // },
      {
        title: "Commitment",
        dataIndex: "commitment",
        key: "commitment",
        sorter: (a, b) => {
          return a.commitment > b.commitment ? -1 : 1;
        },
        render: (record) => {
          if (record) {
            return <>{record}</>;
          } else {
            return <>-</>;
          }
        },
      },
      {
        title: "Potential Savings",
        dataIndex: "potential_savings",
        key: "potential_savings",
        sorter: (a, b) => {
          return a.potential_savings > b.potential_savings ? -1 : 1;
        },
        render: (record) => {
          return <>${numberFormat(record)}</>;
        },
      },
      //   {
      //     title: 'Realized Savings',
      //     dataIndex: 'realized_savings',
      //     key: 'realized_savings',
      //     sorter: (a, b) => {
      //       const datalength = +data.length + +1;
      //       if(Number(a.key) === Number(datalength) || Number(b.key) === Number(datalength)){
      //         return '';
      //       }else{
      //         return ((a.realized_savings) > (b.realized_savings) ? -1 : 1);
      //       }
      //     },
      //     render: (record) => {
      //       if(record !== ''){
      //         return (

      //             <>
      //             ${record}
      //             </>
      //         )
      //       }

      //   }
      // },
      //   {
      //     title: 'Lost Opportunity',
      //     dataIndex: 'lost_opportunity',
      //     key: 'lost_opportunity',
      //     sorter: (a, b) => {
      //       const datalength = +data.length + +1;
      //       if(Number(a.key) === Number(datalength) || Number(b.key) === Number(datalength)){
      //         return '';
      //       }else{
      //         return ((a.lost_opportunity) > (b.lost_opportunity) ? -1 : 1);
      //       }
      //     },
      // },
      {
        title: (
          <Dropdown className="plat-costimize-dropdown">
            <Dropdown.Toggle
              className="plat-three-dort plat-tap-menu plat-box-three-dort"
              id="dropdown-button-dark-example1"
              variant="secondary"
            >
              <Icon icon="ph:dots-three-bold" class="table-coloum-dots-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="plat-box-menu-list more-details">
              <Dropdown.ItemText
                className="plat-dropdown-item-text"
                onClick={props.showAddRow}
              >
                <span className="plat-box-item-title list-expand-left">
                  Add
                </span>
                <span className="plat-box-item-title list-expand-right">
                  <Icon
                    icon="material-symbols:add-box-outline"
                    class="table-add-records-icon"
                  />
                </span>
              </Dropdown.ItemText>
            </Dropdown.Menu>
          </Dropdown>
        ),
        dataIndex: "action",
        key: "action",
        // fixed: 'right',
        render: (record, index) => {
          var editscreen;
          if (index.type === "data") {
            editscreen = (
              <Dropdown.ItemText
                className="plat-dropdown-item-text"
                onClick={() => props.showEditRow(index)}
              >
                <span className="plat-box-item-title list-expand-left">
                  Edit
                </span>
                <span className="plat-box-item-title list-expand-right">
                  <Icon
                    icon="material-symbols:edit-outline"
                    class="table-add-records-icon"
                  />
                </span>
              </Dropdown.ItemText>
            );
            // const datalength = tablerecords.length - 1;
            // if(Number(index.key) === Number(datalength)){
            // }

            return (
              <>
                <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
                  <Dropdown.Toggle
                    className="plat-three-dort plat-tap-menu plat-box-three-dort"
                    id="dropdown-button-dark-example1"
                    variant="secondary"
                  >
                    <Icon
                      icon="ph:dots-three-bold"
                      class="plat-expandalt-icon-view"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="plat-box-menu-list more-details">
                    {editscreen}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            );
          }
        },
      },
    ]);
  }, [props]);
  const costSavingsPdf = () => {
    console.log("pdf");
  };

  return (
    <>
      {/* <MyDocument 
              date = {selectedTopdate}
              recommendationlist = {recommendationlist}
              records={tablerecords}
            /> */}

      <div className="budget-allocated-table">
        <div className="table-right-icon-list">
          <div>
            <Dropdown className="plat-costimize-dropdown">
              <Dropdown.Toggle
                className="plat-three-dort plat-tap-menu plat-box-three-dort"
                id="dropdown-button-dark-example1"
                variant="secondary"
              >
                <Icon
                  icon="file-icons:microsoft-excel"
                  class="budget-table-chart-icon"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="plat-box-menu-list more-details">
                <Dropdown.ItemText
                  className="plat-dropdown-item-text"
                  onClick={costSavingsPdf}
                >
                  <PDFDownloadLink
                    document={
                      <MyDocument
                        date={selectedTopdate}
                        recommendationlist={recommendationlist}
                        records={tablerecords}
                      />
                    }
                    fileName={`Cost_Savings_${Math.random()
                      .toString(16)
                      .slice(2)}.pdf`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <span className="plat-box-item-title list-expand-left">
                          Loading...
                        </span>
                      ) : (
                        <span className="plat-box-item-title list-expand-left">
                          Cost Savings
                        </span>
                      )
                    }
                  </PDFDownloadLink>
                </Dropdown.ItemText>
                <Dropdown.ItemText className="plat-dropdown-item-text">
                  <span className="plat-box-item-title list-expand-left">
                    Licence Savings
                  </span>
                </Dropdown.ItemText>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <br></br>
        <div
          ref={(el) => {
            setPrintContent(el);
          }}
        >
          <div className="table-width" style={{ marginTop: "10px" }}>
            <h1 className="plat-table-title-unrealised">Unrealised</h1>
            <Table
              {...tableProps}
              className="savings_new_table"
              columns={columns}
              dataSource={tablerecords}
              // pagination={true}
              pagination={{
                pageSize: 5,
              }}
              summary={(pageData) => {
                const potential = pageData.reduce(
                  (a, v) => (a = +a + +v?.potential_savings),
                  0
                );
                return (
                  <>
                    <Table.Summary.Row className="savings-table-footer">
                      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3}></Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell index={5}></Table.Summary.Cell>
                      <Table.Summary.Cell index={6}></Table.Summary.Cell>
                      <Table.Summary.Cell index={7}></Table.Summary.Cell>
                      <Table.Summary.Cell index={8}></Table.Summary.Cell>
                      <Table.Summary.Cell index={9}></Table.Summary.Cell>
                      <Table.Summary.Cell index={10}>
                        <Text>${numberFormat(potential)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={11}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}

              // footer={() =>
              //   <>
              //     <tr className='ant-table-row ant-table-row-level-0 savings-table-footer'>
              //       <td className='ant-table-cell'>test</td>
              //       <td className='ant-table-cell'></td>
              //       <td className='ant-table-cell'>test</td>
              //     </tr>
              //   </>
              // }
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AllListSavingsModel;
