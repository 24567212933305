import { MDBFooter } from 'mdb-react-ui-kit';

const CustimizeFooter = () => {
    return(
        <>
        {/* <MDBFooter bgColor='light' className='text-center text-lg-left custimize-footer'>
            <div className='text-center p-3' style={{ backgroundColor: '#fff' }}>
                &copy; {new Date().getFullYear()} Copyright:{' '}
                <a className='text-dark' href='javascript:void(0);'>
                Platform
                </a>
            </div>
        </MDBFooter> */}
        </>
    )
}

export default CustimizeFooter;