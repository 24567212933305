import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OperationTapmenu from '../OperationTapmenu';
import CustimizeFooter from '../../dashboard-v2/CustimizeFooter';
import "./compliance.css";
import { Tabs } from 'antd';
import SLA from './sla/SLA';
import ServiceRequest from './ServiceRequest/ServiceRequest';
import ChangeRequest from './ChangeRequest/ChangeRequest';
import Security from './Security/Security';
import IncidentTrends from './IncidentTrends/IncidentTrends';
import PathManagement from './PatchManagement/PathManagement';


const Compliance = () => {

    const itemlist = [{
        label: "Incident Trends",
        key: 1,
        children: <IncidentTrends />,
    },{
        label: "SLA's",
        key: 2,
        children: <SLA />,
    },{
        label: "Service Request",
        key: 3,
        children: <ServiceRequest />,
    },{
        label: "Change Request",
        key: 4,
        children: <ChangeRequest />,
    },{
        label: "Security",
        key: 5,
        children: <Security />,
    },{
        label: "Patch Management",
        key: 6,
        children: <PathManagement />,
    }
    // ,{
    //     label: "Scheduled Activity",
    //     key: 7,
    //     children: <></>,
    // }
];

    
    return(
        <>
            <div className='plat-operation-body'>
                <div className='plat-dashboard-tabs'>
                    <OperationTapmenu 
                        excalname='Compliance' 
                        type="Compliance"
                        title = "Compliance"
                    />
                     <div className='plat-full-asset-spend-cover'>
                        <Row>
                            <h1 className='ticket_dashbaord_title'>Tickets Dashboard</h1>
                        </Row>
                        <Row>
                            <Tabs
                                className='operation_compliance'
                                type="card"
                                items={itemlist}
                            />
                        </Row>
                     </div>
                </div>
            </div>
            <CustimizeFooter />
        </>
    )
}
export default Compliance;