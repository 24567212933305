import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { useEffect, useState } from 'react';
import { message, Form, Input, Select, DatePicker, Button } from 'antd';
import { Row, Col } from 'react-bootstrap';
import moment from "moment";    
import { useDispatch, useSelector } from 'react-redux';
import { addPlannedActivity, updatePlannedActivity, deletePlannedActivity } from "../../action/costimizev2DashboardAction";
const { TextArea } = Input;

const PlannedActivityDrawer = (props) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [buttonloading, setButtonLoading] = useState(false);
    const { companyName } = useSelector((state) => state.user);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
    useEffect(() => {
        var records = {
            accountability : '',
            pending_activity : '',
            priority : '',
            project : '',
            project_phase : '',
            remarks : '',
            sow_status : ''
        }
        if(props.oldrecords){
            // console.log(`${new Date(props.oldrecords.date).getFullYear()}-${new Date(props.oldrecords.date).getMonth() + 1}-${new Date(props.oldrecords.date).getDate()}`);
            records = {
                accountability : props.oldrecords.accountability,
                pending_activity : props.oldrecords.pending_activity,
                priority : props.oldrecords.priority,
                project : props.oldrecords.project,
                project_phase : props.oldrecords.project_phase,
                remarks : props.oldrecords.remarks,
                sow_status : props.oldrecords.sow_status,
                // next_target_date : `${new Date(props.oldrecords.date).getFullYear()}-${new Date(props.oldrecords.date).getMonth() + 1}-${new Date(props.oldrecords.date).getDate()}`,
            }
        }
        // console.log(records);
        form.setFieldsValue(records);
    },[props])

    const handleSubmit = (value) => {
        setButtonLoading(true);
        const input = {
            accountability : value.accountability,
            company_name : companyName,
            usagedate : props.oldrecords ? `${new Date(props.oldrecords.date).getFullYear()}-${new Date(props.oldrecords.date).getMonth() + 1}-${new Date(props.oldrecords.date).getDate()}` :`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
            report_month : props.oldrecords ? `${new Date(props.oldrecords.date).getFullYear()}-${new Date(props.oldrecords.date).getMonth() + 1}-01` :`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`,
            next_target_date : `${new Date(value.next_target_date).getFullYear()}-${new Date(value.next_target_date).getMonth() + 1}-${new Date(value.next_target_date).getDate()}`,
            pending_activity : value.pending_activity,
            priority : value.priority,
            project : value.project,
            project_phase : value.project_phase,
            remarks : value.remarks,
            sow_status : value.sow_status,
        }

        if(props.deletestatus){
            const activity_id = props.oldrecords.key;
            dispatch(updatePlannedActivity({input, activity_id}))
            .unwrap()
            .then(({ data }) => {
                message.success('Record Updated');
                props.setUpdateStatus(!props.updatestatus);
                props.setDrawerStatus(false);
                setButtonLoading(false);
            })
            .catch(err => {
                console.log(err.message);
            });
        }else{
            dispatch(addPlannedActivity({input}))
            .unwrap()
            .then(({ data }) => {
                message.success('New Records Added');
                props.setUpdateStatus(!props.updatestatus);
                props.setDrawerStatus(false);
                setButtonLoading(false);
            })
            .catch(err => {
                console.log(err.message);
            });
        }
    }   
    const rowDelete = () => { 
        setConfirmLoading(true);
        const activity_id = props.oldrecords.key;
        dispatch(deletePlannedActivity({activity_id}))
        .unwrap()
        .then((data) => {
            message.success('Deleted');
            props.setUpdateStatus(!props.updatestatus);
            props.setDrawerStatus(false);
            setButtonLoading(false);
            setConfirmLoading(false);
        })
        .catch(err => {
            console.log(err.message);
        });  
    }
    return(
        <>
            <DrawerPlat 
                drawerstatus={props.drawerstatus}
                changeDrawerStatus={props.changeDrawerStatus}
                title="Planned & Open Activity"
                deletestatus={props.deletestatus}
                confirmLoading = {confirmLoading}
                rowDelete={rowDelete}
                setDrawerDeleteStatus={setDrawerDeleteStatus}
            >
                <Form 
                    layout="vertical"
                    onFinish={handleSubmit}
                    form = {form}
                >
                    <Row>
                        <Col>
                            <Form.Item
                                    name="sow_status"
                                    label="SOW Status"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    
                                    <Input 
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                    name="project"
                                    label="Project"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    
                                    <Input 
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                    name="project_phase"
                                    label="Project Phase"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    
                                    <Input 
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                    name="priority"
                                    label="Priority"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    
                                    <Input 
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                    name="pending_activity"
                                    label="Pending Activity"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    
                                    <Input 
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                    name="next_target_date"
                                    label="Next Target Date"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    
                                    <DatePicker  
                                        style={{
                                        width: "100%",
                                        }}
                                        // defaultValue={moment('2023-03-30')}
                                    />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                    name="accountability"
                                    label="Accountability"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    
                                    <Input 
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>   
                            <Form.Item
                                name="remarks"
                                label="Remarks"
                                rules={[{ required: true, message: '' }]}
                            >
                                <TextArea 
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item >
                            <Button htmlType="button"  onClick={() => props.changeDrawerStatus(false)}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" style={{ background: "#1b5f9d", color: "#fff", float: "right" }} loading = {buttonloading}>
                                Submit
                            </Button>

                        </Form.Item>
                    </Row>  
                </Form>
            </DrawerPlat>
        </>
    )
}
export default PlannedActivityDrawer;