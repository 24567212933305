import OperationTapmenu from '../OperationTapmenu';
import CustimizeFooter from '../../dashboard-v2/CustimizeFooter';
import ProductionTable from './ProductionTable';


const AssetsManaged = () => {
    return(
        <>
         <div className='plat-operation-body'>
                <div className='plat-dashboard-tabs'>
                    <OperationTapmenu 
                        excalname='Assets Managed' 
                        type="Assets Managed "
                        title = "Assets Managed"
                    />
                     <div className='plat-full-asset-spend-cover'>
                        <ProductionTable />
                     </div>
                </div>
            </div>

            <CustimizeFooter /> 
        </>
    )
}

export default AssetsManaged;