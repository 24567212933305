import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { useState, useEffect } from "react";
import { message, Form, Input, Select, Button, Switch } from "antd";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "./activitytracker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  AddcreateProjectstatus,
  updateaddActionProjectstatus,
  deleteProjectstatus,
} from "../../action/costimizev2DashboardAction";
const { TextArea } = Input;

const ProjectStatus = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [buttonloading, setButtonLoading] = useState(false);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  useEffect(() => {
    var records = {
      next_milestone: "",
      report_date: "",
      project_target_date: "",
      start_date: "",
      progress: "",
      project_name: "",
      project_phase: "",
      status: "",
    };

    if (props.oldrecords) {
      records = {
        next_milestone: props.oldrecords.next_milestone,
        progress: props.oldrecords.progress,
        project_name: props.oldrecords.project_name,
        project_phase: props.oldrecords.project_phase,
        start_date: props.oldrecords.start_date,
        project_target_date: props.oldrecords.project_target_date,
        report_date: props.oldrecords.report_date,
        status: props.oldrecords.status,
      };
    }
    form.setFieldsValue(records);
  }, [props]);
  const handleSubmit = (value) => {
    setButtonLoading(true);

    const input = {
      company_name: companyName,
      project_name: value.project_name,
      project_phase: value.project_phase,
      progress: value.progress,
      status: value.status,
      next_milestone: value.next_milestone,
      report_date: `${new Date(value.report_date).getFullYear()}-${
        new Date(value.report_date).getMonth() + 1
      }-${new Date(value.report_date).getDate()}`,
      project_target_date: `${new Date(
        value.project_target_date
      ).getFullYear()}-${
        new Date(value.project_target_date).getMonth() + 1
      }-${new Date(new Date(value.project_target_date)).getDate()}`,
      start_date: `${new Date(value.start_date).getFullYear()}-${
        new Date(value.start_date).getMonth() + 1
      }-${new Date(new Date(value.start_date)).getDate()}`,
      report_month: `${new Date(value.report_date).getFullYear()}-${
        new Date(value.report_date).getMonth() + 1
      }-01`,
    };
    if (props.deletestatus) {
      const status_id = props.oldrecords.status_id;
      dispatch(updateaddActionProjectstatus({ input, status_id }))
        .unwrap()
        .then(({ data }) => {
          message.success("Record Updated");
          props.setUpdateStatus(!props.updatestatus);
          props.setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      dispatch(AddcreateProjectstatus({ input }))
        .unwrap()
        .then(({ data }) => {
          message.success("New Records Added");
          props.setUpdateStatus(!props.updatestatus);
          props.setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  const rowDelete = () => {
    setConfirmLoading(true);
    const status_id = props.oldrecords.status_id;

    dispatch(deleteProjectstatus({ status_id, companyName }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        props.setUpdateStatus(!props.updatestatus);
        props.setDrawerStatus(false);
        setButtonLoading(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <DrawerPlat
        drawerstatus={props.drawerstatus}
        changeDrawerStatus={props.changeDrawerStatus}
        deletestatus={props.deletestatus}
        title="ProjectStatus"
        rowDelete={rowDelete}
        confirmLoading={confirmLoading}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row>
            <Col>
              <Form.Item
                name="project_name"
                label="Project Name"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="progress"
                label="Progress"
                rules={[{ required: true, message: "" }]}
              >
                {/* <Select
                                    showSearch
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[{
                                        value: "project completed",
                                        label: "Project completed",
                                    },
                                    {
                                        value: "UAT Testing sign off pending",
                                        label: "UAT Testing sign off pending",
                                    },
                                    {
                                        value: "PROD Cutover Plan in progress",
                                        label: "PROD Cutover Plan in progress",
                                    },
                                    {
                                        value: "Design finalization",
                                        label: "Design finalization ",
                                    },
                                    {
                                        value: "Both Prod and Pre-Prod environment has been implemented. App setup is in progress",
                                        label: "Both Prod and Pre-Prod environment has been implemented. App setup is in progress",
                                    }
                                ]}
                                /> */}
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="project_phase"
                label="Project Phase"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Prod",
                      label: "Prod",
                    },
                    {
                      value: "UAT ",
                      label: "UAT ",
                    },
                    {
                      value: "closed",
                      label: "Closed",
                    },
                    {
                      value: "testing",
                      label: "Testing",
                    },
                    {
                      value: "implementation",
                      label: "Implementation",
                    },
                    {
                      value: "design",
                      label: "Design",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="start_date"
                label=" Start Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="project_target_date"
                label="Project Target Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  selected={startDate1}
                  onChange={(date) => setStartDate1(date)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                {/* <Select
                                    showSearch
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[{
                                        value: "Project closure done",
                                        label: "Project closure done",
                                    },
                                    {
                                        value: "Validation completed for both servers project closure is completed",
                                        label: "Validation completed for both servers project closure is completed",
                                    },
                                    {
                                        value: "Pending with UAT sign off",
                                        label: "Pending with UAT sign off",
                                    },
                                    {
                                        value: "Solution walkthrough on planned Wednesday",
                                        label: "Solution walkthrough on planned Wednesday",
                                    },
                                    {
                                        value: "App setup is in progress",
                                        label: "App setup is in progress",
                                    }
                                ]}
                                /> */}
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="report_date"
                label=" Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  selected={startDate2}
                  onChange={(date) => setStartDate2(date)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                name="next_milestone"
                label="Next Milestone"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "uat sign off",
                      label: "UAT sign off",
                    },
                    {
                      value: "infra deployment",
                      label: "Infra Deployment",
                    },
                    {
                      value: "uat testing",
                      label: "UAT Testing",
                    },
                    {
                      value: "none",
                      label: "None",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Form.Item>
              <Button
                htmlType="button"
                onClick={() => props.setDrawerStatus(false)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ background: "#1b5f9d", color: "#fff", float: "right" }}
                loading={buttonloading}
              >
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </DrawerPlat>
    </>
  );
};

export default ProjectStatus;
