import React, { useEffect, useState } from "react";
import { Row, Col, Card, Typography } from "antd";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { checkRolePermission } from "./custom_hook/CustomHook";

function AdminActions() {
  const navigate = useNavigate();
  const [permission, setPermission] = useState({
    usermanagementaccess: checkRolePermission("user_management")
      ? checkRolePermission("user_management").access
      : 0,
    rolemanagementaccess: checkRolePermission("role_management")
      ? checkRolePermission("role_management").access
      : 0,
    accountonboardingaccess: checkRolePermission("account_onboarding")
      ? checkRolePermission("account_onboarding").access
      : 0,
  });

  return (
    <>
      <div className=" text-box  p-1">
        <Row className="ad-header">
          <h4>Admin Panel</h4>
        </Row>
        {permission.usermanagementaccess ? (
          <Row>
            <Card
              style={{ width: "100%" }}
              className="admin-card"
              onClick={() => navigate("/user-mng")}
            >
              <Typography> User Management</Typography>
              <div className="action-btn">
                <Icon
                  icon="uil:arrow-circle-right"
                  style={{ fontSize: "21px", color: "#1c344c" }}
                />
              </div>
            </Card>
          </Row>
        ) : (
          ""
        )}
        {permission.rolemanagementaccess ? (
          <Row>
            <Card
              style={{ width: "100%" }}
              className="admin-card"
              onClick={() => navigate("/role-management")}
            >
              <Typography> Role Management</Typography>
              <div className="action-btn">
                <Icon
                  icon="uil:arrow-circle-right"
                  style={{ fontSize: "21px", color: "#1c344c" }}
                />
              </div>
            </Card>
          </Row>
        ) : (
          ""
        )}
        {permission.accountonboardingaccess ? (
          <Row>
            <Card
              style={{ width: "100%" }}
              className="admin-card"
              onClick={() => navigate("/account-onboarding")}
            >
              <Typography> Account Onboarding</Typography>
              <div className="action-btn">
                <Icon
                  icon="uil:arrow-circle-right"
                  style={{ fontSize: "21px", color: "#1c344c" }}
                />
              </div>
            </Card>
          </Row>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default AdminActions;
