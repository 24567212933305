import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { listServiceSpendDate } from "../../action/costimizeV2Active";
import { useDispatch, useSelector } from "react-redux";

const SpendDayInstanceChart = (props) => {
  const dispatch = useDispatch();

  const [chartvalues, setChartValues] = useState({
    value: [],
    categories: [],
  });
  const {
    selectedCloud,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  useEffect(() => {
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date: props.resourcesdate,
    };
    props.setPreloader(true);
    dispatch(listServiceSpendDate({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        var value = [];
        var categories = [];
        if (data.daywise_spend_service.length > 0) {
          data.daywise_spend_service.map((records) => {
            value.push(records.spend);
            categories.push(records.service);
          });
        }
        setChartValues({
          value: value,
          categories: categories,
        });
        props.setPreloader(false);
      })
      .catch((err) => {
        console.log(err);
        props.setPreloader(false);
      });
  }, [props.resourcesdate]);
  const series = [
    {
      name: "Resorce",
      data: chartvalues.value,
    },
  ];
  const options = {
    chart: {
      type: "bar",
      height: 350,
      events: {
        dataPointSelection: function (event, chartContext, config) {
          // console.log(chartvalues.categories[config.dataPointIndex]);
          // console.log(chartvalues.categories[config.dataPointIndex]);
          props.setSelectedService(
            chartvalues.categories[config.dataPointIndex]
          );
          props.setCartType("resorceslist");
          props.sendResorceschartRecords(
            props.resourcesdate,
            chartvalues.categories[config.dataPointIndex]
          );
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: chartvalues.categories,
    },
    colors: ["#11104E"],
    yaxis: {
      labels: {
        formatter: (record) => {
          const num = (Math.round(record * 100) / 100).toFixed(2);
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
    fill: {
      opacity: 1,
    },
  };
  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </>
  );
};

export default memo(SpendDayInstanceChart);
