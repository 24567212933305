import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { useState, useEffect } from "react";
import { message, Form, Input, Select, Button, Switch } from "antd";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  AddKeyActivities,
  updateKeyActivities,
  deleteKeyActivities,
} from "../../action/costimizev2DashboardAction";
import "./activitytracker.css";
const { TextArea } = Input;

const KeyActivities = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [buttonloading, setButtonLoading] = useState(false);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    var records = {
      application: "",
      date: "",
      category: "",
      description: "",
      action_token: "",
      severity: "",
      cost_impact: "",
    };
    if (props.oldrecords) {
      records = {
        application: props.oldrecords.application,
        date: props.oldrecords.date,
        category: props.oldrecords.category,
        description: props.oldrecords.description,
        action_token: props.oldrecords.action_token,
        severity: props.oldrecords.severity,
        cost_impact: props.oldrecords.cost_impact,
      };
    }
    form.setFieldsValue(records);
  }, [props]);
  const handleSubmit = (value) => {
    setButtonLoading(true);
    const input = {
      company_name: companyName,
      action_token: value.action_token,
      application: value.application,
      category: value.category,
      cost_impact: value.cost_impact,
      description: value.description,
      report_date: `${new Date(value.date).getFullYear()}-${
        new Date(value.date).getMonth() + 1
      }-${new Date(value.date).getDate()}`,
      report_month: `${new Date(value.date).getFullYear()}-${
        new Date(value.date).getMonth() + 1
      }-01`,
      severity: value.severity,
    };
    if (props.deletestatus) {
      const key_id = props.oldrecords.key_id;
      dispatch(updateKeyActivities({ input, key_id }))
        .unwrap()
        .then(({ data }) => {
          message.success("Record Updated");
          props.setUpdateStatus(!props.updatestatus);
          props.setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      dispatch(AddKeyActivities({ input }))
        .unwrap()
        .then(({ data }) => {
          message.success("New Records Added");
          props.setUpdateStatus(!props.updatestatus);
          props.setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  const rowDelete = () => {
    setConfirmLoading(true);
    const key_id = props.oldrecords.key_id;

    dispatch(deleteKeyActivities({ key_id, companyName }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        props.setUpdateStatus(!props.updatestatus);
        props.setDrawerStatus(false);
        setButtonLoading(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <DrawerPlat
        drawerstatus={props.drawerstatus}
        changeDrawerStatus={props.changeDrawerStatus}
        deletestatus={props.deletestatus}
        title="Key Activities"
        rowDelete={rowDelete}
        confirmLoading={confirmLoading}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row>
            <Col>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="application"
                label="Application Name"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="category"
                label="Category"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "changerequest",
                      label: "Change Request",
                    },
                    {
                      value: "changerequest1",
                      label: "Change Request1",
                    },
                    {
                      value: "changerequest2",
                      label: "Change Request2",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value:
                        "the purpose for creating these server for secondary active directory in common service account",
                      label:
                        "The purpose for creating these server for Secondary Active directory in Common service Account",
                    },
                    {
                      value:
                        "the purpose for creating these server for BRS project in production account",
                      label:
                        "The purpose for creating these server for BRS Project in Production Account",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="action_token"
                label="Action Token"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "1 vm provisioned",
                      label: "1 VM provisioned",
                    },
                    {
                      value: "2 vm provisioned",
                      label: "2 VM provisioned",
                    },
                    {
                      value: "3 vm provisioned",
                      label: "3 VM provisioned",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="severity"
                label="Severity "
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "high",
                      label: "High",
                    },
                    {
                      value: "medium",
                      label: "Medium",
                    },
                    {
                      value: "low",
                      label: "Low",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="cost_impact"
                label="Cost Impact "
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "yes",
                      label: "Yes",
                    },
                    {
                      value: "no",
                      label: "No",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item>
              <Button
                htmlType="button"
                onClick={() => props.setDrawerStatus(false)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ background: "#1b5f9d", color: "#fff", float: "right" }}
                loading={buttonloading}
              >
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </DrawerPlat>
    </>
  );
};

export default KeyActivities;
