import { Auth } from "@aws-amplify/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
// import { API } from 'aws-amplify';
// import * as queries from '../../graphql/queries';

export const signUp = createAsyncThunk(
  // action type string
  "auth/signup",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const { user } = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          phone_number: `+91${data.phoneNo}`,
          given_name: data.firstName,
          family_name: data.lastName,
          "custom:companyName": data.companyName,
        },
      });
      return user;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const signIn = createAsyncThunk(
  // action type string
  "auth/signin",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const user = await Auth.signIn(data.username, data.password);
      // console.log(user);
      return user;
    } catch (e) {
      if (e.code === "NotAuthorizedException") {
        return rejectWithValue("Incorrect username or password");
      } else if (e.code === "UserNotConfirmedException") {
        return rejectWithValue("User is not confirmed.");
      } else {
        return rejectWithValue("Incorrect username or password");
      }
    }
  }
);

export const VerificationEamil = createAsyncThunk(
  // action type string
  "auth/verification",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.confirmSignUp(data.email, data.email_otp);
      // console.log("done");
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const resendVerificationCode = createAsyncThunk(
  // action type string
  "auth/verification",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.resendSignUp(data.email);
      // console.log("done");
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  // action type string
  "auth/forgotpassword",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.forgotPassword(data.emailid);
    } catch (e) {
      if (e.code === "UserNotFoundException") {
        return rejectWithValue("Username/client id combination not found.");
      } else {
        return rejectWithValue("Username/client id combination not found.");
      }
    }
  }
);

export const submitForgotPassword = createAsyncThunk(
  // action type string
  "auth/forgotpassword",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.forgotPasswordSubmit(
        data.emailid,
        data.otpvalue,
        data.newpassword
      );
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const signOut = createAsyncThunk(
  // action type string
  "auth/signout",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      await Auth.signOut();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const tokenEndpoint = createAsyncThunk(
  // action type string
  "auth/signup",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      Auth.federatedSignIn("azuread", {
        token: data.idToken,
        expires_at: data.exp_time,
      })
        .then((user) => {
          console.log(user);
          const cognitoJwtToken = user.signInUserSession.idToken.jwtToken;
          // Use the Cognito JWT token for subsequent requests or store it for future use
          console.log("Cognito JWT token:", cognitoJwtToken);
          return cognitoJwtToken;
        })
        .catch((error) => {
          console.error("Error obtaining Cognito JWT token:", error);
        });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const getUserDetails = createAsyncThunk(
//     // action type string
//     'auth/forgotpassword',
//     // callback function
//     async ( data , { rejectWithValue }) => {
//         try {

//             const local_storage = Auth.configure({ storage: window.localStorage });

//             console.log(local_storage)
//             // console.log("local");
//             // console.log(local_storage.storageCognitoIdentityServiceProvider);
//             // const oneTodo = await API.graphql({
//             //     query: queries.getTodo,
//             //     variables: { id: '3113db53-3957-48a1-813b-2ed29d18bdf5' }
//             //   });
//             //   const allTodos = await API.graphql({ query: queries.listTodos });

//             // console.log(allTodos);
//         } catch (e) {
//             console.log(e);
//             return rejectWithValue(e.message);
//         }
//     }
// );
