import { gql } from "@apollo/client";

const TOP_MENU_LIST = gql`
  query MyQuery($company_name: String!, $month: String!, $cloud: String!) {
    newfunctionquery1(
      company_name: $company_name
      month: $month
      cloud: $cloud
    ) {
      account_name
      application
      cloud
      environment
      db
      infra
      os
      service
    }
  }
`;

const LIST_ANNUAL_BUDGET = gql`
  query MyQuery($company_name: String!, $cloud: String!) {
    list_annual_budget(company_name: $company_name, cloud: $cloud) {
      budget_id
      company
      budget_year
      budget_jan
      budget_jun
      budget_feb
      budget_mar
      budget_apr
      budget_may
      budget_jul
      budget_aug
      budget_sept
      budget_oct
      budget_nov
      budget_dec
      annual_total
    }
  }
`;

const FORCOST_SPEND = gql`
  query MyQuery(
    $account: String!
    $month: String!
    $company_name: String!
    $cloud: String!
  ) {
    newfunctionquery8(
      account_name: $account
      company_name: $company_name
      month: $month
      cloud: $cloud
    ) {
      account_names
      projected_spend
      reportmonth
    }
  }
`;

const CREDIT_AMOUNT = gql`
  query MyQuery(
    $account: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery4(
      account_name: $account
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      reportmonth
      spend
    }
  }
`;

const SPEND_DETAILS = gql`
  query MyQuery(
    $account: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery3(
      account_name: $account
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      reportmonth
      spend
      projected_spend
      actualspend_previous_month_dif
      projectedspend_previous_month_diff
    }
  }
`;

const SPEND_DETAILED_VIEW = gql`
  query MyQuery(
    $account: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery32(
      account_name: $account
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      accountname
      reportmonth
      spend
      lineitem_type
    }
  }
`;

const LIST_SAVINGS_MODEL = gql`
  query MyQuery(
    $company_name: String!
    $account_name: String!
    $application: String!
    $cloud: String!
    $db: String!
    $environment: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery10(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      account_names
      applications
      commitment
      current_configuration
      environments
      lost_opportunity
      potential_savings
      recommendation_rule
      recommended_configuration
      report_month
      resource_id
      resource_name
      resource_sub_category
      services
      realised_savings
    }
  }
`;

const LIST_APPLICATION_SPEND = gql`
  query MyQuery(
    $company_name: String!
    $account_name: String!
    $application: String!
    $cloud: String!
    $db: String!
    $environment: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery16(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      application
      spend
      report_month
    }
  }
`;

const UNDER_UTILIZED = gql`
  query MyQuery($cloud: String!, $company_name: String!, $month: String!) {
    newfunctionquery23(
      cloud: $cloud
      company_name: $company_name
      report_month: $month
    ) {
      underutilized_resources
      total_resources
      date
    }
  }
`;

const LIST_APPLICATION_BUDGET = gql`
  query MyQuery(
    $company_name: String!
    $account_name: String!
    $application: String!
    $budget_year: String!
    $cloud: String!
    $environment: String!
  ) {
    list_application_budget(
      account_name: $account_name
      application: $application
      budget_year: $budget_year
      cloud: $cloud
      company_name: $company_name
      environment: $environment
    ) {
      budget_id
      budget_year
      account_name
      environment
      application
      budget_jan
      budget_feb
      budget_mar
      budget_apr
      budget_may
      budget_jun
      budget_jul
      budget_aug
      budget_sept
      budget_oct
      budget_nov
      budget_dec
      annual_total
    }
  }
`;

const START_STOP_SCHEDULER_DASHBOARD = gql`
  query MyQuery(
    $cloud: String!
    $company_name: String!
    $month: String!
    $account: String!
  ) {
    newfunctionquery17(
      cloud: $cloud
      company_name: $company_name
      report_month: $month
      account_name: $account
    ) {
      jobid
      status
      usagedate
    }
  }
`;

const LIST_SAVINGS_REALIZATION_CHART = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
    $os: String!
    $db: String!
    $infra: String!
    $service: String!
  ) {
    newfunctionquery11(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      realised_savings
      report_month
    }
  }
`;

const ADD_SAVINGS_MODEL = gql`
  mutation MyMutation(
    $application: String!
    $environment: String!
    $service: String!
    $account: String!
    $company_name: String!
    $commitment: String!
    $current_configuration: String!
    $lost_opportunity: String!
    $potential_savings: String!
    $realised_savings: String!
    $recommendation_rule: String!
    $recommended_configuration: String!
    $report_month: String!
    $resource_id: String!
    $resource_name: String!
    $resource_sub_category: String!
  ) {
    insert_recommendation_savings(
      account_name: $account
      application: $application
      commitment: $commitment
      company_name: $company_name
      current_configuration: $current_configuration
      environment: $environment
      lost_opportunity: $lost_opportunity
      potential_savings: $potential_savings
      realised_savings: $realised_savings
      recommendation_rule: $recommendation_rule
      recommended_configuration: $recommended_configuration
      report_month: $report_month
      resource_id: $resource_id
      resource_name: $resource_name
      resource_sub_category: $resource_sub_category
      service: $service
    ) {
      account_name
      application
      commitment
      current_configuration
      environment
      lost_opportunity
      realised_savings
      potential_savings
      recommendation_rule
      recommended_configuration
      report_month
      resource_id
      resource_name
      resource_sub_category
      service
    }
  }
`;

const UPDATE_SAVINGS_MODEL = gql`
  mutation MyMutation(
    $application: String!
    $environment: String!
    $service: String!
    $account: String!
    $company_name: String!
    $commitment: String!
    $current_configuration: String!
    $lost_opportunity: String!
    $potential_savings: String!
    $realised_savings: String!
    $recommendation_rule: String!
    $recommended_configuration: String!
    $report_month: String!
    $resource_id: String!
    $resource_name: String!
    $resource_sub_category: String!
  ) {
    update_recommendation_savings(
      account_name: $account
      application: $application
      commitment: $commitment
      company_name: $company_name
      current_configuration: $current_configuration
      environment: $environment
      lost_opportunity: $lost_opportunity
      potential_savings: $potential_savings
      recommendation_rule: $recommendation_rule
      recommended_configuration: $recommended_configuration
      report_month: $report_month
      resource_id: $resource_id
      resource_name: $resource_name
      resource_sub_category: $resource_sub_category
      service: $service
      realised_savings: $realised_savings
    ) {
      account_name
      application
      commitment
      current_configuration
      environment
      lost_opportunity
      potential_savings
      realised_savings
      recommendation_rule
      recommended_configuration
      report_month
      resource_id
      resource_name
      resource_sub_category
      service
    }
  }
`;

const DELETE_SAVINGS_MODEL = gql`
  mutation MyMutation($companyName: String!, $deleteid: String!) {
    delete_recommendation_savings(
      company_name: $companyName
      resource_id: $deleteid
    ) {
      account_name
      application
      commitment
      current_configuration
      environment
      lost_opportunity
      potential_savings
      realised_savings
      recommendation_rule
      recommended_configuration
      report_month
      resource_id
      resource_name
      resource_sub_category
      service
    }
  }
`;

const PROJECTED_SPEND = gql`
  query MyQuery($account: String!, $month: String!, $company_name: String!) {
    newfunctionquery8(
      account_name: $account
      company_name: $company_name
      month: $month
    ) {
      account_names
      projected_spend
      reportmonth
    }
  }
`;

const LIST_INSTANCE_RUNTIME = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
    $os: String!
  ) {
    newfunctionquery12(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      month: $month
      os: $os
    ) {
      actual_runtime
      application
      environment
      planned_runtime
      report_month
      resourceid
      resourcename
      usagestartdate
    }
  }
`;

const LIST_INSTANCE_TRACKING_OLD = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
  ) {
    newfunctionquery15(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      month: $month
    ) {
      application
      environment
      report_month
      resourcename
      status
      changes_occured
      region
      root_device_type
      instance_type
      ebs_volume
    }
  }
`;

const LIST_INSTANCE_TRACKING = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
  ) {
    newfunctionquery15(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
    ) {
      application
      environment
      resourcename
      status
      region
      root_device_type
      instance_type
      ebs_volume
      changes_occured
      report_month
      date
    }
  }
`;

const ASSET_SPEND_VM_RECORDS = gql`
  query MyQuery(
    $company_name: String!
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $os: String!
    $service: String!
    $month: String!
    $first_month: String!
    $second_month: String!
    $thired_month: String!
  ) {
    newfunctionquery30(
      company_name: $company_name
      cur_report_month: $thired_month
      pre_previous_report_month: $second_month
      pre_report_month: $first_month
      report_month: $month
      account_name: $account_name
      application: $application
      cloud: $cloud
      environment: $environment
      infra: "VM"
      os: $os
      service: $service
    ) {
      spend
      ram
      account_name
      application
      average_cpu
      average_memory
      billingperiodstartdate
      cloud
      core
      environment
      first_month_spend
      infra
      instancetype
      maximum_cpu
      maximum_memory
      minimum_cpu
      minimum_memory
      recommendation_rule
      resourceid
      resourcename
      savings_opportunity
      second_month_spend
      storage
      third_month_spend
    }
  }
`;

const ASSET_SPEND_VM_ENVIRONMENT_RECORDS = gql`
  query MyQuery(
    $company_name: String!
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $os: String!
    $service: String!
    $month: String!
    $first_month: String!
    $second_month: String!
    $thired_month: String!
  ) {
    newfunctionquery30(
      application: $application
      account_name: $account_name
      company_name: $company_name
      cloud: $cloud
      cur_report_month: $thired_month
      infra: "VM"
      environment: $environment
      pre_previous_report_month: $second_month
      os: $os
      pre_report_month: $first_month
      report_month: $month
      service: $service
    ) {
      account_name
      environment
      ram
      core
      storage
      spend
      billingperiodstartdate
      first_month_spend
      second_month_spend
      third_month_spend
      savings_opportunity
      count
    }
  }
`;

const ASSET_SPEND_VM_CLOUD_RECORDS = gql`
  query MyQuery(
    $company_name: String!
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $os: String!
    $service: String!
    $month: String!
    $first_month: String!
    $second_month: String!
    $thired_month: String!
  ) {
    newfunctionquery31(
      application: $application
      account_name: $account_name
      company_name: $company_name
      cloud: $cloud
      cur_report_month: $thired_month
      infra: "VM"
      environment: $environment
      pre_previous_report_month: $second_month
      os: $os
      pre_report_month: $first_month
      report_month: $month
      service: $service
    ) {
      account_name
      environment
      cloud
      count
      ram
      core
      storage
      spend
      billingperiodstartdate
      first_month_spend
      second_month_spend
      third_month_spend
      savings_opportunity
    }
  }
`;

const ASSET_SPEND_VM_APPLICATION_RECORDS = gql`
  query MyQuery(
    $company_name: String!
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $os: String!
    $service: String!
    $month: String!
    $first_month: String!
    $second_month: String!
    $thired_month: String!
  ) {
    newfunctionquery32(
      application: $application
      account_name: $account_name
      company_name: $company_name
      cloud: $cloud
      cur_report_month: $thired_month
      infra: "VM"
      environment: $environment
      pre_previous_report_month: $second_month
      os: $os
      pre_report_month: $first_month
      report_month: $month
      service: $service
    ) {
      account_name
      environment
      cloud
      application
      count
      ram
      core
      storage
      billingperiodstartdate
      spend
      first_month_spend
      second_month_spend
      third_month_spend
      savings_opportunity
    }
  }
`;

const ASSET_SPEND_VM_RESORCES_RECORDS = gql`
  query MyQuery(
    $company_name: String!
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $os: String!
    $service: String!
    $month: String!
    $first_month: String!
    $second_month: String!
    $thired_month: String!
  ) {
    newfunctionquery33(
      application: $application
      account_name: $account_name
      company_name: $company_name
      cloud: $cloud
      cur_report_month: $thired_month
      infra: "VM"
      environment: $environment
      pre_previous_report_month: $second_month
      os: $os
      pre_report_month: $first_month
      report_month: $month
      service: $service
    ) {
      account_name
      application
      billingperiodstartdate
      cloud
      core
      environment
      first_month_spend
      instancetype
      ram
      resourcename
      savings_opportunity
      second_month_spend
      spend
      storage
      third_month_spend
      resourceid
    }
  }
`;

// const ASSET_SPEND_POPUP_DETAILS = gql`
//   query MyQuery(
//     $customer: String!
//     $resourceid: String!
//     $start_date: String!
//     $end_date: String!
//   ) {
//     newfunctionquery077(
//       customer: $customer
//       end_date: $end_date
//       resourceid: $resourceid
//       start_date: $start_date
//     ) {
//       resource_id
//       date_time
//       maximum_cpu
//       minimum_cpu
//       average_cpu
//       maximum_memory
//       minimum_memory
//       average_memory
//     }
//   }
// `;

const ASSET_SPEND_POPUP_DETAILS = gql`
  query MyQuery(
    $customer: String!
    $resourceid: String!
    $start_date: String!
    $end_date: String!
  ) {
    newfunctionquery077(
      customer: $customer
      end_date: $end_date
      resourceid: $resourceid
      start_date: $start_date
    ) {
      resource_id
      date_time
      maximum_cpu
      minimum_cpu
      average_cpu
      maximum_memory
      minimum_memory
      average_memory
    }
  }
`;

const GET_USER_ROLE = gql`
  query MyQuery {
    userManagementGet(
      customer: "fivestar"
      report_month: "2023-04-01"
      role_name: "CTO"
    )
  }
`;

const TAGGING_RESOURCES = gql`
  query MyQuery(
    $company_name: String!
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $services: String!
    $os: String!
    $db: String!
    $infra: String!
    $month: String!
  ) {
    newfunctionquery2(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $services
    ) {
      count_tag_category
      tagging_details
      services
      tag_category
    }
  }
`;
const TAGGING_RESOURCES_PAGINATION_COUNT = gql`
  query MyQuery(
    $tagging_details: String!
    $service_name: String!
    $tag_category: String!
    $companyName: String!
    $date: String!
    $count: Int!
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $infra: String!
    $os: String!
    $db: String!
  ) {
    newfunctionquery9(
      company_name: $companyName
      month: $date
      service: $service_name
      tagging_detail: $tagging_details
      tag_category: $tag_category
      count: $count
      account_name: $account_name
      application: $application
      cloud: $cloud
      environment: $environment
      infra: $infra
      os: $os
      db: $db
    ) {
      total_pages
    }
  }
`;

const TAGGING_RESOURCES_LIST = gql`
  query MyQuery(
    $companyName: String!
    $tagging_details: String!
    $service_name: String!
    $date: String!
    $page: Int!
    $tag_category: String!
    $count: Int!
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $infra: String!
    $os: String!
    $db: String!
  ) {
    newfunctionquery7(
      company_name: $companyName
      month: $date
      tagging_detail: $tagging_details
      service: $service_name
      tag_category: $tag_category
      page_number: $page
      page_size: $count
      account_name: $account_name
      application: $application
      cloud: $cloud
      environment: $environment
      infra: $infra
      os: $os
      db: $db
    ) {
      account_id
      application
      environment
      resourceid
      resourcename
      services
      subcategory
      tag_category
      tagging_details
    }
  }
`;
const TAGGING_RESOURCES_LIST_OLD = gql`
  query MyQuery(
    $companyName: String!
    $tagging_details: String!
    $service_name: String!
    $date: String!
    $page: Int!
    $tag_category: String!
    $count: Int!
    $account: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $infra: String!
    $os: String!
    $db: String!
  ) {
    newfunctionquery7(
      company_name: $companyName
      month: $date
      tagging_detail: $tagging_details
      service: $service_name
      tag_category: $tag_category
      page_number: $page
      page_size: $count
      account_name: $account_name
      application: $application
      cloud: $cloud
      environment: $environment
      infra: $infra
      os: $os
      db: $db
    ) {
      account_id
      resourceid
      resourcename
      services
      application
      environment
      subcategory
      tagging_details
    }
  }
`;

const UPDATE_TAGGING_DETAILS = gql`
  mutation MyMutation($resources: [TaggingInput!]!) {
    tagging(resources: $resources)
  }
`;

const LIST_SPEND_BY_DATE = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $db: String!
    $environment: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    daywise_spend(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      date
      spend
    }
  }
`;

const RESOURCES_SPEND_DATE_COUNT = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $db: String!
    $environment: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    daywise_spend_resorce_pagesize(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      date: $month
      os: $os
      service: $service
      count: 15
    ) {
      total_pages
    }
  }
`;

const RESOURCES_SPEND_DATE_LIST = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $db: String!
    $environment: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
    $page_number: Int!
  ) {
    daywise_spend_resorce(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      date: $month
      os: $os
      service: $service
      page_number: $page_number
      page_size: 10
    ) {
      date
      spend
      resourceid
      resourcename
    }
  }
`;

const SERVICES_SPEND_DATE_LIST = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $db: String!
    $environment: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    daywise_spend_service(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      date: $month
      os: $os
      service: $service
    ) {
      spend
      service
    }
  }
`;

const SERVICES_SPEND_MONTH_DATE_LIST = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $db: String!
    $environment: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    daywise_service_spend(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      os: $os
      month: $month
      service: $service
    ) {
      date
      service
      spend
    }
  }
`;

const CHECK_CHATBOT_LIMIT = gql`
  query MyQuery($date: String!, $user_name: String!, $companyName: String!) {
    chatbot_credit_validation(
      date_time: $date
      event_type: "credit_check"
      schema: $companyName
      user_name: $user_name
    )
  }
`;

const CHART_BOT_RESPONSE = gql`
  query MyQuery(
    $date: String!
    $user_name: String!
    $companyName: String!
    $message: String!
    $token: String!
  ) {
    chatbot_chat(
      date_time: $date
      event_type: "chat"
      query: $message
      schema: $companyName
      user_name: $user_name
      user_session: $token
    )
  }
`;

const LIST_STOP_INSTANCE = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
  ) {
    get_stopped_instance(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      month: $month
    ) {
      cloud
      resourceid
      resourcename
      status
    }
  }
`;
export {
  TOP_MENU_LIST,
  LIST_ANNUAL_BUDGET,
  FORCOST_SPEND,
  CREDIT_AMOUNT,
  SPEND_DETAILS,
  SPEND_DETAILED_VIEW,
  LIST_SAVINGS_MODEL,
  LIST_APPLICATION_SPEND,
  UNDER_UTILIZED,
  LIST_APPLICATION_BUDGET,
  START_STOP_SCHEDULER_DASHBOARD,
  LIST_SAVINGS_REALIZATION_CHART,
  ADD_SAVINGS_MODEL,
  UPDATE_SAVINGS_MODEL,
  DELETE_SAVINGS_MODEL,
  PROJECTED_SPEND,
  LIST_INSTANCE_RUNTIME,
  LIST_INSTANCE_TRACKING,
  ASSET_SPEND_VM_RECORDS,
  ASSET_SPEND_POPUP_DETAILS,
  GET_USER_ROLE,
  TAGGING_RESOURCES,
  TAGGING_RESOURCES_PAGINATION_COUNT,
  TAGGING_RESOURCES_LIST,
  UPDATE_TAGGING_DETAILS,
  CHART_BOT_RESPONSE,
  LIST_SPEND_BY_DATE,
  RESOURCES_SPEND_DATE_COUNT,
  RESOURCES_SPEND_DATE_LIST,
  SERVICES_SPEND_DATE_LIST,
  SERVICES_SPEND_MONTH_DATE_LIST,
  CHECK_CHATBOT_LIMIT,
  ASSET_SPEND_VM_ENVIRONMENT_RECORDS,
  ASSET_SPEND_VM_CLOUD_RECORDS,
  ASSET_SPEND_VM_APPLICATION_RECORDS,
  ASSET_SPEND_VM_RESORCES_RECORDS,
  LIST_STOP_INSTANCE,
};
