import React, { useEffect, useState } from "react";
import GeneralHeader from "./GeneralHeader";
import CostimizeHeader from "./CostimizeHeader";
import CamsHeader from "./CamsHeader";
import NavHeader from "./NavHeader";
import costimize_logo from "../../assets/images/costimize_v2/costimize_logo.svg";
import HomeLandingHeader from "./HomeLanding";

const Header = () => {
  const [header, setHeader] = useState(<GeneralHeader />);

  var path = window.location.pathname;
  useEffect(() => {
    // console.log(path);.admindashboard_header
    const path_array = path.split("/");

    if (path_array[1] === "costdemaze") {
      setHeader(
        <NavHeader
          classList={"custimize-drop-down-list"}
          classHeader={"costimize_header"}
          headerText={"CostDeMaze"}
          type="costdemaze"
        />
      );
    } else if (path_array[1] === "operation") {
      setHeader(<HomeLandingHeader />);
    } else if (path_array[1] === "cams") {
      // setHeader(<CamsHeader />);
      setHeader(
        <NavHeader
          classHeader={"cams_header"}
          headerText={"SA"}
          classList={"slm-drop-down-list"}
          type="smart_alert"
        />
      );
    } else if (path_array[1] === "slm") {
      setHeader(
        <NavHeader
          classHeader={"cams_header"}
          headerText={"SLM"}
          classList={"slm-drop-down-list"}
          type="slm"
        />
      );
    } else if (path_array[1] === "peems") {
      setHeader(
        <NavHeader
          classHeader={"cams_header"}
          headerText={"EE"}
          classList={"slm-drop-down-list"}
          type="event_express"
        />
      );
    } else if (path_array[1] === "billing") {
      setHeader(
        <NavHeader
          classHeader={"cams_header"}
          headerText={"Billing"}
          classList={"slm-drop-down-list"}
          type="billing"
        />
      );
    } else if (path_array[1] === "old_operation") {
      setHeader(
        <NavHeader
          classHeader={"cams_header"}
          headerText={"RAMS"}
          classList={"slm-drop-down-list"}
          type="old_operation"
        />
      );
    } else if (path_array[1] === "devops") {
      setHeader(
        <NavHeader
          classHeader={"devops_header"}
          headerText={"DevOps"}
          classList={"slm-drop-down-list"}
          type="devops"
        />
      );
    } else if (
      path_array[1] === "admin-dashboard" ||
      path_array[1] === "user-mng" ||
      path_array[1] === "role-management" ||
      path_array[1] === "account-onboarding" ||
      path_array[1] === "register-new-customer"
    ) {
      setHeader(
        <NavHeader classHeader={"admindashboard_header"} type="user" />
      );
    } else if (path_array[1] === "signin" || path_array[1] === "signup") {
      setHeader(<HomeLandingHeader />);
    } else if (path === "/") {
      setHeader(<HomeLandingHeader />);
    } else {
      setHeader(
        <NavHeader classHeader={"admindashboard_header"} type="user" />
      );
    }
  }, [path]);

  return <>{header}</>;
};
export default Header;
