import { Icon } from "@iconify/react";
import { Table } from "antd";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

const NonProductionRtoRpo = (props) => {
  const [tablerecords, setTableRecords] = useState([]);
  useEffect(() => {
    if (props.records) {
      let records = props.records.map((datas) => {
        return {
          key: datas.ops_id,
          environment: datas.environment,
          date: datas.report_month,
          application_name: datas.application,
          instance_name: datas.instance_name,
          rto: datas.rto,
          rpo: datas.rpo,
          action: "",
          type: "data",
        };
      });
      setTableRecords(records);
    }
  }, [props]);

  const columns = [
    {
      title: " Application Name",
      dataIndex: "application_name",
      key: "application_name",
      sorter: (a, b) => {
        return a.application_name > b.application_name ? -1 : 1;
      },
    },
    {
      title: "Instance Name",
      dataIndex: "instance_name",
      key: "instance_name",
      sorter: (a, b) => {
        return a.instance_name > b.instance_name ? -1 : 1;
      },
    },
    {
      title: "RTO",
      dataIndex: "rto",
      key: "rto",
      sorter: (a, b) => {
        return a.rto > b.rto ? -1 : 1;
      },
      render: (record, index) => {
        return `${record} Minutes`;
      },
    },
    {
      title: "RPO",
      dataIndex: "rpo",
      key: "rpo",
      sorter: (a, b) => {
        return a.rpo > b.rpo ? -1 : 1;
      },
      render: (record, index) => {
        return `${record} Hours`;
      },
    },
    {
      title: (
        <>
          <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
            <Dropdown.Toggle
              className="plat-three-dort plat-tap-menu plat-box-three-dort"
              id="dropdown-button-dark-example1"
              variant="secondary"
            >
              <Icon icon="ph:dots-three-bold" class="table-coloum-dots-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="plat-box-menu-list more-details">
              <Dropdown.ItemText
                className="plat-dropdown-item-text"
                onClick={() => props.addRtoRpo("Non-Production")}
              >
                <span className="plat-box-item-title list-expand-left">
                  Add
                </span>
                <span className="plat-box-item-title list-expand-right">
                  <Icon
                    icon="material-symbols:add-box-outline"
                    class="table-add-records-icon"
                  />
                </span>
              </Dropdown.ItemText>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ),
      dataIndex: "action",
      key: "action",
      // className: "table-edit-list",
      render: (record, index) => {
        const edit = (
          <Dropdown.ItemText
            className="plat-dropdown-item-text"
            onClick={() => props.editRtoRpo(index)}
          >
            <span className="plat-box-item-title list-expand-left">Edit</span>
            <span className="plat-box-item-title list-expand-right">
              <Icon
                icon="material-symbols:edit-outline"
                class="table-add-records-icon"
              />
            </span>
          </Dropdown.ItemText>
        );

        return (
          <>
            <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
              <Dropdown.Toggle
                className="plat-three-dort plat-tap-menu plat-box-three-dort"
                id="dropdown-button-dark-example1"
                variant="secondary"
              >
                <Icon
                  icon="ph:dots-three-bold"
                  class="plat-expandalt-icon-view"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="plat-box-menu-list more-details">
                {edit}
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const tableProps = {};

  return (
    <>
      <div className="budget-allocated-table">
        <h1 className="plat-table-title">RTO and RPO (Non-Production)</h1>
        <div className="table-width">
          <div className="table-planned-actual">
            <Table
              {...tableProps}
              className="savings_new_table"
              columns={columns}
              dataSource={tablerecords}
              pagination={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NonProductionRtoRpo;
