import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, Space, Popconfirm, Form, message } from "antd";
import { useState } from "react";
import "./drawer.css";
import { Icon } from "@iconify/react";

const DrawerPlat = (props) => {
  const showPopconfirm = () => {
    props.setDrawerDeleteStatus(true);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    props.setDrawerDeleteStatus(false);
  };

  return (
    <>
      <Drawer
        title={props.title}
        width="50%"
        onClose={props.changeDrawerStatus}
        open={props.drawerstatus}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <>
            {props.deletestatus && (
              <Popconfirm
                title="Are you sure to delete?"
                open={props.drawerdeletestatus}
                onConfirm={props.rowDelete}
                okButtonProps={{
                  loading: props.confirmLoading,
                }}
                onCancel={handleCancel}
                placement="bottomRight"
              >
                <div>
                  <Icon
                    icon="material-symbols:delete"
                    className="drawer-delete-icon"
                    onClick={showPopconfirm}
                  />
                </div>
              </Popconfirm>
            )}
          </>
        }
      >
        {props.children}
      </Drawer>
    </>
  );
};
export default DrawerPlat;
