import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { useState, useEffect } from "react";
import { message, Form, Input, Select, Button, Switch } from "antd";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "./activitytracker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  AddcreateActionItem,
  updateaddActionitem,
  deleteActionItem,
} from "../../action/costimizev2DashboardAction";
const { TextArea } = Input;

const ActionItems = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [buttonloading, setButtonLoading] = useState(false);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    var records = {
      category: "",
      date: "",
      owner: "",
      points_discussed: "",
      remarks: "",
      status: "",
    };
    if (props.oldrecords) {
      records = {
        category: props.oldrecords.category,
        date: props.oldrecords.date,

        //date: moment(new Date(props.oldrecords.date)),
        owner: props.oldrecords.owner,
        points_discussed: props.oldrecords.points_discussed,
        remarks: props.oldrecords.remarks,
        status: props.oldrecords.status,
      };
    }
    form.setFieldsValue(records);
  }, [props]);

  const handleSubmit = (value) => {
    setButtonLoading(true);
    const input = {
      company_name: companyName,
      owner: value.owner,
      points_discussed: value.points_discussed,
      remarks: value.remarks,
      status: value.status,
      category: value.category,
      report_month: `${new Date(value.date).getFullYear()}-${
        new Date(value.date).getMonth() + 1
      }-01`,
      usagedate: `${new Date(value.date).getFullYear()}-${
        new Date(value.date).getMonth() + 1
      }-${new Date(value.date).getDate()}`,
    };
    if (props.deletestatus) {
      const action_item_id = props.oldrecords.action_item_id;
      dispatch(updateaddActionitem({ input, action_item_id }))
        .unwrap()
        .then(({ data }) => {
          message.success("Record Updated");
          props.setUpdateStatus(!props.updatestatus);
          props.setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      dispatch(AddcreateActionItem({ input }))
        .unwrap()
        .then(({ data }) => {
          message.success("New Records Added");
          props.setUpdateStatus(!props.updatestatus);
          props.setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  const rowDelete = () => {
    setConfirmLoading(true);
    const action_item_id = props.oldrecords.action_item_id;
    dispatch(deleteActionItem({ action_item_id, companyName }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        props.setUpdateStatus(!props.updatestatus);
        props.setDrawerStatus(false);
        setButtonLoading(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <DrawerPlat
        drawerstatus={props.drawerstatus}
        changeDrawerStatus={props.changeDrawerStatus}
        deletestatus={props.deletestatus}
        title="Action Items"
        rowDelete={rowDelete}
        confirmLoading={confirmLoading}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row>
            <Col>
              <Form.Item
                name="points_discussed"
                label="Points Discussed"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="owner"
                label="Owner"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "completed",
                      label: "Completed",
                    },
                    {
                      value: "pending",
                      label: "Pending",
                    },
                    {
                      value: "in-progress",
                      label: "In-Progress",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="category"
                label="Category"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "operations",
                      label: "Operations",
                    },
                    {
                      value: "cost savings",
                      label: "Cost Saving",
                    },
                    {
                      value: "security",
                      label: "Security",
                    },
                    {
                      value: "budgeting",
                      label: "Budgeting",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[{ required: true, message: "" }]}
              >
                <TextArea
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item>
              <Button
                htmlType="button"
                onClick={() => props.setDrawerStatus(false)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ background: "#1b5f9d", color: "#fff", float: "right" }}
                loading={buttonloading}
              >
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </DrawerPlat>
    </>
  );
};

export default ActionItems;
