import React , {useState} from "react";
import { Card } from "antd";
import ReactApexChart from "react-apexcharts";

const NonProductionLinuxChart = (props) => {
  var countfilteredLin1 = props.countsos.filter(function (element) {
    return (
      element.environment == "non-production" &&
      element.os == "linux" &&
      element.patch_status == "compliance"
    );
  }).length;
  var countfilteredLin2 = props.countsos.filter(function (element) {
    return (
      element.environment == "non-production" &&
      element.os == "linux" &&
      element.patch_status == "non-compliance"
    );
  }).length;
  const series = [countfilteredLin1, countfilteredLin2];
  const options = {
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    colors: ["#84AEE7", "#FFEAA0"],
    dataLabels: {
      enabled: true,
      formatter : function (val) {
        return Math.round(val)
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    title : {
        show : true,
        text : "Linux",
        style : {
            textAlign : "center"
        }
    },
    // plotOptions: {
    //   pie: {
    //     donut: {
    //       labels: {
    //         show: true,
    //         name: {
    //           show: true,
    //         },
    //         value: {
    //           show: true,
    //         },
    //         total: {
    //           show: true,
    //           showAlways: true,
    //           label: "Security Score",
    //           formatter: function (val) {
    //             return 87 + "%";
    //           },
    //         },
    //       },
    //     },
    //   },
    // },
  };

  return (
    <>
      {/* <Card>
        <> */}
          <div id="chart">
            <ReactApexChart 
            options={options} series={series} type="donut" />
          </div>
        {/* </>
      </Card> */}
    </>
  );
};

export default NonProductionLinuxChart;
