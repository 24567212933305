import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import ReactApexChart from "react-apexcharts";
import { getRandomColor } from "../../custom_hook/CustomHook";
import React, { useState, useEffect } from "react";

var colors = [
    '#F4D772',
    '#BB3D9F',
    '#5228AC'
  ]


const ApplicationChart = (props) => {
    const [colorlist, setColorList] = useState([]);
    const  [chartdata, setChartData] = useState([])
    useEffect(() => {
        const colors = [];
        const datalist = [];
        props.applicationbudgettable.map((data) => {
            colors.push(getRandomColor());
            datalist.push({
                name: data.application,
                data: [data.budget_jan,data.budget_feb,data.budget_mar,data.budget_apr,data.budget_may,data.budget_jun,data.budget_jul,data.budget_aug,data.budget_sept,data.budget_oct,data.budget_nov,data.budget_dec]
            });
        });
        setColorList(colors);
        setChartData(datalist);
    },[props])

    const options = {
        chart: {
            type: 'line',
            height: 160,
            toolbar: {
                show: false,
            }
        },
        stroke: {
            show: true,
            curve: 'smooth',   
            lineCap: 'butt',
            width: 1,
        },
        grid:{
            show: false
        },
        colors: colorlist,
        xaxis: {
          categories: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
          tickPlacement: 'on',
          axisBorder: {
                show: true,
                color: '#78909C',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            axisTicks: {
                show: false,
                borderType: 'solid',
                color: '#78909C',
                height: 6,
                offsetX: 0,
                offsetY: 0
            },
            labels: {
              offsetX: 0,
              offsetY: -6,
              style: {
                  fontSize: '12px',
                  fontWeight: 400,
              }
          },
          title: {
                text: "Month",
                rotate: -180,
                offsetX: 0,
                offsetY: -10,
                style: {
                    color: undefined,
                    fontSize: '12px',
                    fontWeight: 500,
                },
            }
        },
        yaxis: {
            // tickAmount: 2,
            axisBorder: {
                show: true,
                color: '#78909C',
                offsetX: 0,
                offsetY: 0
            },
            title: {
              text: "$",
              rotate: -180,
              offsetX: 7,
              offsetY: 0,
              style: {
                  color: undefined,
                  fontSize: '12px',
                  fontWeight: 500,
              },
          },
          labels: {
              style: {
                  fontSize: '8px',
                  fontWeight: 400,
              },
              formatter: (num) => { 
                return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
               },
          },
        },
        dataLabels: {
            enabled: false,
        },
        title: {
            text: 'Budget Plan',
            align: 'center',
            margin: 0,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize:  '14px',
              fontWeight:  '500',
              color:  '#000'
            },
        }
      };
      const series =  chartdata;
    return(
        <>
            <DrawerPlat
                drawerstatus={props.chartdrawerstatus}
                changeDrawerStatus={props.changeChartDrawerStatus}
                title="Application Budget Chart"
                deletestatus={false}
            >
                <ReactApexChart options={options} series={series} type="line" height={350} className="trendchartv2" />
            </DrawerPlat>
        </>
    )
}

export default ApplicationChart;