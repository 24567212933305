import ReactApexChart from "react-apexcharts";
import React from "react";
import { useState, useEffect } from "react";

const ActionItemChart = ({ categoryCount }) => {
  const [series, setSeries] = useState([
    {
      name: "Items",
      data: [],
    },
  ]);

  useEffect(() => {
    const categories = ["Cost Savings", "Budgeting", "Security", "Operations"];
    const filterCounts = [];
    console.log(categoryCount);
    if (categoryCount.length > 0) {
      categories.forEach((category) => {
        const filteredItems = categoryCount.filter(
          (item) => item.category.toLowerCase() === category.toLowerCase()
        );
        const filteredCount = filteredItems.length;
        filterCounts.push(filteredCount);
      });
    }

    setSeries([{ name: "Items", data: filterCounts }]);
  }, [categoryCount]);

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ["#1E90FF"],
    title: {
      text: `Action Item`,
      align: "center",
      margin: 0,
      offsetX: 0,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#595959",
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
        return val;
      },
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: undefined,
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },

    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      // categories: ['Cost Savings','Operations',  'Security', 'Budgeting'],
      categories: ["Cost Savings", "Budgeting", "Security", "Operations"],
    },
    // xaxis: {
    //   categories: categories,
    // },
    fill: {
      opacity: 1,
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={300}
      />
    </>
  );
};

export default ActionItemChart;
