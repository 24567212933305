import React from "react";
import SLMSidebar from "../SLMSidebar";
import MainHead from "../../costimize_v2/layout/MainHead";
import "../slm.css";
import "../../../components/costimize_v2/costimize_v2.css";
import { Table, Tab, Typography } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAssetManaged } from "../../action/costimizev2DashboardAction";
import { Row, Col, Form, Button, Input, Spin } from "antd";
import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { Icon } from "@iconify/react";

const { Text } = Typography;

const SLM_AssetManaged = () => {
  const {
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedTopdate,
    selectedos,
    selectedservices,
    selecteddb,
    selectedinfra,
  } = useSelector((state) => state.constimize);
  // const companyName = localStorage.getItem(
  //   "CognitoIdentityServiceProvider.company"
  // );
  const companyName = "fivestar";
  const [tabledata, setTabledata] = useState([]);
  const dispatch = useDispatch();
  const [marchCpuTotalCount, setMarchCpuTotalCount] = useState();
  const [marchMemoryTotalCount, setMarchMemoryTotalCount] = useState();
  const [marchVMCount, setMarchVMCount] = useState();

  const [janCpuTotalCount, setJanCpuTotalCount] = useState();
  const [janMemoryTotalCount, setJanMemoryTotalCount] = useState();
  const [janVMCount, setJanVMCount] = useState();

  const [febCpuTotalCount, setFebCpuTotalCount] = useState();
  const [febMemoryTotalCount, setFebMemoryTotalCount] = useState();
  const [febVMCount, setFebVMCount] = useState();

  const [drawerStatus, setDrawerStatus] = useState();
  const [deletestatus, setDeleteStatus] = useState();

  const [showSpinner, setShowSpinner] = useState(true);

  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  const columns = [
    {
      title: "Application Name",
      dataIndex: "Application_Name",
      key: "Application_Name",
    },
    {
      title: "Environment Name",
      dataIndex: "Environment_Name",
      key: "Environment_Name",
    },
    {
      title: "Running Hours",
      dataIndex: "Running_Hours",
      key: "Running_Hours",
    },
    {
      title: "Operating System",
      dataIndex: "Operating_System",
      key: "Operating_System",
    },
    {
      title: "CPU Core",
      dataIndex: "CPU_Core",
      key: "CPU_Core",
    },
    {
      title: "Memory",
      dataIndex: "Memory",
      key: "Memory",
    },
    {
      title: "Disk Storage GB",
      dataIndex: "Disk_Storage_GB",
      key: "Disk_Storage_GB",
    },
    {
      title: "VM Count",
      dataIndex: "VM_Count",
      key: "VM_Count",
    },
  ];

  const tableProps = {};
  let sum = 0;
  let march_memory_total = 0;
  let march_vm_total = 0;

  useEffect(() => {
    console.log("Selected Top date", selectedTopdate);
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      compnay_name: companyName,
      os: selectedos.toString(),
      selectedservices: selectedos.toString(),
      selecteddb: selectedos.toString(),
      selectedinfra: selectedos.toString(),
      date: "2023-4-01",
    };
    dispatch(listAssetManaged(apivalue))
      .unwrap()
      .then(({ data }) => {
        const list = data.newfunctionquery13.map((data, index) => {
          sum = sum + Number(data.cpu);
          let dataMemory = data.memory;
          march_memory_total =
            march_memory_total +
            Number(dataMemory.substring(0, dataMemory.length - 3));
          march_vm_total = march_vm_total + Number(data.vm_count);
          return {
            key: index,
            type: "data",
            action: "",
            Application_Name: data.applications,
            Environment_Name: "",
            Running_Hours: data.running_hours,
            Operating_System: data.operating_system,
            CPU_Core: data.cpu,
            cpu_core_sub: "",
            Memory: data.memory,
            memory_sub: "",
            Disk_Storage_GB: "",
            disk_storage_db_sub: "",
            VM_Count: data.vm_count,
            vm_count_sub: "",
          };
        });
        setMarchCpuTotalCount(sum);
        setMarchMemoryTotalCount(march_memory_total);
        setTabledata(list);
        setMarchVMCount(march_vm_total);
        setShowSpinner(false);
      })
      .catch((err) => {
        console.log(err.message);
      });

    janMonthTotalCount();
    febMonthTotalCount();
  }, [selectedTopdate]);

  const janMonthTotalCount = () => {
    let jan_cpu_total = 0;
    let jan_memory_total = 0;
    let jan_vm_total = 0;

    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      compnay_name: companyName,
      os: selectedos.toString(),
      selectedservices: selectedos.toString(),
      selecteddb: selectedos.toString(),
      selectedinfra: selectedos.toString(),
      date: "2023-01-01",
    };
    dispatch(listAssetManaged(apivalue))
      .unwrap()
      .then(({ data }) => {
        const list = data.newfunctionquery13.map((data, index) => {
          jan_cpu_total = jan_cpu_total + Number(data.cpu);
          let dataMemory = data.memory;
          jan_memory_total =
            jan_memory_total +
            Number(dataMemory.substring(0, dataMemory.length - 3));
          jan_vm_total = jan_vm_total + Number(data.vm_count);
          return {
            key: index,
            type: "data",
            action: "",
            Application_Name: data.applications,
            Environment_Name: "",
            Running_Hours: data.running_hours,
            Operating_System: data.operating_system,
            CPU_Core: data.cpu,
            cpu_core_sub: "",
            Memory: data.memory,
            memory_sub: "",
            Disk_Storage_GB: "",
            disk_storage_db_sub: "",
            VM_Count: data.vm_count,
            vm_count_sub: "",
          };
        });
        setJanCpuTotalCount(jan_cpu_total);
        setJanMemoryTotalCount(jan_memory_total);
        setJanVMCount(jan_vm_total);
        setShowSpinner(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const febMonthTotalCount = () => {
    let feb_cpu_total = 0;
    let feb_memory_total = 0;
    let feb_vm_total = 0;

    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      compnay_name: companyName,
      os: selectedos.toString(),
      selectedservices: selectedos.toString(),
      selecteddb: selectedos.toString(),
      selectedinfra: selectedos.toString(),
      date: "2023-02-01",
    };
    dispatch(listAssetManaged(apivalue))
      .unwrap()
      .then(({ data }) => {
        const list = data.newfunctionquery13.map((data, index) => {
          feb_cpu_total = feb_cpu_total + Number(data.cpu);
          let dataMemory = data.memory;
          feb_memory_total =
            feb_memory_total +
            Number(dataMemory.substring(0, dataMemory.length - 3));
          feb_vm_total = feb_vm_total + Number(data.vm_count);
          return {
            key: index,
            type: "data",
            action: "",
            Application_Name: data.applications,
            Environment_Name: "",
            Running_Hours: data.running_hours,
            Operating_System: data.operating_system,
            CPU_Core: data.cpu,
            cpu_core_sub: "",
            Memory: data.memory,
            memory_sub: "",
            Disk_Storage_GB: "",
            disk_storage_db_sub: "",
            VM_Count: data.vm_count,
            vm_count_sub: "",
          };
        });
        setFebCpuTotalCount(feb_cpu_total);
        setFebMemoryTotalCount(feb_memory_total);
        setFebVMCount(feb_vm_total);
        setShowSpinner(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleAddClick = () => {
    setDrawerStatus(true);
  };

  return (
    <>
      <DrawerPlat
        drawerstatus={drawerStatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        title="Assets Managed"
      >
        <Form layout="vertical">
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="application_name" label="Application Name">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="environment_name" label="Environment Name">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="running_hours" label="Running Hours">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="operating_system" label="Operating System">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="cpu_core" label="CPU Core">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="memory" label="Memory">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="Disk_Storage_GB" label="Disk Storage GB">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="vm_count" label="VM Count">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>
      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <SLMSidebar />
        <MainHead type="slm" title="Assets Managed" />
        <div className="costimize-body-screen">
          {/* <div className="mt-3">
            <button
              className="table-add-button float-right"
              onClick={handleAddClick}
            >
              <Icon icon="ion:add-circle-outline" />
              Adds
            </button>
          </div> */}
          {/* <div className="table-width"> */}
          <div className="slm table-planned-actual">
            <Table
              {...tableProps}
              className=" slm asset_managed_table"
              columns={columns}
              loading={{ indicator: <Spin />, spinning: showSpinner }}
              dataSource={tabledata}
              pagination={true}
              style={{ overflowX: "scroll" }}
              // rowClassName={(record, index) => record.type === 'footer' ? 'assetmanaged-table-footer-new' : ''}
              summary={(pageData) => {
                return (
                  <>
                    {tabledata.length > 0 && (
                      <>
                        <Table.Summary.Row className="assetmanaged-table-footer">
                          <Table.Summary.Cell index={0}>
                            March Month Total
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1}> </Table.Summary.Cell>
                          <Table.Summary.Cell index={2}></Table.Summary.Cell>
                          <Table.Summary.Cell index={3}></Table.Summary.Cell>
                          <Table.Summary.Cell index={4}>
                            <span>{marchCpuTotalCount}</span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={5}>
                            <span>{marchMemoryTotalCount} </span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6}>
                            <span> 0 </span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={7}>
                            <span> {marchVMCount} </span>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row className="assetmanaged-table-footer">
                          <Table.Summary.Cell index={0}>
                            January Month Total
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1}></Table.Summary.Cell>
                          <Table.Summary.Cell index={2}></Table.Summary.Cell>
                          <Table.Summary.Cell index={3}></Table.Summary.Cell>
                          <Table.Summary.Cell index={4}>
                            <span>{janCpuTotalCount}</span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={5}>
                            <span> {janMemoryTotalCount}</span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6}>
                            <span>0</span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={7}>
                            <span> {janVMCount} </span>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row className="assetmanaged-table-footer">
                          <Table.Summary.Cell index={0}>
                            February Month Total
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1}></Table.Summary.Cell>
                          <Table.Summary.Cell index={2}></Table.Summary.Cell>
                          <Table.Summary.Cell index={3}></Table.Summary.Cell>
                          <Table.Summary.Cell index={4}>
                            <span>{febCpuTotalCount}</span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={5}>
                            <span>{febMemoryTotalCount}</span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6}>
                            <span>0</span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={7}>
                            <span>{febVMCount}</span>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    )}
                  </>
                );
              }}
            />
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default SLM_AssetManaged;
