import React from "react";
import ProductionCard from "./ProductionCard";
import NonProductionCard from "./Non_prodCard";
import { Row, Col } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { listcompliance } from "../../../action/graphql/slm/SLM_reducers";

const PatchManagements = (props) => {
  const [chartdetails, setChartdetails] = useState([]);
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [selectedMonth, setSelectedMonth] = useState(selectedTopdate);
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [refresh, setRefresh] = useState(false);

  const getChartData = () => {
    const date = selectedMonth;
    dispatch(listcompliance({ companyName, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {
        console.log("inside ref fn");
        const countsos = data.newfunctionquery28.map((datas) => {
          return {
            company: datas.company,
            environment: datas.environment,
            month: datas.month,
            os: datas.os,
            patch_id: datas.patch_id,
            patch_status: datas.patch_status,
          };
        });
        setChartdetails(countsos);
        console.log(countsos);
      });
  };

  const refreshData = () => {
    console.log("called");

    setRefresh(true);
    getChartData();
  };

  useEffect(() => {
    getChartData();
    // const date = selectedMonth;
    // dispatch(listcompliance({ companyName, date }))
    //   .unwrap()
    //   .then(({ data }) => {
    //     const countsos = data.newfunctionquery28.map((datas) => {
    //       return {
    //         company: datas.company,
    //         environment: datas.environment,
    //         month: datas.month,
    //         os: datas.os,
    //         patch_id: datas.patch_id,
    //         patch_status: datas.patch_status,
    //       };
    //     });
    //     setChartdetails(countsos);
    //     console.log(countsos);
    //   });
  }, [props.updatestatus, selectedMonth, selectedTopdate]);

  useEffect(() => {}, [refresh, chartdetails]);
  return (
    <>
      <Row>
        <Col xxl={12} xl={11} lg={11} md={12} sm={24} xs={24} className="m-2">
          <ProductionCard countsos={chartdetails} refreshData={refreshData} />
        </Col>
        <Col xxl={1} xl={1} lg={1} md={1} sm={24} xs={24}></Col>
        <Col xxl={12} xl={11} lg={11} md={10} sm={24} xs={24} className="m-2">
          <NonProductionCard
            countsos={chartdetails}
            refreshData={refreshData}
          />
        </Col>

        {/* <Col xxl={5} xl={5} lg={5} md={24} sm={24} xs={24}></Col> */}
      </Row>
    </>
  );
};

export default PatchManagements;
