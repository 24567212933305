import { Icon } from "@iconify/react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { useDispatch } from "react-redux";
import {
  jobConfigIdentifier,
  jobCreate,
  listCustomerIdentifier,
  listEventIdentifier,
  listRuleIdentifier,
} from "../../action/graphql/peems/PeemsAPI";
import "react-js-cron/dist/styles.css";
import { Cron } from "react-js-cron";
import CustomerIdentifierHome from "../Identifier/CustomerIdentifier/CustomerIdentifierHome";
import EventIdentifierHome from "../Identifier/EventIdentifier/EventIdentifierHome";
import RuleIdentifierHome from "../Identifier/RuleIdentifier/RuleIdentifierHome";
import { Option } from "antd/es/mentions";
function JobConfigHome() {
  const [isShowSpinner, setIsShowSpinner] = useState(true);
  const columns = [
    {
      key: 1,
      name: "job name",
      title: "Job Name ",
      dataIndex: "event_identifier",
      fixed: "left",
    },

    {
      key: 2,
      name: "cron description",
      title: "CRON Description ",
      dataIndex: "cron_description",
      fixed: "left",
    },
    {
      key: 3,
      name: "job_actual_runtime",
      title: "Job Run Time (in hrs)",
      dataIndex: "duration",
      fixed: "left",
    },

    {
      key: 5,
      name: "status",
      title: "Status",
      dataIndex: "status",
      fixed: "left",
      width: 200,
      render: (text, record, string) => {
        return record.status === "active" ? (
          <div className="peems-completed">{"Active"}</div>
        ) : record.status === "active" ? (
          <div className="peems-active">Yet To Start</div>
        ) : record.status === "not triggered" ? (
          <div className="peems-not-trigerred">Not Trigerred</div>
        ) : record.status === "failed" ? (
          <div className="peems-failed">Failed</div>
        ) : (
          ""
        );
      },
    },
    {
      key: 6,
      name: "action",
      title: "",
      dataIndex: "action",
      fixed: "left",
      render: (text, record, index) => {
        return (
          <>
            <Icon
              icon="material-symbols:edit"
              onClick={() => handleEditClick(record)}
            />
          </>
        );
      },
    },
  ];
  const [editStatus, setEditStatus] = useState(false);
  const handleEditClick = (record) => {
    setEditStatus(true);
    setDrawerStatus(true);
    form.setFieldsValue(record);
  };
  const handleAddClick = () => {
    setEditStatus(false);
    setDrawerStatus(true);
  };
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    values.cron_value = cron_value;
    console.log(values);
    if (editStatus) {
    } else {
      dispatch(jobCreate({ values, companyName }))
        .unwrap()
        .then(({ data }) => {
          console.log(data);
          setDrawerStatus(false);
          getJobConfigDetails();
          message.success("Record updated");
        });
    }
  };
  const rowDelete = () => {};
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const getJobConfigDetails = () => {
    dispatch(jobConfigIdentifier(companyName))
      .unwrap()
      .then(({ data }) => {
        let response = JSON.parse(data.getjobconfigured);
        console.log(response);
        setTableData(response);
        setIsShowSpinner(false);
      });
  };
  const [customerIdentifierList, setCustomerIdentifierList] = useState([
    {
      rule_id: "",
      condition: "",
      key: "",
      status: "",
      value: "",
    },
  ]);
  const [eventIdentifierList, setEventIdentifierList] = useState([
    {
      rule_id: "",
      condition: "",
      key: "",
      status: "",
      value: "",
    },
  ]);
  const [ruleIdentifierList, setRuleIdentifierList] = useState([
    {
      rule_id: "",
      condition: "",
      key: "",
      status: "",
      value: "",
    },
  ]);
  const getIdentifiers = () => {
    dispatch(listCustomerIdentifier({ companyName }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        let response = data.listPEEMSRules.items;
        setCustomerIdentifierList(response);

        dispatch(listEventIdentifier({ companyName }))
          .unwrap()
          .then(({ data }) => {
            console.log(data);
            let response = data.listPEEMSRules.items;
            setEventIdentifierList(response);
          });
        dispatch(listRuleIdentifier({ companyName }))
          .unwrap()
          .then(({ data }) => {
            console.log(data);
            let response = data.listPEEMSRules.items;
            setRuleIdentifierList(response);
          });
      });
  };
  useEffect(() => {
    getJobConfigDetails();
    getIdentifiers();
  }, []);
  const [cron_value, setCronValue] = useState([]);
  const [value, setValue] = useState("30 5 * * 1,6");
  const [openIdentifierDrawer, setDrawerOpenIdentifier] = useState(false);
  const handleIdentifierDrawerChange = () => {
    getIdentifiers();
    setDrawerOpenIdentifier(false);
  };
  const [identifierType, setIdentifierType] = useState();
  const handleAddIdentifierClick = (type) => {
    setDrawerOpenIdentifier(true);
    if (type === "customer") {
      setIdentifierType("customer");
    } else if (type === "event") {
      setIdentifierType("event");
    } else {
      setIdentifierType("rule");
    }
  };

  return (
    <>
      <DrawerPlat
        drawerstatus={openIdentifierDrawer}
        changeDrawerStatus={handleIdentifierDrawerChange}
        deletestatus={deletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        rowDelete={rowDelete}
        title=" Add Identifier"
      >
        {identifierType == "customer" ? (
          <CustomerIdentifierHome />
        ) : identifierType === "event" ? (
          <EventIdentifierHome />
        ) : (
          <RuleIdentifierHome />
        )}
      </DrawerPlat>
      <DrawerPlat
        drawerstatus={drawerStatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        rowDelete={rowDelete}
        title=" Job Configuration"
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item name="job_type" label="Job Type">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Job Type"
                  options={[
                    {
                      value: "email",
                      label: "Email",
                    },
                    {
                      value: "message",
                      label: "Message",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="job_name" label="Job Name">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="job_description" label="Job Description">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="jobactual_runtime" label="Job Actual Time">
                <Input
                  type="number"
                  addonAfter="in mins"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item name="jobbuffer_runtime" label="Job Buffer Time">
                <Input
                  addonAfter="in mins"
                  type="number"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item name="cron_value" label="CRON Time">
                <Cron
                  value={value}
                  setValue={(selectedPeriod) => {
                    console.log(selectedPeriod);
                    setCronValue(selectedPeriod);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item name="cron_description" label="CRON Description">
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <h6 className="mb-4">Job Identifier Configuration</h6>
          <Row>
            <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
              <Form.Item name="customer_identifier" label="Customer Identifier">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Customer Identifier"
                >
                  {customerIdentifierList.map((option) => (
                    <Option key={option.value} value={option.rule_id}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
              className="d-flex justify-content-end align-items-center"
            >
              <button
                className="table-add-button float-right"
                onClick={() => {
                  handleAddIdentifierClick("customer");
                }}
              >
                <Icon icon="material-symbols:add-circle-outline" />
                Add
              </button>
            </Col>
          </Row>
          <Row>
            <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
              <Form.Item name="event_identifier" label="Event Identifier">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Event Identifier"
                >
                  {eventIdentifierList.map((option) => (
                    <Option key={option.value} value={option.rule_id}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
              className="d-flex justify-content-end align-items-center"
            >
              <button
                className="table-add-button float-right"
                onClick={() => {
                  handleAddIdentifierClick("event");
                }}
              >
                <Icon icon="material-symbols:add-circle-outline" />
                Add
              </button>
            </Col>
          </Row>
          <Row>
            <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
              <Form.Item name="rule_identifier" label="Rule Identifier">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Rule Identifier"
                  mode="multiple"
                >
                  {" "}
                  {ruleIdentifierList.map((option) => (
                    <Option key={option.value} value={option.rule_id}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
              className="d-flex justify-content-end align-items-center"
            >
              <button
                className="table-add-button float-right"
                onClick={() => {
                  handleAddIdentifierClick("rule");
                }}
              >
                <Icon icon="material-symbols:add-circle-outline" />
                Add
              </button>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>
      <div onClick={() => handleAddClick()}>
        <button className="table-add-button float-right">
          <Icon icon="material-symbols:add-circle-outline" />
          Add
        </button>
      </div>
      <div className="peems-report-table">
        <Table
          className="peems"
          columns={columns}
          pagination={true}
          dataSource={tableData}
          loading={{ indicator: <Spin />, spinning: isShowSpinner }}
        />
      </div>
    </>
  );
}

export default JobConfigHome;
