import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import "./budgetPlanning.css";
import { numberFormat } from "../../custom_hook/CustomHook";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const BudgetPlanningSemiDonutChart = (props) => {
  const [chartValue, setChartValue] = useState({
    options: {},
    series: [],
  });
  const [monthallocated, setMonthAllocated] = useState(0);
  const [chartcal, setChartCal] = useState({
    spend: 0,
    budget: 0,
    balance: 0,
  });
  const [budgetChartHeight, setBudgetChartHeight] = useState(350);

  const options = {
    chart: {
      type: "radialBar",
      offsetY: -38,
      offsetX: 0,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        offsetX: 0,
        track: {
          background: "#A1AF01",
          strokeWidth: "100%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: false,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      colors: ["#76C5F1"],
      type: "solid",
    },
    title: {
      text: "Budget Overview",
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 31,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "500",
        fontFamily: "Poppins",
        color: "#263238",
      },
    },
  };

  var total_budget_value;

  var month_spend = 0;
  var project_value = 0;

  var chart_percentage;

  useEffect(() => {
    setChartCalFunc();
    setChartValueFunc();
    var windowWidth = Number(window.innerWidth);
    switch (true) {
      case windowWidth <= 1080 && windowWidth >= 1025:
        setBudgetChartHeight(320);
        break;
      case windowWidth <= 1024 && windowWidth >= 769:
        setBudgetChartHeight(300);
        break;
      case windowWidth <= 768 && windowWidth >= 427:
        setBudgetChartHeight(270);
        break;
      default:
        setBudgetChartHeight(350);
    }
  }, [props]);

  const setChartCalFunc = () => {
    // if (monthallocated === 0) {
    //   total_budget_value = project_value;
    // } else {
    //   total_budget_value = monthallocated;
    // }

    setChartCal({
      spend: Math.round(props.chartcal.spend),
      budget: Math.round(props.chartcal.budget),
      balance: Math.round(props.chartcal.balance),
    });
  };

  const setChartValueFunc = () => {
    // if (
    //   Number.isInteger(Math.round((month_spend * 100) / total_budget_value))
    // ) {
    //   chart_percentage = Math.round((month_spend * 100) / total_budget_value);
    // } else {
    //   chart_percentage = 0;
    // }
    // console.log(props.semichartvalue);
    const series = [props.semichartvalue];
    setChartValue({
      options: options,
      series: series,
    });
  };

  return (
    <>
      <div className="budget-projected-costbox">
        <Row>
          <Col>
            {/* <h1 className='budget-cost-title'>Budget Overview</h1> */}
            <ReactApexChart
              options={chartValue.options}
              series={chartValue.series}
              type="radialBar"
              height={budgetChartHeight}
            />
            <div className="chart-down-value">
              <Row>
                <Col className="mt-3">
                  <h1 className="budget-cost-value">
                    ${numberFormat(chartcal.spend)}
                  </h1>
                  <h1 className="budget-cost-value">Spend</h1>
                </Col>
                <Col className="mt-3">
                  <h1 className="budget-cost-value">
                    ${numberFormat(chartcal.budget)}
                  </h1>
                  <h1 className="budget-cost-value">Budget</h1>
                </Col>
                <Col className="mt-3">
                  {chartcal.balance > 0 ? (
                    <h1 className="budget-cost-value">
                      ${numberFormat(chartcal.balance)}
                    </h1>
                  ) : (
                    <h1
                      className="budget-cost-value"
                      style={{ color: "rgb(190, 30, 45)" }}
                    >
                      ${numberFormat(Math.abs(chartcal.balance))}
                    </h1>
                  )}
                  {chartcal.balance > 0 ? (
                    <h1 className="budget-cost-value">Balance</h1>
                  ) : (
                    <h1 className="budget-cost-value">Over Spend</h1>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BudgetPlanningSemiDonutChart;
