import ReactApexChart from "react-apexcharts";


const ProductionWindowChart = () => {
    const options = {
        chart: {
          type: 'donut',
          background: '#fff',
          // height: "300px"
        },
        title: {
            text: 'Windows',
            align: 'center',
            margin: 0,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize:  '14px',
              fontWeight:  'bold',
              color:  '#595959'
            },
        },
        legend: {
          show: true,
          offsetY: 0,
          position: 'bottom'
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Math.round(val) + "%"
          }
        },
        colors: ['#2da600','#ff8a00'],
        labels: ['Complaints','Non-Complaints'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      };


      const series = [1, 100];

    return(
        <>
            <ReactApexChart options={options} series={series} type="donut" height={300} />
        </>
    )
}

export default ProductionWindowChart;