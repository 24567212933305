import { createAsyncThunk } from "@reduxjs/toolkit";

import { useEffect } from "react";
import {
  BASIC_CONFIG_ADD_UPDATE,
  LIST_DEVOPS_ACCOUNT,
  LIST_DEVOPS_CATEGORY,
  LIST_REQUEST_TABLE,
} from "./DevOpsQuery";
import client_new from "../../client/dashboardapplonewClinet";

export const useErrorNavigation = (error) => {
  if (error) {
    // Check the error condition and navigate accordingly
    if (error.networkError && error.networkError.statusCode === 500) {
      window.location.href = "/signin";
    }
  }
};

export const listDevopsAccount = createAsyncThunk(
  "devops/dashboard",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const datasList = await client_new.mutate({
        mutation: LIST_DEVOPS_ACCOUNT,
        variables: {
          customer: data.customer,
        },
      });
      return datasList;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // if (error.networkError && error.networkError.statusCode === 500) {
      //     window.location.href = "/signin";
      //   }

      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        return rejectWithValue(error.graphQLErrors[0].message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listDevopscategory = createAsyncThunk(
  // action type string
  "devops/dashboard",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const datasListCategory = await client_new.mutate({
        mutation: LIST_DEVOPS_CATEGORY,
        variables: {
          cloud_platform: data.cloud_platform,
        },
      });
      return datasListCategory;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listRequestTable = createAsyncThunk(
  // action type string
  "devops/request",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const datasListCategory = await client_new.mutate({
        mutation: LIST_REQUEST_TABLE,
        variables: {
          customer_name: data.companyName,
        },
      });
      return datasListCategory;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addUpdateForm = createAsyncThunk(
  // action type string
  "devops/add_edit",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    const customer = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    try {
      const save_basic_details = await client_new.mutate({
        mutation: BASIC_CONFIG_ADD_UPDATE,
        variables: {
          event_type: data.values.event_type,
          account_name: data.accountDetailsInfo[0].account_name,
          activity_type: data.basicDetailsInfo.activity_type,
          configurations: data.configDetailsInfo,
          created_by: "123",
          customer_name: customer,
          cx_approval_email: data.accountDetailsInfo[0].customer_approval_email,
          cx_comments: "",
          job_name: "123",
          och_approval_email: data.accountDetailsInfo[0].och_approval_email,
          och_comments: "",
          operation: data.basicDetailsInfo.operation,
          status: data.values.status,
          service_name: data.basicDetailsInfo.service_name,
          cloud_platform: data.basicDetailsInfo.cloud_platform,
        },
      });
      return save_basic_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
