import { Icon } from "@iconify/react";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";
import { zohoMapping } from "../action/graphql/cams/cams_reducers";
import ModalPopUp from "./ModalPopUp";
import TicketInformationPopup from "./TicketInformationPopup";
import ZohoTable from "./ZohoTable";

function TicketInformationTableAlerts({type, camsAllDetailsData}) {

  const dispatch = useDispatch();
  const [viewData, setViewData] = useState([]);
  const [zohoData, setZohoData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    setIsShow(false);
  };
  useEffect(()=>{
    console.log("inside",type);
    console.log({camsAllDetailsData});
    if(type==="cams"){
      console.log("inside if");
      let temp = []
      camsAllDetailsData.forEach(data => {
        let tempObj = {...data};
        tempObj.view = (<> <Icon className="cursor-pointer"
          icon={"mdi:eye"}
          style={{ fontSize: "18px" }}
          onClick={() => viewZohoData(data)}
        /></>)
        console.log({data});
        temp.push(tempObj)
      })
      setViewData(temp);
    }
    else{
      setViewData(camsAllDetailsData)
    }
  },[camsAllDetailsData,zohoData,type])

  const viewZohoData = (data) => {
    setIsShow(true);
    setLoading(true);
    console.log({data});
    dispatch(zohoMapping({date:data.date,subject:data.zoho_mapper,event_type:"CAMS"}))
    .unwrap()
    .then(({data}) => {
      let op = data.zoho_ticket_mapping
      console.log(JSON.parse(op),"first");
      setZohoData([JSON.parse(op)]);
    setLoading(false);

    })
  }

  const camsColumns = [
    {
      dataField: "alert_type",
      text: "Alert Type",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
        borderLeftRadius: "5px",
      },
      sort: true,
    },
    {
      dataField: "from_address",
      text: "From Address",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "server_name",
      text: "Server Name",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "severity",
      text: `Severity Level`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "subject",
      text: `Subject`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "to_address",
      text: `To Address`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "date",
      text: `Date`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "time",
      text: `Time`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "view",
      text: `View`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
  ];

  const allAlertsColumns = [
    {
      dataField: "alert_type",
      text: "Alert Type",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
        borderLeftRadius: "5px",
      },
      sort: true,
    },
    {
      dataField: "from_address",
      text: "From Address",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "server_name",
      text: "Server Name",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "severity",
      text: `Severity Level`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "subject",
      text: `Subject`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "to_address",
      text: `To Address`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "date",
      text: `Date`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "time",
      text: `Time`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    }
  ];

  const data = [
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
    {
      alertType: "PROBLEM Service",
      fromAddress: "nagios@health.1cloudhub.com",
      serverName: "APL-CFRAZUPVA710-AA-Prod",
      severityLevel: "Critical",
      subject:
        "** PROBLEM Service Alert: APL-CFRAZUPVA710-AA-Prod/nrds_C: Drive Space is WARNING **",
      toAddress: "['servicealerts@ses.1cloudhub.com']",
      date: "07/04/2023",
      time: "18:30:15",
    },
  ];

  return (
    <div>
      <div className="alert-detail-table">
        <BootstrapTable
          keyField="id"
          data={viewData}
          columns={type === 'cams' ? camsColumns : allAlertsColumns }
          bordered={false}
          noDataIndication="No Records"
        />
      </div>
      {isShow && (
        <div className="zoho-popupzoho-popup"> 
        <ModalPopUp
          width = "50%"
          title="Ticket Information"
          open={isShow}
          handleCancel={handleCancel}
          data={loading ? <Spin className="spinner-class"/> :
          //  <ZohoTable zohoData={zohoData}/>
          <TicketInformationPopup classname="" zohoData={zohoData}/>
          }
        />
        </div>
      )}
    </div>
  );
}

export default TicketInformationTableAlerts;
