import { Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import AlertDetailsTable from "./AlertDetailsTable";
import SummaryViewTable from "./SummaryViewTable";
import SummaryViewChart from "./SummaryViewChart";
import { useState } from "react";
import ModalPopUp from "./ModalPopUp";
import TicketInformationTable from "./TicketInformationTable";
import { useDispatch } from "react-redux";
import {
  camsAllDetails,
  camsSeverityCount,
  getAlertTrend,
  getCamsAlertCount,
  getDistinctServers,
  getWeeklyData,
} from "../action/graphql/cams/cams_reducers";
import moment from "moment";

function SummaryView({ customer }) {
  const [isShow, setIsShow] = useState(false);
  const handleCancel = () => {
    setIsShow(false);
  };
  const dispatch = useDispatch();
  const company_name =
    localStorage.getItem("CognitoIdentityServiceProvider.company") !==
    "Internal1CH"
      ? localStorage.getItem("CognitoIdentityServiceProvider.company")
      : customer;
  const currentDate = new Date();
  var dateString = moment(currentDate).format("YYYY/MM/DD");
  var displayDate = moment(currentDate).format("Do MMMM YYYY");

  const [serverCount, setServerCount] = useState(0);
  const [allAlertsCount, setAllAlertsCount] = useState(0);
  const [camsAlertsCount, setCamsAlertsCount] = useState(0);
  const [camsAllDetailsData, setCamsAllDetailsData] = useState([]);
  const [severityTableData, setSeverityTableData] = useState([]);
  const currentDateTemp = moment();
  var currentDateTable = moment(currentDateTemp).format("YYYY-MM-DD");
  const [TotalAlertsObj, setTotalAlertsObj] = useState({});
  const [camsObj, setCamsObj] = useState({});
  const [dateLabel, setDateLabel] = useState([]);
  const [allAlertsTrend, setAllAlertsTrend] = useState([]);
  const [camsTrend, setCamsTrend] = useState([]);
  const [trendLoader, setTrendLoader] = useState(true);
  var prevDateTable = moment(currentDateTemp.subtract(30, "days")).format(
    "YYYY-MM-DD"
  );
  // Create an empty array to store the dates
  var datesArray = [];

  useEffect(() => {
    getDates();
    getCountData();
    // getCamsCountData();
    getCamsAllDetails();
    getCamsSeverityCount();
    getAlertTrendFn();
  }, [customer, company_name]);

  useEffect(() => {}, [
    customer,
    company_name,
    severityTableData,
    dateLabel,
    camsTrend,
    allAlertsTrend,
  ]);

  var TotalAlertsObjTemp = {};
  var CamsObjTemp = {};
  const getDates = () => {
    // Define the start and end dates
    var startDate = moment(prevDateTable.toString(), "YYYY-MM-DD");
    var endDate = moment(currentDateTable.toString(), "YYYY-MM-DD");

    var dateLabelTemp = [];
    // Iterate over the dates and add them to the array
    var currentDate = startDate;
    while (currentDate.isSameOrBefore(endDate)) {
      datesArray.push(currentDate.format("YYYY-MM-DD"));
      dateLabelTemp.push(currentDate.format("DD MMM"));
      currentDate.add(1, "day");
    }

    // Create an object with dates as keys and "0" as values

    datesArray.forEach(function (date) {
      TotalAlertsObjTemp[date] = "0";
    });
    datesArray.forEach(function (date) {
      CamsObjTemp[date] = "0";
    });
    setTotalAlertsObj(TotalAlertsObjTemp);
    setCamsObj(CamsObjTemp);
    setDateLabel(dateLabelTemp);
  };
  const getAlertTrendFn = () => {
    dispatch(
      getAlertTrend({
        companyName: company_name,
        currentDate: currentDateTable.toString(),
        previousDate: prevDateTable.toString(),
      })
    )
      .unwrap()
      .then(({ data }) => {
        data.cams_alert_trends.forEach((data) => {
          TotalAlertsObjTemp[data.date.toString()] = data.all_alerts;
          CamsObjTemp[data.date.toString()] = data.outgoing_alerts;
        });
        setAllAlertsTrend(Object.values(TotalAlertsObjTemp));
        setCamsTrend(Object.values(CamsObjTemp));
        setTrendLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setTrendLoader(false);
      });
  };
  const getCamsSeverityCount = () => {
    dispatch(
      camsSeverityCount({
        companyName: company_name,
        selectedTopdate: dateString,
      })
    )
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        let severityData = data.cams_severity_count;
        let recordsArray = [];
        severityData.forEach((data) => {
          let record = {};
          if (data.severity.toLowerCase() !== "(singapore)") {
            record.severity = data.severity
              .toLowerCase()
              .replace(/\b\w/g, (s) => s.toUpperCase());
            record.alert_count = data.alert_count;
            record.server_count = data.server_count;
            record.cams_alert = data.cams_alert;
            recordsArray.push(record);
            console.log(recordsArray);
            setSeverityTableData(recordsArray);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCamsAllDetails = () => {
    dispatch(
      camsAllDetails({ companyName: company_name, selectedTopdate: dateString })
    )
      .unwrap()
      .then(({ data }) => {
        setCamsAllDetailsData(data.cams_all_details);
      });
  };
  const getCountData = () => {
    dispatch(
      getDistinctServers({
        companyName: company_name,
        selectedTopdate: dateString,
      })
    )
      .unwrap()
      .then(({ data }) => {
        setAllAlertsCount(data.cams_count_api[0].alert_count);
        setServerCount(data.cams_count_api[0].server_count);
        setCamsAlertsCount(data.cams_count_api[0].cams_daily_alert);
      });
  };

  // const getCamsCountData = () => {
  //   // console.log({ company_name, dateString });
  //   dispatch(
  //     getCamsAlertCount({
  //       companyName: company_name,
  //       selectedTopdate: dateString,
  //     })
  //   )
  //     .unwrap()
  //     .then(({ data }) => {
  //       // console.log(data);
  //       setCamsAlertsCount(data.cams_daily_alert_count[0].cams_daily_alert);
  //     });
  // };
  return (
    <>
      {" "}
      <div className="summary-view mb-5">
        <Row gutter={[16, { xs: 8, sm: 16, md: 16, lg: 32 }]}>
          <Col xxl={8} xl={8} lg={10} md={10} sm={24} xs={24}>
            <Row gutter={[16, 16]}>
              <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                {" "}
                <Card className="plat-card-1">
                  <div className="d-flex justify-content-center">
                    <h6 className="card-title">Distinct Servers Count</h6>
                  </div>
                  <div className="mt-3">
                    <h6 className="card-value-1">
                      <Icon icon={"solar:server-square-cloud-bold"} />
                      {serverCount && serverCount}
                    </h6>
                  </div>
                </Card>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                {" "}
                <Card className="plat-card-1">
                  <div className="d-flex justify-content-center">
                    <h6 className="card-title">Alerts Count</h6>
                  </div>
                  <div className="mt-3">
                    <h6 className="card-value-1">
                      <Icon icon={"mdi:cloud-alert"} />
                      {allAlertsCount && allAlertsCount}
                    </h6>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3" gutter={[16, 16]}>
              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                {" "}
                <Card className="plat-card-1">
                  <div className="d-flex justify-content-center">
                    <h6 className="card-title">SA Processed Alerts</h6>
                  </div>
                  <div className="mt-3">
                    <h6 className="card-value-1">
                      <Icon icon={"uil:process"} />
                      {camsAlertsCount && camsAlertsCount}
                    </h6>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xxl={16} xl={16} lg={14} md={14} sm={24} xs={24}>
            <Card className="plat-card-table p-2">
              <div className="d-flex justify-content-between mb-1">
                <h6 className="card-title">
                  Severity Level Counts {displayDate && `(${displayDate})`}
                </h6>
                <Icon
                  icon={"ph:dots-three-vertical-bold"}
                  style={{ fontSize: "18px" }}
                />
              </div>

              <div className="table-section">
                <div
                  className="summary-info-icon-position"
                  onClick={() => {
                    setIsShow(true);
                  }}
                >
                  <Icon
                    icon={"material-symbols:info-rounded"}
                    style={{ fontSize: "12px", color: "white" }}
                  />{" "}
                </div>
                <SummaryViewTable severityTableData={severityTableData} />
                <span className="alert-info">
                  <Icon
                    icon={"material-symbols:info-rounded"}
                    style={{ fontSize: "12px" }}
                  />{" "}
                  &nbsp; Click on the icon to know about the ticket information
                </span>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3" gutter={16}>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card className="plat-card-table p-2">
              <div className="d-flex justify-content-between">
                <h6 className="card-title">Alerts trend for last 30 days</h6>
              </div>
              <div className="alert-details-chart pt-3">
                <SummaryViewChart
                  dateLabel={dateLabel}
                  allAlertsTrend={allAlertsTrend}
                  camsTrend={camsTrend}
                  loading={trendLoader}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {isShow && (
        <ModalPopUp
          title="Ticket Information"
          open={isShow}
          handleCancel={handleCancel}
          data={
            <TicketInformationTable camsAllDetailsData={camsAllDetailsData} />
          }
        />
      )}
    </>
  );
}

export default SummaryView;
