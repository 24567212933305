import { memo, useState } from "react";
import { Modal, Steps, theme, Input, Button, message } from "antd";
import ProfileInfo from "../useronboard/ProfileInfo";
import CompanyInfo from "../useronboard/CompanyInfo";
import OfferingInfo from "../useronboard/OfferingInfo";
import { Icon } from "@iconify/react";
import "./usercreate.css";
import markverified from "../../../assets/images/admin/check-mark-verified.gif";

const UserCreate = (props) => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [urldetails, setURLDetails] = useState({
    status: false,
    url: "",
  });
  const handleCopyClick = () => {
    if (urldetails.url) {
      // inputRef.current.select();
      navigator.clipboard
        .writeText(urldetails.url)
        .then(() => {
          message.success("copied!");
        })
        .catch((error) => {
          console.error("Failed to copy input value:", error);
          message.error("Failed to copy input value. Please try again.");
        });
    }
  };

  const steps = [
    {
      title: "Profile Information",
      content: (
        <ProfileInfo
          type="create"
          setCurrent={setCurrent}
          current={current}
          setModelOpen={props.setModelOpen}
          display_status={false}
        />
      ),
    },
    {
      title: "Company Information",
      content: (
        <CompanyInfo
          type="create"
          setCurrent={setCurrent}
          current={current}
          display_status={false}
        />
      ),
    },
    {
      title: "Customer Offering",
      content: (
        <OfferingInfo
          type="create"
          setCurrent={setCurrent}
          current={current}
          display_status={false}
          setURLDetails={setURLDetails}
        />
      ),
    },
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  const handleCancel = () => {
    props.setModelOpen(false);
    setURLDetails({
      status: false,
      url: "",
    });
  };

  return (
    <>
      <Modal
        title=""
        open={props.modelopen}
        onCancel={handleCancel}
        footer={false}
        style={{ textAlign: "center", padding: "20px" }}
        width="70%"
      >
        {urldetails.status ? (
          <>
            <img src={markverified} alt="tick-mark-varified" />
            <p style={{ fontSize: "18px" }}>
              The URL has been generated successfully!
            </p>
            <Input
              value={urldetails.url}
              disabled
              suffix={
                <Button onClick={handleCopyClick}>
                  <Icon icon="ant-design:copy-outlined" />
                </Button>
              }
            />
          </>
        ) : (
          <>
            <div className="form-cover-step">
              <Steps
                type="navigation"
                size="small"
                className="customer-form-step customer-form-step-popup"
                current={current}
                items={items}
              />
            </div>
            <div className="step-form-body">
              <div>{steps[current].content}</div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default memo(UserCreate);
