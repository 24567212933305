import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getSecurityHub } from "../../../action/graphql/slm/SLM_reducers";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import { Icon } from "@iconify/react";

const SecurityChart = () => {
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);

  useEffect(() => {
    getSecurityTableData();
  }, []);
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  const dispatch = useDispatch();
  // const companyName = localStorage.getItem("CognitoIdentityServiceProvider.company");
  const companyName = "fivestar";

  // const { selectedTopdate } = useSelector((state) => state.constimize);
  const selectedTopdate = "2023-4-01";

  const [statusCounts, setStatusCounts] = useState({
    passed: 0,
    failed: 0,
  });

  useEffect(() => {}, [statusCounts]);
  const getSecurityTableData = () => {
    dispatch(getSecurityHub({ companyName, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {
        let totalData = data.newfunctionquery29;
        console.log({ totalData });

        var passedStandards = totalData.filter((data) => {
          return data.security_current_status.toLowerCase() === "passed";
        });
        var failedStandards = totalData.filter((data) => {
          return data.security_current_status.toLowerCase() === "failed";
        });
        console.log({ passedStandards });
        setStatusCounts({
          passed: passedStandards.length,
          failed: failedStandards.length,
        });
        console.log({
          passed: passedStandards.length,
          failed: failedStandards.length,
        });
      });
  };
  const series = [statusCounts.passed, statusCounts.failed];
  const options = {
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    // fill : {
    //     color : ['#0DADD0' , '#FFEAA0']
    // },
    colors: ["#0DADD0", "#FFEAA0"],
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
            },
            value: {
              show: true,
            },
            total: {
              show: true,
              showAlways: true,
              label: "Security Score",
              formatter: function (statusCounts) {
                console.log(statusCounts.passed);
                return 66 + "%";
                // return (((statusCounts.passed)/(statusCounts.passed + statusCounts.failed))*100).toFixed(2) + "%";
              },
            },
          },
        },
      },
    },
  };

  const handleIconClick = () => {
    setDrawerStatus(true);
  };
  return (
    <>
      <DrawerPlat
        drawerstatus={drawerStatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        title="Security Chart"
      >
        <div> test </div>
      </DrawerPlat>
      <div id="chart">
        <Icon
          icon="material-symbols:edit-note-sharp"
          onClick={handleIconClick}
          //   style={{ float: "right" }}
        />

        <ReactApexChart options={options} series={series} type="donut" />
      </div>
    </>
  );
};

export default SecurityChart;
