// features/user/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const currentDate = new Date();
let currentMonth = currentDate.getMonth() + 1;

if (currentMonth < 10) currentMonth = "0" + currentMonth;
const initialState = {
  selectedTopdate: `${new Date().getFullYear()}-${currentMonth}-01`,
};

const peemsReducer = createSlice({
  name: "peems",
  initialState,
  reducers: {
    selectedTopDateMethod: (state, { payload }) => {
      state.selectedTopdate = payload;
    },
  },
});

export default peemsReducer.reducer;
export const { selectedTopDateMethod } = peemsReducer.actions;
