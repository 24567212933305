import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const CPUChart = (props) => {
  const [chartvalue, setChartValue] = useState({
    option: {},
    series: [],
  });
  useEffect(() => {
    // console.log(props);
    setChartValue({
      option: {
        chart: {
          type: "line",
          height: 160,
          // offsetY: 5,
          toolbar: {
            show: false,
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          width: 1,
        },
        grid: {
          show: false,
        },
        colors: ["#B17C03", "#8D1E74", "#0C2E4E"],
        markers: {
          size: 4,
          colors: undefined,
          strokeColors: "#fff",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
        xaxis: {
          //   categories: spendtrandschartmonth,
          categories: props.label,
          tickPlacement: "on",
          axisBorder: {
            show: true,
            color: "#78909C",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },

          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          labels: {
            offsetX: 0,
            offsetY: -2,
            style: {
              fontSize: "12px",
              fontWeight: 400,
            },
          },
          title: {
            text: "Days",
            rotate: -180,
            offsetX: 0,
            offsetY: -10,
            style: {
              color: undefined,
              fontSize: "12px",
              fontWeight: 500,
            },
          },
        },
        yaxis: {
          // tickAmount: 2,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 0,
            offsetY: 0,
          },
          title: {
            text: "Percentage",
            rotate: -90,
            offsetX: 7,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontWeight: 500,
            },
          },
          labels: {
            style: {
              fontSize: "8px",
              fontWeight: 400,
            },
            formatter: (num) => {
              return Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            },
          },
        },
        title: {
          text: "CPU",
          align: "center",
          margin: 0,
          offsetX: 0,
          offsetY: 15,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "#000",
          },
        },
      },
      series: [
        {
          name: "Max",
          data: props.value.cpu_max,
        },
        // {
        //   name: "Min",
        //   data: props.value.cpu_min,
        // },
        {
          name: "Avg",
          data: props.value.cpu_avg,
        },
      ],
    });
  }, [props]);
  return (
    <>
      <ReactApexChart
        options={chartvalue.option}
        series={chartvalue.series}
        type="line"
        height={250}
        className="trendchartv2"
      />
    </>
  );
};

export default memo(CPUChart);
