import { createAsyncThunk } from "@reduxjs/toolkit";
import client_new from "./client/dashboardapplonewClinet";
import client from "../apploClient";
import Chatbotclient from "./client/ChatbotClient";
import { useNavigate } from "react-router-dom";

import {
  TOP_MENU_LIST,
  LIST_ANNUAL_BUDGET,
  FORCOST_SPEND,
  CREDIT_AMOUNT,
  SPEND_DETAILS,
  LIST_SAVINGS_MODEL,
  LIST_APPLICATION_SPEND,
  UNDER_UTILIZED,
  LIST_APPLICATION_BUDGET,
  START_STOP_SCHEDULER_DASHBOARD,
  LIST_SAVINGS_REALIZATION_CHART,
  ADD_SAVINGS_MODEL,
  UPDATE_SAVINGS_MODEL,
  DELETE_SAVINGS_MODEL,
  PROJECTED_SPEND,
  LIST_INSTANCE_RUNTIME,
  LIST_INSTANCE_TRACKING,
  ASSET_SPEND_VM_RECORDS,
  ASSET_SPEND_POPUP_DETAILS,
  GET_USER_ROLE,
  TAGGING_RESOURCES,
  TAGGING_RESOURCES_PAGINATION_COUNT,
  TAGGING_RESOURCES_LIST,
  SPEND_DETAILED_VIEW,
  UPDATE_TAGGING_DETAILS,
  CHART_BOT_RESPONSE,
  LIST_SPEND_BY_DATE,
  RESOURCES_SPEND_DATE_COUNT,
  RESOURCES_SPEND_DATE_LIST,
  SERVICES_SPEND_DATE_LIST,
  SERVICES_SPEND_MONTH_DATE_LIST,
  CHECK_CHATBOT_LIMIT,
  ASSET_SPEND_VM_ENVIRONMENT_RECORDS,
  ASSET_SPEND_VM_CLOUD_RECORDS,
  ASSET_SPEND_VM_APPLICATION_RECORDS,
  ASSET_SPEND_VM_RESORCES_RECORDS,
  LIST_STOP_INSTANCE,
} from "./graphql/CostimizeV2Graphql";
import { useErrorNavigation } from "../custom_hook/CustomHook";

export const getTopMenuList = createAsyncThunk(
  // action type string
  "menu/list",
  // callback function
  async (data, { rejectWithValue }) => {
    const companyname = data.companyName;
    const month = data.selectedTopdate;
    const cloud = data.cloud_list;
    try {
      const envList = await client_new.query({
        query: TOP_MENU_LIST,
        variables: {
          company_name: companyname,
          month: month,
          cloud: cloud,
        },
      });
      return envList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listAnnualBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/annual/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client_new.mutate({
        mutation: LIST_ANNUAL_BUDGET,
        variables: {
          company_name: data.companyName,
          cloud: data.cloud,
        },
      });
      return listbudget;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getForecastSpend = createAsyncThunk(
  // action type string
  "dashboard/spend/forecast",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: FORCOST_SPEND,
        variables: {
          company_name: data.companyName,
          month: data.projected_date,
          account: data.apivalue.account,
          cloud: data.apivalue.cloud,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCreditAmount = createAsyncThunk(
  // action type string
  "dashboard/summarydashboard",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: CREDIT_AMOUNT,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate_new,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSpendDetails = createAsyncThunk(
  // action type string
  "dashboard/spend",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const spend = await client_new.query({
        query: SPEND_DETAILS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate_new,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
        },
      });
      return spend;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listRecommendation = createAsyncThunk(
  // action type string
  "dashboard/recommendation/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const list_rec = await client_new.mutate({
        mutation: LIST_SAVINGS_MODEL,
        variables: {
          company_name: data.companyName,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return list_rec;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listApplicationSpend = createAsyncThunk(
  // action type string
  "dashboard/application/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listapplication = await client_new.mutate({
        mutation: LIST_APPLICATION_SPEND,
        variables: {
          company_name: data.companyName,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return listapplication;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUderUtilized = createAsyncThunk(
  // action type string
  "dashboard/uderutilized",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const aasList = await client_new.query({
        query: UNDER_UTILIZED,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          cloud: data.apivalue.cloud,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listApplicationBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/application/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_APPLICATION_BUDGET,
        variables: {
          company_name: data.input.company_name,
          account_name: data.input.account_name,
          application: data.input.application,
          budget_year: data.input.budget_year,
          cloud: data.input.cloud,
          environment: data.input.environment,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getStartStopScheduler = createAsyncThunk(
  // action type string
  "dashboard/startstopscheduler",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: START_STOP_SCHEDULER_DASHBOARD,
        variables: {
          company_name: data.companyName,
          month: data.yesterdayDate,
          cloud: data.apivalue.cloud,
          account: data.apivalue.account,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listRealizationChart = createAsyncThunk(
  // action type string
  "dashboard/instance/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_SAVINGS_REALIZATION_CHART,
        variables: {
          account_name: data.apivalue.account_name,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.apivalue.company_name,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
          os: data.apivalue.os,
          db: data.apivalue.db,
          infra: data.apivalue.infra,
          service: data.apivalue.service,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const insertRecommendation = createAsyncThunk(
  // action type string
  "dashboard/recommendation/insert",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: ADD_SAVINGS_MODEL,
        variables: {
          application: data.inputs.application,
          environment: data.inputs.environment,
          service: data.inputs.service,
          account: data.inputs.account,
          company_name: data.inputs.company_name,
          commitment: data.inputs.commitment,
          current_configuration: data.inputs.current_configuration,
          lost_opportunity: data.inputs.lost_opportunity,
          potential_savings: data.inputs.potential_savings,
          realised_savings: data.inputs.realised_savings,
          recommendation_rule: data.inputs.recommendation_rule,
          recommended_configuration: data.inputs.recommended_configuration,
          report_month: data.inputs.report_month,
          resource_id: data.inputs.resource_id,
          resource_name: data.inputs.resource_name,
          resource_sub_category: data.inputs.resource_sub_category,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateRecommendation = createAsyncThunk(
  // action type string
  "dashboard/recommendation/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: UPDATE_SAVINGS_MODEL,
        variables: {
          application: data.inputs.application,
          environment: data.inputs.environment,
          service: data.inputs.service,
          account: data.inputs.account,
          company_name: data.inputs.company_name,
          commitment: data.inputs.commitment,
          current_configuration: data.inputs.current_configuration,
          lost_opportunity: data.inputs.lost_opportunity,
          potential_savings: data.inputs.potential_savings,
          realised_savings: data.inputs.realised_savings,
          recommendation_rule: data.inputs.recommendation_rule,
          recommended_configuration: data.inputs.recommended_configuration,
          report_month: data.inputs.report_month,
          resource_id: data.inputs.resource_id,
          resource_name: data.inputs.resource_name,
          resource_sub_category: data.inputs.resource_sub_category,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteRecommendation = createAsyncThunk(
  // action type string
  "dashboard/recommendation/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const deleteSavings = await client_new.mutate({
        mutation: DELETE_SAVINGS_MODEL,
        variables: {
          companyName: data.companyName,
          deleteid: data.deleteid,
        },
      });
      return deleteSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProjectedSpend = createAsyncThunk(
  // action type string
  "dashboard/projectedspend",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: PROJECTED_SPEND,
        variables: {
          company_name: data.companyName,
          month: data.projected_date,
          account: data.apivalue.account,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listInstanceRuntime = createAsyncThunk(
  // action type string
  "dashboard/instance/runtime/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_INSTANCE_RUNTIME,
        variables: {
          account_name: data.apivalue.account_name,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.apivalue.company_name,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
          os: data.apivalue.os,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listInstanceTracking = createAsyncThunk(
  // action type string
  "dashboard/instance/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_INSTANCE_TRACKING,
        variables: {
          account_name: data.apivalue.account_name,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.apivalue.company_name,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
          os: data.apivalue.os,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listStopInstance = createAsyncThunk(
  // action type string
  "dashboard/instance/stoped/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_STOP_INSTANCE,
        variables: {
          account_name: data.apivalue.account_name,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.apivalue.company_name,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssetSpendVMRecords = createAsyncThunk(
  // action type string
  "dashboard/instance/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: ASSET_SPEND_VM_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
          first_month: data.apivalue.first_month,
          second_month: data.apivalue.second_month,
          thired_month: data.apivalue.thired_month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssetSpendVMEnvironmentRecords = createAsyncThunk(
  // action type string
  "dashboard/vm/env/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client.mutate({
        mutation: ASSET_SPEND_VM_ENVIRONMENT_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
          first_month: data.apivalue.first_month,
          second_month: data.apivalue.second_month,
          thired_month: data.apivalue.thired_month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssetSpendVMCloudRecords = createAsyncThunk(
  // action type string
  "dashboard/vm/env/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client.mutate({
        mutation: ASSET_SPEND_VM_CLOUD_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
          first_month: data.apivalue.first_month,
          second_month: data.apivalue.second_month,
          thired_month: data.apivalue.thired_month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssetSpendVMApplicationRecords = createAsyncThunk(
  // action type string
  "dashboard/vm/app/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client.mutate({
        mutation: ASSET_SPEND_VM_APPLICATION_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
          first_month: data.apivalue.first_month,
          second_month: data.apivalue.second_month,
          thired_month: data.apivalue.thired_month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssetSpendVMResorcesRecords = createAsyncThunk(
  // action type string
  "dashboard/vm/app/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client.mutate({
        mutation: ASSET_SPEND_VM_RESORCES_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
          first_month: data.apivalue.first_month,
          second_month: data.apivalue.second_month,
          thired_month: data.apivalue.thired_month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssetSpendDetailsView = createAsyncThunk(
  // action type string
  "dashboard/instance/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const addSavings = await client_new.mutate({
        mutation: ASSET_SPEND_POPUP_DETAILS,
        variables: {
          customer: data.companyName,
          resourceid: data.resourceid,
          start_date: data.firstDate,
          end_date: data.lastDate,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getMemberRole = createAsyncThunk(
  // action type string
  "user/role",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const role = await client_new.mutate({
        mutation: GET_USER_ROLE,
        variables: {},
      });
      return role;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const taggingResources = createAsyncThunk(
  // action type string
  "user/tagging",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const role = await client_new.mutate({
        mutation: TAGGING_RESOURCES,
        variables: {
          company_name: data.input.company_name,
          account_name: data.input.account_name,
          application: data.input.application,
          cloud: data.input.cloud,
          environment: data.input.environment,
          services: data.input.services,
          os: data.input.os,
          db: data.input.db,
          infra: data.input.infra,
          month: data.input.month,
        },
      });
      return role;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPaginationCount = createAsyncThunk(
  // action type string
  "user/pagination/count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const role = await client_new.mutate({
        mutation: TAGGING_RESOURCES_PAGINATION_COUNT,
        variables: {
          tagging_details: data.tagging_details,
          service_name: data.service_name,
          tag_category: data.tag_category,
          companyName: data.companyName,
          date: data.date,
          count: data.count,
          account_name: data.account_name,
          application: data.application,
          cloud: data.cloud,
          environment: data.environment,
          infra: data.infra,
          os: data.os,
          db: data.db,
        },
      });
      return role;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTagginglist = createAsyncThunk(
  // action type string
  "user/tagging/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const role = await client_new.mutate({
        mutation: TAGGING_RESOURCES_LIST,
        variables: {
          companyName: data.companyName,
          tagging_details: data.tagging_details,
          service_name: data.service_name,
          date: data.date,
          page: data.page,
          tag_category: data.tag_category,
          count: data.count,
          account_name: data.account_name,
          application: data.application,
          cloud: data.cloud,
          environment: data.environment,
          infra: data.infra,
          os: data.os,
          db: data.db,
        },
      });
      return role;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSpendDetailedView = createAsyncThunk(
  // action type string
  "dashboard/spend",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const spend = await client_new.query({
        query: SPEND_DETAILED_VIEW,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate_new,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
        },
      });
      return spend;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTaggingDetails = createAsyncThunk(
  // action type string
  "dashboard/spend",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data.resources);
    try {
      const spend = await client_new.mutate({
        mutation: UPDATE_TAGGING_DETAILS,
        variables: {
          resources: data.resources,
        },
      });
      return spend;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listSpendbyDate = createAsyncThunk(
  // action type string
  "chartbot/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client_new.mutate({
        mutation: LIST_SPEND_BY_DATE,
        variables: {
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.companyName,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return listbudget;
    } catch (error) {
      if (
        error.message === "Response not successful: Received status code 500"
      ) {
        console.log("in side");
        const navigate = useNavigate();
        return navigate("/signin");
      }

      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const countResourcesSpendDate = createAsyncThunk(
  // action type string
  "resources/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client_new.mutate({
        mutation: RESOURCES_SPEND_DATE_COUNT,
        variables: {
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.companyName,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
          selectedservice: data.apivalue.selectedservice,
        },
      });
      return listbudget;
    } catch (error) {
      if (
        error.message === "Response not successful: Received status code 500"
      ) {
        console.log("in side");
        const navigate = useNavigate();
        return navigate("/signin");
      }

      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listResourcesSpendDate = createAsyncThunk(
  // action type string
  "resources/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client_new.mutate({
        mutation: RESOURCES_SPEND_DATE_LIST,
        variables: {
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.companyName,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
          page_number: data.apivalue.page,
        },
      });
      return listbudget;
    } catch (error) {
      if (
        error.message === "Response not successful: Received status code 500"
      ) {
        console.log("in side");
        const navigate = useNavigate();
        return navigate("/signin");
      }

      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listServiceSpendDate = createAsyncThunk(
  // action type string
  "service/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client_new.mutate({
        mutation: SERVICES_SPEND_DATE_LIST,
        variables: {
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.companyName,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return listbudget;
    } catch (error) {
      if (
        error.message === "Response not successful: Received status code 500"
      ) {
        console.log("in side");
        const navigate = useNavigate();
        return navigate("/signin");
      }

      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listServiceSpendbyDate = createAsyncThunk(
  // action type string
  "service/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client_new.mutate({
        mutation: SERVICES_SPEND_MONTH_DATE_LIST,
        variables: {
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.companyName,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return listbudget;
    } catch (error) {
      if (
        error.message === "Response not successful: Received status code 500"
      ) {
        console.log("in side");
        const navigate = useNavigate();
        return navigate("/signin");
      }

      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getChartBotResponse = createAsyncThunk(
  // action type string
  "chartbot/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await Chatbotclient.mutate({
        mutation: CHART_BOT_RESPONSE,
        variables: {
          message: data.message,
          companyName: data.companyname,
          date: data.date,
          user_name: data.user_name,
          token: data.token,
        },
      });
      return listbudget;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkChatbotLimit = createAsyncThunk(
  // action type string
  "chartbot/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await Chatbotclient.mutate({
        mutation: CHECK_CHATBOT_LIMIT,
        variables: {
          date: data.date,
          user_name: data.user_name,
          companyName: data.companyname,
        },
      });
      return listbudget;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
