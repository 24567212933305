import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Spin } from "antd";
import paginationFactory from "react-bootstrap-table2-paginator";
const AlertDetailsTable = ({ serverData, alertcountstatus }) => {
  const [tableData, setTableData] = useState([]);
  const [totalAlerts, setTotalAlerts] = useState("0");
  const [totalCams, setTotalCams] = useState("0");
  const [totalRecurring, setTotalRecurring] = useState("0");
  useEffect(() => {
    if (serverData.cams_top_servers) {
      setTableData(serverData.cams_top_servers);
      let totalAlertTemp = 0;
      let totalCamsTemp = 0;
      let totalReccTemp = 0;
      serverData.cams_top_servers.forEach((data) => {
        if (data.total_alerts)
          totalAlertTemp = totalAlertTemp + parseInt(data.total_alerts);
        if (data.cams_processed)
          totalCamsTemp = totalCamsTemp + parseInt(data.cams_processed);
        if (data.recurring_tickets)
          totalReccTemp = totalReccTemp + parseInt(data.recurring_tickets);
      });
      setTotalAlerts(totalAlertTemp);
      setTotalCams(totalCamsTemp);
      setTotalRecurring(totalReccTemp);
    }
  }, [serverData]);

  const columns = [
    {
      dataField: "server_name",
      text: "Server Name",
      footer: "",
      headerAlign: "left",
      align: "left",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
        borderLeftRadius: "5px",
        width: "100px",
      },
      // style: (cell, row, rowIndex, colIndex) => {
      //   return { width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
      // }, // Fixed width for the cells and ellipsis for overflowing text
      footerAlign: "center",
      footerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
    },
    {
      dataField: "total_alerts",
      text: "Total Alerts",
      footer: totalAlerts.toString(),
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      footerAlign: "center",
      footerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
    },
    {
      dataField: "recurring_tickets",
      text: "Recurring Tickets",
      footer: totalRecurring.toString(),
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      footerAlign: "center",
      footerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
    },
    {
      dataField: "cams_processed",
      text: `Tickets Created `,
      footer: totalCams.toString(),
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      footerAlign: "center",
      footerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
    },
  ];
  const data = [
    {
      serverName: "AsterDM-PROD-AZVMAXH2H",
      alerts: "33",
      recurrence: "12",
      camsProcessed: "2",
    },
    {
      serverName: "AsterDM-PROD-AZVMAXH2H",
      alerts: "33",
      recurrence: "12",
      camsProcessed: "2",
    },
    {
      serverName: "AsterDM-PROD-AZVMAXH2H",
      alerts: "33",
      recurrence: "12",
      camsProcessed: "2",
    },
    {
      serverName: "AsterDM-PROD-AZVMAXH2H",
      alerts: "33",
      recurrence: "12",
      camsProcessed: "2",
    },
    {
      serverName: "AsterDM-PROD-AZVMAXH2H",
      alerts: "33",
      recurrence: "12",
      camsProcessed: "2",
    },
    {
      serverName: "AsterDM-PROD-AZVMAXH2H",
      alerts: "33",
      recurrence: "12",
      camsProcessed: "2",
    },
    {
      serverName: "AsterDM-PROD-AZVMAXH2H",
      alerts: "33",
      recurrence: "12",
      camsProcessed: "2",
    },
    {
      serverName: "AsterDM-PROD-AZVMAXH2H",
      alerts: "33",
      recurrence: "12",
      camsProcessed: "2",
    },
    {
      serverName: "AsterDM-PROD-AZVMAXH2H",
      alerts: "33",
      recurrence: "12",
      camsProcessed: "2",
    },
    {
      serverName: "AsterDM-PROD-AZVMAXH2H",
      alerts: "33",
      recurrence: "12",
      camsProcessed: "2",
    },
    {
      serverName: "AsterDM-PROD-AZVMAXH2H",
      alerts: "33",
      recurrence: "12",
      camsProcessed: "2",
    },
    {
      dataField: "server_name",
      text: <> </>,
      footer: "",
      headerAlign: "left",
      align: "left",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
        borderLeftRadius: "5px",
        width: "100px",
      },
      footerAlign: "center",
      footerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
    },
  ];
  return (
    <>
      {/* <h6 className="alert-detail-header pt-4">Top N alert by Count</h6> */}
      <div className="alert-detail-table">
        <Spin spinning={alertcountstatus}>
          <BootstrapTable
            keyField="id"
            data={tableData}
            columns={columns}
            bordered={false}
            noDataIndication="No Records"
            pagination={paginationFactory()}
          />
        </Spin>
      </div>
    </>
  );
};

export default AlertDetailsTable;
