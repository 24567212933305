// import { useEffect, useState } from "react";
// import { memo } from "react";
// import ReactApexChart from "react-apexcharts";

// const SpendDayChart = (props) => {
//   const [chart, setChart] = useState({
//     value: [],
//     categories: [],
//   });

//   useEffect(() => {
//  console.log(props.records)
//  console.log(props.spenddate)
//     if (props.records.length > 0) {
//       var value = [];
//       var categories = [];
//       const today = new Date(props.spenddate);
//       const today1 = new Date();
//       const currentMonth = today1.getMonth();
//       const currentYear = today1.getFullYear();

//       let currentDateCount;
//       console.log(currentMonth)
//       console.log(currentYear)

//       if (today1.getMonth() === today.getMonth()) {
//         currentDateCount = today1.getDate() - 1;

//       } else {
//         currentDateCount = new Date(currentYear, currentMonth + 1, 0).getDate();
//       }

//       // const currentMonth = today.getMonth();
//       // const currentYear = today.getFullYear();
//       // const currentDateCount = new Date(
//       //   currentYear,
//       //   currentMonth + 1,
//       //   0
//       // ).getDate();
//       console.log(currentDateCount)
//       var date_lable_list = [];
//       var value = [];
//       for (let index = 0; index < currentDateCount; index++) {
//         date_lable_list.push(
//           `${new Date(props.spenddate).getFullYear()}-${
//             new Date(props.spenddate).getMonth() + 1
//           }-${+index + +1}`
//         );
//       }
//       console.log(date_lable_list);
//       props.records.map((data) => {
//         value.push(data.spend);
//         categories.push(data.date);
//       });
//       setChart({
//         value: value,
//         categories: date_lable_list,
//       });
//     }
//   }, [props]);
//   const series = [
//     {
//       name: "Usage Trend",
//       data: chart.value,
//     },
//   ];
//   function getOrdinalSuffix(day) {
//     const j = day % 10;
//     const k = day % 100;
//     if (j === 1 && k !== 11) {
//       return "st";
//     }
//     if (j === 2 && k !== 12) {
//       return "nd";
//     }
//     if (j === 3 && k !== 13) {
//       return "rd";
//     }
//     return "th";
//   }
//   const options = {
//     chart: {
//       type: "bar",
//       height: 350,
//       events: {
//         dataPointSelection: function (event, chartContext, config) {
//           props.resourcesChartDate(chart.categories[config.dataPointIndex]);
//         },
//       },
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: "80%",
//         endingShape: "rounded",
//       },
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       show: true,
//       width: 2,
//       colors: ["transparent"],
//     },
//     xaxis: {
//       categories: chart.categories,
//       labels: {
//         formatter: (num) => {
//           const monthNamesShort = [
//             "Jan",
//             "Feb",
//             "Mar",
//             "Apr",
//             "May",
//             "Jun",
//             "Jul",
//             "Aug",
//             "Sep",
//             "Oct",
//             "Nov",
//             "Dec",
//           ];
//           // const current_month_text = `${
//           //   monthNamesShort[new Date(num).getMonth()]
//           // }-${new Date(num).getDate()}`;
//           // return current_month_text;
//           const date = new Date(num);
//           const day = date.getDate();
//           const month = monthNamesShort[date.getMonth()];
//           const current_month_text = `${day}${getOrdinalSuffix(day)} ${month}`;
//           return current_month_text;
//         },
//       },
//     },
//     // colors: ["#915858"],
//     // colors: ["#E5665A"],
//     colors: ["#4527A0"],

//     yaxis: {
//       labels: {
//         formatter: (record) => {
//           const num = (Math.round(record * 100) / 100).toFixed(2);
//           return Math.abs(num) > 999
//             ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
//             : Math.sign(num) * Math.abs(num);
//         },
//       },
//     },
//     fill: {
//       opacity: 1,
//     },
//   };

//   console.log(chart);
//   return (
//     <>
//       <ReactApexChart
//         options={options}
//         series={series}
//         type="bar"
//         height={350}
//       />
//     </>
//   );
// };
// export default memo(SpendDayChart);

import { useEffect, useState } from "react";
import { memo } from "react";
import ReactApexChart from "react-apexcharts";

const SpendDayChart = (props) => {
  const [chart, setChart] = useState({
    value: [],
    categories: [],
  });
  // useEffect(() => {
  //   // setChart({value: [],
  //   //   categories: [],})
  //   if (props.records.length > 0) {
  //     var value = [];
  //     var categories = [];
  //     const today1 = new Date(props.spenddate);
  //     const today = new Date();
  //     console.log(today)
  //     console.log(today1)
  //     const lastDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
  //     const currentMonth = today1.getMonth();
  //     const currentYear = today1.getFullYear();

  //     let currentDateCount;
  //     console.log(currentMonth)
  //     console.log(currentYear)

  //     if (today.getMonth() === today1.getMonth()) {
  //       currentDateCount = today.getDate() - 1;

  //     } else {
  //       currentDateCount = new Date(currentYear, currentMonth + 1, 0).getDate();
  //     }
  //      console.log(currentDateCount)
  //     // const currentMonth = today.getMonth();
  //     // const currentYear = today.getFullYear();
  //     // const currentDateCount = new Date(currentYear, currentMonth + 1, 0).getDate();

  //     var date_lable_list = [];
  //     var value = [];
  //     for (let index = 0; index < currentDateCount; index++) {
  //       date_lable_list.push(
  //         `${new Date(props.spenddate).getFullYear()}-${
  //           new Date(props.spenddate).getMonth() + 1
  //         }-${+index + +1}`
  //       );
  //     }
  //     console.log(date_lable_list)

  //     props.records.map((data) => {
  //       console.log(data.spend)
  //       value.push(data.spend);
  //       categories.push(data.date);
  //     });
  //     setChart({
  //       value: value,
  //       categories: date_lable_list,
  //     });
  //   } else {
  //     // var value1 = [];
  //     // var date_lable_list = [];
  //     setChart({
  //       value: [],
  //       categories: [],
  //     });

  //     // props.prostatus(false);
  //     // message.info('No records found.');
  //   }
  // }, [props.spenddate, props.records]);
  // // in this code graph, for example  categories is 24records but value is 10records so that, it shows 20,buti need 20 recordsand 4 categories also shows

  useEffect(() => {
    if (props.records.length > 0) {
      const today1 = new Date(props.spenddate);
      const today = new Date();

      const currentMonth = today1.getMonth();
      const currentYear = today1.getFullYear();

      let currentDateCount;

      if (today.getMonth() === today1.getMonth()) {
        currentDateCount = today.getDate() - 1;
      } else {
        currentDateCount = new Date(currentYear, currentMonth + 1, 0).getDate();
      }

      const date_lable_list = [];
      const value = Array(currentDateCount).fill(0);

      for (let index = 0; index < currentDateCount; index++) {
        date_lable_list.push(
          `${today1.getFullYear()}-${today1.getMonth() + 1}-${index + 1}`
        );
      }

      props.records.forEach((data) => {
        const spendDate = new Date(data.date);
        const dayOfMonth = spendDate.getDate();
        const index = dayOfMonth - 1;

        value[index] += data.spend;
      });

      setChart({
        value: value,
        categories: date_lable_list,
      });
    } else {
      setChart({
        value: [],
        categories: [],
      });
    }
  }, [props.spenddate, props.records]);

  // useEffect(() => {
  //   console.log(props.spenddate)
  //   if (props.records.length > 0) {
  //     var value = [];
  //     var categories = [];
  //     const today = new Date(props.spenddate);

  //     const currentMonth = today.getMonth();
  //     const currentYear = today.getFullYear();
  //     const currentDateCount = new Date(
  //       currentYear,
  //       currentMonth + 1,
  //       0
  //     ).getDate();
  //     console.log(currentDateCount)
  //     var date_lable_list = [];
  //     var value = [];
  //     for (let index = 0; index < currentDateCount; index++) {
  //       date_lable_list.push(
  //         `${new Date(props.spenddate).getFullYear()}-${
  //           new Date(props.spenddate).getMonth() + 1
  //         }-${+index + +1}`
  //       );
  //     }
  //     console.log(date_lable_list);
  //     props.records.map((data) => {
  //       value.push(data.spend);
  //       categories.push(data.date);
  //     });
  //     setChart({
  //       value: value,
  //       categories: date_lable_list,
  //     });
  //   }
  //   else{
  //     var value1 = [];
  //     setChart({
  //       value: value1,
  //       categories: date_lable_list,
  //     });
  //   }
  // }, [props.spenddate]);
  const series = [
    {
      name: "Usage Trend",
      data: chart.value,
    },
  ];
  function getOrdinalSuffix(day) {
    const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) {
      return "st";
    }
    if (j === 2 && k !== 12) {
      return "nd";
    }
    if (j === 3 && k !== 13) {
      return "rd";
    }
    return "th";
  }
  const options = {
    chart: {
      type: "bar",
      height: 350,
      events: {
        dataPointSelection: function (event, chartContext, config) {
          props.resourcesChartDate(chart.categories[config.dataPointIndex]);
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: chart.categories,
      labels: {
        formatter: (num) => {
          const monthNamesShort = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          // const current_month_text = `${
          //   monthNamesShort[new Date(num).getMonth()]
          // }-${new Date(num).getDate()}`;
          // return current_month_text;
          const date = new Date(num);
          const day = date.getDate();
          const month = monthNamesShort[date.getMonth()];
          const current_month_text = `${day}${getOrdinalSuffix(day)} ${month}`;
          return current_month_text;
        },
      },
    },
    // colors: ["#915858"],
    // colors: ["#E5665A"],
    colors: ["#4527A0"],

    yaxis: {
      labels: {
        formatter: (record) => {
          const num = (Math.round(record * 100) / 100).toFixed(2);
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </>
  );
};
export default memo(SpendDayChart);
