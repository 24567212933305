import { Row, Col } from 'react-bootstrap';
import { message, Form, Input, Select, DatePicker, Button } from 'antd';
import DrawerPlat from '../../properties/Drawer/DrawerPlat';
import { useState } from "react";
import { useSelector } from 'react-redux';


const TrackingRuntime = (props) => {

    const {  environmentList, applicationList } = useSelector((state) => state.constimize);
    const [allapplication, setAllapplication] = useState(applicationList.map((list) => {
        return {
            value: list,
            label: list,
        }
    }));
    const [allenvironment, setAllenvironment] = useState(environmentList.map((list) => {
        return {
          value: list,
          label: list,
        }
    }));

    return(
        <>
            <DrawerPlat 
                drawerstatus={props.trackingdrawerstatus}
                changeDrawerStatus={props.changeTrackingDrawerStatus}
                title="Tracking"
                deletestatus={false}
            >
                <Row>
                    <Col>
                        <Form.Item
                            name="environment"
                            label="Environment"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Select
                                showSearch
                                style={{
                                width: "100%",
                                }}
                                placeholder="Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={allenvironment}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="application"
                            label="Application"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Select
                                showSearch
                                style={{
                                width: "100%",
                                }}
                                placeholder="Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={allapplication}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item
                                name="instance_name"
                                label="Instance Name"
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input 
                                    style={{
                                        width: "100%",
                                    }}
                                />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="date"
                            label="Date"
                            rules={[{ required: true, message: '' }]}
                        >
                            <DatePicker  
                                style={{
                                width: "100%",
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item
                            name="status"
                            label="Status"
                            rules={[{ required: true, message: '' }]}
                        >
                             <Select
                                showSearch
                                style={{
                                width: "100%",
                                }}
                                placeholder="Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={[{
                                value: 'LAUNCHED',
                                label: 'LAUNCHED',
                                },{
                                value: 'DELETED',
                                label: 'DELETED',
                                },{
                                value: 'MODIFIED',
                                label: 'MODIFIED',
                                }]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Form.Item >
                        <Button htmlType="button"  onClick={() => props.setTrackingDrawerStatus(false)}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ background: "#1b5f9d", color: "#fff", float: "right" }}>
                            Submit
                        </Button>

                    </Form.Item>
                </Row>  
            </DrawerPlat>
        </>
    )
}
export default TrackingRuntime;