import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Space,
  Table,
  Col,
  Row,
  Menu,
  Button,
  message,
  Tabs,
  Tag,
  Modal,
  Progress,
  Input,
  Spin,
} from "antd";
import RequestorDocument from "../../../assets/images/RequestorDocument.png";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { listRequestTable } from "../../action/graphql/devops/DevOpsAction";
import {
  getApprovalTableData,
  setRequestAction,
  setRequestorTableData,
} from "../../slice/devopsSlice";
import moment from "moment";

function RequestorTable() {
  const [tagModalOpen, settagModalOpen] = useState(false);
  const [refreshModalOpen, setrefreshModalOpen] = useState(false);
  const [progressValue, setProgressValue] = useState(50);
  const [titletag, setTitletag] = useState("Unknown Log");
  const [showSpinner, setShowSpinner] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [tableData, setTableData] = useState([]);
  function sortById() {
    return function (elem1, elem2) {
      if (elem1.id < elem2.id) {
        return -1;
      } else if (elem1.id > elem2.id) {
        return 1;
      } else {
        return 0;
      }
    };
  }
  const getRequestTableDetails = () => {
    dispatch(listRequestTable({ companyName }))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        setShowSpinner(false);
        let tableDataBeforeStart =
          data.list_platform_devops_accounts_activities.sort(sortById());
        setTableData(tableDataBeforeStart);
        // setTableData([]);
        dispatch(
          getApprovalTableData(data.list_platform_devops_accounts_activities)
        );
      });
  };
  useEffect(() => {
    getRequestTableDetails();
  }, []);
  const handleCreateRequest = () => {
    dispatch(setRequestAction("add"));
    navigate("/devops-config");
  };
  const handleRefreshClick = () => {
    setrefreshModalOpen(true);
    console.log("refresh");
  };
  const tagClick = (record) => {
    setTitletag(record);
    settagModalOpen(true);
  };
  const handleCancel = () => {
    settagModalOpen(false);
    setrefreshModalOpen(false);
  };
  const handleAddClick = () => {
    dispatch(setRequestAction("add"));
    navigate("/devops-config");
  };
  const handleEditClick = (record) => {
    dispatch(setRequestAction("edit"));
    dispatch(setRequestorTableData(record));
    navigate("/devops-config");
  };
  const columns = [
    {
      title: "CR ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "CR Type",
      dataIndex: "activity_type",
      align: "center",
    },
    {
      title: "Account",
      dataIndex: "account_name",
      align: "center",
    },
    {
      title: "Service",
      dataIndex: "service_name",
      align: "center",
    },
    {
      title: "Cloud",
      dataIndex: "cloud_platform",
      align: "center",
    },
    {
      title: "Launch Category",
      dataIndex: "operation",
      align: "center",
    },

    {
      title: "Requested time",
      dataIndex: "created_time",
      render: (text, record, index) =>
        moment(record.created_time).format("DD-MMM-YYYY, HH:MM:SS"),
    },
    {
      title: "Completed time",
      dataIndex: "completed_time",
      render: (text, record, index) => {
        if (record.completed_time) {
          let record_text = moment(record.completed_time).format(
            "DD-MMM-YYYY, HH:MM:SS"
          );
          return record_text;
        }
      },
    },

    {
      title: "Approved by",
      dataIndex: "cx_approved_by",
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (text, record, index) => {
        let color = "";
        let tagText = "";
        let tagTitle = "";

        if (record.status === "Approved") {
          color = "green";
          tagText = "Approved";
          tagTitle = "Approval Log";
        } else if (record.status === "Rejected") {
          color = "volcano";
          tagText = "Rejected";
          tagTitle = "Rejected Log";
        } else if (record.status === "Deployment in progress") {
          color = "orange";
          tagText = "Deployment in progress";
          tagTitle = "Deployment in Progress Log";
        } else if (record.status === "Draft") {
          color = "blue";
          tagText = "Draft";
          tagTitle = "Draft Log";
        } else if (record.status === "Waiting for approval") {
          color = "yellow";
          tagText = "Waiting for approval";
          tagTitle = "Waiting for Approval Log";
        } else if (record.status === "Deployment completed") {
          color = "purple";
          tagText = "Deployment completed";
          tagTitle = "Deployment Completed Log";
        } else if (record.status === "Completed") {
          color = "green";
          tagText = "Completed";
          tagTitle = "Completed Log";
        } else if (record.status === "Implemented") {
          color = "geekblue";
          tagText = "Implemented";
          tagTitle = "Implemented Log";
        } else {
          color = "geekblue";
          tagText = record.status;
        }

        return (
          <Tag
            color={color}
            key={record.status}
            onClick={() => tagClick(record)}
            className="tag-btn"
          >
            {tagText}
          </Tag>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record, index) => {
        let actionIcon = null;

        if (record.Status === "Rejected") {
          actionIcon = (
            <Icon
              className="modal-icon"
              icon="jam:refresh"
              onClick={() => handleRefreshClick()}
            />
          );
        } else if (record.Status === "Draft") {
          actionIcon = (
            <Icon
              className="modal-icon"
              icon="material-symbols:edit"
              onClick={() => handleEditClick()}
            />
          );
        } else {
          actionIcon = (
            <Icon
              className="modal-icon cursor-pointer"
              icon="material-symbols:edit"
              onClick={() => handleEditClick(record)}
            />
          );
        }

        return actionIcon;
      },
    },
  ];

  const data = [
    {
      key: "1",
      CR_ID: "1234",
      CR_Type: "General",
      Account: "fs-common-service",
      Service: "Amazon EC2",
      Cloud: "AWS",
      Launch_Category: "VM",
      Sub_task: "Virtual Machine",
      Requested_time: "12/05/2023,10:00AM",
      Completed_time: "12/05/2023,10:00AM",
      Subnet: "1234",
      Cost_Impact: "Yes",
      Approved_by: "xyz@gmail.com",
      Status: "Approved",
      Action: "",
    },
    {
      key: "2",
      CR_ID: "1234",
      CR_Type: "General",
      Account: "fs-common-service",
      Service: "Amazon EC2",
      Cloud: "AWS",
      Launch_Category: "VM",
      Sub_task: "Virtual Machine",
      Requested_time: "12/05/2023,10:00AM",
      Completed_time: "12/05/2023,10:00AM",
      Subnet: "1234",
      Cost_Impact: "Yes",
      Approved_by: "xyz@gmail.com",
      Status: "Rejected",
      Action: "",
    },
    {
      key: "3",
      CR_ID: "1234",
      CR_Type: "General",
      Account: "fs-common-service",
      Service: "Amazon EC2",
      Cloud: "AWS",
      Launch_Category: "VM",
      Sub_task: "Virtual Machine",
      Requested_time: "12/05/2023,10:00AM",
      Completed_time: "12/05/2023,10:00AM",
      Subnet: "1234",
      Cost_Impact: "Yes",
      Approved_by: "xyz@gmail.com",
      Status: "Draft",
      Action: "",
    },
    {
      key: "4",
      CR_ID: "1234",
      CR_Type: "General",
      Account: "fs-common-service",
      Service: "Amazon EC2",
      Cloud: "AWS",
      Launch_Category: "VM",
      Sub_task: "Virtual Machine",
      Requested_time: "12/05/2023,10:00AM",
      Completed_time: "12/05/2023,10:00AM",
      Subnet: "1234",
      Cost_Impact: "Yes",
      Approved_by: "xyz@gmail.com",
      Status: "Deployment in progress",
      Action: "",
    },
    {
      key: "5",
      CR_ID: "1234",
      CR_Type: "General",
      Account: "fs-common-service",
      Service: "Amazon EC2",
      Cloud: "AWS",
      Launch_Category: "VM",
      Sub_task: "Virtual Machine",
      Requested_time: "12/05/2023,10:00AM",
      Completed_time: "12/05/2023,10:00AM",
      Subnet: "1234",
      Cost_Impact: "Yes",
      Approved_by: "xyz@gmail.com",
      Status: "Waiting for approval",
      Action: "",
    },
    {
      key: "6",
      CR_ID: "1234",
      CR_Type: "General",
      Account: "fs-common-service",
      Service: "Amazon EC2",
      Cloud: "AWS",
      Launch_Category: "VM",
      Sub_task: "Virtual Machine",
      Requested_time: "12/05/2023,10:00AM",
      Completed_time: "12/05/2023,10:00AM",
      Subnet: "1234",
      Cost_Impact: "Yes",
      Approved_by: "xyz@gmail.com",
      Status: "Deployment completed",
      Action: "",
    },
    {
      key: "7",
      CR_ID: "1234",
      CR_Type: "General",
      Account: "fs-common-service",
      Service: "Amazon EC2",
      Cloud: "AWS",
      Launch_Category: "VM",
      Sub_task: "Virtual Machine",
      Requested_time: "12/05/2023,10:00AM",
      Completed_time: "12/05/2023,10:00AM",
      Subnet: "1234",
      Cost_Impact: "Yes",
      Approved_by: "xyz@gmail.com",
      Status: "Implemented",
      Action: "",
    },
    {
      key: "8",
      CR_ID: "1234",
      CR_Type: "General",
      Account: "fs-common-service",
      Service: "Amazon EC2",
      Cloud: "AWS",
      Launch_Category: "VM",
      Sub_task: "Virtual Machine",
      Requested_time: "12/05/2023,10:00AM",
      Completed_time: "12/05/2023,10:00AM",
      Subnet: "1234",
      Cost_Impact: "Yes",
      Approved_by: "xyz@gmail.com",
      Status: "Completed",
      Action: "",
    },
  ];

  // const data = [];
  const items = [
    {
      key: "1",
      label: "CR",
    },
    {
      key: "2",
      label: "SR",
    },
  ];

  return (
    <>
      {" "}
      <>
        {showSpinner ? (
          <>
            <div className="d-flex justify-content-center">
              <Spin />
            </div>
          </>
        ) : (
          <>
            {tableData.length !== 0 ? (
              <>
                <div className="mt-3">
                  <Row>
                    <Col
                      xxl={3}
                      xl={3}
                      lg={3}
                      md={12}
                      sm={12}
                      xs={12}
                      className="head-text"
                    >
                      Choose an option
                    </Col>
                    <Col
                      xxl={16}
                      xl={16}
                      lg={16}
                      md={12}
                      sm={12}
                      xs={12}
                      className="p-1"
                    >
                      <Dropdown
                        menu={{
                          items,
                        }}
                      >
                        <Button className="title-btn">
                          <Space>
                            CR
                            <Icon
                              icon="icon-park:down"
                              className="modal-icon"
                            />
                          </Space>
                        </Button>
                      </Dropdown>
                    </Col>
                    <Col
                      xxl={3}
                      xl={3}
                      lg={3}
                      md={12}
                      sm={12}
                      xs={12}
                      className="p-1"
                    >
                      {/* <Input
                        className="title-text"
                        placeholder="search"
                        type="text"
                      /> */}
                    </Col>
                    <Col
                      xxl={2}
                      xl={2}
                      lg={2}
                      md={4}
                      sm={4}
                      xs={4}
                      className="p-1"
                    >
                      <Button
                        className="title-btn"
                        onClick={() => handleAddClick()}
                      >
                        <Space>
                          <Icon icon="material-symbols:add-circle-outline" />
                          Add
                        </Space>
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Table
                      className="devops_Table"
                      columns={columns}
                      dataSource={tableData}
                      loading={{ indicator: <Spin />, spinning: showSpinner }}
                    />
                  </Row>
                  <Modal
                    title={titletag.status}
                    open={tagModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                    className="modal-tag"
                  >
                    {titletag.status === "Waiting for Approval Log" ||
                      (titletag.status === "Pending" && (
                        <>
                          <Row>
                            <Col
                              xxl={2}
                              xl={2}
                              lg={2}
                              md={2}
                              sm={2}
                              xs={2}
                              className="p-1"
                            >
                              <Icon
                                icon="ic:round-done-all"
                                className="modal-icon"
                              />
                            </Col>
                            <Col
                              xxl={14}
                              xl={14}
                              lg={14}
                              md={14}
                              sm={14}
                              xs={14}
                              className="p-1"
                            >
                              Waiting for approval
                            </Col>
                            <Col
                              xxl={8}
                              xl={8}
                              lg={8}
                              md={8}
                              sm={8}
                              xs={8}
                              className="progress-text-line"
                            >
                              {progressValue}%
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xxl={24}
                              xl={24}
                              lg={24}
                              md={24}
                              sm={24}
                              xs={24}
                              className="p-1"
                            >
                              <Button className="progress-btn">
                                <Progress
                                  percent={progressValue}
                                  // size={[300, 20]}
                                  strokeColor={{
                                    "0%": "red",
                                    "50%": "yellow",
                                    "100%": "green",
                                  }}
                                  showInfo={false}
                                />
                              </Button>
                            </Col>
                          </Row>
                        </>
                      ))}
                    <div>
                      <br />
                      <div className="total-modal">
                        <button className="modal-btn">
                          <Icon
                            icon="fluent-mdl2:message-friend-request"
                            className="modal-icon"
                          />
                        </button>
                        <p className="total-modal-text">
                          Sent request:{" "}
                          {moment(titletag.created_time).format(
                            "DD/MMM/YYYY HH:MM A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="total-modal">
                        <button className="modal-btn">
                          <Icon
                            icon="material-symbols:deployed-code-history-outline-sharp"
                            className="modal-icon"
                          />
                        </button>
                        <p className="total-modal-text">
                          Initiated deployment :{" "}
                          {moment(titletag.created_time).format(
                            "DD/MMM/YYYY HH:MM A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="total-modal">
                        <button className="modal-btn">
                          <Icon
                            icon="material-symbols:deployed-code-outline-sharp"
                            className="modal-icon"
                          />
                        </button>
                        <p className="total-modal-text">
                          deployment completed :
                          {titletag.completed_time
                            ? moment(titletag.completed_time).format(
                                "DD/MMM/YYYY HH:MM A"
                              )
                            : " -- "}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="total-modal">
                        <button className="modal-btn">
                          <Icon
                            icon="ant-design:reload-time-outline"
                            className="modal-icon"
                          />
                        </button>
                        <p className="total-modal-text">
                          Sent for approval :{" "}
                          {moment(titletag.created_time).format(
                            "DD/MMM/YYYY HH:MM A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="total-modal">
                        <button className="modal-btn">
                          <Icon icon="mdi:user" className="modal-icon" />
                        </button>
                        <p className="total-modal-text">
                          Approved by : {titletag.cx_approved_by}
                        </p>
                      </div>
                    </div>
                    {titletag === "Rejected Log" && (
                      <div>
                        <div className="total-modal">
                          <p className="total-modal-text">
                            Comment :asdgqwerty
                          </p>
                        </div>
                      </div>
                    )}
                  </Modal>
                  <Modal
                    open={refreshModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                    width={400}
                  >
                    <div className="p-4">
                      <Row>
                        <Col>Are you sure you want to reinitiate?</Col>
                      </Row>
                      <br />
                      <Row>
                        <Col
                          xxl={12}
                          xl={12}
                          lg={12}
                          md={24}
                          sm={24}
                          xs={24}
                          className="p-1"
                        >
                          <Button
                            htmlType="button"
                            className="no-btn"
                            onClick={() => setrefreshModalOpen(false)}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col
                          xxl={12}
                          xl={12}
                          lg={12}
                          md={24}
                          sm={24}
                          xs={24}
                          className="p-1"
                        >
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="yes-btn"
                          >
                            Yes
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Modal>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  marginTop: "3rem",
                }}
              >
                <div>
                  <div>
                    <img
                      src={RequestorDocument}
                      alt="RequestorDocument"
                      className="center-image"
                    />
                  </div>

                  <br />
                  <div className="d-flex justify-content-center">
                    <div>
                      <div className="center-text">
                        <h4>Your Dashboard is empty</h4>
                      </div>
                      <div className="center-text mt-3">
                        <h4>
                          To create a request{" "}
                          <a
                            onClick={handleCreateRequest}
                            className="click-here-text"
                          >
                            Click here
                          </a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </>
  );
}

export default RequestorTable;
