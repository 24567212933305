import * as React from "react";
import { useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { name: "US East (Ohio)", value: "us-east-2" },
  { name: "US East (N. Virginia)", value: "us-east-1" },
  { name: "US West (N. California)", value: "us-west-1" },
  { name: "US West (Oregon)", value: "us-west-2" },
  { name: "Africa (Cape Town)", value: "af-south-1" },
  { name: "Asia Pacific (Hong Kong)", value: "ap-east-1" },
  { name: "Asia Pacific (Hyderabad)", value: "ap-south-2" },
  { name: "Asia Pacific (Jakarta)", value: "ap-southeast-3" },
  { name: "Asia Pacific (Melbourne)", value: "ap-southeast-4" },
  { name: "Asia Pacific (Mumbai)", value: "ap-south-1" },
  { name: "Asia Pacific (Osaka)", value: "ap-northeast-3" },
  { name: "Asia Pacific (Seoul)", value: "ap-northeast-2" },
  { name: "Asia Pacific (Singapore)", value: "ap-southeast-1" },
  { name: "Asia Pacific (Sydney)", value: "ap-southeast-2" },
  { name: "Asia Pacific (Tokyo)", value: "ap-northeast-1" },
  { name: "Canada (Central)", value: "ca-central-1" },
  { name: "Europe (Frankfurt)", value: "eu-central-1" },
  { name: "Europe (Ireland)", value: "eu-west-1" },
  { name: "Europe (London)", value: "eu-west-2" },
  { name: "Europe (Milan)", value: "eu-south-1" },
  { name: "Europe (Paris)", value: "eu-west-3" },
  { name: "Europe (Spain)", value: "eu-south-2" },
  { name: "Europe (Stockholm)", value: "eu-north-1" },
  { name: "Europe (Zurich)", value: "eu-central-2" },
  { name: "Middle East (Bahrain)", value: "me-south-1" },
  { name: "Middle East (UAE)", value: "me-central-1" },
  { name: "South America (São Paulo)", value: "sa-east-1" },
];

export default function MultipleSelectCheckmarks({
  multi,
  name,
  event_name,
  onChange,
  props_value,
}) {
  console.log(props_value);
  const [personName, setPersonName] = React.useState([]);
  useEffect(() => {
    if (props_value) {
      const old_value = props_value.split(",");
      setPersonName(old_value);
    }
  }, [props_value]);

  const handleChange = (event) => {
    console.log(event);
    onChange(event);
    const {
      target: { value },
    } = event;

    setPersonName(typeof value === "string" ? value.split(",") : value);
    console.log(getSelectedValues(value));
  };

  const getSelectedValues = (selectedNames) => {
    return selectedNames.map((name) => {
      return names.find((item) => item.name === name)?.value || null;
    });
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">{name}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple={multi}
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          name={event_name}
        >
          {names.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {multi ? (
                <Checkbox checked={personName.indexOf(item.value) > -1} />
              ) : null}
              <ListItemText primary={item.name} secondary={item.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
