import React, { useState, useEffect } from "react";
import { Spin, Table } from "antd";
import "../../slm.css";
import { Form, Input, Row, Col, Select, Button, Card } from "antd";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import { getSecurityHub } from "../../../action/graphql/slm/SLM_reducers";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

const SecurityTable = () => {
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    getSecurityTableData();
  }, []);

  // const { selectedTopdate } = useSelector((state) => state.constimize);

  const selectedTopdate = "2023-4-01";

  const columns = [
    {
      title: "Standard",
      dataIndex: "standard",
      key: "standard",
      //   fixed : "left"
    },
    {
      title: "Passed",
      dataIndex: "passed",
      key: "passed",
    },
    {
      title: "Failed",
      dataIndex: "failed",
      key: "failed",
    },
    {
      title: "Scored",
      dataIndex: "scored",
      key: "scored",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    }
  ];

  const data = [
    {
      standard: "CIS AWS Foundations Benchmark v1.2.0",
      passed: "33",
      failed: "7",
      scored: "83%",
    },
    {
      standard: "AWS Foundational Security Best Practices v1.0.0",
      passed: "159",
      failed: "21",
      scored: "88%",
    },
    {
      standard: "CIS AWS Foundations Benchmark v1.2.0",
      passed: "33",
      failed: "7",
      scored: <div className="p-2 shadow bg-white rounded"> Enabled </div>,
    },
    {
      standard: "CIS AWS Foundations Benchmark v1.4.0",
      passed: "",
      failed: "",
      scored: <div className="p-2 shadow bg-white rounded"> Enabled </div>,
    },
  ];

  const [securityTableData, setSecurityTableData] = useState();

  const tableProps = {};

  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  const rowDelete = () => {};
  const handleSubmit = (values) => {

  };

  // const companyName = localStorage.getItem(
  //   "CognitoIdentityServiceProvider.company"
  // );

  const onEditClick = () => {
    
  }
  const companyName = "fivestar";
  var month_list = [];
  let passedCount = 0;
  let failedCount = 0;

  const getSecurityTableData = () => {
    dispatch(getSecurityHub({ companyName, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {
        let records = [];
        let standardsMaster = [];
        let totalData = data.newfunctionquery29;
        const passedStandards = totalData.filter((data) => {
          return data.security_current_status.toLowerCase() === "passed";
        });
        const failedStandards = totalData.filter((data) => {
          return data.security_current_status.toLowerCase() === "failed";
        });

        totalData.forEach((item) => {
          let singleRecord = { standard: "", passed: "", failed: "" };
          let standardName = item.standard;
          if (!standardsMaster.includes(standardName)) {
            standardsMaster.push(standardName);
            let passedCount = 0;
            let failedCount = 0;

            totalData.forEach((element) => {
              if (
                element.standard === standardName &&
                element.security_current_status.toLowerCase() === "passed"
              ) {
                passedCount++;
              }
              if (
                element.standard === standardName &&
                element.security_current_status.toLowerCase() === "failed"
              ) {
                failedCount++;
              }
            });
            singleRecord.standard = standardName;
            singleRecord.passed = passedCount;
            singleRecord.failed = failedCount;
            singleRecord.scored = (
              (passedCount / (passedCount + failedCount)) *
              100
            ).toFixed(2);

            singleRecord.action =  (
              <Icon icon="material-symbols:edit" onClick={onEditClick} />
            );
            records.push(singleRecord);
          }
        });
        setShowSpinner(false);
        setSecurityTableData(records);
        setShowSpinner(false);
        // const records = data.newfunctionquery29.map((item) => {
        //   if (item.standard === "CIS AWS Foundations Benchmark v1.2.0") {
        //     if (item.security_current_status === "passed" || item.security_current_status === "Passed") {
        //       passedCount += 1;
        //       console.log("passed count" , passedCount);
        //     }
        //     if (item.security_current_status === "failed" || item.security_current_status === "Failed") {
        //       failedCount += 1;
        //       console.log("failed count" , failedCount);
        //     }
        //   }
        //   return {
        //     standard: item.standard,
        //     passed: passedCount,
        //     failed: failedCount,
        //     action: (
        //       <Icon
        //         icon="material-symbols:edit"
        //         // onClick={() => handleEditClick(item)}
        //       />
        //     ),
        //     type: "data",
        //   };
        // });
        // setSecurityTableData(records);
      });
  };

  return (
    <>
      <DrawerPlat
        drawerstatus={drawerStatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        title="Security"
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          //  form={form}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="standard"
                label="Standard"
                rules={[{ required: true, message: "" }]}
              >
                <input type="text" className="form-control" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="security_current_status"
                label="Status"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  options={[
                    {
                      value: "passed",
                      label: "Passed",
                    },
                    {
                      value: "failed",
                      label: "Failed",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>

      <div>
        <button
          className="table-add-button float-right"
          onClick={() => setDrawerStatus(true)}
        >
          {/* <Icon icon="ion:add-circle-outline" /> */}
          <Icon icon="material-symbols:add-circle-outline" />
          Add
        </button>
      </div>
      <div className="initial_response_timetable">
        <Table
          {...tableProps}
          className="slm"
          style={{ overflowY: "auto", height: "19rem" }}
          columns={columns}
          dataSource={securityTableData}
          loading={{ indicator: <Spin />, spinning: showSpinner }}
          pagination={false}
        />
      </div>
    </>
  );
};

export default SecurityTable;
