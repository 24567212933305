import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Login from "./Login";
import Register from "./Register";
import HomeImage from "../assets/images/home-page-better.png";
import { enableRegisterFlag } from "./slice/userSlice";
import { useNavigate } from "react-router-dom";
import home1 from "../assets/images/home1.svg";
import home2 from "../assets/images/home2.svg";
import CustimizeFooter from "./dashboard-v2/CustimizeFooter";
import Header from "./header/Header";

function HomePage() {
  const [basicActive, setBasicActive] = useState("tab1");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isRegisterFlag } = useSelector((state) => state.user);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  const handleClose = (event) => {
    dispatch(enableRegisterFlag(false));
  };

  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <h1 className="home-title">Welcome to Cloud Studio</h1>
        </Col>
      </Row>
      <Container>
        <Row style={{ marginTop: 20 }}>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <p className="homepage-content">
              <span className="content-blue"> Cloud Studio </span> is yours
              comprehensive platform that provides a 360-degree view of your
              infrastructure's assets, covering all aspects of cloud
              infrastructure management, including{" "}
              <span className="content-green">SysOps</span>,{" "}
              <span className="content-maroon">FinOps</span>,{" "}
              <span className="content-purple">SecOps</span>, and{" "}
              <span className="content-blue2">DevOps</span>.
            </p>
            <p className="homepage-content plat-margin-top-6">
              With Cloud Studio, you can manage your entire cloud
              infrastructure, including servers, networks, and applications, and
              gain complete control over your systems and servers.
            </p>
            <p className="homepage-content plat-margin-top-6">
              Its <span className="content-green">SysOps</span> capabilities
              offer comprehensive patch management and event management
              features, ensuring your systems are always up-to-date, secure, and
              running smoothly.
            </p>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <img src={home2} alt="home2" className="home2-img" />
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <img src={home1} alt="home1" />
          </Col>
          <Col>
            <p className="homepage-content">
              Additionally, the platform's{" "}
              <span className="content-maroon"> FinOps </span> features enable
              you to monitor your financial operations, optimize your spending,
              and identify cost savings opportunities..
            </p>
            <p className="homepage-content plat-margin-top-6">
              With <span className="content-purple">SecOps</span> capabilities,
              you can track security events, detect and respond to threats, and
              maintain compliance with industry standards.
            </p>
            <p className="homepage-content plat-margin-top-6">
              Finally, Cloud Studio's{" "}
              <span className="content-blue2">DevOps</span> tools help you
              automate and streamline your processes, enabling you to rapidly
              deploy and scale your applications with ease.
            </p>
            <p className="homepage-content plat-margin-top-6">
              With Cloud Studio, you can confidently manage your cloud
              infrastructure from one central location, saving time and
              resources while achieving operational excellence, so that you can
              focus on growing your business and achieving your goals.
            </p>
          </Col>
        </Row>
      </Container>
      <CustimizeFooter />
    </div>
  );
}

export default HomePage;
