import { Table } from "antd";
import Dropdown from "react-bootstrap/Dropdown";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import { message, Form, Input, Select, Button, Switch, DatePicker } from "antd";
//import DatePicker from "react-datepicker";
import { disabledDate } from "../../../custom_hook/CustomHook";
import { useState, useEffect } from "react";
import ProjectStatusChart from "./projectstatuschart";
import {
  getProjectStatusData,
  AddcreateProjectstatus,
  updateaddActionProjectstatus,
  deleteProjectstatus,
} from "../../../action/graphql/slm/SLM_reducers";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Row, Col } from "antd";
import { Icon } from "@iconify/react";
const { TextArea } = Input;
const ProjectStatus = () => {
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  //const [startDate, setStartDate] = useState(new Date());
  // const [startDate1, setStartDate1] = useState(new Date());
  // const [startDate2, setStartDate2] = useState(new Date());
  const [tableData, setTableData] = useState([]);
  const [buttonloading, setButtonLoading] = useState(false);
  const [updatestatus, setUpdateStatus] = useState(true);
  const [oldRecords, setOldRecords] = useState([]);
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [daysDiffrence, setDaysDiffrence] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const monthFormat = "YYYY-MM-01";

  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);
  useEffect(() => {
    const date = selectedTopdate;
    dispatch(getProjectStatusData({ companyName, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {
        console.log("data");
        console.log(data);
        const records = data.newfunctionquery21.map((datas) => {
          return {
            company: datas.company,
            next_milestone: datas.next_milestone,
            progress: datas.progress,
            project_name: datas.project_name,
            project_phase: datas.project_phase,
            project_target_date: datas.project_target_date,
            report_date: datas.report_date,
            start_date: datas.start_date,
            status: datas.status,
            status_id: datas.status_id,
            action: (
              <iconify-icon
                icon="material-symbols:edit"
                onClick={() => handleEditClick(datas)}
              ></iconify-icon>
            ),
            type: "data",
            type: "data",
            // days_remaining: daysDiff
          };
        });
        setTableData(records);
        setDaysDiffrence(records);
      })
      .catch((err) => {
        console.log(err.message);
      });
    // setTableData([]);
  }, [updatestatus, selectedTopdate]);

  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  const handleEditClick = (data) => {
    setDrawerStatus(true);
    setDeleteStatus(true);
    setOldRecords(data);
    console.log(data);
    console.log(typeof data.start_date);

    const records = {
      next_milestone: data.next_milestone,
      progress: data.progress,
      project_name: data.project_name,
      project_phase: data.project_phase,
      start_date: moment(data.start_date, "YYYY-MM-DD"),
      project_target_date: moment(data.project_target_date, "YYYY-MM-DD"),
      report_date: moment(data.report_date, "YYYY-MM-DD"),
      status: data.status,
    };
    form.setFieldsValue(records);
  };
  console.log(oldRecords);
  const handleAddClick = () => {
    setDrawerStatus(true);
    setDeleteStatus(false);
    setOldRecords(null);
    const records = {
      next_milestone: "",
      progress: " ",
      project_name: "",
      project_phase: "",
      start_date: "",
      project_target_date: "",
      report_date: "",
      status: " ",
    };

    form.setFieldsValue(records);
  };

  const columns = [
    // {
    //   title: "Report Date",
    //   dataIndex: "report_date",
    //   key: "report_date",
    //   className: "plat-table-date",
    //   fixed: "left",
    // },
    {
      title: "Report Date",
      dataIndex: "report_date",
      key: "date",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      },
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },

    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
    },
    {
      title: "Project Phase",
      dataIndex: "project_phase",
      key: "project_phase",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "date",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      },
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Project Target Date",
      dataIndex: "project_target_date",
      key: "date",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      },
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Next Milestone",
      dataIndex: "next_milestone",
      key: "next_milestone",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  const tableProps = {};
  const handleSubmit = (value) => {
    setButtonLoading(true);
    setDrawerStatus(false);
    console.log("submit");
    const input = {
      company_name: companyName,
      project_name: value.project_name,
      project_phase: value.project_phase,
      progress: value.progress,
      status: value.status,
      next_milestone: value.next_milestone,
      report_month: `${new Date(value.report_date).getFullYear()}-${
        new Date(value.report_date).getMonth() + 1
      }-01`,
      report_date: `${new Date(value.report_date).getFullYear()}-${
        new Date(value.report_date).getMonth() + 1
      }-${new Date(value.report_date).getDate()}`,
      project_target_date: `${new Date(
        value.project_target_date
      ).getFullYear()}-${
        new Date(value.project_target_date).getMonth() + 1
      }-${new Date(new Date(value.project_target_date)).getDate()}`,
      start_date: `${new Date(value.start_date).getFullYear()}-${
        new Date(value.start_date).getMonth() + 1
      }-${new Date(new Date(value.start_date)).getDate()}`,
    };
    console.log(input);

    if (deletestatus) {
      const status_id = oldRecords.status_id;
      console.log(status_id);
      dispatch(updateaddActionProjectstatus({ input, status_id }))
        .unwrap()
        .then(({ data }) => {
          message.success("Record Updated");
          setUpdateStatus(!updatestatus);
          setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      dispatch(AddcreateProjectstatus({ input }))
        .unwrap()
        .then(({ data }) => {
          message.success("New Records Added");
          setUpdateStatus(!updatestatus);
          setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  const rowDelete = () => {
    const status_id = oldRecords.status_id;
    dispatch(deleteProjectstatus({ status_id, companyName }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        setUpdateStatus(!updatestatus);
        setDrawerStatus(false);
        setButtonLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <>
      <DrawerPlat
        drawerstatus={drawerstatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        rowDelete={rowDelete}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        title="Project Status"
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="project_name"
                label="Project Name"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="next_milestone"
                label="Next Milestone"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "uat sign off",
                      label: "UAT sign off",
                    },
                    {
                      value: "infra deployment",
                      label: "Infra Deployment",
                    },
                    {
                      value: "uat testing",
                      label: "UAT Testing",
                    },
                    {
                      value: "none",
                      label: "None",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="progress"
                label="Progress"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="project_phase"
                label="Project Phase"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Prod",
                      label: "Prod",
                    },
                    {
                      value: "UAT ",
                      label: "UAT ",
                    },
                    {
                      value: "closed",
                      label: "Closed",
                    },
                    {
                      value: "testing",
                      label: "Testing",
                    },
                    {
                      value: "implementation",
                      label: "Implementation",
                    },
                    {
                      value: "design",
                      label: "Design",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="start_date"
                label=" Start Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  format={monthFormat}
                  picker="month"
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="project_target_date"
                label="Project Target Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  format={monthFormat}
                  picker="month"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item
                name="report_date"
                label=" Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  format={monthFormat}
                  picker="month"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row></Row>

          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>
      <div className="projectStatus-body-screen">
        <ProjectStatusChart daysDiffrence={daysDiffrence} />
        <div className="">
          <div onClick={handleAddClick}>
            <button className="table-add-button float-right">
              <Icon icon="material-symbols:add-circle-outline" />
              Add
            </button>
          </div>
          <div className="table-planned-actual">
            <Table
              {...tableProps}
              className="savings_new_table"
              columns={columns}
              style={{ overflowX: "scroll" }}
              dataSource={tableData}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectStatus;
