import { memo, useEffect, useState } from "react";
import { Modal } from "antd";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpendDetails,
  listAnnualBudget,
} from "../../action/costimizeV2Active";

const SpendVSBudgetPopup = (props) => {
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [loader, setLoader] = useState(false);
  const handleCancel = () => {
    props.setOpen(false);
    props.setMonthList(0);
  };
  const [spendlist, setSpendList] = useState([]);
  const [budgetlist, setBudgetList] = useState([]);
  const [chart, setChart] = useState({
    chartoption: {},
    chartvalue: [],
  });
  useEffect(() => {
    setLoader(true);
    if (props.monthlist > 0) {
      let currentDate = new Date(selectedTopdate);
      let lastThreeMonthsDate = new Date(selectedTopdate);
      lastThreeMonthsDate.setMonth(
        currentDate.getMonth() - (props.monthlist - 1)
      );
      const monthNamesShort = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month_list = [];
      var datelist = [];
      for (let i = 0; i < props.monthlist; i++) {
        let date = new Date(
          lastThreeMonthsDate.getFullYear(),
          lastThreeMonthsDate.getMonth() + i,
          1
        );
        month_list.push(
          `${monthNamesShort[date.getMonth()]}-${(
            "" + date.getFullYear()
          ).substr(2)}`
        );
        datelist.push(`${date.getFullYear()}-${date.getMonth() + 1}-1`);
      }

      const selectedTopdate_new = datelist.toString();
      var apivalue = {
        environment: selectedEnvironment.toString(),
        account: selectedAccount.toString(),
        cloud: selectedCloud.toString(),
        application: selectedApplication.toString(),
        services: selectedservices.toString(),
        os: selectedos.toString(),
        infra: selectedinfra.toString(),
        db: selecteddb.toString(),
      };
      dispatch(getSpendDetails({ companyName, selectedTopdate_new, apivalue }))
        .unwrap()
        .then(({ data }) => {
          var spend_list = [];
          var chart_new_format = [];
          var spend_value;
          for (let i = 0; i < props.monthlist; i++) {
            let date = new Date(
              lastThreeMonthsDate.getFullYear(),
              lastThreeMonthsDate.getMonth() + i,
              1
            );
            var spend = data.newfunctionquery3.filter((item) => {
              let spenddate = new Date(item.reportmonth);
              if (spenddate.getMonth() + 1 === date.getMonth() + 1) {
                return item;
              }
            });
            if (
              date.getMonth() + 1 ===
              new Date(selectedTopdate).getMonth() + 1
            ) {
              if (spend.length > 0) {
                spend_value = spend[0].spend;
              }
            }
            if (spend.length > 0) {
              spend_list.push(Math.round(spend[0].spend));
            } else {
              spend_list.push(0);
            }
          }
          setSpendList(spend_list);

          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });

      //budget
      const cloud = selectedCloud.toString();
      // console.log({ companyName, cloud });
      dispatch(listAnnualBudget({ companyName, cloud }))
        .unwrap()
        .then(({ data }) => {
          var datevalue = [];
          if (data.list_annual_budget.length > 0) {
            let currentDate = new Date(selectedTopdate);
            let lastThreeMonthsDate = new Date(selectedTopdate);
            lastThreeMonthsDate.setMonth(
              currentDate.getMonth() - (props.monthlist - 1)
            );
            data.list_annual_budget.map((data) => {
              if (Number(data.budget_year) == new Date().getFullYear()) {
                //days
                let currentDate = new Date(selectedTopdate);
                let lastThreeMonthsDate = new Date(selectedTopdate);
                lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
                for (let i = 0; i < props.monthlist; i++) {
                  let date = lastThreeMonthsDate.getMonth() + i;
                  datevalue.push(getBudgetAllocatedAmount(date, data));
                }
              }
            });
          } else {
            for (let i = 0; i < props.monthlist; i++) {
              datevalue.push(0);
            }
          }
          setBudgetList(datevalue);
        })
        .catch((err) => {
          console.log(err.message);
        });

      //   console.log(month_list);
    }
  }, [props]);

  useEffect(() => {
    if (spendlist.length > 0 && budgetlist.length > 0) {
      let currentDate = new Date(selectedTopdate);
      let lastThreeMonthsDate = new Date(selectedTopdate);
      lastThreeMonthsDate.setMonth(
        currentDate.getMonth() - (props.monthlist - 1)
      );
      const monthNamesShort = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month_list = [];
      var chart_new_format = [];
      var spend_value = [];
      var budget_value = [];
      for (let i = 0; i < props.monthlist; i++) {
        let date = new Date(
          lastThreeMonthsDate.getFullYear(),
          lastThreeMonthsDate.getMonth() + i,
          1
        );
        month_list.push(
          `${monthNamesShort[date.getMonth()]}-${(
            "" + date.getFullYear()
          ).substr(2)}`
        );
        spend_value.push(spendlist[i]);
        budget_value.push(budgetlist[i]);
      }
      // console.log(chart_new_format);

      setChart({
        chartoption: {
          chart: {
            height: 350,
            type: "line",
          },
          stroke: {
            width: [0, 4],
            curve: "straight",
          },
          tooltip: {
            enabled: true,
            intersect: false,
            shared: true,
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: [1],
          },
          labels: month_list,
          // colors: ["#0063ff", "#ff31f2"],
          // colors: ["#fcbb29", "#82ccdc"],
          colors: ["#e37d05", "#11104e"],
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            offsetX: 0,
            markers: {
              radius: 12,
            },
            itemMargin: {
              horizontal: 10,
              vertical: 0,
            },
          },
          markers: {
            size: 4,
            hover: {
              size: 7,
            },
          },
          yaxis: [
            {
              title: {
                text: "Budget",
                style: {
                  fontSize: "12px",
                  fontWeight: 500,
                },
              },
              labels: {
                formatter: (num) => {
                  return Math.abs(num) > 999
                    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                    : Math.sign(num) * Math.abs(num);
                },
              },
            },
            {
              opposite: true,
              title: {
                text: "Spend",
                style: {
                  fontSize: "12px",
                  fontWeight: 500,
                },
              },
              labels: {
                formatter: (num) => {
                  return Math.abs(num) > 999
                    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                    : Math.sign(num) * Math.abs(num);
                },
              },
            },
          ],
        },
        chartvalue: [
          {
            name: "Budget",
            type: "column",
            data: budget_value,
          },
          {
            name: "Spend",
            type: "line",
            data: spend_value,
          },
        ],
      });
    }
  }, [spendlist, budgetlist]);

  const getBudgetAllocatedAmount = (current_month, data) => {
    var allocated_buget = 0;
    if (current_month === 1) {
      allocated_buget = data.budget_jan;
    } else if (current_month === 2) {
      allocated_buget = data.budget_feb;
    } else if (current_month === 3) {
      allocated_buget = data.budget_mar;
    } else if (current_month === 4) {
      allocated_buget = data.budget_apr;
    } else if (current_month === 5) {
      allocated_buget = data.budget_may;
    } else if (current_month === 6) {
      allocated_buget = data.budget_jun;
    } else if (current_month === 7) {
      allocated_buget = data.budget_jul;
    } else if (current_month === 8) {
      allocated_buget = data.budget_aug;
    } else if (current_month === 9) {
      allocated_buget = data.budget_sept;
    } else if (current_month === 10) {
      allocated_buget = data.budget_oct;
    } else if (current_month === 11) {
      allocated_buget = data.budget_nov;
    } else {
      allocated_buget = data.budget_dec;
    }
    return allocated_buget;
  };
  return (
    <>
      <Modal
        title="Spend vs Budget"
        open={props.open}
        onCancel={handleCancel}
        footer={false}
        style={{ textAlign: "center" }}
        width="auto"
        maxWidth={800}
      >
        <ReactApexChart
          options={chart.chartoption}
          series={chart.chartvalue}
          type="bar"
          height={300}
        />
      </Modal>
    </>
  );
};

export default memo(SpendVSBudgetPopup);
