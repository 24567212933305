// userAction.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import client from "../apploClient";
import without_token_client from "../action/client/withoutTokenApploClient";
import {
  CREATE_USER_INFO,
  LOGIN_USER,
  CREATE_CUSTOMER_ONBOARDING,
  DELETE_USER,
  GET_USER_DETAILS,
  UPDATE_CUSTOMER_ONBOARDING,
  UPDATE_USER_STATUS,
  USERMANAGER_LIST,
  CHANGE_USER_PASSWORD,
  GET_USER_ROLE,
  EDITUSER,
  GET_USER_ACCOUNT_NEW,
  INSERT_TEMP_USER_DETAILS,
  GET_TEMP_USER_DETAILS,
} from "../Graphql";
import { useNavigate } from "react-router-dom";
import { useErrorNavigation } from "../custom_hook/CustomHook";

export const registerUser = createAsyncThunk(
  // action type string
  "user/register",
  // callback function
  async (
    { firstName, email, password, companyName, phoneNo, lastName },
    { rejectWithValue }
  ) => {
    try {
      const createUser = await client.mutate({
        mutation: CREATE_USER_INFO,
        variables: {
          createuserconfiginput: {
            email,
            first_name: firstName,
            last_name: lastName,
            contact_no: phoneNo,
            password,
            company_name: companyName,
            status: "register",
          },
        },
      });
      return createUser;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const loginUser = createAsyncThunk(
  // action type string
  "user/login",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const loginUser = await client.query({
        query: LOGIN_USER,
        variables: {
          user_id: data.user_id,
        },
      });
      return loginUser;
    } catch (error) {
      // console.log(error);
      // useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserDetails = createAsyncThunk(
  // action type string
  "user/details",
  // callback function
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const details = await client.query({
        query: GET_USER_DETAILS,
        variables: {
          email: username,
          password,
        },
      });
      return details;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const customerOnboarding = createAsyncThunk(
  // action type string
  "user/customerOnboarding",
  // callback function
  async (
    {
      email_id,
      first_name,
      last_name,
      master_contact_no,
      company_name,
      company_mail_id,
      company_headquaters,
      company_contact_no,
      address_information,
      state,
      country,
      master_email,
      master_first_name,
      master_last_name,
      user_id,
      time_zone,
      Offerings,
      Role,
      status,
    },
    { rejectWithValue }
  ) => {
    try {
      const onboardCustomer = await client.mutate({
        mutation: CREATE_CUSTOMER_ONBOARDING,
        variables: {
          email_id,
          first_name,
          last_name,
          company_name,
          company_mail_id,
          company_headquaters,
          company_contact_no,
          address_information,
          state,
          country,
          master_email,
          master_first_name,
          master_last_name,
          master_contact_no,
          time_zone,
          Offerings,
          Role,
          status,
          user_id,
        },
      });
      return onboardCustomer;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const customerUpdate = createAsyncThunk(
  // action type string
  "user/customerupdate",
  // callback function
  async (
    {
      email_id,
      first_name,
      last_name,
      company_name,
      company_mail_id,
      company_headquaters,
      company_contact_no,
      address_information,
      state,
      country,
      master_email,
      master_first_name,
      master_last_name,
      master_contact_no,
      time_zone,
      Offerings,
      role,
      status,
      user_id,
    },
    { rejectWithValue }
  ) => {
    try {
      console.log({ master_contact_no });
      const onboardCustomer = await client.mutate({
        mutation: UPDATE_CUSTOMER_ONBOARDING,
        variables: {
          email_id,
          first_name,
          last_name,
          company_name,
          company_mail_id,
          company_headquaters,
          company_contact_no,
          address_information,
          state,
          country,
          master_email,
          master_first_name,
          master_last_name,
          master_contact_no,
          time_zone,
          Offerings,
          role,
          status,
          user_id,
        },
      });
      return onboardCustomer;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const insertTempUserDetails = createAsyncThunk(
  // action type string
  "user/inserttempdetails",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const onboardCustomer = await client.mutate({
        mutation: INSERT_TEMP_USER_DETAILS,
        variables: {
          company_contact_no: data.company_contact_no,
          company_headquaters: data.company_headquaters,
          company_mail_id: data.company_mail_id,
          compnay_name: data.company_name,
          contact_number: data.mobile_number,
          country: data.country,
          email_id: data.email_id,
          first_name: data.first_name,
          last_name: data.last_name,
          offering: data.Offerings,
          role: data.role,
          state: data.state,
          temp_user_id: data.id,
          time_zone: data.time_zone,
          address_information: data.address_information,
        },
      });
      return onboardCustomer;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTempUserDetails = createAsyncThunk(
  // action type string
  "user/gettempuserdetails",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const tempCustomer = await without_token_client.mutate({
        mutation: GET_TEMP_USER_DETAILS,
        variables: {
          temp_user_id: data.temp_user_id,
        },
      });
      return tempCustomer;
    } catch (error) {
      // useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/delete",
  async (user_id, { rejectWithValue }) => {
    // Expect user_id as a direct value
    console.log(user_id);
    try {
      const createUser = await client.mutate({
        mutation: DELETE_USER,
        variables: {
          user_id: user_id,
        },
      });
      return createUser;
    } catch (error) {
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changeUserPassword = createAsyncThunk(
  // action type string
  "user/changepassword",
  // callback function
  async ({ email, password }, { rejectWithValue }) => {
    console.log(email);
    console.log(password);
    try {
      const createUser = await client.mutate({
        mutation: CHANGE_USER_PASSWORD,
        variables: {
          email,
          password,
        },
      });
      return createUser;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editUser = createAsyncThunk(
  // action type string
  "user/user_edit",
  // callback function
  async (user_id, { rejectWithValue }) => {
    try {
      console.log(user_id);
      const createUser = await client.mutate({
        mutation: EDITUSER,
        variables: {
          user_id,
        },
      });
      return createUser;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userStatusUpdate = createAsyncThunk(
  // action type string
  "user/status_update",
  // callback function
  async (
    { user_id, status, email, company_name, role },
    { rejectWithValue }
  ) => {
    try {
      console.log(user_id, status, email, company_name, role);
      const createUser = await client.mutate({
        mutation: UPDATE_USER_STATUS,
        variables: {
          emailid: email,
          status: status,
          company_name: company_name,
          user_id: user_id,
          role: role,
        },
      });
      return createUser;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const usermanagerUserList = createAsyncThunk(
  // action type string
  "user/listuser",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const createUser = await client.mutate({
        mutation: USERMANAGER_LIST,
      });
      return createUser;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getMemberRole = createAsyncThunk(
  // action type string
  "user/role",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data);

    try {
      const role = await client.mutate({
        mutation: GET_USER_ROLE,
        variables: {
          customer: data.input.customer,
          report_month: data.input.report_month,
          role_name: data.input.role_name,
        },
      });
      return role;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getAccountdetailsNew = createAsyncThunk(
  // action type string
  "user/role",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const role = await client.mutate({
        mutation: GET_USER_ACCOUNT_NEW,
        variables: {
          company: data.customer,
          event_type: data.event_type,
        },
      });
      return role;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
