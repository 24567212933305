import { Button, DatePicker, Row, Select, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import export_logo from "./../../assets/images/cams/excel_logo.png";
import moment from "moment";
import { useDispatch } from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  getReportData,
  getReportsPages,
} from "../action/graphql/cams/cams_reducers";
import { saveAs } from "file-saver";

const CamsReport = (customer) => {
  const options = [
    {
      label: "SA Processed",
      value: "cams_processed",
    },
    {
      label: "All Alerts",
      value: "all_alerts",
    },
  ];
  const tempDate = new Date();
  // const defaultDate = moment(tempDate, 'YYYY-MM-DD');
  // const [selectedDate, setSelectedDate] = useState(moment(tempDate).format("YYYY-MM-DD"));
  const [selectedDate, setSelectedDate] = useState();
  const dispatch = useDispatch();
  const [tableDisplayData, setTableDisplayData] = useState([]);
  const [readyForExport, setReadyForExport] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((obj) => Object.values(obj).join(","));
    return `${header}\n${rows.join("\n")}`;
  }

  function downloadCSV(excelrecords, date, type) {
    const data = excelrecords;
    const filename = `${customer.customer}_${date}_${type}.csv`;
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, filename);
    message.success("Report Downloaded");
  }
  var tempDataArray = [];
  const getTableData = (tmpDate, currentPage, totalPages) => {
    let currTemp = currentPage + 1;
    let totalTemp = totalPages;

    dispatch(
      getReportData({
        company_name: customer.customer,
        date: tmpDate,
        page_size: 100,
        alert_type: type,
        page_count: currentPage,
      })
    )
      .unwrap()
      .then(({ data }) => {
        tempDataArray.push(...data.cams_report_tab_main_table);
        currentPage = currentPage + 1;
        if (currTemp <= totalTemp) {
          getTableData(tmpDate, currTemp, totalTemp);
        } else {
          setTableDisplayData(tempDataArray);
          setReadyForExport(true);
          setLoading(false);
          setShowSpinner(false);
        }
      });
  };
  const generateReports = () => {
    if (selectedDate) {
      let tmpDate = moment(selectedDate).format("YYYY-MM-DD");
      if (tmpDate === "Invalid date") {
        message.error("Please Select Date");
        return;
      } else {
        setLoading(true);
        setSelectedDate(tmpDate);
        setTableDisplayData([]);
        setShowSpinner(true);
        tempDataArray = [];
        dispatch(
          getReportsPages({
            company_name: customer.customer,
            date: tmpDate,
            count: 100,
            alert_type: type,
          })
        )
          .unwrap()
          .then(({ data }) => {
            var totalPages = Number(
              data.cams_report_tab_table_pagesize[0].total_pages
            );
            var currentPage = 1;
            getTableData(tmpDate, currentPage, totalPages);
          });
      }
    } else {
      message.error("Please Select Date");
      return;
    }
  };
  const [type, setType] = useState("all_alerts");
  const [reportData, setReportData] = useState([]);
  const columns = [
    {
      dataField: "alert_type",
      text: "Alert Type",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
        borderLeftRadius: "5px",
      },
      sort: true,
    },
    {
      dataField: "from_address",
      text: "From Address",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "server_name",
      text: "Server Name",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "severity",
      text: `Severity Level`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "subject",
      text: `Subject`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
    {
      dataField: "to_address",
      text: `To Address`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
      formatter: (cell, row) => {
        const split_records = cell.split("(");
        if (split_records.length >= 2) {
          const replace_records_1 = cell.replace("(", "");
          const replace_records_2 = replace_records_1.replace(")", "");
          const mail_list = replace_records_2.split(",");
          const mail_list_html = mail_list.map((data) => {
            return (
              <>
                {data} <br></br>
              </>
            );
          });
          return mail_list_html;
        } else {
          return cell;
        }
      },
    },
    {
      dataField: "date",
      text: `Date`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
        width: "10rem",
      },
      style: { width: "10rem" },
      sort: true,
    },
    {
      dataField: "time",
      text: `Time`,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        backgroundColor: "#1c344c",
        color: "white",
      },
      sort: true,
    },
  ];

  const disabledDate = (current) => {
    // Disable all dates after today
    return current && current > moment().endOf("day");
  };

  const handleDateChange = (date, dateString) => {
    const formattedDate = moment(dateString).format("YYYY-MM-DD");

    setSelectedDate(formattedDate);
    // if(formattedDate !== "Invalid date"){
    // }
  };

  const downloadReport = () => {
    if (tableDisplayData.length > 0) {
      downloadCSV(tableDisplayData, selectedDate, type);
    } else {
      message.error("No data to export");
    }
  };
  return (
    <>
      <div className="h-100 cams-content  mb-5">
        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <div className="d-flex justify-content-end align-items-center mr-3 ">
              <div className="cams-date">
                <DatePicker
                  className="datepicker"
                  disabledDate={disabledDate}
                  // defaultValue={dayjs(selectedDate, "YYYY-MM-DD")}
                  format={"YYYY-MM-DD"}
                  onChange={handleDateChange}
                />
              </div>
              <Select
                defaultValue={type}
                options={options}
                dropdownMatchSelectWidth={false}
                onChange={(val) => {
                  setType(val);
                }}
              />

              <Button
                className="reports-submit"
                onClick={!loading ? generateReports : undefined}
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="alert-detail-table-reports reports-table d-block">
            {showSpinner ? (
              <Spin className="reports-spinner" />
            ) : (
              <>
                <div
                  className=" export d-flex justify-content-end"
                  onClick={downloadReport}
                >
                  <span>
                    <img
                      className="ml-2 cursor-pointer"
                      alt="Export"
                      src={export_logo}
                    />
                    Export
                  </span>
                </div>
                <div className="alert-detail-table2-height">
                  <BootstrapTable
                    keyField="id"
                    data={tableDisplayData}
                    columns={columns}
                    bordered={false}
                    noDataIndication="No Records"
                    pagination={paginationFactory()}
                  />
                </div>
              </>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};
export default CamsReport;
