import React, { useState } from "react";
import { memo, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Radio,
  Space,
  Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { setConfigurationFormData } from "../../../slice/devopsSlice";
function ConfigureDetails(props) {
  const navigateFn = useNavigate();
  const [isShowAWS, setIsShowAWS] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const devopsStore = useSelector((store) => store.devops);

  const { configuration } = devopsStore;

  const [response, setResponse] = useState(configuration[0].configurations);
  useEffect(() => {
    if (devopsStore.requestAction === "add") {
      if (
        devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform !== ""
      ) {
        form.setFieldsValue(
          JSON.parse(devopsStore.saveBasicDetailsData.configDetailsInfo)
        );
      }
    } else if (devopsStore.requestAction === "edit") {
      console.log(devopsStore.requestorTableData.configurations);
      let json_var = JSON.parse(devopsStore.requestorTableData.configurations);
      json_var = json_var.replace;
      form.setFieldsValue(eval(devopsStore.requestorTableData.configurations));
    }
  }, []);

  const handleSubmit = (values) => {
    dispatch(setConfigurationFormData(values));
    props.setCurrent(props.current + 1);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleAdditionalTags = () => {
    console.log("in");
    setIsModalOpen(true);
  };
  return (
    <>
      <div className="mt-5 configuration-details">
        <span className="sub-heading-devops-config">
          {" "}
          Virtual Machine Configuration
        </span>
      </div>
      {isShowAWS ? (
        <>
          {" "}
          <div className="configuration-card-body">
            <Row gutter={[16, 16]} className="d-flex justify-content-center">
              <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                <Form onFinish={handleSubmit} form={form}>
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      Name and tags (required)
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                      <Form.Item name="company_name">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                      <span
                        className="click-here-text"
                        onClick={() => handleAdditionalTags()}
                      >
                        Add additional tags
                      </span>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
          <div className="configuration-details">
            <span className="sub-heading-devops-config ms-5">
              &nbsp; &nbsp;Application and OS Images (Amazon Machine Image)
            </span>
          </div>
          <div className="configuration-card-body">
            <Row gutter={[16, 16]} className="d-flex justify-content-center">
              <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                <Form onFinish={handleSubmit} form={form}>
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      OS
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select OS"
                        options={[
                          {
                            label: "AWS",
                            value: "aws",
                          },
                          {
                            label: "Azure",
                            value: "azure",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      Amazon Machine Image(AMI)
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select AMI"
                        options={[
                          {
                            label: "AWS",
                            value: "aws",
                          },
                          {
                            label: "Azure",
                            value: "azure",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      Architecture
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Architecture"
                        options={[
                          {
                            label: "AWS",
                            value: "aws",
                          },
                          {
                            label: "Azure",
                            value: "azure",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      Instance Type
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Instance Type"
                        options={[
                          {
                            label: "AWS",
                            value: "aws",
                          },
                          {
                            label: "Azure",
                            value: "azure",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      Key pair (login)
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Key Pair"
                        options={[
                          {
                            label: "AWS",
                            value: "aws",
                          },
                          {
                            label: "Azure",
                            value: "azure",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
          <div className="configuration-details">
            <span className="sub-heading-devops-config ms-5">Network</span>
          </div>
          <div className="configuration-card-body">
            <Row gutter={[16, 16]} className="d-flex justify-content-center">
              <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                <Form onFinish={handleSubmit} form={form}>
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      VPC
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select VPC"
                        options={[
                          {
                            label: "AWS",
                            value: "aws",
                          },
                          {
                            label: "Azure",
                            value: "azure",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      Subnet
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Subnet"
                        options={[
                          {
                            label: "AWS",
                            value: "aws",
                          },
                          {
                            label: "Azure",
                            value: "azure",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      Auto Assign Public IP
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Auto Assign Public IP"
                        options={[
                          {
                            label: "AWS",
                            value: "aws",
                          },
                          {
                            label: "Azure",
                            value: "azure",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <br />
                  <div>Network Settings</div>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      &nbsp;&nbsp;Select existing security group
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Security Group"
                        options={[
                          {
                            label: "AWS",
                            value: "aws",
                          },
                          {
                            label: "Azure",
                            value: "azure",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      Configure storage
                    </Col>
                    1x
                    <Col xxl={3} xl={3} lg={3} md={3} sm={24}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Architecture"
                        options={[
                          {
                            label: "AWS",
                            value: "aws",
                          },
                          {
                            label: "Azure",
                            value: "azure",
                          },
                        ]}
                      />
                    </Col>
                    GiB &nbsp;&nbsp;
                    <Col xxl={3} xl={3} lg={3} md={3} sm={24}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Architecture"
                        options={[
                          {
                            label: "AWS",
                            value: "aws",
                          },
                          {
                            label: "Azure",
                            value: "azure",
                          },
                        ]}
                      />
                    </Col>
                    Root Volume (Encrypted)
                    <Col
                      xxl={6}
                      xl={6}
                      lg={4}
                      md={4}
                      sm={24}
                      className="d-flex justify-content-end"
                    >
                      <Button>
                        <Icon
                          icon="gridicons:add-outline"
                          style={{ fontSize: "16px" }}
                        />{" "}
                        Add New Volume
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          htmlType="button"
                          onClick={() => props.setCurrent(props.current - 1)}
                        >
                          Back
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="form-button"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          <Form onFinish={handleSubmit} form={form}>
            <div className="sub-heading-devops-config ms-5">Basics</div>
            <br />
            <div className="configuration-card-body">
              <Row gutter={[16, 16]} className="d-flex justify-content-center">
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  {response?.subscription_id !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        Subscription
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="subscription_id">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Subscription"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {response?.resource_group_name !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        Resource group
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="resource_group_name">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Resource Group Name"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
            <br />
            <div className="sub-heading-devops-config ms-5">
              Instance Details
            </div>
            <br />
            <div className="configuration-card-body">
              <Row gutter={[16, 16]} className="d-flex justify-content-center">
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  {response?.virtual_machine_name !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        Virtual Machine Name
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="virtual_machine_name">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Virtual Machine Name"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {response?.location !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        Location
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="location">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Location"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {response?.os_publisher !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        OS Publisher
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="os_publisher">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter OS Publisher"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {response?.os_offer !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        OS Offer
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="os_offer">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter OS Offer"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {response?.os_sku !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        OS SKU
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="os_sku">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter OS SKU"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {response?.os_version !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        OS Version
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="os_version">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter OS Version"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {response?.virtual_machine_size !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        Virtual Machine Size
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="virtual_machine_size">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Virtual Machine Size"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
            <br />
            <div className="sub-heading-devops-config ms-5">
              Administrator Account
            </div>
            <br />
            <div className="configuration-card-body">
              <Row gutter={[16, 16]} className="d-flex justify-content-center">
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  {response?.admin_username !== "undefined" && (
                    <>
                      <Row gutter={[16, 16]}>
                        <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                          Username
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                          <Form.Item name="admin_username">
                            <Input
                              style={{
                                width: "100%",
                              }}
                              placeholder="Enter Username"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  {response?.admin_password !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        Password
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="admin_password">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Password"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
            <br />
            <div className="sub-heading-devops-config ms-5">
              Network Interface
            </div>
            <br />
            <div className="configuration-card-body">
              <Row gutter={[16, 16]} className="d-flex justify-content-center">
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  {response?.virtual_network_name !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        Virtual Network
                      </Col>

                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="virtual_network_name">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Virtual Network"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {response?.network_interface_name !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        Virtual Network Interface
                      </Col>

                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="network_interface_name">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Virtual Network Interface"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {response?.subnet_name !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        Subnet Name
                      </Col>

                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="subnet_name">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Subnet Name"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {response?.subnet_address_prefix !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        Subnet Address prefix
                      </Col>

                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="subnet_address_prefix">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Subnet Address prefix"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {response?.public_ip_name !== "undefined" && (
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                        Public IP
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                        <Form.Item name="public_ip_name">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Public IP"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {response?.network_security_group_name !== "undefined" && (
                    <>
                      {" "}
                      <Row gutter={[16, 16]}>
                        <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                          NIC network security group
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                          <Form.Item name="network_security_group_name">
                            <Input
                              style={{
                                width: "100%",
                              }}
                              placeholder="Enter NIC network security group"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </div>
            <br />
            {/* <div className="sub-heading-devops-config ms-5">Tags</div>
          <br />
          <div className="configuration-card-body">
            <Row gutter={[16, 16]} className="d-flex justify-content-center">
              <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                <Form onFinish={handleSubmit} form={form}>
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      Name
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                      <Form.Item name="company_name">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                      Value
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                      <Form.Item name="company_name">
                        <Input
                          style={{
                            width: "100%",
                          }}
                          placeholder="Enter Value"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          htmlType="button"
                          onClick={() => props.setCurrent(props.current - 1)}
                        >
                          Back
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="form-button"
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div> */}
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item>
                  <Button
                    htmlType="button"
                    onClick={() => props.setCurrent(props.current - 1)}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="form-button"
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
      <Modal
        title="Add Tag"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="additional-tag-modal"
        width={800}
      >
        <div className="job-schedule-modal">
          <div className="d-flex justify-content-center">
            <div className="mt-4">
              <Row gutter={[16, 16]} className="d-flex justify-content-center">
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form layout="vertical" onFinish={handleSubmit} form={form}>
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Key">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Key"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Value">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Value"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Resource">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Name"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={24}
                        className="d-flex align-items-center"
                      >
                        <span className="color-red">Remove</span>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Key">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Key"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Value">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Value"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Resource">
                          <Input
                            style={{
                              width: "100%",
                            }}
                            placeholder="Enter Name"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={24}
                        className="d-flex align-items-center"
                      >
                        <span className="color-red">Remove</span>
                      </Col>
                    </Row>
                    <br />
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ConfigureDetails;
