import { Table, Typography } from "antd";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { CgDetailsMore } from "react-icons/cg";
import { Tooltip } from "antd";
import { numberFormat } from "../../custom_hook/CustomHook";
import { Icon } from "@iconify/react";

const { Text } = Typography;

const RealizedTable = (props) => {
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [data, setDate] = useState([
    {
      key: "1",
      date: "02-01-2023",
      account: "Log-archive",
      application: "Ckyc",
      environment: "Production",
      cloud: "AWS",
      services: "AmazonEC2",
      resource_sub_category: "Instance",
      recommendation_type: "Right Sizing",
      resource_id: "i-90567879-0867",
      resource_name: "Ckyc-Production-AmazonEC2",
      current_configuration: "M5.large",
      recommended_configuration: "M5.xlarge",
      commitment: "-",
      potential_savings: "100",
      realized_savings: "12000",
      lost_opportunity: "",
      action: "",
      type: "data",
    },
    {
      key: "2",
      date: "02-06-2023",
      account: "Audit-security",
      application: "Compliance",
      environment: "Production",
      cloud: "AWS",
      services: "AmazonCloudFront",
      resource_sub_category: "Instance",
      recommendation_type: "OS Licensing",
      resource_id: "i-90567879-0867",
      resource_name: "Compliance-Production-AmazonCloudFront",
      current_configuration: "Windows",
      recommended_configuration: "Linux",
      commitment: "-",
      potential_savings: "500",
      realized_savings: "6000",
      lost_opportunity: "",
      action: "",
      type: "data",
    },
    {
      key: "3",
      date: "02-08-2023",
      account: "Fivestar-management",
      application: "Credence",
      environment: "Production",
      cloud: "AWS",
      services: "AmazonS3",
      resource_sub_category: "Instance",
      recommendation_type: "DB Licensing",
      resource_id: "i-90567879-0867",
      resource_name: "Credence-Production-AmazonS3",
      current_configuration: "MSSQL",
      recommended_configuration: "MySql",
      commitment: "-",
      potential_savings: "600",
      realized_savings: "",
      lost_opportunity: "",
      action: "",
      type: "data",
    },
    {
      key: "4",
      date: "02-10-2023",
      account: "Fs-common-services",
      application: "Dedupe",
      environment: "Production",
      cloud: "AWS",
      services: "AmazonRoute53",
      resource_sub_category: "EBS",
      recommendation_type: "Storage Tiering",
      resource_id: "i-90567879-0867",
      resource_name: "Dedupe-Production-AmazonRoute53",
      current_configuration: "SD",
      recommended_configuration: "HDD",
      commitment: "-",
      potential_savings: "50",
      realized_savings: "",
      lost_opportunity: "",
      action: "",
      type: "data",
    },
    {
      key: "5",
      date: "02-11-2023",
      account: "Log-archive",
      application: "Employee portal",
      environment: "Production",
      cloud: "AWS",
      services: "AmazonSES",
      resource_sub_category: "Instance",
      recommendation_type: "Start/Stop",
      resource_id: "i-90567879-0867",
      resource_name: "Employee portal-Production-AmazonSES",
      current_configuration: "24/7",
      recommended_configuration: "8/7",
      commitment: "-",
      potential_savings: "80",
      realized_savings: "720",
      lost_opportunity: "",
      action: "",
      type: "data",
    },
    {
      key: "6",
      date: "02-16-2023",
      account: "Fs-non-prod-business",
      application: "Finnone prod-dr",
      environment: "Production",
      cloud: "AWS",
      services: "AmazonSNS",
      resource_sub_category: "Instance",
      recommendation_type: "Savings Plan",
      resource_id: "i-90567879-0867",
      resource_name: "Finnone prod-dr-Production-AmazonSNS",
      current_configuration: "on-demand",
      recommended_configuration: "5$/Hour",
      commitment: "1 year",
      potential_savings: "95",
      realized_savings: "1000",
      lost_opportunity: "",
      action: "",
      type: "data",
    },
    {
      key: "7",
      date: "02-19-2023",
      account: "Fs-common-services",
      application: "Finnone-1.3",
      environment: "Production",
      cloud: "aws",
      services: "AmazonVPC",
      resource_sub_category: "Instance",
      recommendation_type: "Instance Tiering",
      resource_id: "i-90567879-0867",
      resource_name: "Finnone-1.3-Production-AmazonVPC",
      current_configuration: "on-demand",
      recommended_configuration: "RI",
      commitment: "3 years",
      potential_savings: "300",
      realized_savings: "3600",
      lost_opportunity: "",
      action: "",
      type: "data",
    },
  ]);

  const [columns, setColumns] = useState([]);
  const [recommendationlist, setRecommendationList] = useState([]);

  const tableProps = {};

  const [tablerecords, setTableRecords] = useState([]);
  useEffect(() => {
    var value_list = [];
    var keyvalue = 1;
    const tablelist = props.records.map((data) => {
      if (
        data.realised_savings !== null &&
        Math.round(data.realised_savings) !== 0
      ) {
        const lost_opportunity = data.potential_savings - data.realised_savings;
        value_list.push({
          key: keyvalue,
          date: data.report_month,
          account: data.account_names,
          application: data.applications,
          environment: data.environments,
          services: data.services,
          resource_sub_category: data.resource_sub_category,
          recommendation_type: data.recommendation_rule,
          resource_id: data.resource_id,
          resource_name: data.resource_name,
          current_configuration: data.current_configuration,
          recommended_configuration: data.recommended_configuration,
          commitment: data.commitment,
          potential_savings: Number(data.potential_savings),
          realized_savings: Number(data.realised_savings),
          lost_opportunity: Number(lost_opportunity),
          action: "",
          type: "data",
        });
        keyvalue++;
      }
    });
    //recommendation type
    const recommendation_list = value_list.map((v) => v.recommendation_type);
    const recommendation_set = new Set([...recommendation_list]);
    const recommendation = [...recommendation_set];
    var recom_list = [];
    recommendation.map((value) => {
      recom_list.push({
        text: value,
        value: value,
      });
    });
    setRecommendationList(recom_list);

    const potential = value_list.reduce(
      (a, v) => (a = +a + +v.potential_savings),
      0
    );
    const realised = value_list.reduce(
      (a, v) => (a = +a + +v.realized_savings),
      0
    );
    setTableRecords(value_list);

    setColumns([
      {
        title: "Month",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => {
          const datalength = tablerecords.length - 1;
          if (
            Number(a.key) === Number(datalength) ||
            Number(b.key) === Number(datalength)
          ) {
            return "";
          } else {
            return a.date > b.date ? -1 : 1;
          }
        },
        render: (record) => {
          return record;
          // if(record === 'Total'){
          //   return record;
          // }else{
          //   return `${monthNamesShort[new Date(record).getMonth()]} ${new Date(record).getDate()}`;
          // }
        },
      },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
        sorter: (a, b) => {
          return a.account > b.account ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Application",
        dataIndex: "application",
        key: "application",
        fixed: "left",
        sorter: (a, b) => {
          return a.application > b.application ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Environment",
        dataIndex: "environment",
        key: "environment",
        sorter: (a, b) => {
          return a.environment > b.environment ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      // {
      //   title: 'Cloud',
      //   dataIndex: 'cloud',
      //   key: 'cloud',
      //   fixed: 'left',
      //   sorter: (a, b) => {
      //     const datalength = +data.length + +1;
      //     if(Number(a.key) === Number(datalength) || Number(b.key) === Number(datalength)){
      //       return '';
      //     }else{
      //       return ((a.cloud) > (b.cloud) ? -1 : 1);
      //     }
      //   },
      // },
      {
        title: "Services",
        dataIndex: "services",
        key: "services",
        sorter: (a, b) => {
          return a.services > b.services ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      // {
      //   title: 'Resource Sub Category',
      //   dataIndex: 'resource_sub_category',
      //   key: 'resource_sub_category',
      //   sorter: (a, b) => {
      //       return ((a.resource_sub_category) > (b.account) ? -1 : 1);
      //   },
      //   render: (record, index) => {
      //     if(record.length > 10 ){
      //       return <>
      //                     <Tooltip placement="bottom" title={record}>
      //                       {record.substring(0, 10)}...
      //                   </Tooltip>
      //               </>;
      //     }else{
      //       return record;
      //     }
      //   }
      // },
      {
        title: "Recommendation Type",
        dataIndex: "recommendation_type",
        key: "recommendation_type",
        filters: recom_list,
        onFilter: (value, record) =>
          record.recommendation_type.indexOf(value) === 0,

        sorter: (a, b) => {
          return a.recommendation_type > b.recommendation_type ? -1 : 1;
        },
      },

      // {
      //   title: 'Resource ID',
      //   dataIndex: 'resource_id',
      //   key: 'resource_id',
      //   sorter: (a, b) => {
      //     const datalength = tablerecords.length - 1;
      //     if(Number(a.key) === Number(datalength) || Number(b.key) === Number(datalength)){
      //       return '';
      //     }else{
      //       return ((a.identified) > (b.identified) ? -1 : 1);
      //     }
      //   },

      // },

      {
        title: "Resource Name",
        key: "resource_name",
        dataIndex: "resource_name",
        sorter: (a, b) => {
          return a.resource_name > b.resource_name ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Current Configuration",
        dataIndex: "current_configuration",
        key: "current_configuration",
        sorter: (a, b) => {
          return a.current_configuration > b.current_configuration ? -1 : 1;
        },
        render: (record, index) => {
          if (record.length > 10) {
            return (
              <>
                <Tooltip placement="bottom" title={record}>
                  {record.substring(0, 10)}...
                </Tooltip>
              </>
            );
          } else {
            return record;
          }
        },
      },
      {
        title: "Recommended Configuration",
        dataIndex: "recommended_configuration",
        key: "recommended_configuration",
        sorter: (a, b) => {
          return a.environment > b.environment ? -1 : 1;
        },
      },
      {
        title: "Commitment",
        dataIndex: "commitment",
        key: "commitment",
        sorter: (a, b) => {
          return a.commitment > b.commitment ? -1 : 1;
        },
      },
      // {
      //     title: 'Potential Savings',
      //     dataIndex: 'potential_savings',
      //     key: 'potential_savings',
      //     sorter: (a, b) => {
      //       const datalength = +data.length + +1;
      //       if(Number(a.key) === Number(datalength) || Number(b.key) === Number(datalength)){
      //         return '';
      //       }else{
      //         return ((a.commitment) > (b.commitment) ? -1 : 1);
      //       }
      //     },
      //     render: (record) => {
      //       return (
      //           <>
      //           ${record}
      //           </>
      //       )
      //   }
      // },
      {
        title: "Realized Savings",
        dataIndex: "realized_savings",
        key: "realized_savings",
        sorter: (a, b) => {
          return a.realized_savings > b.realized_savings ? -1 : 1;
        },
        render: (record) => {
          if (record !== "") {
            return <>${numberFormat(record)}</>;
          }
        },
      },
      {
        title: "Lost Opportunity",
        dataIndex: "lost_opportunity",
        key: "lost_opportunity",
        sorter: (a, b) => {
          return a.lost_opportunity > b.lost_opportunity ? -1 : 1;
        },
        render: (record) => {
          if (record !== "") {
            return <>${numberFormat(record)}</>;
          }
        },
      },
      {
        title: " ",
        dataIndex: "action",
        key: "action",
        // fixed: 'right',
        render: (record, index) => {
          var editscreen;
          var addscreen;
          if (index.type === "data") {
            editscreen = (
              <Dropdown.ItemText
                className="plat-dropdown-item-text"
                onClick={() => props.showEditRow(index)}
              >
                <span className="plat-box-item-title list-expand-left">
                  Edit
                </span>
                <span className="plat-box-item-title list-expand-right">
                  <Icon
                    icon="material-symbols:edit-outline"
                    class="table-add-records-icon"
                  />
                </span>
              </Dropdown.ItemText>
            );
            // const datalength = tablerecords.length - 1;
            // if(Number(index.key) === Number(datalength)){
            // addscreen = <Dropdown.ItemText className="plat-dropdown-item-text" onClick={props.showAddRow}>
            //     <span className='plat-box-item-title list-expand-left'>
            //         Add
            //     </span>
            //     <span className='plat-box-item-title list-expand-right'>
            //       <Icon icon="material-symbols:add-box-outline"  class="table-add-records-icon"/>
            //     </span>
            // </Dropdown.ItemText>
            // }

            return (
              <>
                <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
                  <Dropdown.Toggle
                    className="plat-three-dort plat-tap-menu plat-box-three-dort"
                    id="dropdown-button-dark-example1"
                    variant="secondary"
                  >
                    <Icon
                      icon="ph:dots-three-bold"
                      class="plat-expandalt-icon-view"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="plat-box-menu-list more-details">
                    {editscreen}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            );
          }
        },
      },
    ]);
  }, [props]);

  return (
    <>
      <div className="budget-allocated-table">
        <div className="table-right-icon-list">
          <div>
            <Dropdown className="plat-costimize-dropdown">
              <Dropdown.Toggle
                className="plat-three-dort plat-tap-menu plat-box-three-dort"
                id="dropdown-button-dark-example1"
                variant="secondary"
              >
                <Icon
                  icon="file-icons:microsoft-excel"
                  class="budget-table-chart-icon"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="plat-box-menu-list more-details">
                <Dropdown.ItemText className="plat-dropdown-item-text">
                  <span className="plat-box-item-title list-expand-left">
                    Cost Savings
                  </span>
                </Dropdown.ItemText>
                <Dropdown.ItemText className="plat-dropdown-item-text">
                  <span className="plat-box-item-title list-expand-left">
                    Licence Savings
                  </span>
                </Dropdown.ItemText>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <br></br>

        <div className="table-width" style={{ marginTop: "10px" }}>
          <h1 className="plat-table-title-unrealised">Realised</h1>
          <Table
            {...tableProps}
            className="savings_new_table"
            columns={columns}
            dataSource={tablerecords}
            rowClassName={(record, index) =>
              record.type === "footer" ? "savings-table-footer" : ""
            }
            pagination={true}
            summary={(pageData) => {
              const realized_savings = pageData.reduce(
                (a, v) => (a = +a + +v?.realized_savings),
                0
              );
              const lost_opportunity = pageData.reduce(
                (a, v) => (a = +a + +v?.lost_opportunity),
                0
              );
              return (
                <>
                  <Table.Summary.Row className="savings-table-footer">
                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                    <Table.Summary.Cell index={10}>
                      <Text>${numberFormat(realized_savings)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11}>
                      <Text>${numberFormat(lost_opportunity)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
            // footer={() =>
            //   <>
            //     <tr className='ant-table-row ant-table-row-level-0 savings-table-footer'>
            //       <td className='ant-table-cell'>test</td>
            //       <td className='ant-table-cell'></td>
            //       <td className='ant-table-cell'>test</td>
            //     </tr>
            //   </>
            // }
          />
        </div>
      </div>
    </>
  );
};
export default RealizedTable;
