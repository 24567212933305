// in ActionProvider.jsx
import React, { useContext, useState } from "react";
import { smallCase } from "../../custom_hook/CustomHook";
import { useChatContext } from "react-chatbot-kit";
import { createCustomMessage } from "react-chatbot-kit";
import { useDispatch, useSelector } from "react-redux";
import { requestMessage } from "./ChatbotApiCalls";
import Credits from "./MessageTemplates/Credits";
import CreditRequest from "./MessageTemplates/CreditRequest";
import Loading from "./Loading";
import Charts from "./widgets/Charts/Charts";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const dispatch = useDispatch();
  const { cloudList } = useSelector((state) => state.constimize);

  const deleteRecord = () => {
    // children.props.children.props.state.messages.pop();
    // const botMessage = children.props.children.props.state.messages;
    setState((prev) => ({
      ...prev,
      messages: prev.messages.slice(0, -1),
    }));
  };

  const cloudCustomPrompts = (message) => {
    console.log("testing");
  };

  const handleLoader = () => {
    const botMessage = createChatBotMessage(<Loading />);

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleChart = () => {
    const botMessage = createChatBotMessage("Here is the Chart statistics", {
      widget: "Charts",
    });

    // const botMessage = createChatBotMessage("Here is the Table statistics",{
    //   widget:"table",
    // });

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const ChatbotMessage = (message) => {
    deleteRecord();
    const botMessage = createChatBotMessage(message, {
      loading: true,
      terminateLoading: true,
    });
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  function generateTransactionId(length) {
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let transactionId = "";

    for (let i = 0; i < length; i++) {
      let randomIndex = Math.floor(Math.random() * characters.length);
      transactionId += characters.charAt(randomIndex);
    }

    return transactionId;
  }
  const promptsLibrary = () => {
    const messages = createChatBotMessage(
      "Prompts library to open on the left side",
      { widget: "messageParser", withAvatar: true }
    );
    addMessageToBotState(messages);
  };
  const addMessageToBotState = (messages, newState) => {
    if (Array.isArray(messages)) {
      setState((state) => ({
        ...state,
        ...newState,
        messages: [...state.messages, ...messages],
        gist: "",
        infoBox: "",
      }));
    } else {
      setState((state) => ({
        ...state,
        ...newState,
        messages: [...state.messages, messages],
        gist: "",
        infoBox: "",
      }));
    }
  };
  const handleCustom = (message, botName) => {
    if (message === "Prompts Library") {
      promptsLibrary();
    } else {
      handleLoader();
      var cloud_name = "multi-cloud";
      if (cloudList.length === 1) {
        cloud_name = cloudList.toString();
      }
      const input = {
        message: message,
        date_time: `${new Date().getFullYear()}-${
          new Date().getMonth() + 1
        }-${new Date().getDate()}`,
        schema: localStorage.getItem("CognitoIdentityServiceProvider.company"),
        user_name: localStorage.getItem("CognitoIdentityServiceProvider.auth"),
        cloud: cloud_name.toLowerCase(),
        user_session: localStorage.getItem("_token"),
      };
      dispatch(requestMessage(input))
        .unwrap()
        .then(({ data }) => {
          const records = JSON.parse(data.chatbot_chat);
          deleteRecord();
          if (records.remaining_credits <= 0) {
            const botMessage = createChatBotMessage(
              <CreditRequest
                message="We have reached our limit for the day and are unable to accept further messages at the moment."
                total={records.total_credits}
                remaining={0}
                ChatbotMessage={ChatbotMessage}
                handleLoader={handleLoader}
              />,
              {
                botAvatar: true,
                loading: true,
                terminateLoading: true,
              }
            );
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages, botMessage],
            }));
          } else {
            const botMessage = createChatBotMessage(
              <Credits
                message={records.chat}
                total={records.total_credits}
                remaining={records.remaining_credits}
              />,
              {
                botAvatar: true,
                loading: true,
                terminateLoading: true,
              }
            );
            setState((prev) => ({
              ...prev,
              messages: [...prev.messages, botMessage],
            }));
          }
        })
        .catch((err) => {
          console.log(err);
          deleteRecord();
          const message_1 = createChatBotMessage(
            "I'm sorry, I'm unable to comprehend your question. Can you please provide more specific information or ask a different question?"
          );
          addMessageToBotState(message_1);
        });
    }
  };

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleCustom,
            // handleHello,
            promptsLibrary,
            handleLoader,
            handleChart,
            deleteRecord,
            ChatbotMessage,
            cloudCustomPrompts,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
