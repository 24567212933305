import { memo, useEffect, useState } from "react";
import { Modal, Spin, Button, message } from "antd";
import Papa from "papaparse";
import AWS from "aws-sdk";
import { selectFilter } from "react-bootstrap-table2-filter";

AWS.config.update({
  accessKeyId: "AKIAUNMDL3MXCBFVA75Z",
  secretAccessKey: "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",
  region: "ap-south-1",
  signatureVersion: "v4",
});

const TaggingUploadPopup = (props) => {
  const [bucketName, setBucketName] = useState();
  const s3 = new AWS.S3();

  useEffect(() => {
    setBucketName(process.env.TAGGING_BUCKET_NAME);
  }, []);

  const handleCancel = () => {
    props.setUploadModelOpen(false);
  };
  const [csvData, setCsvData] = useState([]);
  const [errorstatus, setErrorStatus] = useState(false);

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result;
      const parsed = Papa.parse(result, { header: true });
      if (Array.isArray(parsed.data)) {
        setErrorStatus(false);
        setCsvData(parsed.data);
      } else {
        setErrorStatus(true);
      }
    };
    reader.readAsText(file);
  };

  const [file, setFile] = useState();
  const handleFileChange = (e) => {
    // console.log(e.target.files[0])
    setFile(e.target.files[0]);
    // console.log(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = {
      Bucket: "platform-update-tag-bucket",
      Key: `platform_update_tag/${file.name}`,
      Body: file,
    };

    // console.log(params);
    const { Location } = await s3.upload(params).promise();
    // console.log('uploading to s3', Location);
    message.success("File uploaded");
    props.setUploadModelOpen(false);
  };

  return (
    <>
      <Modal
        title=""
        open={props.uploadmodelopen}
        onCancel={handleCancel}
        footer={false}
        style={{ textAlign: "left" }}
        width={400}
      >
        {errorstatus && (
          <p className="danger-color">Please provide valid file.</p>
        )}
        <input type="file" id="fileInput" onChange={handleFileChange} />
        <button className="btn tagging-upload-button" onClick={handleSubmit}>
          Upload
        </button>

        {/* <ul>
          {csvData.map((row, i) => (
            <li key={i}>{JSON.stringify(row)}</li>
          ))}
        </ul> */}
      </Modal>
    </>
  );
};

export default memo(TaggingUploadPopup);
