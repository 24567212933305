import { Table } from "antd";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { listPlannedActivity } from "../../action/costimizev2DashboardAction";
import PlannedActivityDrawer from "./PlannedActivityDrawer";
import { Icon } from "@iconify/react";

const PlannedTable = () => {
  const [tablerecords, setTableRecords] = useState(false);
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [updatestatus, setUpdateStatus] = useState(true);
  const [oldrecords, setOldRecords] = useState();
  // const { companyName } = useSelector((state) => state.user);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();

  useEffect(() => {
    const date = selectedTopdate;
    dispatch(listPlannedActivity({ companyName, date }))
      .unwrap()
      .then(({ data }) => {
        const records = data.newfunctionquery18.map((datas) => {
          return {
            key: datas.activity_id,
            date: datas.date,
            sow_status: datas.sow_status,
            project: datas.project,
            project_phase: datas.project_phase,
            priority: datas.priority,
            pending_activity: datas.pending_activity,
            next_target_date: datas.next_target_date,
            accountability: datas.accountability,
            remarks: datas.remarks,
            action: "",
            type: "data",
          };
        });
        setTableRecords(records);
      })
      .catch((err) => {
        console.log(err.message);
      });
    setTableRecords([]);
  }, [updatestatus]);
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => {
        return a.date > b.date ? -1 : 1;
      },
    },
    {
      title: "SOW Status",
      dataIndex: "sow_status",
      key: "sow_status",
      sorter: (a, b) => {
        return a.sow_status > b.sow_status ? -1 : 1;
      },
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      sorter: (a, b) => {
        return a.project > b.project ? -1 : 1;
      },
    },
    {
      title: "Project Phase",
      dataIndex: "project_phase",
      key: "project_phase",
      sorter: (a, b) => {
        return a.project_phase > b.project_phase ? -1 : 1;
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      sorter: (a, b) => {
        return a.priority > b.priority ? -1 : 1;
      },
    },
    {
      title: "Pending Activity",
      dataIndex: "pending_activity",
      key: "pending_activity",
    },
    {
      title: "Next Target Date",
      dataIndex: "next_target_date",
      key: "next_target_date",
      sorter: (a, b) => {
        return a.next_target_date > b.next_target_date ? -1 : 1;
      },
    },
    {
      title: "Accountability",
      dataIndex: "accountability",
      key: "accountability",
      sorter: (a, b) => {
        return a.accountability > b.accountability ? -1 : 1;
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: (
        <>
          <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
            <Dropdown.Toggle
              className="plat-three-dort plat-tap-menu plat-box-three-dort"
              id="dropdown-button-dark-example1"
              variant="secondary"
            >
              <Icon icon="ph:dots-three-bold" class="table-coloum-dots-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="plat-box-menu-list more-details">
              <Dropdown.ItemText
                className="plat-dropdown-item-text"
                onClick={() => addPlannedActivity()}
              >
                <span className="plat-box-item-title list-expand-left">
                  Add
                </span>
                <span className="plat-box-item-title list-expand-right">
                  <Icon
                    icon="material-symbols:add-box-outline"
                    class="table-add-records-icon"
                  />
                </span>
              </Dropdown.ItemText>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ),
      dataIndex: "action",
      key: "action",
      // className: "table-edit-list",
      render: (record, index) => {
        if (index.type === "data") {
          const edit = (
            <Dropdown.ItemText
              className="plat-dropdown-item-text"
              onClick={() => editPlannedActivity(index)}
            >
              <span className="plat-box-item-title list-expand-left">Edit</span>
              <span className="plat-box-item-title list-expand-right">
                <Icon
                  icon="material-symbols:edit-outline"
                  class="table-add-records-icon"
                />
              </span>
            </Dropdown.ItemText>
          );

          return (
            <>
              <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
                <Dropdown.Toggle
                  className="plat-three-dort plat-tap-menu plat-box-three-dort"
                  id="dropdown-button-dark-example1"
                  variant="secondary"
                >
                  <Icon
                    icon="ph:dots-three-bold"
                    class="plat-expandalt-icon-view"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="plat-box-menu-list more-details">
                  {edit}
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        }
      },
    },
  ];

  const tableProps = {};
  const addPlannedActivity = () => {
    setDrawerStatus(true);
    setDeleteStatus(false);
    setOldRecords();
  };
  const editPlannedActivity = (data) => {
    setOldRecords(data);
    setDrawerStatus(true);
    setDeleteStatus(true);
  };
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  return (
    <>
      <PlannedActivityDrawer
        drawerstatus={drawerstatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        setUpdateStatus={setUpdateStatus}
        updatestatus={updatestatus}
        setDrawerStatus={setDrawerStatus}
        oldrecords={oldrecords}
      />

      <div className="budget-allocated-table">
        <h1 className="plat-table-title">Planned & Open Activity</h1>
        <div className="table-width">
          <div className="table-planned-actual">
            <Table
              {...tableProps}
              className="savings_new_table"
              columns={columns}
              dataSource={tablerecords}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PlannedTable;
