import OperationSection1 from "./OperationSection1";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OperationSection2 from "./OperationSection2";
import Header from "../header/Header";


const Operation = () => {
    return (
        <div>
            <Row>
                <Col>
                    <div className="sticky-costimize-menu">
                        <div className="v2-section-1">
                            <OperationSection1 />
                        </div>
                    </div>
                    
                    <div className="v2-section-2">
                        <OperationSection2 />
                    </div>
                </Col>
            </Row>  
        </div>
    )
}

export default Operation;