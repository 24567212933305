import { memo, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Table,
  Popconfirm,
  message,
  Spin,
} from "antd";
import { Icon } from "@iconify/react";
import RoleForm from "./RoleForm";
import RoleDetailsView from "./RoleDetailsView";
import { listAllRole, deletedCustomRole } from "../../action/accountAction";
import { usermanagerUserList } from "../../action/userAction";
import { useDispatch } from "react-redux";
import RoleEditForm from "./RoleEditForm";
import { checkRolePermission } from "../../custom_hook/CustomHook";

const RoleManagement = () => {
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [drawereditstatus, setDrawerEditStatus] = useState(false);

  const [drawerdetailsstatus, setDrawerDetailsStatus] = useState(false);
  const [updatestatus, setUpdateStatus] = useState(false);
  const [tablerecords, setTableRecords] = useState([]);
  const [selectedrecords, setSelectedRecords] = useState();
  const [companylist, setCompanyList] = useState([]);
  const [loaderstatus, setLoaderStatus] = useState(true);

  const [permission, setPermission] = useState({
    rolewrite: checkRolePermission("role_management")
      ? checkRolePermission("role_management").write
      : 0,
  });

  const tableProps = {};
  const dispatch = useDispatch();
  const role_type = localStorage.getItem(
    "CognitoIdentityServiceProvider.offering"
  );

  useEffect(() => {
    var company_name;
    if (role_type.toLocaleLowerCase() === "master") {
      company_name = "all";
    } else {
      company_name = localStorage.getItem(
        "CognitoIdentityServiceProvider.company"
      );
    }
    setLoaderStatus(true);
    dispatch(listAllRole({ company_name }))
      .unwrap()
      .then(({ data }) => {
        setLoaderStatus(false);
        const records = JSON.parse(data.usermanagementallroles);
        console.log(records);

        setTableRecords(records.data);
      })
      .catch((err) => {
        setLoaderStatus(false);

        console.log(err.message);
      });
  }, [updatestatus]);

  useEffect(() => {
    dispatch(usermanagerUserList())
      .unwrap()
      .then(({ data }) => {
        if (data.listPlatformUsers) {
          let listUserConfigs = data.listPlatformUsers.items.map((v) => ({
            ...v,
            checked: false,
          }));

          const reducerObject = listUserConfigs.reduce((acc, curr) => {
            const company_name = curr["company_name"].toLowerCase();
            if (acc[company_name]) {
              acc[company_name].count += 1;
            } else {
              acc[company_name] = {
                ...curr,
                count: 1,
              };
            }
            return acc;
          }, {});
          const company_list = Object.keys(reducerObject);
          setCompanyList(company_list);
        }
      })
      .catch((err) => {
        // setError(err);
      });
  }, []);

  function deleteConfirm(records) {
    const input = {
      customer: records.customer,
      role_id: records.role_id,
      user_id: localStorage.getItem("CognitoIdentityServiceProvider.auth"),
    };
    console.log(input);
    dispatch(deletedCustomRole({ input }))
      .unwrap()
      .then(({ data }) => {
        setUpdateStatus(!updatestatus);
        message.success("Deleted");
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const columns = [
    {
      title: "Role Name",
      dataIndex: "role_name",
      key: "role_name",
      sorter: (a, b) => {
        return a.role_name > b.role_name ? -1 : 1;
      },
      align: "center",
      render: (record) => {
        return record;
      },
    },
    {
      title: "offering",
      key: "offering",
      dataIndex: "offering",
      align: "center",
      sorter: (a, b) => {
        return a.offering > b.offering ? -1 : 1;
      },
      render: (text, record) => (
        <Button className="status-button">{record.offering}</Button>
      ),
    },
    {
      title: "Role Category",
      dataIndex: "role_category",
      key: "role_category",
      align: "center",
      sorter: (a, b) => {
        return a.role_category > b.role_category ? -1 : 1;
      },
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      align: "center",
      render: (record, index) => {
        // console.log(index);
        var editlist;
        if (
          index.customer !== "General" ||
          localStorage
            .getItem("CognitoIdentityServiceProvider.offering")
            .toLocaleLowerCase() === "master"
        ) {
          editlist = (
            <>
              <Icon
                icon="material-symbols:edit"
                className="role-table-icon"
                onClick={() => {
                  setDrawerEditStatus(true);
                  setSelectedRecords(index);
                }}
              />
            </>
          );
        } else {
          editlist = (
            <>
              <Icon icon="material-symbols:edit" className="role-table-icon" />
            </>
          );
        }
        var permissionlist = "";
        if (permission.rolewrite) {
          permissionlist = (
            <>
              {editlist}
              {index.customer !== "General" ? (
                <Popconfirm
                  title="Are you sure delete this role?"
                  onConfirm={() => deleteConfirm(index)}
                  okText="Yes"
                  cancelText="No"
                  placement="bottomRight"
                >
                  <Icon
                    icon="material-symbols:delete"
                    className={
                      "role-table-icon " +
                      (index.customer.toLocaleLowerCase() !== "General"
                        ? "text-danger"
                        : "")
                    }
                  />
                </Popconfirm>
              ) : (
                <Icon
                  icon="material-symbols:delete"
                  className={"role-table-icon "}
                />
              )}
            </>
          );
        }
        return (
          <>
            <div style={{ cursor: "pointer" }}>
              <Icon
                icon="mdi:eye"
                className="role-table-icon"
                onClick={() => {
                  setDrawerDetailsStatus(true);
                  setSelectedRecords(index);
                }}
              />
              {permissionlist}
            </div>
          </>
        );
      },
    },
  ];

  // const tablerecords = [
  //   {
  //     role_name: "admin",
  //     offering: "costimize",
  //     role_category: "default",
  //   },
  // ];
  return (
    <>
      {drawerstatus && (
        <RoleForm
          drawerstatus={drawerstatus}
          setDrawerStatus={setDrawerStatus}
          formType="add"
          company_list={companylist}
          updatestats={updatestatus}
          setUpdateStatus={setUpdateStatus}
        />
      )}
      {drawereditstatus && (
        <RoleEditForm
          setDrawerEditStatus={setDrawerEditStatus}
          drawereditstatus={drawereditstatus}
          selectedrecords={selectedrecords}
          updatestats={updatestatus}
          setUpdateStatus={setUpdateStatus}
        />
      )}
      {drawerdetailsstatus && (
        <RoleDetailsView
          drawerdetailsstatus={drawerdetailsstatus}
          setDrawerDetailsStatus={setDrawerDetailsStatus}
          selectedrecords={selectedrecords}
        />
      )}

      <div className="role-body">
        <Row>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <h1 className="role-management-title">Role Management</h1>
          </Col>
        </Row>
        {permission.rolewrite ? (
          <Row>
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Button
                htmlType="submit"
                className="float-right"
                onClick={() => setDrawerStatus(true)}
              >
                <Icon icon="ion:add-circle-outline" /> Add
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row className="mt-2">
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <Spin spinning={loaderstatus}>
              <Table
                {...tableProps}
                className="role-management-table"
                columns={columns}
                dataSource={tablerecords}
                pagination={true}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(RoleManagement);
