import { Table, Button } from "antd";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ProjectStatus from "./ProjectStatus";
import { useDispatch, useSelector } from "react-redux";
import { listProjectStatus } from "../../action/costimizev2DashboardAction";
import { Icon } from "@iconify/react";

const ProjectStatusTable = ({ setDaysDiff }) => {
  const [tablerecords, setTableRecords] = useState([]);
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [updatestatus, setUpdateStatus] = useState(true);
  const [oldrecords, setOldRecords] = useState();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();

  useEffect(() => {
    const date = selectedTopdate;

    dispatch(listProjectStatus({ companyName, date }))
      .unwrap()
      .then(({ data }) => {
        const records = data.newfunctionquery21.map((datas) => {
          // const startDate = new Date(datas.start_date);
          // const targetDate = new Date(datas.project_target_date);
          // const timeDiff = Math.abs(targetDate.getTime() - startDate.getTime());
          // const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
          // //setDaysDiff(prevDaysDiff => prevDaysDiff.concat(daysDiff));
          // const daysDiffObj = {
          //     days_remaining: daysDiff,
          //     project_target_date: datas.project_target_date,
          //     start_date: datas.start_date,
          // };

          // setDaysDiff(prevDaysDiff => prevDaysDiff.concat(daysDiffObj));
          // console.log(daysDiff)

          return {
            company: datas.company,
            next_milestone: datas.next_milestone,
            progress: datas.progress,
            project_name: datas.project_name,
            project_phase: datas.project_phase,
            project_target_date: datas.project_target_date,
            report_date: datas.report_date,
            start_date: datas.start_date,
            status: datas.status,
            status_id: datas.status_id,
            action: "",
            type: "data",
            // days_remaining: daysDiff
          };
        });
        setTableRecords(records);
        setDaysDiff(records);
      })
      .catch((err) => {
        console.log(err.message);
      });
    setTableRecords([]);
  }, [updatestatus, selectedTopdate]);

  // useEffect(() => {
  //     const date = selectedTopdate;

  //     dispatch(listProjectStatus({ companyName, date }))
  //         .unwrap()
  //         .then(({ data }) => {
  //             console.log("data");

  //             console.log(data);
  //             const records = data.newfunctionquery21.map((datas) => {
  //                 return {

  //                     company: datas.company,
  //                     next_milestone: datas.next_milestone,
  //                     progress: datas.progress,
  //                     project_name: datas.project_name,
  //                     project_phase: datas.project_phase,
  //                     project_target_date: datas.project_target_date,
  //                     report_date: datas.report_date,
  //                     start_date: datas.start_date,
  //                     status: datas.status,
  //                     status_id: datas.status_id,
  //                     action: '',
  //                     type: 'data'
  //                 }

  //             });
  //             setTableRecords(records);

  //         })
  //         .catch(err => {
  //             console.log(err.message);

  //         });
  //     setTableRecords([]);
  // }, [updatestatus, selectedTopdate]);
  const columns = [
    {
      title: "Date",
      dataIndex: "report_date",
      key: "report_date",
      className: "plat-table-date",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      },
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
      sorter: (a, b) => {
        return a.project_name > b.project_name ? -1 : 1;
      },
    },
    {
      title: "Project Phase",
      dataIndex: "project_phase",
      key: "project_phase",
      sorter: (a, b) => {
        return a.project_phase > b.project_phase ? -1 : 1;
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      },
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Project Target Date",
      dataIndex: "project_target_date",
      key: "project_target_date",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      },
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => {
        return a.status > b.status ? -1 : 1;
      },
    },
    {
      title: "Next Milestone",
      dataIndex: "next_milestone",
      key: "next_milestone",
    },

    {
      title: (
        <Dropdown className="plat-costimize-dropdown">
          <Dropdown.Toggle
            className="plat-three-dort plat-tap-menu plat-box-three-dort"
            id="dropdown-button-dark-example1"
            variant="secondary"
          >
            <Icon icon="bi:filetype-csv" class="table-coloum-chart-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="plat-box-menu-list more-details">
            <Dropdown.ItemText className="plat-dropdown-item-text">
              <span className="plat-box-item-title list-expand-left">
                IMPORT TEMPLATE
              </span>
            </Dropdown.ItemText>
            <Dropdown.ItemText className="plat-dropdown-item-text">
              <span className="plat-box-item-title list-expand-left">
                DOWNLOAD TEMPLATE
              </span>
            </Dropdown.ItemText>
          </Dropdown.Menu>
        </Dropdown>
      ),
      dataIndex: "action",
      key: "action",
      // className: "table-edit-list",
      render: (record, index) => {
        if (index.type === "data") {
          const edit = (
            <Dropdown.ItemText
              className="plat-dropdown-item-text"
              onClick={() => showEditRow(index)}
            >
              <span className="plat-box-item-title list-expand-left">Edit</span>
              <span className="plat-box-item-title list-expand-right">
                <Icon
                  icon="material-symbols:edit-outline"
                  class="table-add-records-icon"
                />
              </span>
            </Dropdown.ItemText>
          );

          var addicon = "";
          return (
            <>
              <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
                <Dropdown.Toggle
                  className="plat-three-dort plat-tap-menu plat-box-three-dort"
                  id="dropdown-button-dark-example1"
                  variant="secondary"
                >
                  <Icon
                    icon="ph:dots-three-bold"
                    class="plat-expandalt-icon-view"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="plat-box-menu-list more-details">
                  {edit}
                  {addicon}
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        }
      },
    },
  ];
  const tableProps = {};
  const showAddRow = () => {
    setDrawerStatus(true);
    setDeleteStatus(false);
    setOldRecords();
  };

  const showEditRow = (data) => {
    setDrawerStatus(true);
    setDeleteStatus(true);
    setOldRecords(data);
  };
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  return (
    <>
      <ProjectStatus
        drawerstatus={drawerstatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        setUpdateStatus={setUpdateStatus}
        updatestatus={updatestatus}
        setDrawerStatus={setDrawerStatus}
        oldrecords={oldrecords}
      />
      <div className="budget-allocated-table">
        <h1 className="plat-table-title">Project Status</h1>
        <Button
          type="primary"
          onClick={() => showAddRow()}
          htmlType="submit"
          style={{ background: "#fff", color: "#1c344c", float: "right" }}
        >
          <span>Add</span>
        </Button>

        <div className="table-width">
          <div className="table-planned-actual">
            <Table
              {...tableProps}
              className="savings_new_table"
              columns={columns}
              dataSource={tablerecords}
              pagination={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectStatusTable;
