import React,{ useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { listRecommendation } from "../../action/costimizev2DashboardAction";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { Spin } from 'antd';


import { smallCase } from "../../custom_hook/CustomHook";

const SavingsPieChart = () => {
      // const { companyName } = useSelector((state) => state.user);
      const companyName = localStorage.getItem("CognitoIdentityServiceProvider.company")
      const {   selectedTopdate, selectedEnvironment, selectedAccount, selectedCloud, selectedApplication,  selectedservices, selectedos, selectedinfra,selecteddb } = useSelector((state) => state.constimize);
      const dispatch = useDispatch();
      const [preloader, setPreloader] = useState(true);
      const [piechart, setPieChart] = useState({
        options: [],
        series: []
      })

      useEffect(() => {
        var apivalue = {
          environment : selectedEnvironment.toString(),
          account: selectedAccount.toString(),
          cloud: selectedCloud.toString(),
          application: selectedApplication.toString(),
          services: selectedservices.toString(),
          os: selectedos.toString(),
          infra: selectedinfra.toString(),
          db: selecteddb.toString(),
          date: selectedTopdate
      }
        dispatch(listRecommendation({companyName, apivalue }))
        .unwrap()
        .then(({data}) => {
          // console.log(data.newfunctionquery10);
          if(data.newfunctionquery10.length > 0){
            const recommendation_list = data.newfunctionquery10.map(v => v.recommendation_rule);
            const recommendation_set = new Set([...recommendation_list]);
            const recommendation = [...recommendation_set];
            var recommendation_count = [];
            recommendation.map((recom_data) => {
              let list = data.newfunctionquery10?.filter((v, index) => v.recommendation_rule === recom_data);
              // console.log(list);
              recommendation_count.push(list.length);
            });
            const newlist = recommendation.map((recom_data) => smallCase(recom_data))
            // console.log("capitalizeFirst");
            // console.log(newlist);
            setPieChart({
              options: newlist,
              series: recommendation_count
            })
          }
          setPreloader(false);
        })
        .catch(err => {
        });
      },[companyName, selectedEnvironment, selectedAccount, selectedCloud, selectedApplication, selectedservices, selectedos, selectedinfra, selecteddb, selectedTopdate]);


      const options = {
        chart: {
          type: 'pie',
          background: '#fff',
          // height: "300px"
        },
        title: {
            text: '',
            align: 'center',
            margin: 0,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize:  '14px',
              fontWeight:  'bold',
              color:  '#595959'
            },
        },
        colors:['#355c75','#1e75ac','#edab63','#c1e455','#d546b6','#aadcef'],
        legend: {
          show: true,
          offsetY: 0,
          position: 'bottom'
        },
        dataLabels: {
          enabled: true,
          // distributed: false,
          dropShadow: {
            enabled: false,
          },
          style: {
            fontSize: '9px'
        },
          formatter: function (val) {
            return Math.round(val) + "%"
          }
        },
        labels: piechart.options,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      };
      
      const series = piechart.series;
      
    return(
        <>
         <Spin tip="Loading..." spinning={preloader} className="plat-spinning">
          <ReactApexChart options={options} series={series} type="pie" />
         </Spin>
        </>
    )
}

export default React.memo(SavingsPieChart);