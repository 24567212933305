import { Table, Button } from "antd";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import ActionItems from "./ActionItems";
import { listActionItem } from "../../action/costimizev2DashboardAction";
import { Icon } from "@iconify/react";

const ActionTable = ({ setCategoryCount }) => {
  const [tablerecords, setTableRecords] = useState([]);
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [oldrecords, setOldRecords] = useState();
  const [updatestatus, setUpdateStatus] = useState(true);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();

  useEffect(() => {
    const date = selectedTopdate;

    dispatch(listActionItem({ companyName, date }))
      .unwrap()
      .then(({ data }) => {
        const records = data.newfunctionquery20.map((datas) => {
          return {
            action_item_id: datas.action_item_id,
            category: datas.category,
            company: datas.company,
            date: datas.date,
            month: datas.month,
            owner: datas.owner,
            points_discussed: datas.points_discussed,
            remarks: datas.remarks,
            status: datas.status,
            action: "",
            type: "data",
          };
        });
        setTableRecords(records);
        setCategoryCount(records);
      })
      .catch((err) => {
        console.log(err.message);
      });
    setTableRecords([]);
  }, [updatestatus, selectedTopdate]);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      className: "plat-table-date",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      },
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return <span>{formattedDate}</span>;
      },
    },

    {
      title: "Points Discussed",
      dataIndex: "points_discussed",
      key: "points_discussed",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      sorter: (a, b) => {
        return a.owner > b.owner ? -1 : 1;
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => {
        return a.status > b.status ? -1 : 1;
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => {
        return a.category > b.category ? -1 : 1;
      },
    },
    {
      title: (
        <Dropdown className="plat-costimize-dropdown">
          <Dropdown.Toggle
            className="plat-three-dort plat-tap-menu plat-box-three-dort"
            id="dropdown-button-dark-example1"
            variant="secondary"
          >
            <Icon icon="bi:filetype-csv" class="table-coloum-chart-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="plat-box-menu-list more-details">
            <Dropdown.ItemText className="plat-dropdown-item-text">
              <span className="plat-box-item-title list-expand-left">
                IMPORT TEMPLATE
              </span>
            </Dropdown.ItemText>
            <Dropdown.ItemText className="plat-dropdown-item-text">
              <span className="plat-box-item-title list-expand-left">
                DOWNLOAD TEMPLATE
              </span>
            </Dropdown.ItemText>
          </Dropdown.Menu>
        </Dropdown>
      ),
      dataIndex: "action",
      key: "action",
      // className: "table-edit-list",
      render: (record, index) => {
        if (index.type === "data") {
          const edit = (
            <Dropdown.ItemText
              className="plat-dropdown-item-text"
              onClick={() => showEditRow(index)}
            >
              <span className="plat-box-item-title list-expand-left">Edit</span>
              <span className="plat-box-item-title list-expand-right">
                <Icon
                  icon="material-symbols:edit-outline"
                  class="table-add-records-icon"
                />
              </span>
            </Dropdown.ItemText>
          );

          var addicon = "";
          return (
            <>
              <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
                <Dropdown.Toggle
                  className="plat-three-dort plat-tap-menu plat-box-three-dort"
                  id="dropdown-button-dark-example1"
                  variant="secondary"
                >
                  <Icon
                    icon="ph:dots-three-bold"
                    class="plat-expandalt-icon-view"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="plat-box-menu-list more-details">
                  {edit}
                  {addicon}
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        }
      },
    },
  ];
  const tableProps = {};
  const showAddRow = () => {
    setDrawerStatus(true);
    setDeleteStatus(false);
    setOldRecords(null);
  };

  const showEditRow = (data) => {
    setDrawerStatus(true);
    setDeleteStatus(true);
    setOldRecords(data);
  };

  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  return (
    <>
      <ActionItems
        drawerstatus={drawerstatus}
        changeDrawerStatus={changeDrawerStatus}
        deletestatus={deletestatus}
        setUpdateStatus={setUpdateStatus}
        updatestatus={updatestatus}
        setDrawerStatus={setDrawerStatus}
        oldrecords={oldrecords}
      />
      <div className="budget-allocated-table">
        <h1 className="plat-table-title">Action Items</h1>

        <Button
          type="primary"
          onClick={() => showAddRow()}
          htmlType="submit"
          style={{ background: "#fff", color: "#1c344c", float: "right" }}
        >
          <span>Add</span>{" "}
        </Button>

        <div className="table-width">
          <div className="table-planned-actual">
            <Table
              {...tableProps}
              className="savings_new_table"
              columns={columns}
              pagination={true}
              dataSource={tablerecords}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ActionTable;
